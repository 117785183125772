import React from 'react';
import Layout from '../components/layout.component';
import styled from 'styled-components';
import { graphql, useStaticQuery, Link } from 'gatsby';
import SEO from '../components/seo';

const ExamPreps = () => {
    const data = useStaticQuery(query);
    const {
        allStrapiExamPrepPage: { nodes: examPrep },
    } = data;

    const {
        heading,
        subheading,
        one_to_one_coaching_info,
        personal_trainer_info,
        prep_institute,
        why_are_we_best,
        one_to_one_coaching_img,
        personal_trainer_img,
    } = examPrep[0];

    return (
        <Layout>
            <SEO
                title="IELTS, GRE, TOEFL, OET and PTE Exam Preparation | Abroad Ninja"
                description="Prepare and crack IELTS, PTE, OET, GRE, and TOEFL examinations with high scores with Abroad Ninja, and get enrolled in your dream university."
                og_type="website"
                og_url="https://abroadninja.in/exam-preps/"
                og_title="IELTS, GRE, TOEFL, OET and PTE Exam Preparation | Abroad Ninja"
                og_description="Prepare and crack IELTS, PTE, OET, GRE, and TOEFL examinations with high scores with Abroad Ninja, and get enrolled in your dream university."
                og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
                twitter_card="summary_large_image"
                twitter_url="https://abroadninja.in/exam-preps/"
                twitter_title="IELTS, GRE, TOEFL, OET and PTE Exam Preparation | Abroad Ninja"
                twitter_description="Prepare and crack IELTS, PTE, OET, GRE, and TOEFL examinations with high scores with Abroad Ninja, and get enrolled in your dream university."
                twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
                robots="index, follow"
                canonical="https://abroadninja.in/exam-preps/"
            />
            <ExamPreparationStyled id="ExamPreparation">
                <ExamPreparationWrapperStyled id="ExamPreprationWrapper">
                    <div id="ExamPrepHeadingSubHeading">
                        <h1>{heading}</h1>
                        <p className="ExamPrepSubHeading">{subheading}</p>
                    </div>
                    <SecondSectionImageWrapperStyle id="SecondSectionImageWrapper">
                        <div className="PersonalTrainer">
                            <img
                                src={personal_trainer_img.url}
                                loading="lazy"
                                alt="Personal Trainer Example Image"
                            />
                            <p>{personal_trainer_info}</p>
                        </div>
                        <div className="OneToOneCoaching">
                            <img
                                src={one_to_one_coaching_img.url}
                                alt="One to One coaching Example Image"
                                loading="lazy"
                            />
                            <p>{one_to_one_coaching_info}</p>
                        </div>
                    </SecondSectionImageWrapperStyle>
                    <WhyAreWeBestStyled id="WhyAreWeBest">
                        <h2>Why are we best </h2>
                        <div className="WhyAreWeBestPoints">
                            {why_are_we_best.map((best) => (
                                <div
                                    className="WhyAreWeBestCircleCard"
                                    key={best.id}
                                >
                                    <div className="WhyAreWeBestCircleImageWrapper">
                                        <img
                                            src={best.counselling_img.url}
                                            alt={best.counselling_img.name}
                                        />
                                    </div>
                                    <small>{best.counselling_details}</small>
                                </div>
                            ))}
                        </div>
                    </WhyAreWeBestStyled>
                    <IeltsNinjaProgramsStyled id="ieltsNinjaPrograms">
                        <h3>
                            Our programs are offered by IELTS Ninja, India’s
                            biggest test prep institute
                        </h3>
                        <div id="ieltsNinjaProgramCardsWrapper">
                            {prep_institute.map((institute) => (
                                <div
                                    className="ieltsNinjProgramsCard"
                                    key={institute.id}
                                >
                                    <div className="ieltsNinjProgramsCardImageWrapper">
                                        <img
                                            src={institute.img.url}
                                            alt={institute.img.name}
                                        />
                                    </div>
                                    <small className="ieltsNinjaProgramName">
                                        {institute.heading}
                                    </small>
                                    <p className="ieltsNinjaProgramDesc">
                                        {institute.subheading}
                                    </p>
                                    <Link
                                        to={institute.redirect_to}
                                        className="viewCoursesLink"
                                    >
                                        {institute.button_name}
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </IeltsNinjaProgramsStyled>
                </ExamPreparationWrapperStyled>
            </ExamPreparationStyled>
        </Layout>
    );
};

const ExamPreparationStyled = styled.section`
    padding: 10rem 0.75rem;
`;
const ExamPreparationWrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 900;
        font-size: 3rem;
        line-height: 58px;
        text-align: center;
        color: #113a68;
        margin-bottom: 1rem;
    }
    .ExamPrepSubHeading {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 29px;
        text-align: center;
        color: #396eb0;
    }
    #ExamPrepHeadingSubHeading {
        margin-bottom: 5.5rem;
    }

    @media (max-width: 500px) {
        #ExamPrepHeadingSubHeading {
            width: 100%;
            margin-bottom: 3rem;
        }
        h1 {
            font-weight: 900;
            font-size: 1.5rem;
            line-height: 29px;
            margin-bottom: 0.5rem;
        }
        .ExamPrepSubHeading {
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 17px;
        }
    }
`;

const SecondSectionImageWrapperStyle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11.875rem;
    margin-bottom: 7.5rem;
    .PersonalTrainer,
    .OneToOneCoaching {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .PersonalTrainer > p {
        max-width: 215px;
    }
    .OneToOneCoaching > p {
        max-width: 402px;
    }
    .PersonalTrainer > p,
    .OneToOneCoaching > p {
        margin-top: 1.5rem;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 24px;
        text-align: center;
        color: #505050;
    }

    @media (max-width: 1050px) {
        gap: 4rem;
    }
    @media (max-width: 760px) {
        flex-direction: column;
    }
    @media (max-width: 500px) {
        .PersonalTrainer > p,
        .OneToOneCoaching > {
            font-weight: 400;
            font-size: 1rem;
            line-height: 24px;
            max-width: 100%;
            text-align: center;
        }
        .OneToOneCoaching > img {
            height: 176px;
            max-width: 301px;
        }
    }

    @media (max-width: 330px) {
        .OneToOneCoaching > img,
        .PersonalTrainer > img {
            height: 150px;
            width: 270px;
        }
    }

    @media (max-width: 300px) {
        .OneToOneCoaching > img,
        .PersonalTrainer > img {
            height: 120px;
            width: 240px;
        }
    }
`;

const WhyAreWeBestStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h2 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 900;
        font-size: 3rem;
        line-height: 58px;
        text-align: center;
        color: #113a68;
        margin-bottom: 3rem;
    }
    .WhyAreWeBestPoints {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        width: fit-content;
        gap: 7.5rem;
        margin-bottom: 7.5rem;
    }

    .WhyAreWeBestCircleImageWrapper {
        min-height: 4.5rem;
        max-height: 4.5rem;
        min-width: 4.5rem;
        max-width: 4.5rem;
        border-radius: 50%;
        background: #fff0eb;
        display: grid;
        place-items: center;
        margin-bottom: 0.75rem;
    }
    .WhyAreWeBestCircleCard {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    .WhyAreWeBestCircleCard > small {
        max-width: 10rem;
        font-weight: 400;
        font-size: 1.25rem;
        line-height: 24px;
        text-align: center;
        color: #396eb0;
    }

    @media (max-width: 1050px) {
        gap: 4.5rem;
    }
    @media (max-width: 900px) {
        gap: 2rem;
        .WhyAreWeBestPoints {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (max-width: 500px) {
        width: 100%;
        h2 {
            font-weight: 900;
            font-size: 1.75rem;
            line-height: 34px;
            text-align: center;
        }
        .WhyAreWeBestPoints {
            gap: 3rem 1rem;
        }
        .WhyAreWeBestCircleCard > small {
            font-weight: 400;
            font-size: 1rem;
            line-height: 19px;
            text-align: center;
        }
    }

    @media (max-width: 330px) {
        .WhyAreWeBestPoints {
            grid-template-columns: 1fr;
            gap: 1rem;
        }
    }
`;

const IeltsNinjaProgramsStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3 {
        font-weight: 900;
        font-size: 3rem;
        line-height: 58px;
        text-align: center;
        color: #113a68;
        margin-bottom: 3rem;
        max-width: 61rem;
    }
    #ieltsNinjaProgramCardsWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
    }
    .ieltsNinjProgramsCard {
        display: grid;
        background: #ffffff;
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        grid-template-columns: repeat(3, auto);
        padding: 2.5rem;
        width: 100%;
        align-items: center;
        align-content: center;
        justify-content: center;
        min-height: 182px;
        max-height: 182px;
    }
    .ieltsNinjProgramsCardImageWrapper {
        grid-row: 1 / 3;
        display: flex;
        align-items: center;
        margin-right: 3rem;
    }
    .ieltsNinjProgramsCardImageWrapper img {
        height: 96px;
        width: 96px;
    }
    .viewCoursesLink {
        font-weight: 500;
        font-size: 1rem;
        line-height: 19px;
        text-align: center;
        color: #396eb0;
        text-decoration: none;
        border: 1px solid #396eb0;
        border-radius: 8px;
        padding: 0.90625rem 1.875rem;
        grid-column: 3;
        grid-row: 1 / 3;
        margin-left: 6rem;
    }
    .viewCoursesLink:hover {
        background-color: #396eb0;
        color: white;
    }
    .ieltsNinjaProgramName {
        font-weight: 700;
        font-size: 1.75rem;
        line-height: 34px;
        color: #113a68;
        margin-bottom: 0.5rem;
    }
    .ieltsNinjaProgramDesc {
        font-weight: 300;
        font-size: 1rem;
        line-height: 19px;
        color: #396eb0;
        max-width: 531px;
        grid-column: 2;
    }
    @media (max-width: 1080px) {
        width: 100%;

        #ieltsNinjaProgramCardsWrapper {
            width: 100%;
        }
    }

    @media (max-width: 900px) {
        .ieltsNinjProgramsCardImageWrapper {
            margin-right: 1.5rem;
        }

        .ieltsNinjProgramsCard {
            padding: 1rem;
        }
    }
    @media (max-width: 700px) {
        .ieltsNinjProgramsCardImageWrapper {
            display: none;
        }
        .ieltsNinjaProgramDesc {
            grid-column: 1;
        }
    }

    @media (max-width: 760px) {
        .viewCoursesLink {
            margin-left: 1rem;
        }
    }

    @media (max-width: 600px) {
        .viewCoursesLink {
            margin-top: 1rem;
            margin-left: 0rem;
        }
    }

    @media (max-width: 600px) {
        .ieltsNinjProgramsCard {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            min-height: 220px;
            max-height: 220px;
        }
    }
    @media (max-width: 500px) {
        h3 {
            font-weight: 900;
            font-size: 1.75rem;
            line-height: 34px;
            text-align: center;
        }
        .ieltsNinjaProgramName {
            font-weight: 900;
            font-size: 1.25rem;
            line-height: 24px;
        }
        .ieltsNinjaProgramDesc {
            font-weight: 300;
            font-size: 0.875rem;
            line-height: 17px;
        }
        .ieltsNinjProgramsCard {
            justify-content: flex-start;
            min-height: 100%;
            max-height: 100%;
        }
    }
`;
export const query = graphql`
    {
        allStrapiExamPrepPage {
            nodes {
                heading
                subheading
                personal_trainer_info
                one_to_one_coaching_info
                one_to_one_coaching_img {
                    url
                    alternativeText
                }
                personal_trainer_img {
                    url
                    alternativeText
                }
                why_are_we_best {
                    counselling_img {
                        url
                        name
                    }
                    id
                    counselling_details
                }
                prep_institute {
                    img {
                        url
                        name
                    }
                    id
                    heading
                    subheading
                    button_name
                    redirect_to
                }
            }
        }
    }
`;
export default ExamPreps;
