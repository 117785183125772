import React from 'react';
import { useCallback, useState } from 'react';
import './style.css';

const Accordion = ({ data }) => {
    const Accordion = ({ section, key }) => {
        const { isOpen, toggle } = useOpenController(false);

        return (
            <div className="accordion-container">
                <ExpendableColumn
                    question={section.question}
                    isOpen={isOpen}
                    toggle={toggle}
                />
                {isOpen && <TextSection text={section.answer} />}
            </div>
        );
    };

    const ExpendableColumn = ({ question, isOpen, toggle }) => {
        return (
            <div className="column-container" onClick={toggle}>
                <div className="column-text">{question}</div>
                <button className="expendable-button">
                    <img
                        src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/3f3e8004fe7535770a8978a8d5b5d946.svg"
                        style={{
                            transform: `rotate(${isOpen ? 180 : 0}deg)`,
                            transition: 'all 0.25s',
                        }}
                    />
                </button>
            </div>
        );
    };

    const TextSection = ({ text }) => {
        return (
            <div
                dangerouslySetInnerHTML={{ __html: text }}
                className="text-container"
            />
        );
    };

    function useOpenController(initialState) {
        const [isOpen, setOpenState] = useState(initialState);

        const toggle = useCallback(() => {
            setOpenState((state) => !state);
        }, [setOpenState]);

        return { isOpen, toggle };
    }

    return (
        <div>
            {data.map((section, index) => (
                <Accordion key={index} section={section} />
            ))}
        </div>
    );
};

export default Accordion;
