import React, { useState, useEffect } from "react";
import Layout from "../components/layout.component";
import styled from "styled-components";
import SEO from "../components/seo";
import { Link } from "gatsby";
const isBrowser = typeof window !== "undefined";
const PrScore = () => {
	let pr_score;
	if (isBrowser) {
		pr_score = localStorage.getItem("pr_score");
		pr_score = JSON.parse(pr_score);
	}

	if (pr_score === undefined || pr_score == null) {
		return (
			<>
				Please Calculate your score <Link to={"/pr-eligibility-calculator"}>here</Link>
			</>
		);
	}
	return (
		<Layout>
			<SEO title={"Pr Score Page"} />
			<MainScorePage>
				<MainScorePageWrapper>
					<HeadingStyle>PR Eligibility Report</HeadingStyle>
					<SubHeading className="subheading">Hey, {pr_score.name}</SubHeading>
					{pr_score?.finalScore > 400 ? (
						<SubHeading className="subheading" color="#F4805E">
							Congratulation you’re eligible for Canada PR express entry and multiple PNP
						</SubHeading>
					) : (
						<SubHeading className="subheading" color="#F4805E">
							Currently you’re not eligible for Canada PR express entry and multiple PNP
						</SubHeading>
					)}

					<SubHeading className="subheading" color="#396EB0">
						Your Projected CRS score
					</SubHeading>
					<SubHeading className="subheading" color="#F4805E" fontWeight="700" fontSize="2rem">
						{pr_score?.finalScore}
					</SubHeading>
					{pr_score?.finalScore > 400 ? (
						<SubHeading className="subheading" color="#396EB0" fontSize="2rem" maxWidth="40rem">
							You have a <strong>HIGH</strong> chance for getting a PR
						</SubHeading>
					) : (
						<SubHeading className="subheading" color="#396EB0" fontSize="2rem" maxWidth="40rem">
							However,we can work on your profile which can increase your chances of getting the PR
						</SubHeading>
					)}

					<SubHeading className="subheading" color="#396EB0">
						Your required IELTS Band
					</SubHeading>

					<SubHeading className="subheading" color="#F4805E" fontWeight="700" fontSize="2rem">
						{pr_score?.ieltsScore}
					</SubHeading>

					<SubHeading className="subheading" color="#396EB0" maxWidth="58rem">
						For a more exhaustive analysis, we need more details about you, you can schedule an evaluation
						with our Settle abroad consultant
					</SubHeading>
					<ScheduleSessionLink
						href="https://abroadninja.in/abroad-form?formName=study-abroad&source=abroad-ninja&utm_sou[…]medium=medium&language=lang&brandid=17&do_what=-&segment=1880"
						target="_blank"
					>
						Schedule Detailed Evaluation
					</ScheduleSessionLink>
				</MainScorePageWrapper>
			</MainScorePage>
		</Layout>
	);
};
const MainScorePage = styled.div`
	padding: 8rem 0.75rem;
`;
const HeadingStyle = styled.div`
	font-family: "Inter";
	font-style: normal;
	font-weight: 900;
	font-size: 3rem;
	line-height: 58px;
	text-align: center;
	color: #113a68;
	margin-bottom: 1rem;
	@media (max-width: 500px) {
		align-items: flex-start;
		font-weight: 900;
		font-size: 1.5rem;
		line-height: 29px;
		margin-bottom: 0.5rem;
	}
`;

const SubHeading = styled.div`
	font-weight: ${(props) => props.fontWeight || 400};
	font-size: ${(props) => props.fontSize || "1.5rem"};
	line-height: 29px;
	text-align: center;
	color: ${(props) => props.color || "#396eb0"};
	margin-bottom: 2.5rem;
	max-width: ${(props) => props.maxWidth || "37.4375rem"};
	@media (max-width: 500px) {
		align-items: flex-start;
		text-align: left;
		font-weight: ${(props) => props.fontWeight || 400};
		font-size: 0.875rem;
		line-height: 17px;
		margin-bottom: 1rem;
	}
`;

const MainScorePageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	h1 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		color: #113a68;
		margin-bottom: 1rem;
	}
	#subheading {
		font-weight: 400;
		font-size: 1.5rem;
		line-height: 29px;
		text-align: center;
		color: #396eb0;
		margin-bottom: 4.5rem;
		max-width: 36.4375rem;
	}

	@media (max-width: 500px) {
		align-items: flex-start;

		h1 {
			font-weight: 900;
			font-size: 1.5rem;
			line-height: 29px;
			margin-bottom: 0.5rem;
		}
		#subheading {
			text-align: left;
			font-weight: 400;
			font-size: 0.875rem;
			line-height: 17px;
			margin-bottom: 1.5rem;
		}
	}
`;

const ScheduleSessionLink = styled.a`
	background-color: #f4805e;
	color: #fff;
	border: none;
	padding: 1rem 2rem;
	border-radius: 8px;
	font-weight: 400;
	font-size: 1rem;
	@media (max-width: 500px) {
		font-weight: 400;
		padding: 0.8rem 1.5rem;
		font-size: 0.8rem;
	}
`;
export default PrScore;
