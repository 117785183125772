import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { graphql, useStaticQuery, Link } from "gatsby";
import { TypewriterHeader } from "../../utils";
const Hero = () => {
	const [newHeader, setNewHeader] = useState("");
	const data = useStaticQuery(query);
	const {
		allStrapiSettleHero: { nodes: SettleHero },
	} = data;

	const { heading, subheading, button_name, pop_up_form, redirect_to } = SettleHero[0];
	const typeHeader = TypewriterHeader(heading);
	useEffect(() => {
		setNewHeader(typeHeader);
	}, [typeHeader]);
	return (
		<HeroContainer>
			<h1 key={`heading`} dangerouslySetInnerHTML={{ __html: newHeader }} />
			<p
				key={`subheading`}
				className="hero_section_subheading"
				dangerouslySetInnerHTML={{ __html: subheading }}
			/>

			<div>
				{!pop_up_form ? (
					<Link to={`/abroad-form?formName=${redirect_to}`}>
						<button id="get_started">{button_name}</button>
					</Link>
				) : (
					<button id="get_started">{button_name}</button>
				)}
			</div>
		</HeroContainer>
	);
};
const HeroContainer = styled.section`
	padding: 10em 1em 10.375em 1em;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	& h1 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		color: #396eb0;
		margin-bottom: 0.3em;
		max-width: 10em;
	}
	& h1 span {
		color: var(--dark-blue);
	}
	.hero_section_subheading {
		margin-bottom: 4.5em;
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1.5rem;
		line-height: 24px;
		text-align: center;
		color: #396eb0;
		max-width: 26em;
	}
	#get_started {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 1.25rem;
		line-height: 24px;
		color: #ffffff;
		border-radius: 0.75em;
		background: var(--dark-blue);
		padding: 1.25em 2.5em;
		border: none;
		outline: none;
		cursor: pointer;
	}
`;
export const query = graphql`
	{
		allStrapiSettleHero {
			nodes {
				heading
				subheading
				button_name
				pop_up_form
				redirect_to
			}
		}
	}
`;
export default Hero;
