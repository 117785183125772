export const countryOptions = [
    { value: 'United States', label: 'United States', filter: 'f_country' },
    {
        value: 'United Kingdom',
        label: 'United Kingdom',
        filter: 'f_country',
    },
    { value: 'Canada', label: 'Canada', filter: 'f_country' },
    { value: 'Germany', label: 'Germany', filter: 'f_country' },
    { value: 'New Zealand', label: 'New Zealand', filter: 'f_country' },
];
export const universityTypeOptions = [
    { value: 'Public', label: 'Public', filter: 'f_university_type' },
    { value: 'Private', label: 'Private', filter: 'f_university_type' },
];
export const rankingOptions = [
    { value: '1-10', label: 'Top 10', filter: 'f_top_univ' },
    { value: '11-50', label: 'Top 50', filter: 'f_top_univ' },
    { value: '51-100', label: 'Top 100', filter: 'f_top_univ' },
    { value: '101-500', label: 'Top 500', filter: 'f_top_univ' },
    // { value: "100-500", label: "#100-500", filter: "f_top_univ" },
];
export const coursesOptions = [
    {
        value: 'Computer Science and IT',
        label: 'Computer Science and IT',
        filter: 'f_department_names',
    },
    {
        value: 'Agriculture, Forestry and Fishery',
        label: 'Agriculture, Forestry and Fishery',
        filter: 'f_department_names',
    },
    {
        value: 'Applied Sciences & Professions',
        label: 'Applied Sciences & Professions',
        filter: 'f_department_names',
    },
    {
        value: 'Architecture and Building',
        label: 'Architecture and Building',
        filter: 'f_department_names',
    },
    {
        value: 'Arts & Design',
        label: 'Arts & Design',
        filter: 'f_department_names',
    },

    {
        value: 'Business & Management',
        label: 'Business & Management',
        filter: 'f_department_names',
    },

    {
        value: 'Education & Training',
        label: 'Education & Training',
        filter: 'f_department_names',
    },

    {
        value: 'Engineering and Technology',
        label: 'Engineering and Technology',
        filter: 'f_department_names',
    },

    {
        value: 'Medicine & Health',
        label: 'Medicine & Health',
        filter: 'f_department_names',
    },

    {
        value: 'Hospitality, Leisure & Sports',
        label: 'Hospitality, Leisure & Sports',
        filter: 'f_department_names',
    },

    {
        value: 'Humanities',
        label: 'Humanities',
        filter: 'f_department_names',
    },

    {
        value: 'Journalism and Information',
        label: 'Journalism and Information',
        filter: 'f_department_names',
    },

    {
        value: 'Law',
        label: 'Law',
        filter: 'f_department_names',
    },

    {
        value: 'Natural Sciences',
        label: 'Natural Sciences',
        filter: 'f_department_names',
    },

    {
        value: 'Manufacturing and Processing',
        label: 'Manufacturing and Processing',
        filter: 'f_department_names',
    },

    {
        value: 'Mathematics and Statistics',
        label: 'Mathematics and Statistics',
        filter: 'f_department_names',
    },

    {
        value: 'Social and Behavioural Science',
        label: 'Social and Behavioural Science',
        filter: 'f_department_names',
    },
];

export const studyAbroadDemoVideoData = [
    {
        imgLink:'https://s3.us-east-2.wasabisys.com/ufaber-lms/files/fe5050df4d57f52a394c08cbdc287444.svg',
        VideoUrl:'https://www.youtube.com/watch?v=gJBP6FUOuqc',
    },
    {
        imgLink:'http://s3.us-east-2.wasabisys.com/ufaber-lms/files/4508ed2ddfbd1ca3f1f95b15d0c3b2b4.1684766905607224',
        VideoUrl:'https://www.youtube.com/watch?v=4Mu3L4GEtA4',
    },
    {
        imgLink:'https://s3.us-east-2.wasabisys.com/ufaber-lms/files/5e62b49b6a3ee3f8408e4003e1bb2f67.svg',
        VideoUrl:'https://www.youtube.com/watch?v=s-sMcNYFxrE&feature=youtu.be',
    },
    {
        imgLink:'https://s3.us-east-2.wasabisys.com/ufaber-lms/files/9d8fee670e2b43a7bd553b3a77da5577.svg',
        VideoUrl:'https://www.youtube.com/watch?v=bKpZylx5Z14&feature=youtu.be',
    },
    {
        imgLink:'https://s3.us-east-2.wasabisys.com/ufaber-lms/files/0c1f309ded310c9c8a721af62f69599f.svg',
        VideoUrl:'https://www.youtube.com/watch?v=ddlNyGnrvD0&feature=youtu.be',
    },
    {
        imgLink:'https://s3.us-east-2.wasabisys.com/ufaber-lms/files/c6cb2eace02f04b8d15ad7ef1e40560e.svg',
        VideoUrl:'https://www.youtube.com/watch?v=4Mu3L4GEtA4',
    }
]