import React from 'react';
import styled from 'styled-components';

import { graphql, useStaticQuery, Link } from 'gatsby';
const EvalauteYourChances = ({ slug }) => {
    const Querydata = useStaticQuery(query);
    const {
        allStrapiMastersEvaluateChance: { nodes: mastersEvaluateChance },
    } = Querydata;

    let aMastersEvaluateChance = mastersEvaluateChance.filter(
        (item) => item.slug === slug[0]
    );

    let renderMaster =
        aMastersEvaluateChance?.length > 0
            ? aMastersEvaluateChance[0]
            : mastersEvaluateChance.filter((item) => item.slug === slug[1])[0];

    const {
        heading,
        card_footer,
        evaluate_chance_option,
        button_name,
        redirect_to,
        pop_up_form,
    } = renderMaster;
    return (
        <EvalauteYourChancesSectionStyled>
            <EvalauteYourChancesSectionWrapperStyled>
                <h4
                    key={`heading`}
                    dangerouslySetInnerHTML={{ __html: heading }}
                />

                <MonthIntakeWrapperStyled>
                    <RadioButtonQuestionWrapperStyled>
                        {evaluate_chance_option.map((data) => {
                            return (
                                <IndividualRadioBtnQuestionStyled key={data.id}>
                                    <input
                                        type="radio"
                                        className="individual_question_radio_choices"
                                        name="individual_question_radio_choices"
                                        value="question_1"
                                    />
                                    <p>{data.option}</p>
                                </IndividualRadioBtnQuestionStyled>
                            );
                        })}
                    </RadioButtonQuestionWrapperStyled>
                    <p
                        key={`subheading`}
                        id="share_academic_work_exp"
                        dangerouslySetInnerHTML={{ __html: card_footer }}
                    />

                    {!pop_up_form ? (
                        <Link to={redirect_to}>
                            <GetFreeConsultationStyled>
                                {button_name}
                            </GetFreeConsultationStyled>
                        </Link>
                    ) : (
                        <GetFreeConsultationStyled>
                            {button_name}
                        </GetFreeConsultationStyled>
                    )}
                </MonthIntakeWrapperStyled>
            </EvalauteYourChancesSectionWrapperStyled>
        </EvalauteYourChancesSectionStyled>
    );
};

const EvalauteYourChancesSectionStyled = styled.section`
    padding: 0 1rem;

    input[type='radio'] {
        cursor: pointer;
        appearance: none;
        max-width: 1.25rem;
        min-width: 1.25rem;
        max-height: 1.25rem;
        min-height: 1.25rem;
        border: 2px solid #1f1f1f;
        border-radius: 50%;
        display: grid;
        place-content: center;
    }

    input[type='radio']::before {
        content: '';
        max-width: 0.75rem;
        min-width: 0.75rem;
        min-height: 0.75rem;
        max-height: 0.75rem;
        border-radius: 50%;
        transform: scale(0);
        background-color: #1f1f1f;
    }
    input[type='radio']:checked::before {
        transform: scale(1);
    }
    @media (max-width: 500px) {
        padding: 0 0.75rem;
        margin-top: 2rem;
    }
`;
const EvalauteYourChancesSectionWrapperStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 2rem;
    & h4 {
        margin-bottom: 2.5rem;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 900;
        font-size: 3rem;
        line-height: 58px;
        text-align: center;
        color: #113a68;
    }
    @media (max-width: 500px) {
        & h4 {
            font-size: 1.75rem;
            line-height: 34px;
        }
    }
`;

const MonthIntakeWrapperStyled = styled.div`
    padding: 2rem;
    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 12.5rem;
    #share_academic_work_exp {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 19px;
        text-align: center;
        color: #8f8f8f;
        max-width: 42.125rem;
        margin-bottom: 1.5rem;
        margin-top: 2rem;
    }
    @media (max-width: 860px) {
        width: 100%;
    }
    @media (max-width: 500px) {
        padding: 0.5rem 0.5rem 2rem 0.5rem;
        margin-bottom: 7.5rem;
    }
`;
const RadioButtonQuestionWrapperStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
`;
const IndividualRadioBtnQuestionStyled = styled.div`
    background: #fff0eb;
    border-radius: 12px;
    padding: 1.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    position: relative;
    min-width: 22.5rem;
    max-width: 22.5rem;
    & p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        text-align: center;
        color: #113a68;
    }
    @media (max-width: 500px) {
        max-width: 100%;
        min-width: 100%;
        & p {
            font-size: 1rem;
            line-height: 19px;
        }
    }

    @media (max-width: 310px) {
        & p {
            font-size: 0.85rem;
        }
    }
`;

const GetFreeConsultationStyled = styled.button`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 19px;
    color: #ffffff;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0.90625rem 1.75rem;
    background: #113a68;
    border-radius: 8px;
`;

export const query = graphql`
    query StrapiMastersEvaluateChance($slug: [String]) {
        allStrapiMastersEvaluateChance(filter: { slug: { in: $slug } }) {
            nodes {
                slug
                heading
                card_footer
                button_name
                redirect_to
                pop_up_form
                evaluate_chance_option {
                    option
                }
            }
        }
    }
`;
export default EvalauteYourChances;
