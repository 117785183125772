import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
const ExpertisAndReach = () => {
	const data = useStaticQuery(query);
	const {
		allStrapiSettleExpertise: { nodes: abroadExpertise },
	} = data;
	const { heading, expertise } = abroadExpertise[0];

	return (
		<ExpertisAndReachStyle>
			<ExpertisAndReachWrapperStyle>
				<h4 key={`heading`} dangerouslySetInnerHTML={{ __html: heading }} />

				<ExpertiseAndReachOptions>
					{expertise.map((data, index) => {
						return (
							<div className="expertise_and_reach_individual_option" key={index}>
								<small>{data.heading}</small>
								<p
									key={`subheading`}
									dangerouslySetInnerHTML={{
										__html: data.info,
									}}
								/>
							</div>
						);
					})}
				</ExpertiseAndReachOptions>
			</ExpertisAndReachWrapperStyle>
		</ExpertisAndReachStyle>
	);
};
const ExpertisAndReachStyle = styled.section``;
const ExpertisAndReachWrapperStyle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	gap: 4rem;
	padding: 1.5rem;
	margin-bottom: 5rem;
	& h4 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		color: var(--dark-blue);
	}

	@media (max-width: 500px) {
		gap: 2rem;
		padding: 1rem;

		& h4 {
			font-size: 1.75rem;
			line-height: 34px;
		}
	}
`;
const ExpertiseAndReachOptions = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	gap: 6rem;
	flex-wrap: wrap;
	.expertise_and_reach_individual_option {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		gap: 1rem;
	}

	& small {
		font-weight: 700;
		font-size: 1.25rem;
		line-height: 24px;
		text-align: center;
		color: var(--dark-blue);
	}
	& p {
		font-weight: 400;
		font-size: 1.25rem;
		line-height: 24px;
		text-align: center;
		color: #fc997c;
	}

	@media (max-width: 1145px) {
		.expertise_and_reach_individual_option {
			min-width: 23.0625rem;
		}
	}

	@media (max-width: 500px) {
		gap: 2rem;

		.expertise_and_reach_individual_option {
			min-width: 100%;
		}

		& small,
		& p {
			font-size: 1rem;
			line-height: 19px;
		}
	}

	@media (max-width: 325px) {
		& small,
		& p {
			font-size: 0.9rem;
		}
	}
	@media (max-width: 315px) {
		& small,
		& p {
			font-size: 0.78rem;
		}
	}
`;

export const query = graphql`
	{
		allStrapiSettleExpertise {
			nodes {
				heading
				expertise {
					strapi_id
					heading
					info
				}
			}
		}
	}
`;
export default ExpertisAndReach;
