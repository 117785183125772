export const eduction = [
    {
        value: 'bachelors',
        label: 'Bachelor’s',
    },
    {
        value: 'masters',
        label: 'Master’s',
    },
    {
        value: 'phd',
        label: 'PHD',
    },
    {
        value: 'diploma',
        label: 'Diploma',
    },
    {
        value: 'secondaryHighSchool',
        label: 'Secondary high school',
    },
    {
        value: 'lessThanSecondaryHighSchool',
        label: 'Less than secondary high school',
    },
];

export const workExperience = [
    {
        value: 'noExperience',
        label: 'No experience',
    },
    {
        value: 'oneYear',
        label: 'One year',
    },
    {
        value: 'twoYears',
        label: 'Two years',
    },
    {
        value: 'threeYears',
        label: 'Three years',
    },
    {
        value: 'fourYears',
        label: 'Four years',
    },
    {
        value: 'fiveYearsOrMore',
        label: 'Five years or more',
    },
];

export const Reading = [
    // {
    //     value: '9.0',
    //     label: '9.0+',
    // },
    {
        value: '8.5',
        label: '8.5',
    },
    {
        value: '8.0',
        label: '8.0',
    },
    {
        value: '7.5',
        label: '7.5',
    },
    {
        value: '7.0',
        label: '7.0',
    },
    {
        value: '6.5',
        label: '6.5',
    },
    {
        value: '6.0',
        label: '6.0',
    },
    {
        value: '5.5',
        label: '5.5',
    },
    {
        value: '5.0',
        label: '5.0',
    },
    {
        value: '4.5',
        label: '4.5',
    },
    {
        value: '4.0',
        label: '4.0',
    },
    {
        value: '3.5',
        label: '3.5',
    },
    {
        value: '3.0',
        label: '3.0 or less',
    },
];
export const Writing = [
    // {
    // 	value: "9.0",
    // 	label: "9.0+",
    // },
    {
        value: '8.5',
        label: '8.5',
    },
    {
        value: '8.0',
        label: '8.0',
    },
    {
        value: '7.5',
        label: '7.5',
    },
    {
        value: '7.0',
        label: '7.0',
    },
    {
        value: '6.5',
        label: '6.5',
    },
    {
        value: '6.0',
        label: '6.0',
    },
    {
        value: '5.5',
        label: '5.5',
    },
    {
        value: '5.0',
        label: '5.0',
    },
    {
        value: '4.5',
        label: '4.5',
    },
    {
        value: '4.0',
        label: '4.0',
    },
    {
        value: '3.5',
        label: '3.5',
    },
    {
        value: '3.0',
        label: '3.0 or less',
    },
];
export const Listening = [
    // {
    // 	value: "9.0",
    // 	label: "9.0+",
    // },
    {
        value: '8.5',
        label: '8.5',
    },
    {
        value: '8.0',
        label: '8.0',
    },
    {
        value: '7.5',
        label: '7.5',
    },
    {
        value: '7.0',
        label: '7.0',
    },
    {
        value: '6.5',
        label: '6.5',
    },
    {
        value: '6.0',
        label: '6.0',
    },
    {
        value: '5.5',
        label: '5.5',
    },
    {
        value: '5.0',
        label: '5.0',
    },
    {
        value: '4.5',
        label: '4.5',
    },
    {
        value: '4.0',
        label: '4.0',
    },
    {
        value: '3.5',
        label: '3.5',
    },
    {
        value: '3.0',
        label: '3.0 or less',
    },
];
export const Speaking = [
    // {
    // 	value: "9.0",
    // 	label: "9.0+",
    // },
    {
        value: '8.5',
        label: '8.5',
    },
    {
        value: '8.0',
        label: '8.0',
    },
    {
        value: '7.5',
        label: '7.5',
    },
    {
        value: '7.0',
        label: '7.0',
    },
    {
        value: '6.5',
        label: '6.5',
    },
    {
        value: '6.0',
        label: '6.0',
    },
    {
        value: '5.5',
        label: '5.5',
    },
    {
        value: '5.0',
        label: '5.0',
    },
    {
        value: '4.5',
        label: '4.5',
    },
    {
        value: '4.0',
        label: '4.0',
    },
    {
        value: '3.5',
        label: '3.5',
    },
    {
        value: '3.0',
        label: '3.0 or less',
    },
];

export const offerLetterCanada = [
    {
        value: 'above_manager_level',
        label: 'Above Manager Level',
    },
    {
        value: 'management_job',
        label: 'Management Job',
    },
    {
        value: 'doctore_dentist_architect',
        label: 'Doctor, Dentist, Architect',
    },
];

export const yearsExperience = [
    {
        value: '1',
        label: '1',
    },
    {
        value: '2',
        label: '2',
    },
    {
        value: '3',
        label: '3+',
    },
];

export const canadaEducation = [
    {
        value: 'one_year_diploma',
        label: '1 year diploma',
    },
    {
        value: 'diploma_or_more',
        label: 'Diploma or more',
    },
    {
        value: 'none',
        label: 'none',
    },
];
