import { useState, useEffect, Fragment } from 'react';
import React from 'react';
import styled from 'styled-components';
import useAPI from '../../../hooks/study-abroad/university-search/useAPI';
import Universities from './Universities';
// import { Link } from "gatsby";

const UniversitiesByCountry = (props) => {
    const {
        country,
        viewAllHandler,
        showViewAll,
        heading,
        showExploreUniversity,
    } = props;

    const [universities, setUniversities] = useState([]);
    const { getUniversities } = useAPI();

    const clickHandler = () => {
        viewAllHandler({ filter: 'f_country', value: `${country}` });
    };

    useEffect(() => {
        const asyncFn = async () => {
            const universities = await getUniversities(
                `https://fapi.ufaber.com/study-abroad/universities/?page=1&f_country=${country}`
            );

            setUniversities(universities.data);
            setUniversities(universities.data.slice(0, 5));
        };
        asyncFn();
    }, [country]);
    return (
        <Fragment>
            <TopCollegesTitle>
                <p>
                    {heading ?? 'Top colleges in '}
                    {country}
                </p>
                {/* <Link
          to="/study-abroad/university-search"
          state={{
            receivedFilters: [{ filter: "f_country", value: `${country}` }],
          }}
        >
          View All
        </Link> */}
                {!!props?.showViewAll && (
                    <button onClick={clickHandler}>View All</button>
                )}
                {/* <button onClick={clickHandler}>View All</button> */}
            </TopCollegesTitle>
            <Universities
                universities={universities}
                showExploreUniversity={showExploreUniversity}
            />
        </Fragment>
    );
};

const TopCollegesTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    p {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 24px;
        color: #113a68;
    }

    button {
        cursor: pointer;
        font-weight: 700;
        font-size: 1rem;
        line-height: 19px;
        color: #f4805e;
        background-color: #fff;
        border: none;
    }

    @media (max-width: 768px) {
        p {
            font-size: 1rem;
        }

        a {
            font-size: 0.9rem;
        }
    }
`;

export default UniversitiesByCountry;
