import React, { useEffect, useState } from 'react';
import Layout from '../components/layout.component';
import SEO from '../components/seo';
import styled from 'styled-components';
import { Link } from 'gatsby';
import EligibilityTable from '../components/ui/eligibilityTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
function PrEligibilityReport() {
    const [prScoreData, setPrScoreData] = useState([]);
    const [crsScoreData, setCrsScoreData] = useState([]);
    const [sinpScoreData, setSinpScoreData] = useState([]);
    const [isCopied, setIsCopied] = useState(false);
    const [generalDetail, setGeneralDetails] = useState({
        name: '',
        total_points: '',
        snip_total_point: '',
        crs_total_point: '',
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const isBrowser = typeof window !== 'undefined';
    let email = '';
    let url_string = '';
    if (isBrowser) {
        url_string = window.location.href;

        let url = new URL(url_string);
        let params = new URLSearchParams(url.search);

        email = params.get('email');
        // console.log('params', params.toString());
        // redirect = params.toString();
    }

    let studyObject = [
        {
            heading: 'Overview',
        },
        {
            heading: 'Eligibility Analysis',
        },
        {
            heading: 'Types of CRS Scores in this Report',
        },
        {
            heading: 'Express Entry CRS score',
        },
        {
            heading: 'SINP score',
        },
        {
            heading: 'Probability estimation',
        },
        {
            heading:
                'How to increase your chances of eligibility(How to increase your scores)',
        },
    ];

    const prDummyData = [
        {
            sectionTitle: 'Age Details',
            columns: ['Value', 'Your Points', 'Maximum Points'],

            rows: [
                {
                    title: 'Your Age',
                    value: 0,
                    your_point: 0,
                    maximum_point: 12,
                    type_val: 'age',
                    type_your_point: 'age_score',
                },
            ],
        },
        {
            sectionTitle: 'Education',
            columns: ['Value', 'Your Points', 'Maximum Points'],

            rows: [
                {
                    title: 'Current education level',
                    value: 0,
                    your_point: 0,
                    maximum_point: 25,
                    type_val: 'Select_your_Education_Level',
                    type_your_point: 'education_score',
                },
                {
                    title: 'Canadian Education level',
                    value: 0,
                    your_point: 0,
                    maximum_point: 21,
                    type_val: 'What_level_of_education_from_canada_do_you_have',
                    type_your_point: 'canada_education_score',
                },
            ],
        },
        {
            sectionTitle: 'Work Experience',
            columns: ['Value', 'Your Points', 'Maximum Points'],
            rows: [
                {
                    title: 'Total years of work experience',
                    value: 0,
                    your_point: 0,
                    maximum_point: 15,
                    type_val: 'Enter_your_number_of_years_of_work_experience',
                    type_your_point: 'work_experience_score',
                },
                {
                    title: 'Total years of canadian work experience',
                    value: 0,
                    your_point: 0,
                    maximum_point: 10,
                    type_val:
                        'How_many_years_of_Canadian_work_experience_do_you_have',
                    type_your_point:
                        'How_many_years_of_Canadian_work_experience_do_you_have_score',
                },
            ],
        },
        {
            sectionTitle: 'Adaptability',
            columns: ['Value', 'Your Points', 'Maximum Points'],
            rows: [
                {
                    title: 'Valid job offer',
                    value: 0,
                    your_point: 0,
                    maximum_point: 10,
                    type_val: 'Do_you_have_any_job_offer_from_Canada',
                    type_your_point:
                        'Do_you_have_any_job_offer_from_Canada_score',
                },
                // {
                //     title: 'Arranged Employment in Canada',
                //     value: 0,
                //     your_point: 0,
                //     maximum_point: 5,
                //     type_val: 'Do_you_have_any_job_offer_from_Canada',
                //     type_your_point:
                //         'Do_you_have_any_job_offer_from_Canada_score',
                // },
                {
                    title: 'Relatives in Canada This relative must be a: <br/>of a parent (sibling)<br/> of a grandparent (aunt or uncle) <br/>of a parent (niece or nephew)',
                    value: 0,
                    your_point: 0,
                    maximum_point: 5,
                    type_val:
                        'Does_your_spouse_or_siblings_have_a_PR_in_Canada',
                    type_your_point:
                        'Does_your_spouse_or_siblings_have_a_PR_in_Canada_score',
                },
                {
                    title: 'Spouse’s Work Experience in Canada',
                    value: 0,
                    your_point: 0,
                    maximum_point: 5,
                    type_val: 'Select_your_spouse_Canadian_work_experience',
                    type_your_point:
                        'Select_your_spouse_Canadian_work_experience_score',
                },
            ],
        },
        {
            sectionTitle: 'Language Abilities',
            columns: ['Value', 'Your Points', 'Maximum Points'],
            isCibilScore: true,
            rows: [
                {
                    title: 'Reading',
                    value: 0,
                    your_point: 0,
                    maximum_point: 24,
                    type_val: 'Reading',
                    type_your_point: 'clb_level_score',
                },
                {
                    title: 'Listening',
                    value: 0,
                    your_point: 0,
                    maximum_point: 24,
                    type_val: 'Listening',
                    type_your_point: 'clb_level_score',
                },
                {
                    title: 'Writing',
                    value: 0,
                    your_point: 0,
                    maximum_point: 24,
                    type_val: 'Writing',
                    type_your_point: 'clb_level_score',
                },
                {
                    title: 'Speaking',
                    value: 0,
                    your_point: 0,
                    maximum_point: 24,
                    type_val: 'Speaking',
                    type_your_point: 'clb_level_score',
                },
            ],
        },
        {
            sectionTitle: '',
            columns: ['', '', ''],
            isTotal: true,
            rows: [
                {
                    title: 'Total Points',
                    value: 0,
                    your_point: '',
                    maximum_point: 100,
                    type_val: 'total_points',
                    type_your_point: 'total_points',
                },
                // {
                //     title: 'Cutoff Points',
                //     value: 0,
                //     your_point: '',
                //     maximum_point: 67,
                //     type_val: '',
                //     type_your_point: '',
                // },
            ],
        },
    ];

    const crsDummyScoreData = [
        {
            sectionTitle: 'Age Details',
            columns: ['Value', 'Your Points', 'Maximum Points'],
            rows: [
                {
                    title: 'Your Age',
                    value: 0,
                    your_point: 0,
                    maximum_point: 110,
                    type_val: 'age',
                    type_your_point: 'age_score',
                },
                {
                    title: 'Relationship Status',
                    value: 0,
                    your_point: 'NA',
                    maximum_point: 25,
                    type_val: 'Select_your_Marital_Status',
                    type_your_point: 'Select_your_Marital_Status',
                },
            ],
        },
        {
            sectionTitle: 'Education',
            columns: ['Value', 'Your Points', 'Maximum Points'],
            rows: [
                {
                    title: 'Current education level',
                    value: 0,
                    your_point: 'NA',
                    maximum_point: 150,
                    type_val: 'Select_your_Education_Level',
                    type_your_point: 'education_score',
                },
                {
                    title: 'Canadian Education level',
                    value: 0,
                    your_point: 'NA',
                    maximum_point: 30,
                    type_val: 'What_level_of_education_from_canada_do_you_have',
                    type_your_point: 'canada_education_score',
                },
            ],
        },
        {
            sectionTitle: 'Work Experience',
            columns: ['Value', 'Your Points', 'Maximum Points'],
            rows: [
                // {
                //     title: 'Total years of work experience',
                //     value: 0,
                //     your_point: 'NA',
                //     maximum_point: 50,
                //     type_val: 'Enter_your_number_of_years_of_work_experience',
                //     type_your_point: 'foreign_experience_clb',
                // },
                {
                    title: 'Total years of canadian work experience',
                    value: 0,
                    your_point: 'NA',
                    maximum_point: 80,
                    type_val:
                        'How_many_years_of_Canadian_work_experience_do_you_have',
                    type_your_point: 'canada_work_exp_score',
                },
            ],
        },

        {
            sectionTitle: 'Language Abilities',
            columns: ['Value', 'Your Points', 'Maximum Points'],
            isCibilScore: true,
            rows: [
                {
                    title: 'Reading',
                    value: 0,
                    your_point: 0,
                    maximum_point: 136,
                    type_val: 'Reading',
                    type_your_point: 'language_skill_score',
                },
                {
                    title: 'Listening',
                    value: 0,
                    your_point: 0,
                    maximum_point: 136,
                    type_val: 'Listening',
                    type_your_point: 'language_skill_score',
                },
                {
                    title: 'Writing',
                    value: 0,
                    your_point: 0,
                    maximum_point: 136,
                    type_val: 'Writing',
                    type_your_point: 'language_skill_score',
                },
                {
                    title: 'Speaking',
                    value: 0,
                    your_point: 0,
                    maximum_point: 136,
                    type_val: 'Speaking',
                    type_your_point: 'language_skill_score',
                },
                {
                    title: 'CLB Level',
                    value: 0,
                    your_point: 0,
                    maximum_point: 136,
                    type_val: 'clb_score',
                    type_your_point: 'language_skill_score',
                },
            ],
        },

        {
            sectionTitle: 'Skill Transferability',
            columns: ['Value', 'Your Points', 'Maximum Points'],
            rows: [
                {
                    title: 'Foreign Work Experience & CLB Level points',
                    value: 0,
                    your_point: 0,
                    maximum_point: 50,
                    type_val: '',
                    type_your_point: 'foreign_experience_clb',
                },
                {
                    title: 'Education & CLB Level points',
                    value: 0,
                    your_point: 0,
                    maximum_point: 50,
                    type_val: '',
                    type_your_point: 'education_clb',
                },
                {
                    title: 'Foreign and Canadian Work Experience',
                    value: 0,
                    your_point: 0,
                    maximum_point: 50,
                    type_val: '',
                    type_your_point: 'foreign_canadian_exp',
                },
            ],
        },

        {
            sectionTitle: 'Other Factors',
            columns: ['Value', 'Your Points', 'Maximum Points'],
            rows: [
                {
                    title: 'Valid job offer from canada',
                    value: 0,
                    your_point: 0,
                    maximum_point: 200,
                    type_val: 'Do_you_have_any_job_offer_from_Canada',
                    type_your_point: 'offer_score',
                },
                {
                    title: 'Relatives in Canada This relative must be a:<br/>of a parent (sibling)<br/>of a grandparent (aunt or uncle)<br/>of a parent (niece or nephew)<br/>',
                    value: 0,
                    your_point: 0,
                    maximum_point: 15,
                    type_val:
                        'Does_your_spouse_or_siblings_have_a_PR_in_Canada',
                    type_your_point: 'sibling_score',
                },
            ],
        },

        {
            sectionTitle: 'Spouse Details',
            columns: ['Value', 'Your Points', 'Maximum Points'],
            isSpouse: true,
            rows: [
                {
                    title: 'Spouses Current education level',
                    value: 0,
                    your_point: 0,
                    maximum_point: 50,
                    type_val: 'Select_your_spouse_education_level',
                    type_your_point: 'spouse_education_score',
                },
                {
                    title: 'Spouses Work Experience',
                    value: 0,
                    your_point: 0,
                    maximum_point: 50,
                    type_val: 'Select_your_spouse_Canadian_work_experience',
                    type_your_point: 'spouse_work_experience_score',
                },
                {
                    title: 'Spouses Language Level',
                    value: 0,
                    your_point: 0,
                    maximum_point: 20,
                    type_val: '',
                    type_your_point: 'spouse_clb_score',
                },
            ],
        },

        {
            sectionTitle: '',
            columns: ['', '', ''],
            isTotal: true,
            rows: [
                {
                    title: 'Total Points',
                    value: 0,
                    your_point: '',
                    maximum_point: 1200,
                    type_val: '',
                    type_your_point: 'total_points',
                },
                // {
                //     title: 'Cutoff Points',
                //     value: 0,
                //     your_point: '',
                //     maximum_point: 450,
                //     type_val: '',
                //     type_your_point: '',
                // },
            ],
        },
    ];

    const snipDummyScoreData = [
        {
            sectionTitle: 'Age Details',
            columns: ['Value', 'Your Points', 'Maximum Points'],
            rows: [
                {
                    title: 'Your Age',
                    value: 0,
                    your_point: 0,
                    maximum_point: 12,
                    type_val: 'age',
                    type_your_point: 'age_score',
                },
            ],
        },
        {
            sectionTitle: 'Education',
            columns: ['Value', 'Your Points', 'Maximum Points'],
            rows: [
                {
                    title: 'Current education level',
                    value: 0,
                    your_point: 'NA',
                    maximum_point: 23,
                    type_val: 'Select_your_Education_Level',
                    type_your_point: 'education_score',
                },
                {
                    title: 'Canadian Education level',
                    value: 0,
                    your_point: 'NA',
                    maximum_point: 15,
                    type_val: 'What_level_of_education_from_canada_do_you_have',
                    type_your_point: 'canada_education_score',
                },
            ],
        },
        {
            sectionTitle: 'Work Experience',
            columns: ['Value', 'Your Points', 'Maximum Points'],
            rows: [
                {
                    title: 'Total years of work experience',
                    value: 0,
                    your_point: 'NA',
                    maximum_point: 10,
                    type_val: 'Enter_your_number_of_years_of_work_experience',
                    type_your_point: 'work_experience_score',
                },
            ],
        },

        {
            sectionTitle: 'Language Abilities',
            columns: ['Value', 'Your Points', 'Maximum Points'],
            isCibilScore: true,
            rows: [
                {
                    title: 'Reading',
                    value: 0,
                    your_point: 0,
                    maximum_point: 20,
                    type_val: 'Reading',
                    type_your_point: 'language_score',
                },
                {
                    title: 'Listening',
                    value: 0,
                    your_point: 0,
                    maximum_point: 20,
                    type_val: 'Listening',
                    type_your_point: 'language_score',
                },
                {
                    title: 'Writing',
                    value: 0,
                    your_point: 0,
                    maximum_point: 20,
                    type_val: 'Writing',
                    type_your_point: 'language_score',
                },
                {
                    title: 'Speaking',
                    value: 0,
                    your_point: 0,
                    maximum_point: 20,
                    type_val: 'Speaking',
                    type_your_point: 'language_score',
                },
                {
                    title: 'CLB Level',
                    value: 0,
                    your_point: 0,
                    maximum_point: 20,
                    type_val: 'clb_score',
                    type_your_point: 'clb_score',
                },
            ],
        },

        {
            sectionTitle: 'Adaptability',
            columns: ['Value', 'Your Points', 'Maximum Points'],
            rows: [
                {
                    title: 'High skilled employment offer from a Saskatchewan employer',
                    value: 0,
                    your_point: 'NA',
                    maximum_point: 30,
                    type_val:
                        'Do_you_have_any_past_experience_from_Saskatchewan',
                    type_your_point:
                        'Do_you_have_any_past_experience_from_Saskatchewan',
                },
                {
                    title: 'Close family relative in Saskatchewan',
                    value: 0,
                    your_point: 'NA',
                    maximum_point: 20,
                    type_val:
                        'Do_you_have_any_close_family_relative_in_Saskatchewan',
                    type_your_point:
                        'Do_you_have_any_close_family_relative_in_Saskatchewan',
                },
                {
                    title: 'Education in Saskatchewan',
                    value: 0,
                    your_point: 'NA',
                    maximum_point: 5,
                    type_val:
                        'Have_you_completed_your_education_from_Sakatchewan',
                    type_your_point:
                        'Have_you_completed_your_education_from_Sakatchewan',
                },
            ],
        },

        {
            sectionTitle: '',
            columns: ['', '', ''],
            isTotal: true,
            rows: [
                {
                    title: 'Total Points',
                    value: 0,
                    your_point: '',
                    maximum_point: 100,
                    type_val: '',
                    type_your_point: 'total_points',
                },
                // {
                //     title: 'Cutoff Points',
                //     value: 0,
                //     your_point: '',
                //     maximum_point: 60,
                //     type_val: '',
                //     type_your_point: '',
                // },
            ],
        },
    ];
    const scrollToId = (e) => {
        let element = document.getElementById(`${e.currentTarget.id}_right`);

        document
            .querySelectorAll('.CountryPlanDetailsText')
            .forEach(function (element) {
                element.classList.remove('active');
            });
        e.currentTarget.classList.add('active');

        var headerOffset = 145;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition =
            elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        });
    };

    function getSnipScoreString(value) {
        if (value > 75) {
            return 'Almost certain';
        } else if (value >= 69 && value <= 75) {
            return 'Extremely high';
        } else if (value >= 65 && value <= 68) {
            return 'Very high';
        } else if (value >= 60 && value <= 64) {
            return 'High';
        } else if (value >= 55 && value <= 59) {
            return 'Moderate';
        } else {
            return 'Low';
        }
    }

    function getCRSCategory(score) {
        if (score < 400) {
            return 'Low';
        } else if (score >= 400 && score <= 449) {
            return 'Moderate';
        } else if (score >= 450 && score <= 470) {
            return 'High';
        } else if (score >= 471 && score <= 500) {
            return 'Very high';
        } else if (score >= 501 && score <= 540) {
            return 'Extremely high';
        } else {
            return 'Almost certain';
        }
    }

    const fetchEligibilityResult = async (email) => {
        try {
            setLoading(true);
            const response = await fetch(
                `https://apix.ufaber.com/api/sales/wati/get-student-crs-report/?email=${email}`
            );
            const { data } = await response.json();
            console.log('data', data.score['67']);
            setGeneralDetails({
                name: data?.name,
                total_points: data?.score['67']?.total_points,
                snip_total_point: data?.score['sinp']?.total_points,
                crs_total_point: data?.score['crs']?.total_points,
            });
            const processScoreData = (scoreKey, setData, arr = []) => {
                const scoreData = data.score[scoreKey];
                if (scoreData) {
                    const newData = arr.map((item) => {
                        const newRows = item.rows.map((eItems) => ({
                            ...eItems,
                            value:
                                data[eItems.type_val] ||
                                (item.isTotal ? '' : 'None'),

                            ...(!item.isTotal && {
                                your_point: scoreData[eItems.type_your_point],
                            }),
                            ...(item.isTotal && {
                                your_point:
                                    scoreData[eItems.type_your_point] ||
                                    eItems.maximum_point,
                            }),
                        }));
                        return {
                            ...item,
                            rows: newRows,

                            ...(item.isSpouse && {
                                isSpouse:
                                    data.Select_your_Marital_Status !==
                                        'Single' &&
                                    data.Will_your_spouse_come_to_Canada_with_you.toLowerCase() !==
                                        'no'
                                        ? true
                                        : false,
                            }),
                        };
                    });
                    setData(newData);
                }
            };

            processScoreData('67', setPrScoreData, prDummyData);
            processScoreData('crs', setCrsScoreData, crsDummyScoreData);
            processScoreData('sinp', setSinpScoreData, snipDummyScoreData);
        } catch (error) {
            console.error('Error fetching eligibility result:', error);
            setError(true);
        } finally {
            setLoading(false);
        }
    };
    async function copyTextToClipboard(text) {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand('copy', true, text);
        }
    }
    const handleCopyClick = () => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(url_string)
            .then(() => {
                // If successful, update the isCopied state value
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 1500);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    function logit(e) {
        let activeElement;
        document
            .querySelectorAll('.scroll-heading')
            .forEach(function (element, index) {
                let elementPosition = element.getBoundingClientRect().top;
                const elementID = element.getAttribute('data-id');
                let elementright = document.getElementById(elementID);
                let finalPosition = elementPosition - 150;
                elementright.classList.remove('active');
                if (finalPosition <= 0) {
                    activeElement = elementright;
                } else {
                    elementright.classList.remove('active');
                }
            });
        if (activeElement) {
            // document.querySelector(`${activeElement.id}`).scrollIntoView({ behavior: "smooth" });
            // const julie = document.getElementById(activeElement.id);

            // julie.scrollIntoView(false);
            activeElement.classList.add('active');
        } else {
            // activeElement && activeElement.classList.remove("active");
        }
    }
    useEffect(() => {
        function watchScroll() {
            window.addEventListener('scroll', logit);
        }
        watchScroll();
        // Remove listener (like componentWillUnmount)
        return () => {
            window.removeEventListener('scroll', logit);
        };
    }, []);
    useEffect(() => {
        email && fetchEligibilityResult(email);
    }, [email]);
    if (!email) {
        return;
    }

    if (loading) {
        return <div>loading...</div>;
    }

    if (error) {
        return <div>Error Occurred While Fetching Report . </div>;
    }
    console.log('generalDetail', generalDetail);

    return (
        <Layout>
            <SEO title="PR Eligibility Report" />
            <StudyPageStyle>
                <section className="CountryPageTopSection">
                    <div className="TopSectionWrapper">
                        <div className="CountryImageWrapper">
                            <img
                                src={
                                    'http://s3.us-east-2.wasabisys.com/ufaber-lms/files/443eb0d6b6598a18df66bac811d3cfc5.svg'
                                }
                                alt={'PR Report'}
                            />
                        </div>
                        <div className="CountryNameWrapper">
                            <h1>PR Eligibility Report</h1>
                            <div className="group">
                                <a
                                    href=" https://calendly.com/prem-vishwakarma/guaranteed-canada-pr-in-365-days?month=2024-03"
                                    className="getConsultation"
                                >
                                    <img
                                        src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/6db7fa315d5e456e8a407ef8c8d4f283.svg"
                                        alt="Headphone with mic icon"
                                    />
                                    Get Consultation
                                </a>
                                <button
                                    onClick={handleCopyClick}
                                    className="getConsultation"
                                >
                                    <FontAwesomeIcon icon={faCopy} size="1x" />
                                    {isCopied ? 'Link Copied!' : 'Share Link'}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="CountryStudySettlePlan">
                    <div class="BottomSectionWrapper">
                        <div className="leftMenuCard" id="leftMenuCardID">
                            <ul>
                                {studyObject.map((data) => (
                                    <li>
                                        <label
                                            htmlFor="educationalStruc"
                                            className="CountryPlanDetailsText "
                                            id={data.heading.replace(
                                                /\W/g,
                                                '_'
                                            )}
                                            onClick={scrollToId}
                                        >
                                            {data.heading}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="rightDetailsSection">
                            <div
                                className="scroll-heading"
                                data-id={`${'Overview'.replace(/\W/g, '_')}`}
                                id={`${'Overview'.replace(/\W/g, '_')}_right`}
                            ></div>
                            <div className="rightDetailsSectionCard">
                                <small className="DetailsSectionTitle">
                                    Overview
                                </small>
                                {generalDetail?.total_points >= 67 ? (
                                    <div className="DetailsSectionContentWrapper">
                                        <div className="mb-2">
                                            Congratulations{' '}
                                            <b>{generalDetail?.name}</b> 🎉!
                                        </div>
                                        <div className="mb-2">
                                            {' '}
                                            You are eligible to apply for Canada
                                            PR with{' '}
                                            <strong>
                                                {generalDetail?.total_points}{' '}
                                                eligibility points.
                                            </strong>
                                        </div>
                                        <div className="mb-2">
                                            Your Express entry points are{' '}
                                            <strong>
                                                {generalDetail.crs_total_point}{' '}
                                            </strong>{' '}
                                            , your chances for express entry is{' '}
                                            <strong>
                                                {getCRSCategory(
                                                    generalDetail.crs_total_point
                                                )}{' '}
                                                .
                                            </strong>
                                        </div>
                                        <div className="mb-2">
                                            Your SINP points are{' '}
                                            <strong>
                                                {generalDetail.snip_total_point}
                                            </strong>{' '}
                                            , your chances for provincial
                                            nomination is{' '}
                                            <strong>
                                                {getSnipScoreString(
                                                    generalDetail.snip_total_point
                                                )}
                                                .
                                            </strong>
                                        </div>
                                        <div className="mb-2">
                                            Connect with our immigration
                                            consultant to get a detailed
                                            understanding of the report.{' '}
                                            <a href="https://calendly.com/prem-vishwakarma/guaranteed-canada-pr-in-365-days">
                                                Click here to book
                                            </a>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="DetailsSectionContentWrapper">
                                        <div>
                                            Dear <b>{generalDetail?.name}</b> !
                                        </div>
                                        <div className="">
                                            {' '}
                                            {/* You are not eligible to apply for
                                            Canada PR, your total score is{' '}
                                            <strong>
                                                {generalDetail?.total_points} as
                                                per 67 points eligibility
                                            </strong> */}
                                            Your eligibility score for Canada PR
                                            is{' '}
                                            <strong>
                                                {generalDetail?.total_points}{' '}
                                                which is lower as per 67 points
                                                cutoff eligibility.
                                            </strong>
                                        </div>

                                        <div className="">
                                            Your Express entry points are{' '}
                                            <strong>
                                                {generalDetail.crs_total_point}{' '}
                                                .
                                            </strong>
                                        </div>
                                        <div className="mb-2">
                                            Your SINP points are{' '}
                                            <strong>
                                                {generalDetail.snip_total_point}
                                            </strong>{' '}
                                            , your chances for provincial
                                            nomination is{' '}
                                            <strong>
                                                {getSnipScoreString(
                                                    generalDetail.snip_total_point
                                                )}
                                                .
                                            </strong>
                                        </div>
                                        <div>
                                            <p className="mb-2">
                                                However, you're still eligible
                                                for the Provincial Nominee
                                                Program (PNP) with certain
                                                conditions. We'll need to
                                                discuss your options in more
                                                detail. Moreover, you remain
                                                eligible for the PNP, and
                                                there's a possibility you might
                                                qualify for Australian Permanent
                                                Residency (PR) as well.
                                            </p>
                                            <p>
                                                To explore these possibilities,
                                                please get in touch with our
                                                Immigration Manager. Book a
                                                consultation with the manager
                                                here for a probability
                                                estimation:{' '}
                                                <a href="https://calendly.com/prem-vishwakarma/guaranteed-canada-pr-in-365-days?month=2024-03">
                                                    Connect with Consultant
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div
                                className="scroll-heading"
                                data-id={`${'Eligibility_Analysis'.replace(
                                    /\W/g,
                                    '_'
                                )}`}
                                id={`${'Eligibility_Analysis'.replace(
                                    /\W/g,
                                    '_'
                                )}_right`}
                            ></div>
                            <div className="rightDetailsSectionCard">
                                <small className="DetailsSectionTitle">
                                    Eligibility Analysis
                                </small>
                                <div className="DetailsSectionContentWrapper">
                                    <ul class="EducStructListTag">
                                        <li>
                                            Eligibility cut off score is 67.
                                            Candidates scoring equal to or
                                            higher than 67 points can apply for
                                            Canada PR express entry route
                                        </li>
                                        {generalDetail?.total_points >= 67 && (
                                            <li>
                                                Your score is{' '}
                                                <b>
                                                    {
                                                        generalDetail?.total_points
                                                    }
                                                </b>
                                                , hence you are currently
                                                eligible for canada PR
                                            </li>
                                        )}

                                        <li>
                                            You are within the “Golden window”
                                            for Canada PR application and must
                                            create a profile on priority. In
                                            another 2 to 4 years your points can
                                            decrease and you might lose
                                            eligibility.
                                        </li>
                                    </ul>
                                    <p class="EducStructTableWrapperHeading">
                                        Canadian PR Eligibility Breakdown
                                    </p>
                                    <p className="mb-2">
                                        The table below outlines the criteria,
                                        your corresponding scores, and the
                                        maximum points achievable for a certain
                                        criteria.
                                    </p>
                                    <div id="EduStructTableOneWrapper">
                                        {prScoreData.length && (
                                            <EligibilityTable
                                                data={prScoreData}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="scroll-heading"
                                data-id={`${'Types_of_CRS_Scores_in_this_Report'.replace(
                                    /\W/g,
                                    '_'
                                )}`}
                                id={`${'Types_of_CRS_Scores_in_this_Report'.replace(
                                    /\W/g,
                                    '_'
                                )}_right`}
                            ></div>
                            <div className="rightDetailsSectionCard">
                                <small className="DetailsSectionTitle">
                                    Types of CRS Scores in this Report
                                </small>
                                <div className="DetailsSectionContentWrapper">
                                    <ol class="EducStructListTag">
                                        <li>
                                            <p className="bold underline">
                                                Express Entry CRS Score :
                                            </p>
                                            Express Entry serves as the central
                                            national pool for immigration
                                            applications. The CRS score acts as
                                            a threshold for inviting candidates
                                            at a national level. Scores are
                                            calculated out of a maximum of 1200,
                                            and cutoff scores typically range
                                            from 410 to 540 depending on the
                                            type of draw.
                                        </li>
                                        <li>
                                            <p className="bold underline">
                                                SINP Score:
                                            </p>
                                            The Saskatchewan Immigrant Nominee
                                            Program (SINP) is specific to the
                                            province of Saskatchewan. Points are
                                            allocated based on factors such as a
                                            Saskatchewan job offer, education,
                                            relatives in Saskatchewan etc.
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div
                                className="scroll-heading"
                                data-id={`${'Express_Entry_CRS_score'.replace(
                                    /\W/g,
                                    '_'
                                )}`}
                                id={`${'Express_Entry_CRS_score'.replace(
                                    /\W/g,
                                    '_'
                                )}_right`}
                            ></div>
                            <div className="rightDetailsSectionCard">
                                <small className="DetailsSectionTitle">
                                    Express Entry CRS score
                                </small>
                                <div className="DetailsSectionContentWrapper">
                                    <p class="EducStructTableWrapperHeading">
                                        How to understand your Express Entry CRS
                                        Score report
                                    </p>
                                    <p>
                                        The Express Entry CRS score report
                                        provides a comprehensive overview for
                                        immigration to Canada. Here's how to
                                        interpret it :
                                    </p>
                                    <ol class="EducStructListTag">
                                        <li>
                                            Total Score: The total CRS score
                                            represents the cumulative points
                                            earned by the applicant based on
                                            various factors such as age,
                                            education, work experience, language
                                            proficiency, and adaptability.
                                        </li>
                                        <li>
                                            Breakdown of Points: The report
                                            breaks down the total score into
                                            individual components, indicating
                                            how many points were awarded for
                                            each criterion. This breakdown helps
                                            applicants understand their
                                            strengths and areas for improvement
                                        </li>
                                        <li>
                                            Additional Information: Depending on
                                            the immigration program or pathway
                                            selected, the report may include
                                            additional information such as
                                            points awarded for a provincial
                                            nomination, job offer, or Canadian
                                            education credentials.
                                        </li>
                                        <li>
                                            Express entry CRS is calculated from
                                            a maximum of 1200 points.
                                        </li>
                                        <li>
                                            Canada rewards candidates for
                                            English language proficiency, Young
                                            individuals, High Work experience
                                            and Higher education
                                        </li>
                                        <li>
                                            Candidates can earn extra reward
                                            points by following parameters
                                            <ol type="a">
                                                <li>
                                                    Job offer from a Canadian
                                                    company
                                                </li>
                                                <li>
                                                    Previous Work experience in
                                                    Canada
                                                </li>
                                                <li>
                                                    Blood relatives in Canada
                                                </li>
                                                <li>
                                                    Degree or Diploma from
                                                    Canada
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                    <p>
                                        Overall, the report will guide you in
                                        tracking your immigration progress and
                                        making informed decisions.
                                    </p>
                                    <p class="EducStructTableWrapperHeading">
                                        CRS score calculation
                                    </p>
                                    <p className="mb-2">
                                        Your overall Predicted CRS score Express
                                        entry is{' '}
                                        <b>{generalDetail?.crs_total_point}</b>
                                    </p>
                                    <div id="EduStructTableOneWrapper">
                                        {crsScoreData.length && (
                                            <EligibilityTable
                                                data={crsScoreData}
                                            />
                                        )}
                                        {/* <EligibilityTable data={crsScoreData} /> */}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="scroll-heading"
                                data-id={`${'SINP_score'.replace(/\W/g, '_')}`}
                                id={`${'SINP_score'.replace(/\W/g, '_')}_right`}
                            ></div>
                            <div className="rightDetailsSectionCard">
                                <small className="DetailsSectionTitle">
                                    SINP score
                                </small>

                                <div className="DetailsSectionContentWrapper">
                                    <p class="EducStructTableWrapperHeading">
                                        How to understand your SINP and other
                                        PNP score and report
                                    </p>
                                    <p>
                                        The report provides a comprehensive
                                        overview , here’s how to understand the
                                        PNP score{' '}
                                    </p>
                                    <ul class="EducStructListTag">
                                        <li>
                                            SINP, Prince Edward Island, and
                                            Quebec have distinct reward systems
                                            compared to Express Entry,
                                            particularly regarding age, language
                                            proficiency, and work experience
                                            criteria.
                                        </li>
                                        <li>
                                            For example, SINP awards maximum age
                                            points for applicants up to 33 years
                                            old, whereas Express Entry typically
                                            rewards maximum age points for
                                            individuals aged 25-30.
                                        </li>
                                        <li>
                                            Consequently, the likelihood of a
                                            more experienced candidate receiving
                                            higher points is greater in SINP
                                            compared to a direct invitation from
                                            Express Entry.
                                        </li>
                                    </ul>
                                    <p class="EducStructTableWrapperHeading">
                                        SINP Score Calculation
                                    </p>
                                    <p className="mb-2">
                                        Your overall Predicted SINP score is{' '}
                                        <b>{generalDetail?.snip_total_point}</b>
                                    </p>
                                    <div id="EduStructTableOneWrapper">
                                        {/* <EligibilityTable
                                            data={snipScoreData}
                                        /> */}
                                        {sinpScoreData.length && (
                                            <EligibilityTable
                                                data={sinpScoreData}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="scroll-heading"
                                data-id={`${'Probability_estimation'.replace(
                                    /\W/g,
                                    '_'
                                )}`}
                                id={`${'Probability_estimation'.replace(
                                    /\W/g,
                                    '_'
                                )}_right`}
                            ></div>
                            <div className="rightDetailsSectionCard">
                                <small className="DetailsSectionTitle">
                                    Probability estimation
                                </small>

                                <div className="DetailsSectionContentWrapper">
                                    <p>
                                        Based on your scores and cutoffs your
                                        chances for
                                    </p>
                                    <ul class="EducStructListTag">
                                        <li>
                                            Express entry is{' '}
                                            {getCRSCategory(
                                                generalDetail.crs_total_point
                                            )}{' '}
                                            {/* {generalDetail.crs_total_point < 400
                                                ? 'moderate'
                                                : 'almost certain'}{' '} */}
                                        </li>
                                        <li>
                                            SINP is almost{' '}
                                            {getSnipScoreString(
                                                generalDetail.snip_total_point
                                            )}
                                        </li>
                                    </ul>
                                    <p className="mt-2">
                                        Connect with our consultant to
                                        understand the probability estimation as
                                        well as how to get a canada PR{' '}
                                        <a href="https://calendly.com/prem-vishwakarma/guaranteed-canada-pr-in-365-days">
                                            Book a Session with Immigration
                                            Consultant
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div
                                className="scroll-heading"
                                data-id={`${'How_to_increase_your_chances_of_eligibility_How_to_increase_your_scores_'.replace(
                                    /\W/g,
                                    '_'
                                )}`}
                                id={`${'How_to_increase_your_chances_of_eligibility_How_to_increase_your_scores_'.replace(
                                    /\W/g,
                                    '_'
                                )}_right`}
                            ></div>
                            <div className="rightDetailsSectionCard">
                                <small className="DetailsSectionTitle">
                                    How to increase your chances of
                                    eligibility(How to increase your scores)
                                </small>

                                <div className="DetailsSectionContentWrapper">
                                    <p>
                                        You can enhance your CRS score through
                                        corrective actions or alternative
                                        pathways. Abroad Ninja provides
                                        assistance and programs for all CRS
                                        profile improvement routes.Following are
                                        the ways in which you can improve your
                                        CRS and SINP Score
                                    </p>
                                    <ol class="EducStructListTag">
                                        <li>
                                            <p className="bold">
                                                Maximize Language Points:
                                            </p>
                                            <ol type="a">
                                                <li>
                                                    Achieving a CLB level of
                                                    8777 for both you and your
                                                    spouse can optimize your
                                                    Express Entry CRS score.
                                                </li>
                                                <li>
                                                    Consider Rohan versus Sumit:
                                                    Comparing Rohan and Sumit,
                                                    both with identical profiles
                                                    - master's degree holders
                                                    aged 29. Sumit's IELTS score
                                                    improvement from CLB 8 to
                                                    CLB 9 results in a total
                                                    jump of 25 points
                                                </li>
                                                <li>
                                                    Check out Abroad Ninja's 1:1
                                                    programs for increasing your
                                                    language proficiency
                                                    <br></br>
                                                    <a href="https://ieltsninja.com/programs/?programtype=ielts_general">
                                                        Click Here
                                                    </a>
                                                </li>
                                            </ol>
                                        </li>

                                        <li>
                                            <p className="bold">
                                                Job and Work Visa Route:{' '}
                                            </p>
                                            <ol type="a">
                                                <li>
                                                    Obtaining a valid job offer
                                                    from a Canadian employer. A
                                                    job offer supported by a
                                                    Labour Market Impact
                                                    Assessment (LMIA) can
                                                    provide additional CRS
                                                    points.
                                                </li>
                                                <li>
                                                    Check out Abroad Ninja’s
                                                    Work visa and job offer
                                                    assistance programs
                                                    <ol type="i">
                                                        <li>
                                                            <a href="https://abroadninja.in/program/career-leap-abroad">
                                                                Career Leap
                                                                Abroad
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://abroadninja.in/program/canada-immigration">
                                                                Canada Work Visa
                                                                Program
                                                            </a>
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>

                                        <li>
                                            <p className="bold">
                                                Provincial Nomination Route:{' '}
                                            </p>
                                            Exploring Provincial Nominee
                                            Programs (PNPs) offered by different
                                            provinces in Canada. Some PNPs have
                                            lower CRS score requirements and may
                                            offer pathways to permanent
                                            residency for candidates with lower
                                            CRS scores.Abroad Ninja offers
                                            guidance to get provincial
                                            nomination. Click here to understand
                                            PR programs by abroad ninja.
                                        </li>

                                        <li>
                                            <p className="bold">
                                                1-Year Study Abroad Route{' '}
                                            </p>
                                            <ol type="a">
                                                <li>
                                                    Enrolling in a 1-year MBA
                                                    program can transform a half
                                                    chance into a guaranteed
                                                    entry. Additionally, as a
                                                    student, you can acquire a
                                                    3-year work permit, with
                                                    each additional year of work
                                                    experience earning you
                                                    points.
                                                </li>
                                                <li>
                                                    For instance, Sumit, who
                                                    completed his bachelor's in
                                                    2023, pursues an MBA and
                                                    re-applies in 2025, gaining
                                                    additional points for
                                                    Canadian education.
                                                    <ol type="i">
                                                        <li>
                                                            <a href="https://abroadninja.in/program/">
                                                                Study in Canada
                                                                Program
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a href="https://abroadninja.in/program/mission-admit-program">
                                                                Mission Admit
                                                                Program
                                                            </a>
                                                        </li>
                                                    </ol>
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                    <div>
                                        Need more clarity on which Canada PR
                                        pathway to take? Connect with our
                                        consultants by booking a session{' '}
                                        <a href="https://calendly.com/prem-vishwakarma/guaranteed-canada-pr-in-365-days">
                                            Click here to book
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </StudyPageStyle>
        </Layout>
    );
}

const StudyPageStyle = styled.div`
    .mt-2 {
        margin-top: 0.5rem;
    }
    a {
        color: #0000ee;
        font-weight: 500;
    }
    .group {
        display: flex;
        gap: 10px;
    }
    .scroll-heading {
        /* display: none; */
    }
    .DetailsSectionContentWrapper {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 0rem 1.5rem 1.5rem 1.5rem;
    }

    .DetailsSectionContentWrapper > ol {
        padding-left: 1.5rem;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 0.5rem;
    }

    .DetailsSectionContentWrapper ol li > span {
        font-weight: 500;
    }

    .eligibility_table {
        background: #ffffff;
        width: 100%;
        border-collapse: collapse;
        font-weight: 400;
        font-size: 1rem;
        line-height: 22px;
        color: #113a68;
        border-radius: 12px;
        overflow: hidden;
    }
    .eligibility_table thead tr > th {
        font-weight: 500;
        font-size: 1rem;
        line-height: 19px;
    }

    .eligibility_table thead tr {
        text-align: left;
        background-color: #ffd6c9;
        padding: 1rem;
    }
    .eligibility_table th,
    .eligibility_table td {
        padding: 0.9rem;
    }
    .eligibility_table th:first-child {
        border-right: 1px solid #9cb7d7;
    }
    .eligibility_table td:first-child {
        border-right: 1px solid #9cb7d7;
    }
    .eligibility_table tbody tr {
        border-bottom: 1px solid #9cb7d7;
    }
    .eligibility_table tbody tr:last-child {
        border-bottom: none;
    }

    .eligibility_table th:not(:last-child) {
        border-right: 1px solid rgb(156, 183, 215);
    }
    .eligibility_table td:not(:last-child) {
        border-right: 1px solid rgb(156, 183, 215);
    }
    .border-right {
        border-right: 1px solid rgb(156, 183, 215);
    }

    ol > li::marker {
        font-weight: 500;
    }
    li > ol {
        margin-top: 0.7rem;
        padding-left: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: flex-start;
    }
    .bold {
        font-weight: 600;
        font-size: 1rem;
    }
    .underline {
        text-decoration: underline;
    }
    li > a {
        color: #0000ee;
        font-weight: 500;
    }
    .DetailsSectionContentWrapper ul {
        list-style-type: disc;
    }
    .CountryPageTopSection {
        background: linear-gradient(
            180deg,
            #fff0eb 0%,
            rgba(255, 240, 235, 0) 0.01%,
            #fff0eb 100%
        );
        padding: 11rem 10rem 3.5rem 10rem;
    }
    .TopSectionWrapper {
        display: flex;
    }
    .CountryImageWrapper {
        border-radius: 50%;
        height: 160px;
        width: 160px;
        display: flex;
        align-items: center;
        overflow: hidden;
        margin-right: 3rem;
    }
    .CountryImageWrapper img {
        min-width: 100%;
        max-width: 100%;
        min-height: 100%;
        max-height: 100%;
        object-fit: cover;
    }
    .CountryNameWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 1.375rem;
    }
    .CountryNameWrapper h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 3rem;
        line-height: 58px;
        color: #113a68;
    }
    .getConsultation {
        background: #f4805e;
        border-radius: 10px;
        color: #ffffff;
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 22px;
        padding: 1rem 1.75rem;
        display: flex;
        align-items: center;
        gap: 0.75rem;
        border: none;
        cursor: pointer;
    }

    .BottomSectionWrapper {
        margin-top: 2rem;
        margin-bottom: 5rem;
        justify-content: center;
        display: grid;
        grid-template-columns: repeat(2, auto);
        width: 100%;
        column-gap: 2rem;
        padding: 0rem 0.75rem;
    }
    .leftMenuCard {
        background: #ffffff;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        padding-top: 1.5rem;
        padding-bottom: 1.125rem;
        min-width: 261px;
        max-width: 261px;
        min-height: 468px;
        max-height: 468px;
        position: sticky;
        top: 8rem;
    }
    .leftMenuCard ul {
        list-style-type: none;
    }
    .leftMenuCard ul li {
        margin-bottom: 1.5rem;
    }
    input[type='radio'] {
        display: none;
    }
    .CountryPlanDetailsText {
        position: relative;
        color: #505050;
        font-weight: 300;
        font-size: 1rem;
        line-height: 19px;
        display: block;
        cursor: pointer;
        padding-left: 1rem;
    }

    .active::after {
        position: absolute;
        content: '';
        height: 100%;
        width: 4px;
        left: 0;
        top: 0;
        background-color: #113a68;
    }
    .CountryPlanDetailsText {
    }
    .active {
        font-weight: 700;
        color: #113a68;
    }
    .rightDetailsSectionCard {
        background: #ffffff;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        padding-top: 1rem;
        max-width: 827px;
        margin-bottom: 2rem;
    }
    @media (max-width: 990px) {
        .rightDetailsSection {
            grid-column: 1 / 3;
            padding: 0rem 0.75rem;
        }
    }
    @media (max-width: 990px) {
        .CountryImageWrapper {
            margin-right: 0rem;
        }
        .CountryNameWrapper {
            align-items: center;
            gap: 1rem;
        }
        .TopSectionWrapper {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1.5rem;
        }
        .BottomSectionWrapper {
            column-gap: 0rem;
            row-gap: 1.5rem;
            grid-template-columns: repeat(2, 1fr);
            margin-top: 0rem;
            padding: 0rem;
        }
        .leftMenuCard {
            grid-column: 1 / 3;
            min-width: 100%;
            max-width: 100%;
            min-height: 100%;
            max-height: 100%;
            box-shadow: none;
            border-radius: 0px;
            padding-top: 0rem;
            padding-bottom: 0rem;
            position: sticky;
            top: 5rem;
            z-index: 1;
        }
        .active {
            font-weight: 700;
            color: #113a68;
        }
        .rightDetailsSectionCard {
            max-width: 100%;
            width: 100%;
        }

        .leftMenuCard ul li {
            margin-bottom: 0rem;
            white-space: nowrap;
        }

        .leftMenuCard ul {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            overflow-x: scroll;
            width: 100%;
        }
        .leftMenuCard ul::-webkit-scrollbar {
            display: none;
        }
        .CountryPlanDetailsText {
            padding-left: 0rem;
            padding: 1.25rem;
            display: inline-block;
            font-weight: 500;
            font-size: 1rem;
            line-height: 19px;
            color: #113a68;
        }
        input[type='radio']:checked + .CountryPlanDetailsText::after {
            position: absolute;
            content: '';
            height: 4px;
            width: 100%;
            left: 0%;
            top: unset;
            bottom: 0%;
            background-color: #fc997c;
        }
        input[type='radio']:checked + .CountryPlanDetailsText {
            color: #f4805e;
        }
        .CountryPageTopSection {
            padding: 11rem 0rem 3.5rem 0rem;
        }
    }
    @media (max-width: 660px) {
        .TopUniversities {
            grid-template-columns: repeat(3, 1fr);
        }
        .UniversityOfAlverta {
            grid-row: 3;
            grid-column: 1 / 3;
        }
        .UniversityOfMontreal {
            grid-row: 3;
            grid-column: 2 / 4;
        }
    }
    @media (max-width: 500px) {
        .CountryPageTopSection {
            padding: 7rem 0rem 3.5rem 0rem;
        }
        .CountryImageWrapper {
            min-height: 96px;
            max-height: 96px;
            min-width: 96px;
            max-width: 96px;
        }
        .CountryNameWrapper h1 {
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 24px;
        }
        .getConsultation {
            font-weight: 700;
            font-size: 0.875rem;
            line-height: 17px;
            padding: 0.75rem 1.71875rem;
        }
        .DetailsSectionContentWrapper {
            margin-top: 0.5rem;
            padding: 0rem 0.75rem 1.5rem 0.75rem;
        }
        .OverviewSectionContent,
        .EducStructContent,
        .PlacementIndustrySectionContent,
        .COEandROISectionContent,
        .SalaryLifestyleSectionContent,
        .EducStructListTag li {
            font-size: 0.875rem;
            line-height: 17px;
        }
        .EducStructTableWrapperHeading {
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 17px;
        }
        .EducStructTableOne {
            font-size: 0.875rem;
        }
        .EducStructTableOne th,
        .EducStructTableOne td {
            width: 65%;
            padding: 0.5rem;
        }
        .TopUniversitiesSectionTitle {
            font-weight: 700;
            font-size: 1rem;
            line-height: 19px;
        }
        .TopUniversitiesListWrapper > small {
            font-weight: 400;
            font-size: 0.6875rem;
            line-height: 13px;
            text-align: center;
            max-width: 85px;
        }
        .TopUniversitiesListImgWrapper {
            min-height: 80px;
            max-height: 80px;
            min-width: 80px;
            max-width: 80px;
        }
        .TopUniversities {
            margin-bottom: 4rem;
        }
        .EducStructTableTwo {
            font-size: 0.875rem;
            font-weight: 300;
        }
        .EducStructTableTwo thead tr > th,
        .EducStructTableTwo thead tr th > span {
            font-size: 0.875rem;
        }
        .EducStructTableTwo tbody tr td:first-child {
            font-weight: 400;
        }
        .EducStructTableTwo th,
        .EducStructTableTwo td {
            padding: 0.8rem 0.6rem;
        }
        .TopCoursesNote ol > li,
        .TopCoursesNote ul > li {
            font-weight: 300;
            font-size: 0.875rem;
            line-height: 17px;
        }
        .PlacementIndustryTableOne th,
        .PlacementIndustryTableOne td {
            padding: 0.8rem 0.6rem;
        }
        .PlacementIndustryTableOne,
        .ECOEandROITableOne {
            font-size: 0.875rem;
        }
        .ECOEandROITableOne th,
        .ECOEandROITableOne td {
            padding: 0.8rem 0.6rem;
        }
        .COIandROIContent,
        .SaveUpto50KText,
        .AffordHouseCarContent {
            font-weight: 500;
            font-size: 1rem;
            line-height: 19px;
        }
        .DetailsSectionContentWrapper ol > li {
            font-size: 0.875rem;
        }
        .PathwaytoCitizenshipContent {
            font-size: 0.875rem;
        }
        .ForMoreAboutPRInCanada {
            margin-top: 1rem;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 17px;
        }

        .DetailsSectionContentWrapper {
            padding: 0rem 0.75rem 1.5rem 0.75rem;
        }
        .DetailsSectionContentWrapper ol > li {
            font-size: 0.875rem;
        }

        .EducStructListTag li {
            font-size: 0.875rem;
            line-height: 17px;
        }
        .eligibility_table th,
        .eligibility_table td {
            padding: 0.7rem;
        }
        .eligibility_table {
            font-size: 0.8rem;
        }
        #EduStructTableOneWrapper {
            width: 100%;
            overflow: scroll;
        }
        .DetailsSectionContentWrapper {
            padding: 0rem 1rem 1rem;
        }
    }
    @media (max-width: 320px) {
        .UniversityOfToronto {
            grid-column: 3/4;
        }
        .UniversityOfColumbia {
            grid-row: 3;
            grid-column: 1/2;
        }
        .UniversityOfAlverta {
            grid-row: 4;
            grid-column: 2;
        }
        .UniversityOfMontreal {
            grid-column: 3/4;
        }
    }

    .DetailsSectionTitle {
        font-weight: 700;
        font-size: 1rem;
        line-height: 19px;
        color: #113a68;
        position: relative;
        display: block;
        padding-left: 1.5rem;
    }
    .DetailsSectionTitle::before {
        position: absolute;
        content: '';
        height: 100%;
        width: 4px;
        left: 0;
        top: 0;
        background-color: #113a68;
    }

    .EducStructListTag {
        margin-top: 1.5rem;
        padding-left: 1.5rem;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: flex-start;
    }
    .EducStructListTag li {
        font-weight: 300;
        font-size: 1rem;
        line-height: 19px;
        color: #333333;
    }
    .EducStructListTag li > span {
        font-weight: 500;
    }

    .EducStructTableWrapperHeading {
        font-weight: 700;
        font-size: 1rem;
        line-height: 19px;
        color: #333333;
        margin: 1rem 0;
    }

    .table-heading {
        background: rgb(209 213 219);
        color: #000;
        font-weight: 500;
    }

    .total {
        color: #000;
        font-weight: 600;
    }
    .mb-2 {
        margin-bottom: 0.5rem;
    }
`;
export default PrEligibilityReport;
