import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
function HelpYouMakeChoiceCard({ heading, subHeading }) {
    return (
        <HelpYouMakeRightChoice>
            <div className="make-choice">
                <img
                    src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/6048f157d2a3c0c0cbf9dcdd4249fe26.svg"
                    alt="Arrow on Dartboard"
                />
                <p id="help_you_make_right_choice_head1">{heading}</p>
                <p id="help_you_make_right_choice_head2">{subHeading}</p>
                <Link
                    to={
                        '/abroad-form/?formName=study-abroad&source=abroad-ninja&utm_source=source&utm_campaign=cap&utm_medium=medium&language=lang&brandid=17&do_what=-&segment=1880'
                    }
                >
                    <button id="schedule_session">Schedule a Session</button>
                </Link>
            </div>
        </HelpYouMakeRightChoice>
    );
}

const HelpYouMakeRightChoice = styled.div`
    padding: 2rem 15.875rem;
    margin-top: 7.5rem;
    width: 100%;
    .make-choice {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: rgb(255, 240, 235);
        border-radius: 16px;
        padding: 2rem 5.875rem;
    }

    #help_you_make_right_choice_head1 {
        margin-top: 1rem;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 900;
        font-size: 2rem;
        line-height: 39px;
        text-align: center;
        color: #113a68;
        margin-bottom: 0.75rem;
    }

    #help_you_make_right_choice_head2 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 19px;
        text-align: center;
        color: #396eb0;
        margin-bottom: 2rem;
    }

    #schedule_session {
        font-weight: 700;
        font-size: 1rem;
        line-height: 19px;
        color: #ffffff;
        border: none;
        outline: none;
        background: #113a68;
        border-radius: 8px;
        padding: 0.90625rem 1.75rem;
    }

    @media (max-width: 1200px) {
        .make-choice {
            padding: 2rem 0.8rem;
            width: 100%;
        }
    }

    @media (max-width: 500px) {
        #help_you_make_right_choice_head2 {
            font-size: 0.875rem;
            line-height: 17px;
            font-weight: 400;
        }
        #help_you_make_right_choice_head1 {
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 29px;
        }
        margin-top: 1rem;
        padding: 1rem;
    }

    @media (max-width: 325px) {
        padding: 1rem;
        margin-top: 3rem;
        #help_you_make_right_choice_head1 {
            font-size: 1.3rem;
        }
    }

    @media (max-width: 313px) {
        padding: 0.5rem;
    }
`;

export default HelpYouMakeChoiceCard;
