import React, { Fragment } from "react";
import styled from "styled-components";
import Layout from "../../components/layout.component";
import UniversitiesByCountry from "../../components/study-abroad/university-search/UniversitiesByCountry";
const TopUniversities = () => {
  return (
    <Layout>
        <UniversitySearchTitle>
          <h1>University Search</h1>
          <p>Explore your dream universities for studying abroad</p>
        </UniversitySearchTitle>
        
      <UniversitySearchContainer>
        <UniversitiesByCountry country="United States" />
        <UniversitiesByCountry country="Canada" />
        <UniversitiesByCountry country="United Kingdom" />
      </UniversitySearchContainer>
    </Layout>
  );
};
export default TopUniversities;

const UniversitySearchContainer = styled.div`
  max-width: 1120px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    max-width: 700px;
  }

  @media (max-width: 768px) {
    max-width: 450px;
  }

  @media (max-width: 480px) {
    max-width: 380px;
  }

  @media (max-width: 400px) {
    max-width: 300px;
  }
`;

const UniversitySearchTitle = styled.div`
  padding: 10rem 0 3rem 0;

  h1 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    font-size: 2.5rem;
    line-height: 50px;
    text-align: center;
    color: #113a68;
    margin-bottom: 1rem;
  }

  p {
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 29px;
    text-align: center;
    color: #396eb0;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 1.75rem;
      line-height: 34px;
    }

    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      color: #9cb7d7;
    }
  }
`;