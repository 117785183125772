import styled from 'styled-components';

const LocalPrStyled = styled.section`
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

    .main-wrapper-localpr {
        max-width: 100%;
        width: 100%;
        margin: 0 auto;
    }
    .header__top-section {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 0.5rem;
        padding: 7.5rem 1rem 3.125rem;
    }
    .header__heading-one {
        font-weight: 600;
        font-size: 1.75rem;
        line-height: 34px;
        text-transform: capitalize;
        color: #101828;
        opacity: 0.9;
    }
    .header__heading-one > svg {
        margin: 0rem 0.3rem;
        height: 25px;
        position: relative;
        top: 2px;
    }
    .hero__img-section {
        width: 100%;
        height: 100%;
        display: flex;
    }
    .hero__img-section img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .section__track-record {
        display: flex;
        align-items: stretch;
        justify-content: center;
        padding: 4.5rem 1rem;
        gap: 2.5rem;
        flex-wrap: wrap;
    }
    .track-record__left {
        width: 100%;
        max-width: 403px;
    }
    .track-record__right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        padding-top: 2rem;
    }
    .track-record__heading {
        font-weight: 800;
        font-size: 2.5rem;
        line-height: 48px;
        text-align: right;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #113a68;
        max-width: 556px;
        margin-bottom: 2rem;
    }
    .track-record__brand-logo {
        margin-bottom: 0.875rem;
    }
    .track-record__visa-success-rate {
        border: 1px solid #ff8663;
        border-radius: 10px;
        display: flex;
        gap: 1rem;
        padding: 0.40625rem 1.75rem;
    }
    .percentage__number {
        color: #ff8663;
        font-weight: 600;
        font-size: 5.5rem;
    }
    .percentage__symbol {
        font-size: 2rem;
    }
    .visa-success-rate-right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 0.5rem;
    }
    .visa-success-rate-right > p {
        font-weight: 800;
        font-size: 1rem;
        line-height: 19px;
        letter-spacing: 0.04em;
        color: #113a68;
        max-width: 128px;
    }
    .section__multiple-immigration-routes {
        width: 100%;
        padding: 3rem 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .multiple-immigration-routes-wrapper {
        background: #ffffff;
        border: 1px solid rgba(106, 110, 105, 0.1);
        box-shadow: 0px 10px 13px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        padding: 2.5rem 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 1060px;
    }
    .multiple-immigration-routes-title {
        font-weight: 900;
        font-size: 1.75rem;
        line-height: 34px;
        text-align: center;
        color: #396eb0;
        max-width: 344px;
        margin-bottom: 2rem;
    }
    .btn-schedule-consultation {
        width: 100%;
        max-width: 340px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.75rem;
        font-weight: 800;
        font-size: 1.25rem;
        line-height: 24px;
        text-transform: capitalize;
        color: #ffffff;
        text-decoration: none;
        background: #ff8663;
        border-radius: 8px;
        padding: 1.25rem 0rem;
    }
    .btn-check-eligibility {
        background: #ff8663;
        border-radius: 8px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        color: #ffffff;
        font-weight: 800;
        font-size: 1.25rem;
        line-height: 24px;
        padding: 1.25rem 0rem;
        max-width: 307px;
        width: 100%;
    }
    .multiple-immigration-routes-card-wrapper {
        width: 100%;
        margin-bottom: 3rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 1rem;
        column-gap: 0.8rem;
    }
    .multiple-immigration-route-card {
        border: 2px solid rgba(106, 110, 105, 0.1);
        border-radius: 8px;
        width: 100%;
        max-width: 328px;
        display: flex;
        align-items: center;
        gap: 1.5rem;
        padding: 1.688rem 0rem 1.688rem 3.125rem;
    }
    .m-i-r-c__title {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 24px;
        color: #113a68;
        max-width: 137.5px;
    }
    .m-i-r-c__country-logo {
        height: 64px;
        width: 64px;
        border-radius: 50%;
        overflow: hidden;
    }
    .m-i-r-c__country-logo img {
        height: 100%;
        object-fit: cover;
    }
    .max-width__fix {
        max-width: 100px;
    }
    .section__comparison {
        padding: 6.25rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .comparison-section__title {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        margin-bottom: 2.25rem;
    }
    .comparison-section__title > span {
        font-weight: 800;
        font-size: 1.625rem;
        line-height: 31px;
        color: #999999;
    }
    .comparsion__table {
        width: 100%;
        max-width: 1060px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.25rem;
        margin-bottom: 2.75rem;
    }
    .table__left-section {
        width: 100%;
        max-width: 520px;
        background: #ffffff;
        border: 2px solid #e0e7f0;
        border-radius: 16px;
        overflow: hidden;
    }
    .table__right-section {
        width: 100%;
        max-width: 520px;
        background: #ffffff;
        border: 2px solid #f4f4f4;
        border-radius: 16px;
        overflow: hidden;
    }
    .table__left-heading {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f3f5f7;
        padding: 1.5rem 0rem;
        height: 75px;
    }
    .table__right-heading {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f3f5f7;
        padding: 1.5rem 0rem;
        height: 75px;
    }
    .table__right-heading > span {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 27px;
        color: #101828;
    }
    .table__left-section ul {
        list-style-position: inside;
    }
    .table__left-comparison-points,
    .table__right-comparison-points {
        display: flex;
        flex-direction: column;
    }
    .individual-comparison-points {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1.25rem;
        padding: 2.5rem 1.5rem;
        border-bottom: 1px solid #e7ebf0;
    }
    .individual-comparison-points:last-child {
        border-bottom: 0px;
    }
    .individual-comparison-points > p {
        font-weight: 600;
        font-size: 1.25rem;
        color: #113a68;
    }
    .right-comparison-points-text > p {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 30px;
        color: #101828;
    }
    .free-consultation-text {
        font-weight: 600;
        font-size: 1rem;
        line-height: 19px;
        text-align: center;
        color: #435058;
        margin-bottom: 0.5rem;
    }
    .btn-schedule-session {
        background: #ff8663;
        border-radius: 8px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.25rem 0rem;
        gap: 0.75rem;
        font-weight: 800;
        font-size: 1.25rem;
        line-height: 24px;
        text-transform: capitalize;
        color: #ffffff;
        max-width: 327px;
        width: 100%;
    }
    .section__our-results {
        background-color: #396eb0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 7.5rem 1rem 5.875rem;
    }
    .our-results-heading {
        margin-bottom: 1.25rem;
        display: flex;
    }
    .our-results-text {
        font-weight: 900;
        font-size: 4.5rem;
        line-height: 87px;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
        margin-bottom: 0.375rem;
    }
    .our-results-subheading {
        font-weight: 900;
        font-size: 1.75rem;
        line-height: 34px;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
    }
    .btn-view-all {
        width: 100%;
        max-width: 327px;
        text-decoration: none;
        background: #ff8663;
        border: 1px solid #635557;
        box-shadow: 0px 5px 9px rgba(40, 26, 69, 0.15),
            0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 1.25rem 0rem;
        font-weight: 500;
        font-size: 1rem;
        line-height: 24px;
        color: #ffffff;
    }
    .our-results-card-wrapper {
        margin: 3rem 0rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1.25rem;
    }
    .result-card-img-wrapper {
        width: 340px;
        height: auto;
        overflow: hidden;
    }
    .result-card-img-wrapper img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .track-record__right-img-result {
        height: 468px;
        width: 326px;
    }
    .track-record__right-img-result img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    @media (max-width: 830px) {
        .comparsion__table {
            flex-direction: column;
        }
    }
    @media (max-width: 820px) {
        .section__track-record {
            gap: 1rem;
        }
        .track-record__left {
            max-width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
        }
        .track-record__heading {
            max-width: 500px;
            margin-bottom: 1rem;
            text-align: center;
        }
        .track-record__right {
            padding-top: 0rem;
        }
    }
    @media (max-width: 740px) {
    }
    @media (max-width: 500px) {
        .header__top-section {
            padding: 3.75rem 1rem 1.5rem;
            align-items: flex-start;
        }
        .header__heading-one {
            font-weight: 600;
            font-size: 1.875rem;
            line-height: 36px;
        }
        .header__heading-two {
            font-weight: 600;
            font-size: 1.875rem;
            line-height: 36px;
        }
        .section__track-record {
            padding: 2rem 1rem;
        }
        .section__multiple-immigration-routes {
            padding: 2rem 0.875rem;
        }
        .track-record__heading {
            font-weight: 800;
            font-size: 1.875rem;
            line-height: 36px;
            letter-spacing: 0.04em;
            margin-bottom: 1rem;
            text-align: left;
            max-width: 326px;
        }
        .percentage__number {
            font-size: 5rem;
            line-height: 0.8;
        }
        .percentage__symbol {
            font-size: 2.5rem;
        }
        .visa-success-rate-right {
            flex-direction: row-reverse;
            align-items: flex-end;
        }
        .track-record__visa-success-rate {
            padding: 0.875rem 2.25rem;
        }
        .visa-success-rate-right > p {
            max-width: 73px;
        }
        .multiple-immigration-routes-wrapper {
            background: #ffffff;
            border: 2px solid rgba(106, 110, 105, 0.1);
            box-shadow: 0px 18px 13px rgba(0, 0, 0, 0.25);
            border-radius: 16px;
            padding: 2.5rem 0.75rem;
        }
        .m-i-r-c__title {
            font-size: 1rem;
            line-height: 19px;
            text-align: center;
            max-width: 170px;
        }
        .m-i-r-c__country-logo {
            height: 40px;
            width: 40px;
        }
        .multiple-immigration-routes-card-wrapper {
            margin-bottom: 1.5rem;
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        .multiple-immigration-route-card {
            max-width: 100%;
            padding: 1rem 0rem;
            flex-direction: column;
            align-items: center;
            gap: 0.75rem;
        }
        .multiple-immigration-route-card:nth-child(5) {
            grid-column: 1 / 3;
        }
        .max-width__fix {
            max-width: 80px;
        }
        .btn-check-eligibility {
            max-width: 100%;
        }
        .section__comparison {
            padding: 2.5rem 1rem;
        }
        .comparison-section__title > span {
            font-size: 19px;
        }
        .versus__image {
            height: 47px;
            width: 48px;
        }
        .abroad_logo_comp-sec {
            width: 107px;
        }
        .abroad_j_logo_comp-sec {
            width: 20px;
        }
        .comparison-section__title {
            margin-bottom: 1.5rem;
        }
        .comparsion__table {
            flex-direction: column;
            margin-bottom: 2.5rem;
        }
        .individual-comparison-points > p {
            font-weight: 600;
            font-size: 1rem;
        }
        .btn-schedule-session {
            max-width: 100%;
        }
        .section__our-results {
            padding: 3.5rem 1rem 2.5rem;
        }
        .btn-view-all {
            max-width: 100%;
        }
        .our-results-heading {
            width: 154px;
        }
        .our-results-heading img {
            width: 100%;
        }
        .our-results-text {
            font-weight: 900;
            font-size: 2.5rem;
            line-height: 48px;
            text-align: left;
            width: 100%;
        }
        .our-results-subheading {
            width: 100%;
            text-align: left;
            font-weight: 900;
            font-size: 1.75rem;
            line-height: 34px;
        }
        .our-results-card-wrapper {
            margin: 2rem 0rem 3rem;
        }
        .result-card-img-wrapper {
            width: 100%;
        }
        .track-record__left {
            align-items: flex-start;
        }
        .track-record__right {
            width: 100%;
        }
        .track-record__right-img-result {
            height: 100%;
            width: 100%;
        }
    }
`;
export default LocalPrStyled;
