import React, { useState } from 'react';
import styled from 'styled-components';
import Layout from '../components/layout.component';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';
import SEO from '../components/seo';
import HelpYouMakeChoiceCard from '../components/ui/helpYouMakeChoiceCard';
import ProgramCard from '../components/ui/programCard';
const Program = () => {
    const [filter, setFilter] = useState('all');
    const data = useStaticQuery(query);
    const {
        allStrapiAbroadDifferentProgram: { nodes: abroadDifferentProgram },
    } = data;
    const { program_page_heading, program_page_subheading, different_program } =
        abroadDifferentProgram[0];

    const onClickFilter = (name) => {
        setFilter(name);
    };
    return (
        <Layout>
            <ProgramStyled>
                <ProgramsWrapper>
                    <h1
                        key={`heading`}
                        dangerouslySetInnerHTML={{
                            __html: program_page_heading,
                        }}
                    />

                    <p
                        key={`subheading`}
                        className="abroad_ninja_programs_wrapper_subheading"
                        dangerouslySetInnerHTML={{
                            __html: program_page_subheading,
                        }}
                    />

                    <StudyabroadFilterwrapper>
                        <input
                            type="radio"
                            name="abroad_ninja_programs"
                            id="all"
                            defaultValue="all"
                        />
                        <label
                            htmlFor="all"
                            id="all_button"
                            name="all"
                            className={`${filter == 'all' ? 'isActive' : ''}`}
                            onClick={() => onClickFilter('all')}
                        >
                            All
                        </label>
                        <input
                            type="radio"
                            name="abroad_ninja_programs"
                            id="study_abroad"
                            defaultValue="study_abroad"
                        />
                        <label
                            htmlFor="study_abroad"
                            id="study_abroad_button"
                            onClick={() => onClickFilter('Study Abroad')}
                            className={`${
                                filter == 'Study Abroad' ? 'isActive' : ''
                            }`}
                            name="Study Abroad"
                        >
                            Study Abroad
                        </label>
                        <input
                            type="radio"
                            name="abroad_ninja_programs"
                            id="permanent_resident"
                        />
                        <label
                            htmlFor="permanent_resident"
                            id="permanent_resident_button"
                            name="Permanent Resident"
                            onClick={() => onClickFilter('Permanent Resident')}
                            className={`${
                                filter == 'Permanent Resident' ? 'isActive' : ''
                            }`}
                        >
                            Permanent Resident
                        </label>
                    </StudyabroadFilterwrapper>
                    <IndividualProgramsStyled>
                        {different_program
                            .filter((data) => {
                                if (filter === 'all' || filter === ' ') {
                                    return data;
                                }
                                return data.type === filter;
                            })
                            .map((program) => {
                                // {different_program.map((program) => {
                                return (
                                    <ProgramCard
                                        program={program}
                                        key={program.id}
                                    />
                                );
                            })}
                    </IndividualProgramsStyled>
                    <HelpYouMakeChoiceCard
                        heading=" We can help you make the right choice"
                        subHeading="  Our experts can recommend the ideal package based on your profile"
                    />
                    {/* <div id="help_you_make_right_choice">
                        <img
                            src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/6048f157d2a3c0c0cbf9dcdd4249fe26.svg"
                            alt="Arrow on Dartboard"
                        />
                        <p id="help_you_make_right_choice_head1">
                            We can help you make the right choice
                        </p>
                        <p id="help_you_make_right_choice_head2">
                            Our experts can recommend the ideal package based on
                            your profile
                        </p>
                        <Link
                            to={
                                '/abroad-form/?formName=study-abroad&source=abroad-ninja&utm_source=source&utm_campaign=cap&utm_medium=medium&language=lang&brandid=17&do_what=-&segment=1880'
                            }
                        >
                            <button id="schedule_session">
                                Schedule a Session
                            </button>
                        </Link>
                    </div> */}
                </ProgramsWrapper>
            </ProgramStyled>
        </Layout>
    );
};

const StudyabroadFilterwrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    #all_button {
        padding: 0.625rem 1.5rem;
    }
    #study_abroad_button {
        padding: 0.625rem 1.25rem;
    }
    #permanent_resident_button {
        padding: 0.625rem 1.25rem;
    }
    @media (max-width: 500px) {
        padding: 0.8rem 0rem;
        justify-content: flex-start;
        width: 100%;
        overflow-y: scroll;
        gap: 0.5rem;
    }
`;
const ProgramsWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    h1 {
        font-weight: 900;
        font-size: 3rem;
        line-height: 58px;
        text-align: center;
        color: #396eb0;
        margin-bottom: 1rem;
    }
    h1 > span {
        color: #113a68;
    }
    .abroad_ninja_programs_wrapper_subheading {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 29px;
        text-align: center;
        color: #396eb0;
        margin-bottom: 5.25rem;
    }
`;
const ProgramStyled = styled.section`
    padding: 10rem 0.75rem;

    input[type='radio'] {
        display: none;
    }
    label {
        position: relative;
        font-weight: 500;
        font-size: 1rem;
        line-height: 19px;
        color: #113a68;
        background: #fff0eb;
        border-radius: 63px;
        cursor: pointer;
    }
    #study_abroad_button {
        min-width: 9.25rem;
    }
    #permanent_resident_button {
        min-width: 12.1875rem;
    }
    label::before {
        content: '';
        height: 30px;
        width: 30px;
    }
    .isActive {
        background-color: #113a68;
        color: #fff0eb;
        transition: 0.2s all;
    }
    /* input[type="radio"]:checked + label {
		background-color: #113a68;
		color: #fff0eb;
		transition: 0.2s all;
	}
	input[type="radio"]:checked + label::before {
		background-color: white;
	} */

    #schedule_session {
        font-weight: 700;
        font-size: 1rem;
        line-height: 19px;
        color: #ffffff;
        border: none;
        outline: none;
        background: #113a68;
        border-radius: 8px;
        padding: 0.90625rem 1.75rem;
    }

    @media (max-width: 500px) {
        #abroad_ninja_programs_wrapper h1 {
            font-size: 1.75rem;
            line-height: 34px;
        }
        .abroad_ninja_programs_wrapper_subheading {
            font-size: 1rem;
            line-height: 19px;
            font-weight: 500;
        }

        #all_studyabroad_permres_radiobtn_wrapper {
            padding: 0.8rem 0rem;
            justify-content: flex-start;
            width: 100%;
            overflow-y: scroll;
            gap: 0.5rem;
        }
        .abroad_ninja_programs_wrapper_subheading {
            margin-bottom: 3.5rem;
        }
    }
    #view_all_programs {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 19px;
        color: #113a68;
        cursor: pointer;
        outline: none;
        padding: 1rem 2rem;
        border: 1px solid #113a68;
        border-radius: 8px;
        background-color: #ffffff;
    }

    .fa-circle-chevron-right {
        color: #fc997c;
        font-size: 2.5rem;
        cursor: pointer;
    }
    .program_wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1.5rem;
        padding: 1rem;
        min-width: 21rem;
        background: #ffffff;
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
        border-radius: 1rem;
    }

    .individual_programs {
        margin-top: 3.5rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 3rem;
        column-gap: 3.5rem;
        width: fit-content;
    }

    #view_all_programs_btn_wrapper {
        min-width: 21rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .program_wrapper_bottom_country_img > small {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 17px;
        color: #889db4;
    }
    .overseas_phd {
        gap: 0.25rem;
    }
    @media (max-width: 1200px) {
        .individual_programs {
            gap: 1.5rem;
        }
    }
    @media (max-width: 1130px) {
        .individual_programs {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    @media (max-width: 750px) {
        .individual_programs {
            grid-template-columns: 1fr;
        }
    }
    @media (max-width: 500px) {
        .program_wrapper {
            min-width: 100%;
        }
        #program_for_individual_wrapper h5 {
            font-size: 1.75rem;
            line-height: 34px;
        }
        #program_for_individual_wrapper {
            gap: 3rem;
        }
        .individual_programs {
            gap: 1.5rem;
            width: 100%;
        }
        #view_all_programs_btn_wrapper {
            min-width: 100%;
        }
    }
    @media (max-width: 325px) {
        .fa-circle-chevron-right {
            font-size: 2rem;
        }
        #program_for_individual_wrapper h5 {
            font-size: 1.5rem;
        }
        .program_wrapper {
            padding: 0.8rem;
        }
    }
    @media (max-width: 313px) {
        .program_wrapper {
            min-height: 8.8rem;
        }
        .fa-circle-chevron-right {
            font-size: 1.7rem;
        }
        .program_wrapper_top_heading1 {
            font-size: 1.3rem;
        }
        .program_wrapper_top_heading2 {
            font-size: 0.775rem;
        }
        .program_wrapper_bottom_country_img > small {
            font-size: 0.675rem;
        }
    }
`;

const IndividualProgramsStyled = styled.div`
    margin-top: 3.5rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 3rem;
    column-gap: 3.5rem;
    width: fit-content;

    @media (max-width: 1200px) {
        gap: 1.5rem;
    }

    @media (max-width: 750px) {
        grid-template-columns: 1fr;
    }

    @media (max-width: 500px) {
        gap: 1.5rem;
        width: 100%;
    }
`;

export const query = graphql`
    {
        allStrapiAbroadDifferentProgram {
            nodes {
                heading
                program_page_heading
                program_page_subheading
                different_program {
                    id
                    slug
                    heading
                    info
                    type
                    flag_new_img {
                        id
                        strapi_id
                        flag_img {
                            url
                            name
                        }
                    }
                }
            }
        }
    }
`;

export const Head = () => {
    return (
        <SEO
            title="Study Abroad Programs | Abroad Ninja - Your Gateway to Global Education"
            description="Explore a diverse range of study abroad programs offered by Abroad Ninja. Discover opportunities for international education, cultural immersion, and personal growth. Start your global education journey today!"
            og_type="website"
            og_url="https://abroadninja.in/program/"
            og_title="Study Abroad Programs | Abroad Ninja - Your Gateway to Global Education"
            og_description="Explore a diverse range of study abroad programs offered by Abroad Ninja. Discover opportunities for international education, cultural immersion, and personal growth. Start your global education journey today!"
            og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            twitter_card="summary_large_image"
            twitter_url="https://abroadninja.in/program/"
            twitter_title="Study Abroad Programs | Abroad Ninja - Your Gateway to Global Education"
            twitter_description="Explore a diverse range of study abroad programs offered by Abroad Ninja. Discover opportunities for international education, cultural immersion, and personal growth. Start your global education journey today!"
            twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            robots="index, follow"
            canonical="https://abroadninja.in/program/"
        />
    );
};
export default Program;
