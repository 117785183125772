import React from 'react';
import styled from 'styled-components';

const PrivacyPolicyLink = () => {
    return (
        <PrivacyPolicyLinkStyle>
            <a href='https://abroadninja.in/privacy_policy/' target='_blank'>Privacy Policy</a>
        </PrivacyPolicyLinkStyle>
    );
}

const PrivacyPolicyLinkStyle = styled.div`
    display: flex;
    justify-content: center;
    padding: 0.5rem 0rem;
    background-color: #FFFFFF;
    a {
        text-align: center;
        width: 100%;
        color: #333;
        font-size: 1rem;
        font-weight: 500;
    }
`

export default PrivacyPolicyLink;

