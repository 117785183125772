import styled from 'styled-components';

const StudyAbroadLocalStyled = styled.section`
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
    .main-wrapper_pr {
        max-width: 100%;
        width: 100%;
    }
    .header__top-section {
        padding: 5.75rem 1rem 4.375rem;
    }

    .header__top-section-heading {
        font-weight: 600;
        font-size: 3rem;
        line-height: 58px;
        color: #101828cc;
        max-width: 100%;
        text-align: center;
    }
    .hero__img-section {
        width: 100%;
        height: 100%;
        display: flex;
    }
    .hero__img-section .hero__web-view-image {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .hero__mobile-view-image {
        display: none;
    }

    .section__comparison {
        padding: 6.25rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .comparison-section__title {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        margin-bottom: 2.25rem;
    }
    .comparison-section__title > span {
        font-weight: 800;
        font-size: 1.625rem;
        line-height: 31px;
        color: #999999;
    }
    .comparsion__table {
        width: 100%;
        max-width: 1060px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.25rem;
        margin-bottom: 2.75rem;
    }
    .table__left-section {
        width: 100%;
        max-width: 520px;
        background: #ffffff;
        border: 2px solid #e0e7f0;
        border-radius: 16px;
        overflow: hidden;
    }
    .table__right-section {
        width: 100%;
        max-width: 520px;
        background: #ffffff;
        border: 2px solid #f4f4f4;
        border-radius: 16px;
        overflow: hidden;
    }
    .table__left-heading {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f3f5f7;
        padding: 1.5rem 0rem;
        height: 75px;
    }
    .table__right-heading {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f3f5f7;
        padding: 1.5rem 0rem;
        height: 75px;
    }
    .table__right-heading > span {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 27px;
        color: #101828;
    }
    .table__left-section ul {
        list-style-position: inside;
    }
    .table__left-comparison-points,
    .table__right-comparison-points {
        display: flex;
        flex-direction: column;
    }
    .individual-comparison-points {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 1.25rem;
        padding: 2.5rem 1.5rem;
        border-bottom: 1px solid #e7ebf0;
    }
    .individual-comparison-points:last-child {
        border-bottom: 0px;
    }
    .individual-comparison-points > p {
        font-weight: 600;
        font-size: 1.25rem;
        color: #113a68;
    }
    .right-comparison-points-text > p {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 30px;
        color: #101828;
    }
    .free-consultation-text {
        font-weight: 600;
        font-size: 1rem;
        line-height: 19px;
        text-align: center;
        color: #435058;
        margin-bottom: 0.5rem;
    }
    .btn-schedule-session {
        background: #ff8663;
        border-radius: 8px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.25rem 0rem;
        gap: 0.75rem;
        font-weight: 800;
        font-size: 1.25rem;
        line-height: 24px;
        text-transform: capitalize;
        color: #ffffff;
        max-width: 327px;
        width: 100%;
    }
    .section__our-results {
        background-color: #396eb0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 7.5rem 1rem 5.875rem;
    }
    .our-results-heading {
        margin-bottom: 1.25rem;
        display: flex;
    }
    .our-results-text {
        font-weight: 900;
        font-size: 4.5rem;
        line-height: 87px;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
        margin-bottom: 0.375rem;
    }
    .our-results-subheading {
        font-weight: 900;
        font-size: 1.75rem;
        line-height: 34px;
        text-align: center;
        text-transform: uppercase;
        color: #ffffff;
    }
    .btn-view-all {
        width: 100%;
        max-width: 327px;
        text-decoration: none;
        background: #ff8663;
        border: 1px solid #635557;
        box-shadow: 0px 5px 9px rgba(40, 26, 69, 0.15),
            0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        padding: 1.25rem 0rem;
        font-weight: 500;
        font-size: 1rem;
        line-height: 24px;
        color: #ffffff;
    }
    .our-results-card-wrapper {
        margin: 3rem 0rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1.25rem;
    }
    .result-card-img-wrapper {
        width: 340px;
        height: auto;
        overflow: hidden;
    }
    .result-card-img-wrapper img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .section__send-your-profile {
        padding: 18rem 0rem 8rem 0rem;
        background: url('https://s3.us-east-2.wasabisys.com/ufaber-lms/files/1350fd5979ee8ee5b1af69e500f042ef.webp');
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
    }
    .btn-eval-elig {
        font-weight: 500;
        font-size: 1rem;
        line-height: 18px;
        color: #ffffff;
        background: #f4805e;
        box-shadow: 0px 3.68539px 6.63371px rgba(40, 26, 69, 0.15),
            0px 0.737079px 1.47416px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.75rem;
        padding: 1rem 0rem;
        width: 100%;
        max-width: 448px;
    }
    .send-your-profile__right-text {
        font-weight: 600;
        font-size: 48px;
        line-height: 58px;
        text-transform: capitalize;
        color: #113a68;
        max-width: 390px;
        text-align: right;
    }
    .send-your-profile__right-text > span {
        color: #f4805e;
        max-width: 448px;
    }
    .send-your-profile__right-btn-text-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        max-width: 448px;
        gap: 2.25rem;
    }
    .send-your-profile__bottom-sec {
        width: 100%;
        display: flex;
        margin-top: 2.625rem;
        gap: 3.75rem;
        align-items: center;
        justify-content: flex-end;
    }
    .send-your-profile__left-img-wrapper {
        height: 570px;
        width: fit-content;
    }
    .send-your-profile__left-img-wrapper img {
        height: 100%;
        object-fit: contain;
    }
    .indias-leader-s-a-s__text {
        text-align: center;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        text-transform: capitalize;
        color: #113a68;
        margin-top: 1.5rem;
    }
    .indias-leader-s-a-s__text > span {
        color: #f4805e;
    }
    @media (max-width: 1105px) {

        .section__send-your-profile {
            padding: 11rem 0rem 8rem;
            background-size: contain;
        }
        .send-your-profile__right-btn-text-wrapper {
            max-width: 100%;
            text-align: center;
            align-items: center;
            padding: 0rem 1rem;
        }
        .send-your-profile__left-img-wrapper {
            height: 400px;
            width: 400px;
        }
        .send-your-profile__left-img-wrapper img {
            height: 100%;
            object-fit: contain;
            width: 100%;
    }
    }
    @media (max-width: 860px) {
        .section__send-your-profile {
            padding: 8rem 0rem 8rem;
        }
        .header__top-section {
            padding: 3.5rem 1rem;
        }
        .header__top-section-heading-webview {
            max-width: 100%;
        }
    }
    @media (max-width: 830px) {
        .comparsion__table {
            flex-direction: column;
        }
        .send-your-profile__bottom-sec {
            gap: 1.5rem;
        }
    }
    @media (max-width: 500px) {
        .header__top-section {
            padding: 3.75rem 1rem 1.5rem;
            align-items: flex-start;
        }
        .header__top-section-heading {
            font-size: 1.875rem;
            line-height: 36px;
            font-weight: 600;
        }

        .section__track-record {
            padding: 2rem 1rem;
        }
        .section__multiple-immigration-routes {
            padding: 2rem 0.875rem;
        }
        .track-record__heading {
            font-weight: 800;
            font-size: 1rem;
            line-height: 19px;
            letter-spacing: 0.04em;
            margin-bottom: 1rem;
        }
        .percentage__number {
            font-size: 5rem;
        }
        .percentage__symbol {
            font-size: 2.5rem;
        }
        .visa-success-rate-right {
            flex-direction: row-reverse;
            align-items: center;
        }
        .track-record__visa-success-rate {
            padding: 0.875rem 2.25rem;
        }
        .visa-success-rate-right > p {
            max-width: 73px;
        }
        .multiple-immigration-routes-wrapper {
            background: #ffffff;
            border: 2px solid rgba(106, 110, 105, 0.1);
            box-shadow: 0px 18px 13px rgba(0, 0, 0, 0.25);
            border-radius: 16px;
            padding: 2.5rem 0.75rem;
        }
        .m-i-r-c__title {
            font-size: 1rem;
            line-height: 19px;
            text-align: center;
            max-width: 170px;
        }
        .m-i-r-c__country-logo {
            height: 40px;
            width: 40px;
        }
        .multiple-immigration-routes-card-wrapper {
            margin-bottom: 1.5rem;
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        .multiple-immigration-route-card {
            max-width: 100%;
            padding: 1rem 0rem;
            flex-direction: column;
            align-items: center;
            gap: 0.75rem;
        }
        .multiple-immigration-route-card:nth-child(5) {
            grid-column: 1 / 3;
        }
        .max-width__fix {
            max-width: 80px;
        }
        .btn-check-eligibility {
            max-width: 100%;
        }
        .section__comparison {
            padding: 2.5rem 1rem;
        }
        .comparison-section__title > span {
            font-size: 19px;
        }
        .versus__image {
            height: 47px;
            width: 48px;
        }
        .abroad_logo_comp-sec {
            width: 107px;
        }
        .abroad_j_logo_comp-sec {
            width: 20px;
        }
        .comparison-section__title {
            margin-bottom: 1.5rem;
        }
        .comparsion__table {
            flex-direction: column;
            margin-bottom: 2.5rem;
        }
        .individual-comparison-points > p {
            font-weight: 600;
            font-size: 1rem;
        }
        .btn-schedule-session {
            max-width: 100%;
        }
        .section__our-results {
            padding: 3.5rem 1rem 2.5rem;
        }
        .btn-view-all {
            max-width: 100%;
        }
        .our-results-heading {
            width: 154px;
        }
        .our-results-heading img {
            width: 100%;
        }
        .our-results-text {
            font-weight: 900;
            font-size: 2.5rem;
            line-height: 48px;
            text-align: left;
            width: 100%;
        }
        .our-results-subheading {
            width: 100%;
            text-align: left;
            font-weight: 900;
            font-size: 1.75rem;
            line-height: 34px;
        }
        .our-results-card-wrapper {
            margin: 2rem 0rem 3rem;
        }
        .result-card-img-wrapper {
            width: 100%;
        }
        .hero__web-view-image {
            display: none;
        }
        .hero__mobile-view-image {
            display: block;
            width: 100%;
        }
        .header__top-section {
            padding: 3.5rem 0.875rem 3rem;
        }
        .section__send-your-profile {
            padding: 4.5rem 0rem;
        }
        .send-your-profile__right-text {
            font-weight: 600;
            font-size: 1.875rem;
            line-height: 36px;
            text-align: left;
        }
        .indias-leader-s-a-s__text {
            font-size: 1.875rem;
            line-height: 36px;
        }
        .send-your-profile__bottom-sec {
            flex-direction: column-reverse;
        }
        .send-your-profile__left-img-wrapper {
            width: 100%;
            max-width: 100%;
            height: 100%;
        }
        .send-your-profile__left-img-wrapper img {

        }
    }
`;
export default StudyAbroadLocalStyled;
