import React from 'react';
import Index from '../components/StudyAbroadLocalPRIndex';
import SEO from '../components/seo';
const StudyAbroadLocalPR = () => {
    return <Index />;
};

export const Head = () => {
    return <SEO title={'Study Abroad Local PR'} />;
};
export default StudyAbroadLocalPR;
