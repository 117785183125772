const useAPI = () => {
	const getUniversities = async (url) => {
		const response = await fetch(url);
		const universities = await response.json();

		return universities;
	};
	const getScholarships = async (url) => {
		const response = await fetch(url);
		const scholarships = await response.json();

		return scholarships;
	}	

	return {
		getUniversities,
		getScholarships,
	};
};

export default useAPI;
