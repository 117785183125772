import styled from 'styled-components';

const StudyAbroadPartnerStyled = styled.section`
    font-family: 'Inter';
    margin-top: 0px;

    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

        header{
            display: flex;
            justify-content: center;
            padding: 24.26px 0;
            width: 100%;
            max-width: 1400.45px;
            margin: 0 auto;
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        /*section one*/
        .section__one{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 4.5rem 1rem 5.875em;
        }
        .sec__one__img__wrapper{
            width: 100%;
            max-width: 1127px;
            margin-bottom: 36.6px;
        }
        .sec__one__img__wrapper__mob{
            display: none;
        }
        .sec__one__img{
            width: 100%;
        }
        .sec__One__heading__wrapper{
            width: 100%;
            max-width: 546px;
            margin-bottom: 36.5px;
            padding: 0 12px 0 15px ;
        }
        .sec__one__heading__one{
            font-weight: 600;
            font-size: 32px;
            line-height: 39px;
            text-transform: uppercase;
            color: #313131;
        }
        .sec__one__canda{
            font-weight: 600;
            font-size: 96px;
            line-height: 116px;
            display: flex;
            align-items: flex-end;
            letter-spacing: -0.035em;
            text-transform: capitalize;

            color: #DC3E3E;
        }
        .sec__one__heading__three{
            font-weight: 600;
            font-size: 56px;
            line-height: 67.2px;
            text-transform: capitalize;
            color: #313131;
        }
        .sec__one__card__wrapper{
            display: flex;
            gap: 24px;
            justify-content: center;
            width: 100%;
           flex-wrap: wrap;
        }
        .sec__one__card{
            width: 100%;
            max-width: 452px;
            background: #F1F1F0;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 24px;
            padding: 32px 0;
        }
        .sec__one__card__info{
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #454A44;
            width: 100%;
            max-width: 340px;
            
        }
        .sec__one__card__info > span{
            color: #F0680E;
        }

        /*section two*/
        .section__two{
            display: flex;
            justify-content: center;
            align-items: flex-start;
            background: #FF9393;
            gap: 133px;
            padding: 72px 0;
            flex-wrap: wrap;
            padding-inline: 1rem
            ;
        }
        .sec__two__left {
            margin-top: 22px;
        }
        .sec__two__left__one{
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            color: #FFFFFF;
        }
        .sec__two__img__wrapper{
            width: 100%;
            max-width: 370px;
        }
        .sec__two__img{
            width: 100%;
        }
        .sec__two__left__three{
            font-weight: 700;
            font-size: 36px;
            line-height: 108%;
            color: #FFFFFF;
        }
       
        .sec__two__img__hand__wrapper{
           width: 100%;
        }
        .sec__two__img__hand{
            width: 100%;
        }
        .sec__two__right{
            width: 100%;
            max-width: 430px;
        }
        .sec__two__form__wrapper{
            width: 100%;
            max-width: 430px;
        }
        
        
        form{
            display: flex;
            flex-direction: column;
            background: #FFFFFF;
            box-shadow: 0px 4px 20px rgba(17, 58, 104, 0.18);
            border-radius: 16px;
            padding: 32px;
            width: 100%;
        }
        label{
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #313131;
            margin-bottom: 8px;
        }
        input{
            width: 100%;
        }
        ::placeholder{
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: rgba(49, 49, 49, 0.4);
        }

        input{
            padding: 16px 12px;
            margin-bottom: 16px;
            border: 1.12329px solid rgba(49, 49, 49, 0.2);
            border-radius: 8px;
        }
        select {
            padding: 16px 12px;
            margin-bottom: 16px;
            border: 1.12329px solid rgba(49, 49, 49, 0.2);
            border-radius: 8px;
            background-color: white;
            outline: none;
        }
        select > .select-placeholder {
            font-weight: 400 ;
            font-size: 16px;
            line-height: 19px;
            color: rgba(49, 49, 49, 0.4);
        }
        input:last-of-type{
            margin-bottom: 24px;
        }
        .sec__two__in__touch{
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #435058;
            margin-bottom: 8px;
        }
        .sec__two__btn{
            display: flex;
            justify-content: center;
            gap: 12px;
            width: 100%;
            background: #E71D36;
            border-radius: 8px;
            padding: 19px 0;
            font-weight: 800;
            font-size: 20px;
            line-height: 24px;
            align-items: center;
            text-transform: capitalize;
            color: #FFFFFF;

        }
        .advantage-text {
            font-family:sans-serif;
            font-style: italic;
            font-weight: 700;
            font-size: 48px;
            text-align: center;
            line-height: 108%;
            -webkit-text-stroke:1px #E71D36;
             -webkit-text-fill-color:transparent;
        }
        /*section three*/
        .sectio__three{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: 96.26px;
        }
        .sec__three__logo__wrapper{
            width: 100%;
            max-width: 154.45px;
        }
        .sec__three__logo__wrapper img{
            width: 100%;
        }
        .sec__three__advantage__img__wrapper{
            width: 100%;
            max-width: 300px;
            margin-bottom: 48px;
        }
        .sec__three__advantage__img__wrapper img{
            width: 100%;
        }
        .sec__three__card__main__wrapper{
            display: flex;
            flex-direction: column;
            gap: 24px;
            margin-bottom: 48px;
        }
        .sec__three__advantage__card__wrapper{
            width: 100%;
            max-width: 928px;
            display: flex;
            justify-content: center; 
            gap: 24px;
            flex-wrap: wrap;
        }
        .sec__three__adv__card{
            width: 100%;
            max-width: 452px;
            border: 2px solid rgba(231, 29, 54, 0.6);
            border-radius: 8px ;
            padding: 32px 47.5px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
        }
        .sec__three__adv__card > svg{
            width: 100%;
            max-width: 48px;
        }
        .sec__three__adv__info{
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            text-transform: capitalize;
            color: #113A68;
        }
        .sec__three__btn{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 12px;
            background: #E71D36;
            border-radius: 8px;
            padding: 19px 0;
            width: 100%;
            max-width:335px;
            font-weight: 800;
            font-size: 20px;
            line-height: 24px;
            text-transform: capitalize;
            color: #FFFFFF;
        }
        /*section four*/
        .section__four{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 96px 16px 0px;
        }
        .sec__four__heading{
            font-weight: 700;
            font-size: 40px;
            line-height: 120%;
            text-align: center;
            color: #313131;
            margin-bottom: 48px;
        }
        .sec__four__btn{
            background: #E71D36;
            border-radius: 8px;
            width: 100%;
            max-width: 335px;
            font-weight: 800;
            font-size: 20px;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
            color: #FFFFFF;
            gap: 12px;
            padding: 19px 0;
        }

        /*section five*/
        .section__five{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 48px 0 96px;
        }
        .sec__five__heading{
            width: 100%;
            max-width: 336px;
            margin-bottom: 48px;
        }
        .sec__five__heading__one{
            font-weight: 500;
            font-size: 28px;
            line-height: 34px;
            text-align: center;
            text-transform: uppercase;
            color: #313131;
        }
        .sec__five__heading__two{
            font-weight: 700;
            font-size: 56px;
            line-height: 108%;
            text-align: center;
            color: #313131;
        }
        .sec__five__heading__two span{
            color: #E71D36;
        }
        .sec__five__card__wrapper{
            display: flex;
            justify-content: center;
            gap: 24px;
            width: 100%;
            max-width: 928px;
            flex-wrap: wrap;
        }
        .sec__five__card{
            border: 1px solid rgba(17, 58, 104, 0.1);
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 18px 10px;
            width: 100%;
            max-width: 452px;
        }
        .sec__card__five__img__wrapper{
            width: 100%;
            max-width: 144px;
            display: flex;
            justify-content: center;
            gap: 12px;
            margin-bottom: 12px;
        }
        .sec__five__card__heading{
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #113A68;
            width: 100%;
            padding-bottom: 23px;
            border-bottom: 1px solid rgba(17, 58, 104, 0.1);;
        }

        .sec__five__offer__wrapper{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            max-width: 386px;
            padding-top: 23px;
        }
        .sec__five__offer__card__right, .sec__five__offer__card__left {
            width: 100%;
            max-width: 173px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 13px;
        }
        .projectt__offerd{
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            color: #113A68;
            gap: 4px;
            background: #FDE9EB;
            border-radius: 4px;
            padding:8.5px 0;
            width: 100%;
        }
        .sec__five__offer__card__info{
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            letter-spacing: -0.07em;
            color: #113A68;
        }
        .sec__five__offer__card__info span{
                font-size: 12px;
                line-height: 14.52px;
        }
        .ticket__size{
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            color: #113A68;
            gap: 4px;
            background: #FDE9EB;
            border-radius: 4px;
            padding:8.5px 0;
            width: 100%;
            max-width: 130px;
        }
        .sec__five__split__top{
            margin-bottom: 24px;
        }
        .sec__five__split__bottom{
            margin-bottom: 48px;
        }
        .sec__five__btn{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 12px;
            max-width: 100%;
            width: 335px;
            background: #E71D36;
            border-radius: 8px;
            padding: 19px 0;
            font-weight: 800;
            font-size: 20px;
            line-height: 24px;
            text-transform: capitalize;
            color: #FFFFFF;
            
        }
        .sec__five__vertical{
                height: 40px;
                width: 2px;
                border-radius: 2px;
                background: #E7EBF0;
                border: none;
            
        }

        /*section six*/
        .section__six{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 105px;
            margin-bottom: 96px;
            flex-wrap: wrap;
        }
        .sec__six__left{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 24px;
        }
        .sec__six__heading{
            font-weight: 700;
            font-size: 48px;
            line-height: 108%;
            text-align: center;
            color: #313131;
        }
        .sec__six__heading span{
            color: #E71D36;
        }
        .sec__six__btn{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 12px;
            max-width: 100%;
            width: 335px;
            background: #E71D36;
            border-radius: 8px;
            padding: 19px 0;
            font-weight: 800;
            font-size: 20px;
            line-height: 24px;
            text-transform: capitalize;
            color: #FFFFFF;
        }
        .sec__six__btn-mobile {
            display: none;
        }
        .sec__six__right{
            width: 100%;
            max-width: 449px;
        }
        .sec__six__right__wrapper{
            width: 100%;
            max-width: 449px;
            background: #FFFFFF;
            border: 2px solid rgba(57, 110, 176, 0.1);
            border-radius: 16px;
            padding-inline: 24px;
        }
        .sec__six__right__div{
            display: flex;
            align-items: center;
            gap:20px;
            padding: 21.75px 0;

        }
        .sec__six__right__div__info{
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            text-transform: capitalize;
            color: #113A68;
            width: 100%;
            max-width: 350px;
        }


        /*section seven*/
        .section__seven{
            display: flex;
            justify-content: center;
            align-items: center;
            gap:105px ;
            margin-bottom: 110.48px;
            flex-wrap: wrap;
        }
        .sec__seven__left__wrapper{
            width: 100%;
            max-width: 449px;
            background: #FFFFFF;
            border: 2px solid rgba(57, 110, 176, 0.1);
            border-radius: 16px;
            padding-inline: 24px;
        }
        
        .sec__seven__left__div{
            display: flex;
            align-items: center;
            gap:20px;
            padding: 44.83px 0;
        }
        .sec__seven__left__div__info{
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            text-transform: capitalize;
            color: #113A68;
            width: 100%;
            max-width: 350px;
        }

        .sec__seven__right{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 43px;
            width: 100%;
            max-width: 381px;
        }
        .sec__seven__heading{
            font-weight: 700;
            font-size: 48px;
            line-height: 108%;
            text-align: center;
            color: #313131;
        }
        .sec__seven__btn{
            background: #E71D36;
            border-radius: 8px;
            width: 100%;
            max-width: 335px;
            font-weight: 800;
            font-size: 20px;
            line-height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
            color: #FFFFFF;
            padding: 19px 0;

        }
        .sec__seven__btn-mobile {
            display: none;
        }
        .sec-four-bottom-div {
            background: #FFFFFF;
            border: 2px solid rgba(57, 110, 176, 0.1);
            border-radius: 16px;
            width: 100%;
            max-width: 928px;
            margin: 0 auto 3rem;
            padding: 34.5px 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            
        }
        .bottom-div-individual-card {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0rem 1rem;
            gap: 1rem;
            border-right: 1px solid rgba(17, 58, 104, 0.1);
        }
        .bottom-div-individual-card:last-child {
            border: none;
        }
        .bottom-div-individual-card  > p{
            font-weight: 600;
            font-size: 1rem;
            text-transform: capitalize;
            color: #113A68;
            max-width: 205px;
        }
        @media (max-width: 500px){
            header{
                border-bottom: none;
                padding: 56px 0 32.52px;

            }
            .sec__one__img__wrapper{
                display: none;
            }
            .sec__one__img__wrapper__mob{
                display: block;
                margin-bottom: 32px;
                width: 100%;
            }
            .sec__one__img__wrapper__mob img{
                width: 100%;
            }
            .sec__one__heading__one{
                font-weight: 600;
                font-size: 24px;
                line-height: 29px;
            }
            .sec__one__canda{
                font-weight: 600;
                font-size: 80px;
                line-height: 97px;
            }
            .sec__one__heading__three{
                font-weight: 700;
                font-size: 36px;
                line-height: 44px;
                max-width: 150px;
            }
            .sec__One__heading__wrapper{
                margin-bottom: 32px;
            }
            .sec__one__card{
                padding: 20px 0;
                width: 100%;
                gap: 12px;
            }
            .sec__one__card__wrapper{
                padding: 0rem 1rem;
            }
            .sec__one__card__info{
                max-width: 260px;
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
            }
            ::placeholder{
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;

            }
            .section__two{
                padding-top: 56px;
                padding-bottom: 72px;
                gap: 1rem;

            }
            .sec__two__img__hand{
                margin-top: 1rem;
            }
            form{
                padding: 32px 18px 24px;
                width: 100%;
            }
            .section__one {
                padding: 0px 0px 72px;
            }
            .sec__two__right, .sec__two__form__wrapper {
                max-width: 100%;
            }
            .sec__two__left__one {
                font-weight: 700;
                font-size: 36px;
                line-height: 108%;
            }
            .sec__two__left {
                width: 100%;
            }
            .sectio__three {
                padding: 2.5rem 1rem 3.5rem;
                align-items: flex-start;
            }
            .sec__three__logo__wrapper {
                max-width: 360px;
            }
            .sec__three__advantage__img__wrapper {
                max-width: 100%;
                margin-bottom: 2.5rem;
            }
            .sec__three__adv__card {
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                padding: 32px 9.25px;
                width: 100%;
                max-width: 100%;
            }
            .sec__three__card__main__wrapper {
                width: 100%;
            }
            .sec__three__adv__info {
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
            }
            .sec__three__advantage__card__wrapper {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
            .sec__three__btn {
                max-width: 100%;
            }
            .section__four {
                padding: 0rem 1rem 3.5rem;
            }
            .sec__four__heading {
                margin-bottom: 1.5rem;
            }
            .sec-four-bottom-div {
                flex-direction: column;
                padding: 0rem 24px 34.5px;
            }
            .bottom-div-individual-card {
                width: 100%;
                padding: 34.5px 0 34.5px;

                border-bottom: 1px solid rgba(17, 58, 104, 0.1);
                border-right:none;
                align-items: center;
                justify-content: center;
            }
            .bottom-div-individual-card:last-child {
                padding-bottom: 0;
            }
            .sec__four__btn {
                max-width: 100%;
            }
            .section__five {
                padding: 0rem 1rem 3.5rem;
            }
            .sec__five__heading__one {
                font-weight: 600;
                font-size: 1.5rem;
                line-height: 29px;
            }
            .sec__five__heading__two {
                font-weight: 700;
                font-size: 36px;
                line-height: 39px;
                text-align: center;
            }
            .sec__five__heading {
                margin-bottom: 1.5rem;
            }
            .sec__five__card {
                max-width: 100%;
            }
            .sec__five__vertical {
                height: 40px;
                width: 2px;
                border-radius: 2px;
                background: #E7EBF0;
                border: none;
            }
            .sec__five__offer__wrapper {
                align-items: flex-end;
            }
            .sec__five__btn {
                width: 100%;
            }
            .sec__six__heading {
                font-weight: 700;
                font-size: 36px;
                line-height: 108%;
            }
            .sec__six__btn{
                display: none;
            }
            .sec__six__btn-mobile {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 12px;
                max-width: 100%;
                width: 100%;
                background: #E71D36;
                border-radius: 8px;
                padding: 19px 0;
                font-weight: 800;
                font-size: 20px;
                line-height: 24px;
                text-transform: capitalize;
                color: #FFFFFF;
            }
            .sec__six__left {
                width: 100%;
            }
            .section__six {
                gap: 2rem;
                padding: 0rem 1rem 3.5rem;
                margin-bottom: 0rem;
            }
            .sec__six__right__div {
                padding: 0;
            }
            .sec__six__right__div__info {
                padding: 21.75px 0;
            }
            .sec__six__right__wrapper {
                border: none;
            }
            .unique-p {
                border-bottom: none !important;
            }
            .section__seven {
                padding: 0rem 1rem 3.5rem;
                flex-direction: column-reverse;
                gap: 2rem;
                margin-bottom: 0;
            }
            .sec__seven__btn{
                display: none;

        }
        .sec__seven__btn-mobile {
            background: #E71D36;
                border-radius: 8px;
                width: 100%;
                max-width: 100%;
                font-weight: 800;
                font-size: 20px;
                line-height: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: capitalize;
                color: #FFFFFF;
                padding: 19px 0;
        }
            .sec__seven__right {
                width: 100%;
                max-width: 100%;
                gap: 2rem;
            }
            .sec__seven__left__div {
                padding: 21.75px 0px;
            }
            .sec__seven__left__wrapper {
                border: none;
            }
        }
`;

export default StudyAbroadPartnerStyled;
