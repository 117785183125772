// extracted by mini-css-extract-plugin
export var courseDetails = "CourseCard-module--course-details--2afce";
export var courseDetails__icon = "CourseCard-module--course-details__icon--f7bf4";
export var courseDetails__scholarshipIcon = "CourseCard-module--course-details__scholarship-icon--dc05c";
export var courseDetails__title = "CourseCard-module--course-details__title--923b3";
export var courseDetails__value = "CourseCard-module--course-details__value--8cb27";
export var shortlist__icon = "CourseCard-module--shortlist__icon--4768a";
export var shortlisted = "CourseCard-module--shortlisted--96a4a";
export var universityAction = "CourseCard-module--university-action--61df4";
export var universityCard = "CourseCard-module--university-card--1d695";
export var universityDetails = "CourseCard-module--university-details--97f02";
export var universityLocation = "CourseCard-module--university-location--40d1a";
export var universityLogoImg = "CourseCard-module--university-logo-img--013ed";
export var universityName = "CourseCard-module--university-name--a23d2";
export var universityRank = "CourseCard-module--university-rank--4b9d0";
export var universityRanking = "CourseCard-module--university-ranking--63ff7";
export var universityShortlist = "CourseCard-module--university-shortlist--62636";
export var universityView = "CourseCard-module--university-view--4d63b";