import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout.component';
import UniversitiesByCountry from '../components/study-abroad/university-search/UniversitiesByCountry';
import { Link } from 'gatsby';
import { useStudyData } from '../hooks/study';
import RightSection from '../components/studyPage/rightSection';
const isBrowser = typeof window !== 'undefined';
const ProfileEvaluationResult = (props) => {
    let profile_evaluation;
    if (isBrowser) {
        profile_evaluation = localStorage.getItem('profile_evaluation');
        profile_evaluation = JSON.parse(profile_evaluation);
    }
    const studyPage = useStudyData();

    if (profile_evaluation === undefined || profile_evaluation == null) {
        return (
            <>
                Please Evaluate your profile{' '}
                <Link to={'/profile-evaluation'}>here</Link>
            </>
        );
    }

    let nameCountry = {
        'United States': 'study-in-usa',
        'United Kingdom': 'study-in-uk',
        Canada: 'study-in-canada',
        Germany: 'study-in-germany',
        'New Zealand': 'study-in-new-zealand',
        Australia: 'study-in-australia',
    };

    let aStudyPages = studyPage.filter(
        (item) =>
            item.slug === nameCountry[profile_evaluation?.preferredcountry] &&
            item.page !== 'case-studie'
    );

    let aStudyPage = aStudyPages[0];
    let study_content = aStudyPage.study_content.filter((item) => {
        if (item.heading === 'Top Courses') {
            return aStudyPage;
        }
    });

    return (
        <Layout>
            <UniversitySearchContainer>
                <UniversitySearchTitle>
                    <h2>Your Profile Report</h2>
                    <SubHeading>Hello, {profile_evaluation?.name}</SubHeading>
                    <SubHeading fontSize="1.5rem">
                        Congratulations!, you have a bright chances for studying
                        in {''}
                        {profile_evaluation?.preferredcountry}
                    </SubHeading>

                    <SubHeading color="#396EB0" fontSize="1.3rem">
                        Your desired IELTS Score
                    </SubHeading>
                    <SubHeading
                        color="#F4805E"
                        fontWeight="700"
                        fontSize="2.5rem"
                    >
                        {profile_evaluation?.targetIeltsExam}
                    </SubHeading>
                    <SubHeading color="#396EB0" fontSize="1.3rem">
                        Number of top universities you are eligible for :{' '}
                        <strong>{profile_evaluation?.no_university}</strong>
                    </SubHeading>
                </UniversitySearchTitle>
                <UniversitiesByCountry
                    country={profile_evaluation?.preferredcountry}
                    heading={'Top universities in '}
                    showViewAll={false}
                    showExploreUniversity={true}
                />
                <p style={{ marginBottom: '1.3rem' }}>
                    Top Courses you can pursue in{' '}
                    {profile_evaluation?.preferredcountry}
                </p>
                <RightSection aStudyPage={{ ...aStudyPage, study_content }} />
            </UniversitySearchContainer>
        </Layout>
    );
};

const SubHeading = styled.div`
    font-weight: ${(props) => props.fontWeight || 400};
    font-size: ${(props) => props.fontSize || '1.5rem'};
    line-height: 29px;
    text-align: center;
    color: ${(props) => props.color || '#396eb0'};

    margin: ${(props) => props.margin || '1rem'};
    max-width: ${(props) => props.maxWidth || ''};
    @media (max-width: 500px) {
        align-items: flex-start;
        text-align: left;
        font-weight: ${(props) => props.fontWeight || 400};
        font-size: 1rem;
        line-height: 21px;
        margin-bottom: 1rem;
    }
`;
const UniversitySearchContainer = styled.div`
    max-width: 1120px;
    margin: 0 auto;
    p {
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 24px;
        color: #113a68;
    }
    @media (max-width: 1200px) {
        max-width: 700px;
    }

    @media (max-width: 768px) {
        padding: 1rem;
    }

    @media (max-width: 480px) {
        padding: 0.8rem;
    }

    @media (max-width: 400px) {
        padding: 0.5rem;
    }
`;

const UniversitySearchTitle = styled.div`
    padding: 10rem 0 3rem 0;

    h2 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 900;
        font-size: 2.5rem;
        line-height: 50px;
        text-align: center;
        color: #113a68;
        margin-bottom: 1rem;
    }

    p {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 29px;
        text-align: center;
        color: #396eb0;
    }

    @media (max-width: 768px) {
        h2 {
            font-size: 1.75rem;
            line-height: 34px;
        }

        p {
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
            color: #9cb7d7;
        }
    }
`;

export default ProfileEvaluationResult;
