import React from 'react';
import styled from 'styled-components';
function MarketingFooter() {
    return (
        <MarketingFooterStyle>
            <div className="section_1">
                <h3>Study Abroad Consultants In</h3>
                <p>Study Abroad Consultants in Mumbai</p>
                <p>Study Abroad Consultants in Delhi</p>
                <p>Study Abroad Consultants in Bangalore</p>
                <p>Study Abroad Consultants in Hyderabad</p>
                <p>Study Abroad Consultants in Pune</p>
                <p>Study Abroad Consultants in Nashik</p>
                <p>Study Abroad Consultants in Nagpur</p>
                <p>Study Abroad Consultants in Kochi</p>
                <p>Study Abroad Consultants in Coimbatore</p>
                <p>Study Abroad Consultants in Chennai</p>
                <p>Study Abroad Consultants in Kolkata</p>
                <p>Study Abroad Consultants in Vizag</p>
                <p>Study Abroad Consultants in Guntur</p>
                <p>Study Abroad Consultants in Vijayawada</p>
                <p>Study Abroad Consultants in Ahmedabad</p>
                <p>Study Abroad Consultants in Vadodara</p>
                <p>Study Abroad Consultants in Jaipur</p>
            </div>
            <div className="section_2">
                <h3>Study Abroad</h3>
                <p>Study In UK</p>
                <p>Study In USA</p>
                <p>Study In Canada</p>
                <p>Study In Australia</p>
            </div>
            <div className="section_3">
                <h3>Master Degree</h3>
                <p>Masters Degree In Canada</p>
                <p>Masters Degree In USA</p>
                <p>Masters Degree In Australia</p>
                <p>Masters Degree In UK</p>
            </div>
        </MarketingFooterStyle>
    );
}

const MarketingFooterStyle = styled.footer`
    h3 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        color: #7cafe9;
        margin-bottom: 18px;
    }
    p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        color: #ffffff;
    }
    background-color: var(--dark-blue);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    padding: 75px 10px;
    @media (max-width: 600px) {
        grid-template-columns: 1fr;
        gap: 40px;
        text-align: center;
    }
`;
export default MarketingFooter;
