import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Layout from '../../components/layout.component';
import UniversitiesByCountry from '../../components/study-abroad/university-search/UniversitiesByCountry';
import UniversitySearchResults from '../../components/study-abroad/university-search/university-search-results/UniversitySearchResults';
import UniversityFilter from '../../components/study-abroad/university-search/UniversityFilter';
import UniversitySearch from '../../components/study-abroad/university-search/UniversitySearch';
import useAPI from '../../hooks/study-abroad/university-search/useAPI';
import SEO from '../../components/seo';
import {
    countryOptions,
    universityTypeOptions,
    rankingOptions,
    coursesOptions,
} from '../../data';
import Universities from '../../components/study-abroad/university-search/Universities';
import SearchCourseCard from '../../components/study-abroad/course-card/SearchCourseCard';
const UniversitySearchPage = (props) => {
    let receivedFilters = [];
    const [searchUniversity, setSearchUniversity] = useState(null);
    const [searchCourse, setSearchCourse] = useState(null);
    const [searchText, setSearchText] = useState('');

    if (props.location.state) {
        receivedFilters = props.location.state.receivedFilters ?? [];
        // receivedFilters = [];
    } else {
        receivedFilters = [];
    }

    const [filters, setFilters] = useState(receivedFilters);
    const [url, setUrl] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    const [universities, setUniversities] = useState([]);
    const { getUniversities } = useAPI();

    useEffect(() => {
        const urlDomain = `https://fapi.ufaber.com/study-abroad/universities/`;
        let query = '';
        if (filters) {
            filters.forEach((filter) => {
                if (query) {
                    query = query + `&${filter.filter}=${filter.value}`;
                } else {
                    query = query + `?${filter.filter}=${filter.value}`;
                }
            });

            setUrl(urlDomain + query);
        }
    }, [filters]);

    useEffect(() => {
        const asyncFn = async () => {
            const universities = await getUniversities(url);

            if (
                universities.data.length > 0 &&
                universities.pagination.length > 0
            ) {
                setUniversities(universities.data);
                setTotalPages(universities.pagination[0]['total_pages']);
            } else {
                setUniversities([]);
                setTotalPages(0);
            }
        };
        asyncFn();
    }, [url]);

    const clearFiltersHandler = () => {
        setFilters([]);
        setSearchText('');
        setSearchCourse(null);
        setSearchUniversity(null);
    };

    const filterChangeHandler = (filter) => {
        // const newFilters = filters.filter((ftr) => ftr.filter !== filter.filter);

        // setFilters([...newFilters, filter]);
        setFilters((prevValue) =>
            prevValue.filter((ftr) => ftr.filter !== filter.filter)
        );
        setFilters((prevValue) => [...prevValue, filter]);
    };

    return (
        <Layout>
            <SEO 
                title="Abroad Ninja: University & College Study Abroad Programs" 
                description="Explore study abroad university search with Abroad Ninja. Discover top universities, courses, and programs worldwide. Use our search tool to find your perfect education destination and embark on a transformative learning experience!"
                og_type="website"
                og_url="https://abroadninja.in/study-abroad/university-search/"
                og_title="Abroad Ninja: University & College Study Abroad Programs"
                og_description="Explore study abroad university search with Abroad Ninja. Discover top universities, courses, and programs worldwide. Use our search tool to find your perfect education destination and embark on a transformative learning experience!"
                og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
                twitter_card="summary_large_image"
                twitter_url="https://abroadninja.in/study-abroad/university-search/"
                twitter_title="Abroad Ninja: University & College Study Abroad Programs"
                twitter_description="Explore study abroad university search with Abroad Ninja. Discover top universities, courses, and programs worldwide. Use our search tool to find your perfect education destination and embark on a transformative learning experience!"
                twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
                robots="index, follow"
                canonical="https://abroadninja.in/study-abroad/university-search/"
            />
            <UniversitySearchContainer>
                <UniversitySearchTitle>
                    <h1>University Search</h1>
                    <p>Explore your dream universities for studying abroad</p>
                </UniversitySearchTitle>
                <Search>
                    <UniversitySearch
                        setSearchUniversity={setSearchUniversity}
                        setSearchCourse={setSearchCourse}
                        setSearchText={setSearchText}
                        searchText={searchText}
                    />

                    <UniversityFilters>
                        <UniversityFilter
                            onFilterChange={filterChangeHandler}
                            filterName="Courses"
                            filterValue={
                                filters &&
                                filters.find(
                                    (ftr) => ftr.filter === 'f_department_names'
                                ) &&
                                filters.find(
                                    (ftr) => ftr.filter === 'f_department_names'
                                )['value']
                            }
                            options={coursesOptions}
                            filterSelected={
                                filters &&
                                filters.find(
                                    (ftr) => ftr.filter === 'f_department_names'
                                ) !== undefined
                            }
                        />
                        <UniversityFilter
                            onFilterChange={filterChangeHandler}
                            filterName="Country"
                            filterValue={
                                filters &&
                                filters.find(
                                    (ftr) => ftr.filter === 'f_country'
                                ) &&
                                filters.find(
                                    (ftr) => ftr.filter === 'f_country'
                                )['value']
                            }
                            options={countryOptions}
                            filterSelected={
                                filters &&
                                filters.find(
                                    (ftr) => ftr.filter === 'f_country'
                                ) !== undefined
                            }
                        />
                        <UniversityFilter
                            onFilterChange={filterChangeHandler}
                            filterName="University Type"
                            filterValue={
                                filters &&
                                filters.find(
                                    (ftr) => ftr.filter === 'f_university_type'
                                ) &&
                                filters.find(
                                    (ftr) => ftr.filter === 'f_university_type'
                                )['value']
                            }
                            options={universityTypeOptions}
                            filterSelected={
                                filters &&
                                filters.find(
                                    (ftr) => ftr.filter === 'f_university_type'
                                ) !== undefined
                            }
                        />
                        <UniversityFilter
                            onFilterChange={filterChangeHandler}
                            filterName="Ranking"
                            filterValue={
                                filters &&
                                filters.find(
                                    (ftr) => ftr.filter === 'f_top_univ'
                                ) &&
                                filters.find(
                                    (ftr) => ftr.filter === 'f_top_univ'
                                )['label']
                            }
                            options={rankingOptions}
                            filterSelected={
                                filters &&
                                filters.find(
                                    (ftr) => ftr.filter === 'f_top_univ'
                                ) !== undefined
                            }
                        />
                    </UniversityFilters>
                    <Clear onClick={clearFiltersHandler}>
                        <p>Clear All</p>
                    </Clear>
                </Search>

                {filters.length === 0 &&
                    searchUniversity == null &&
                    searchCourse == null && (
                        <div>
                            <UniversitiesByCountry
                                viewAllHandler={filterChangeHandler}
                                country="United States"
                            />
                            <UniversitiesByCountry
                                viewAllHandler={filterChangeHandler}
                                country="Canada"
                            />
                            <UniversitiesByCountry
                                viewAllHandler={filterChangeHandler}
                                country="United Kingdom"
                            />
                        </div>
                    )}
                {searchUniversity && (
                    <Universities
                        universities={searchUniversity}
                        searchText={searchText}
                    />
                )}
                {searchCourse && (
                    <>
                        {searchText && (
                            <SearchHeading>
                                {`Search in "${searchText}" in Course`}
                            </SearchHeading>
                        )}

                        <FlexContainer>
                            {searchCourse.map((course) => (
                                <SearchCourseCard course={course} />
                            ))}
                        </FlexContainer>
                    </>
                )}
                {filters.length > 0 && universities && (
                    <UniversitySearchResults
                        url={url}
                        universitiesData={universities}
                        totalPages={totalPages}
                        filters={filters}
                    />
                )}
            </UniversitySearchContainer>
        </Layout>
    );
};

const SearchHeading = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 1rem;
    line-height: 25px;
    color: #113a68;
    margin-bottom: 1rem;
`;
const UniversitySearchContainer = styled.div`
    max-width: 1120px;
    margin: 0 auto;

    @media (max-width: 1200px) {
        max-width: 700px;
    }

    @media (max-width: 768px) {
        max-width: 600px;
        padding: 0rem 1rem;
    }

    @media (max-width: 500px) {
        max-width: 500px;
    }
`;

const UniversitySearchTitle = styled.div`
    padding: 10rem 0 3rem 0;

    h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 900;
        font-size: 2.5rem;
        line-height: 50px;
        text-align: center;
        color: #113a68;
        margin-bottom: 1rem;
    }

    p {
        font-weight: 400;
        font-size: 1.3rem;
        line-height: 29px;
        text-align: center;
        color: #396eb0;
    }

    @media (max-width: 768px) {
        h1 {
            font-size: 1.75rem;
            line-height: 34px;
        }

        p {
            font-weight: 300;
            font-size: 14px;
            line-height: 17px;
            color: #9cb7d7;
        }
    }
`;

const Search = styled.div`
    display: flex;
    gap: 1.5rem;
    margin-bottom: 3rem;

    @media (max-width: 1200px) {
        flex-direction: column;
    }
`;

const Clear = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    p {
        color: #fc997c;
        border-bottom: 1px solid #fc997c;
        font-weight: 500;
    }
`;

const UniversityFilters = styled.div`
    display: flex;
    gap: 0.8rem;
    align-items: center;
    flex-wrap: wrap;
`;

const FlexContainer = styled.div`
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
`;

export default UniversitySearchPage;
