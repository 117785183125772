import React from 'react';
import { getRankMessage } from '../../../utils';
import universityLogo from '../../../assets/images/university-logo.png';
import globeImage from '../../../assets/icons/globe.svg';
import courseIcon from '../../../assets/icons/course.svg';
import scholarshipIcon from '../../../assets/icons/scholarship.svg';
import tuitionFeeIcon from '../../../assets/icons/tuition-fee.svg';
import * as styles from './CourseCard.module.css';
import { Link } from 'gatsby';

const CourseCard = (props) => {
    const { university } = props;
    return (
        <>
            {university.courses.map((course) => {
                return (
                    <>
                        <div className={styles['universityCard']}>
                            <div className={styles['universityDetails']}>
                                <img
                                    className={styles['universityLogoImg']}
                                    src={universityLogo}
                                    alt="universityLogo"
                                />
                                <div>
                                    <p className={styles['universityName']}>
                                        {university.name}
                                    </p>
                                    <p className={styles['universityLocation']}>
                                        {university.city},{' '}
                                        <b>{university.country}</b>
                                    </p>
                                </div>
                            </div>
                            <div className={styles['universityAction']}>
                                <div>
                                    {university.minrank &&
                                        university.maxrank && (
                                            <div
                                                className={
                                                    styles['universityRanking']
                                                }
                                            >
                                                <img src={globeImage} />
                                                <p
                                                    className={
                                                        styles['universityRank']
                                                    }
                                                >
                                                    {getRankMessage(
                                                        university.minrank,
                                                        university.maxrank
                                                    ) ?? ''}
                                                </p>
                                            </div>
                                        )}
                                    <div
                                        className={
                                            styles[
                                                'courseDetails__scholarshipIcon'
                                            ]
                                        }
                                    >
                                        <img
                                            src={scholarshipIcon}
                                            alt="Scholarship icon"
                                        />
                                    </div>
                                </div>
                                {/* <button
                                    onClick={() =>
                                        onClickShortlistCourse(
                                            isshortlisted,
                                            course
                                        )
                                    }
                                    className={`${
                                        isshortlisted
                                            ? `${styles['university-shortlist']} ${styles['shortlisted']}`
                                            : styles['university-shortlist']
                                    }`}
                                >
                                    {isshortlisted ? (
                                        <img
                                            src={selectedShortlistIcon}
                                            alt="Shortlist icon"
                                        />
                                    ) : (
                                        <img
                                            src={shortlistIcon}
                                            alt="Shortlist icon"
                                        />
                                    )}
                                    <span>
                                        {isshortlisted
                                            ? 'Shortlisted'
                                            : 'Shortlist'}
                                    </span>
                                </button> */}
                            </div>
                            <div className={styles['courseDetails']}>
                                <div>
                                    <div
                                        className={
                                            styles['courseDetails__icon']
                                        }
                                    >
                                        <img
                                            src={courseIcon}
                                            alt="Course Icon"
                                        />
                                    </div>
                                    <div>
                                        <p
                                            className={
                                                styles['courseDetails__title']
                                            }
                                        >
                                            Course Name - {course.courseID}
                                        </p>
                                        <p
                                            className={
                                                styles['courseDetails__value']
                                            }
                                        >
                                            {course.info.courseName}
                                        </p>
                                    </div>
                                </div>
                                <div>
                                    <div
                                        className={
                                            styles['courseDetails__icon']
                                        }
                                    >
                                        <img src={tuitionFeeIcon} />
                                    </div>
                                    <div>
                                        <p
                                            className={
                                                styles['courseDetails__title']
                                            }
                                        >
                                            Average Tuition Fee
                                        </p>
                                        <p
                                            className={
                                                styles['courseDetails__value']
                                            }
                                        >
                                            {course.tuitionFee.annualTuitionFee}
                                            /yr
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Link
                                    to={`/study-abroad/course-details/?course=${course.courseID}`}
                                    className={styles['universityView']}
                                >
                                    View Details
                                </Link>
                            </div>
                        </div>
                    </>
                );
            })}
        </>
    );
};

export default CourseCard;
