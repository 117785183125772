import React from "react";
import Index from "../components/BestCanadaPRconsultant";

const BestCanadaPRconsultant = () => {
    return (
        <Index />
    )

}

export default BestCanadaPRconsultant;