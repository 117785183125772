import React from 'react'
import styled from "styled-components";

const LORandScholarship = () => {

    return (
        <LORandScholarshipOuterStyled>
            <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/1a3e46eb4e7a7e6ce4667603dc13b27f.svg" alt=""/>

            <LORandScholarshipSuccessStyled>
                <h2>Our success:</h2>

                <h6>Scholarship success rate <span>60%</span></h6>

                <div>
                    <a href="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/157793d50be7bd678f3ce06acec5f5e6.svg" target='_blank' ><img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/380c7896c30e9afbd5b5bd1781e2cce2.svg" alt="" /></a>
                    <a href="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/0da2837bd5a85e4d8a14c1ebc5adc1e5.svg" target='_blank' ><img src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/eba39e1b171e16b12b70982bec3501b1.168630606412995" alt="" /></a>
                </div>

                <h6><span>High</span> ranked universities</h6>

                <div>
                    <a href="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/848a53a552d811bff65e2d6dbfc662dc.svg" target='_blank' ><img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/0b75fba23e0c902ed05042ddd22d1cf2.svg" alt="" /></a>
                    <a href="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/15668aa00771aa3dc7664a26c57c2feb.svg" target='_blank' ><img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/ca693a6b488492e1693878d8d28e8049.svg" alt="" /></a>
                </div>

            </LORandScholarshipSuccessStyled>

        </LORandScholarshipOuterStyled>
    )
}

export default LORandScholarship


const LORandScholarshipOuterStyled = styled.section`

    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
	display: flex;
    flex-direction: column;
    width: 82%;
    padding: 50px 0px;


    h1 {
        text-align: left;
        padding-left: 20px;
        border-left: 13px solid #DC3E3E;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 35px;
        line-height: 33px;
        leading-trim: both;
        text-edge: cap;
        color: #113A68;
    }

    h3 {
        font-family: 'Open Sans';
        margin: 50px 0 10px;
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 21px;
        leading-trim: both;
        text-edge: cap;
        color: #113A68;
    }
`;

const LORandScholarshipCardsStyled = styled.section`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    padding: 30px 0px 50px;

    .card-img {
        width: 31%;
    }
    
`;

const LORandScholarshipSuccessStyled = styled.section`
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

    h2 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 31px;
        leading-trim: both;
        text-edge: cap;
        color: #113A68;
        padding: 20px 0;
    }

    h6 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 19px;
        leading-trim: both;
        text-edge: cap;
        color: #113A68;
        padding: 25px 0;
    }

    h6 span {
        font-size: 24px;
        color: #0BA111;
    }

    div {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    div a {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 30px;
        cursor: pointer;
    }

    div a img {
        width:80%;
    }
`;