import React from "react";
import styled from "styled-components";
import Hero from "./hero";
import Gooduniversity from "./goodUniversity";
import IndustryDefyingPerformance from "./industryDefyingPerformance";
import HowWeDo from "./howWeDo";
import ExpertisAndReach from "./expertisAndReach";
import ProgramForIndividual from "./programForIndividual";
import NeedMoreClarity from "./needMoreClarity";
import DoYourOwnResearch from "./doYourOwnResearch";
import ReportAndArticle from "./artice";

const StudyAbroadPage = () => {
	return (
		<StudyAbroadPageContainer>
			<Hero />
			<Gooduniversity />
			<IndustryDefyingPerformance />
			<HowWeDo />
			<ExpertisAndReach />
			<ProgramForIndividual />
			<NeedMoreClarity />
			<DoYourOwnResearch />
			<ReportAndArticle />
		</StudyAbroadPageContainer>
	);
};

const StudyAbroadPageContainer = styled.div`
	background-image: url("https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/09472a56ed99461fbedcf8561423a705.svg");
	background-repeat: no-repeat;
	background-position: top;
	background-size: 100%;
	@media (max-width: 600px) {
		background-image: url("https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/096db779ef6644a183f4d62a481f36c7.svg");
	}
`;

export default StudyAbroadPage;
