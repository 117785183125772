import { useStaticQuery, graphql } from "gatsby";

export const useAboutUsData = () => {
	const { allStrapiAboutUs } = useStaticQuery(
		graphql`
			query aboutUsData {
				allStrapiAboutUs {
					nodes {
						heading
						about_us_desc {
							strapi_id
							description
						}
						our_product {
							strapi_id
							redirect_to
							product_img {
								url
								name
							}
						}
						our_teams {
							name
							desg
							team_quote
							team_img {
								url
								name
							}
						}
					}
				}
			}
		`
	);
	return allStrapiAboutUs.nodes[0];
};
