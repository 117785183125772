import ReactPaginate from 'react-paginate';
import React, { useState, useEffect } from 'react';
import Universities from '../Universities';
import useAPI from '../../../../hooks/study-abroad/university-search/useAPI';
import {
    pageItem,
    active,
    pagination,
} from '../university-search-results/UniversitySearchResults.module.css';
import styled from 'styled-components';
// import NotFoundPage from "../../../../pages/study-abroad/not-found";
import { Link } from 'gatsby';
import CourseCard from '../../course-card/CourseCard';

const UniversitySearchResults = (props) => {
    const { universitiesData, totalPages, url, filters } = props;

    // const [page, setPage] = useState(0);
    const [universities, setUniversities] = useState([]);
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        setUniversities(universitiesData);
        setPageCount(totalPages);
    }, [universitiesData, totalPages]);

    const { getUniversities } = useAPI();

    const fetchUniversities = async (currentPage) => {
        if (url.includes('?')) {
            const universities = await getUniversities(
                `${url}&page=${currentPage}`
            );
            setUniversities(universities.data);
            setPageCount(universities.pagination[0]['total_pages']);
        } else {
            const universities = await getUniversities(
                `${url}?page=${currentPage}`
            );
            setUniversities(universities.data);
            setPageCount(universities.pagination[0]['total_pages']);
        }
    };

    const handlePageClick = async (data) => {
        let currentPage = data.selected + 1;
        await fetchUniversities(currentPage);
    };

    let selectedFilter = filters.filter(
        (item) => item.filter === 'f_department_names'
    );

    return (
        <div>
            <Title>
                <p>
                    Showing{' '}
                    {selectedFilter.length > 0 ? 'Courses' : 'Universities'}
                </p>
            </Title>
            {universities.length === 0 && totalPages === 0 ? (
                // <NotFoundPage />
                <Message>
                    <p>
                        We are currently working on this university details, to
                        know more you can contact our counsellor
                    </p>
                    <ScheduleSessionLink
                        to="https://abroadninja.in/abroad-form?formName=study-abroad&source=abroad-ninja&utm_sou[…]medium=medium&language=lang&brandid=17&do_what=-&segment=1880"
                        target="_blank"
                    >
                        Schedule Session
                    </ScheduleSessionLink>
                </Message>
            ) : (
                // <NoUniversity>
                //   <p>We are currently working on this university details, to know more
                //     you can contact our counsellor</p>
                // </NoUniversity>
                <div>
                    {universities.length > 0 && selectedFilter.length > 0 && (
                        <FlexContainer>
                            {universities.map((university) => (
                                <CourseCard university={university} />
                            ))}
                        </FlexContainer>
                    )}
                    {selectedFilter.length === 0 && (
                        <Universities universities={universities} />
                    )}

                    <ReactPaginate
                        previousLabel={'< Previous'}
                        nextLabel={'Next >'}
                        breakLabel={'...'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={pagination}
                        pageClassName={pageItem}
                        // pageLinkClassName={"page-link"}
                        previousClassName={pageItem}
                        // previousLinkClassName={"page-link"}
                        nextClassName={pageItem}
                        // nextLinkClassName={"page-link"}
                        breakClassName={pageItem}
                        // breakLinkClassName={"page-link"}
                        activeClassName={active}
                    />
                </div>
            )}
        </div>
    );
};

const Title = styled.div`
    p {
        color: #113a68;
        margin-bottom: 1rem;
        font-weight: 500;
    }
`;

// const NoUniversity = styled.div`
// 	padding-top: 5rem;
// 	padding-bottom: 10rem;
// 	p {
// 		display: flex;
// 		align-items: center;
// 		justify-content: center;
// 		color: #113a68;
// 	}
// `;

const Message = styled.div`
    p {
        text-align: center;
    }
    box-sizing: border-box;
    display: flex;
    gap: 3rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem 0 10rem 0;
`;

const ScheduleSessionLink = styled(Link)`
    background-color: #113a68;
    color: #fff;
    border: none;
    padding: 1rem 2rem;
    border-radius: 8px;
    font-weight: 400;
    font-size: 1rem;
`;
const FlexContainer = styled.div`
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
`;

export default UniversitySearchResults;
