import { useStaticQuery, graphql } from "gatsby";

export const useSuccessStoriesHeaderData = () => {
	const { allStrapiSuccessStoriesHeader } = useStaticQuery(
		graphql`
			query successStoriesHeader {
				allStrapiSuccessStoriesHeader {
					nodes {
						header
						subheading
						slug
						section
					}
				}
			}
		`
	);
	return allStrapiSuccessStoriesHeader.nodes;
};
