import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import downArrowIcon from '../../../assets/icons/down-arrow.svg';

const UniversityFilter = (props) => {
    const { onFilterChange, filterName, filterValue, options, filterSelected } =
        props;

    const [isOptionsVisible, setIsOptionsVisible] = useState(false);
    const filterRef = useRef();

    // const [filter, setFilter] = useState(filterValue);

    // if (filterValue) {
    // } else {
    //   const [filter, setFilter] = useState();
    // }
    const [isSelected, setIsSelected] = useState(false);
    // const [filter, setFilter] = useState({});

    useEffect(() => {
        setIsSelected(filterSelected);
    }, [filterSelected]);

    const handleClickOutside = (event) => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            setIsOptionsVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    const selectFilterHandler = (filter, value, label) => {
        // setFilter(value);
        setIsSelected(true);
        // setFilterValue(value);
        setIsOptionsVisible(false);
        onFilterChange({ filter: filter, value: value, label: label });
    };

    const filterClickHandler = () => {
        setIsOptionsVisible((prevValue) => !prevValue);
    };

    return (
        <FilterContainer>
            <Filter onClick={filterClickHandler} isSelected={isSelected}>
                {/* <p>{filter && isSelected ? filter : filterName}</p> */}
                <p>{isSelected ? filterValue : filterName}</p>
                <img src={downArrowIcon} alt="Down Arrow Icon" />
            </Filter>
            {isOptionsVisible && (
                <Options ref={filterRef}>
                    {options.map((option) => {
                        return (
                            <Option key={option.label}>
                                <p
                                    onClick={() =>
                                        selectFilterHandler(
                                            option.filter,
                                            option.value,
                                            option.label
                                        )
                                    }
                                >
                                    {option.label}
                                </p>
                            </Option>
                        );
                    })}
                </Options>
            )}
        </FilterContainer>
    );
};

const FilterContainer = styled.div`
    position: relative;
`;

const Filter = styled.div`
    ${({ isSelected }) => isSelected && `background-color: #ffd6c9;`}
    border-radius: 12px;
    border: 1px solid #ffd6c9;
    color: #113a68;
    font-size: 1rem;
    padding: 0.7rem 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const Options = styled.div`
    position: absolute;
    width: fit-content;
    background-color: #fff;
    left: 0;
    top: 3rem;
    display: flex;
    gap: 0.15rem;
    flex-direction: column;
    border: 1px solid #ffd6c9;
    border-radius: 12px;
    padding: 2px;
    z-index: 10;
`;

const Option = styled.div`
    border-radius: 12px;
    cursor: pointer;

    p {
        white-space: nowrap;
        :hover {
            background-color: #ffd6c9;
        }
        border-radius: 9px;
        background-color: #fff;
        color: #113a68;
        padding: 0.7rem 0.5rem;
    }
`;

export default UniversityFilter;
