import React from "react";
import Layout from "../components/layout.component";
import Index from "../components/FlashNews";
import SEO from "../components/seo";
const FlashNews = () => {
	return (
		<Layout>
			<SEO 
				title="Flash News | Abroad Ninja" 
				description="Get real-time updates with Abroad Ninja's flash news. Stay informed about the latest announcements, scholarship opportunities, study abroad trends, and more. Don't miss out on valuable information - stay connected today!"
				og_type="website"
				og_url="https://abroadninja.in/flash-news/"
				og_title="Flash News | Abroad Ninja"
				og_description="Get real-time updates with Abroad Ninja's flash news. Stay informed about the latest announcements, scholarship opportunities, study abroad trends, and more. Don't miss out on valuable information - stay connected today!"
				og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
				twitter_card="summary_large_image"
				twitter_url="https://abroadninja.in/flash-news/"
				twitter_title="Flash News | Abroad Ninja"
				twitter_description="Get real-time updates with Abroad Ninja's flash news. Stay informed about the latest announcements, scholarship opportunities, study abroad trends, and more. Don't miss out on valuable information - stay connected today!"
				twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
				robots="index, follow"
				canonical="https://abroadninja.in/flash-news/"
			/>
			<Index />
		</Layout>
	);
};

export default FlashNews;
