import { useStaticQuery, graphql } from 'gatsby';

export const useProgramSuccessStoriesData = () => {
    const { allStrapiProgramSuccessStorie } = useStaticQuery(
        graphql`
            query programSuccessStorie {
                allStrapiProgramSuccessStorie {
                    nodes {
                        name
                        desg
                        slug
                        show_on_all_page
                        type
                        order
                        video {
                            ... on STRAPI__COMPONENT_UPLOAD_VIDEO_UPLOAD_VIDEO {
                                id
                                strapi_id
                                strapi_component
                                upload_video {
                                    url
                                    name
                                }
                            }
                            ... on STRAPI__COMPONENT_VIDEO_LINK_VIDEO_LINK {
                                id
                                video_link
                                video_name
                                strapi_component
                            }
                        }
                        thumbnail {
                            url
                            name
                        }
                    }
                }
            }
        `
    );
    return allStrapiProgramSuccessStorie.nodes;
};
