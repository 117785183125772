import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';

import styled from 'styled-components';
function ProgramCard({ program }) {
    return (
        <ProgramWrapperStyled>
            <div className="program_wrapper_top">
                <small className="program_wrapper_top_heading1">
                    {program.heading}
                </small>
                <small className="program_wrapper_top_heading2">
                    {program.info}
                </small>
            </div>
            <div className="program_wrapper_bottom">
                <div className="program_wrapper_bottom_country_img">
                    {program?.flag_new_img?.length > 0 &&
                        program?.flag_new_img.map((flg) => {
                            return (
                                <div
                                    key={flg.id}
                                    className={`${
                                        program?.flag_new_img?.length > 2
                                            ? 'small-flag-img'
                                            : 'flag-img'
                                    }`}
                                >
                                    <img
                                        src={flg?.flag_img?.url}
                                        alt="Country Flag Image"
                                    />
                                </div>
                            );
                        })}
                </div>
                <Link to={`/program/${program.slug}`}>
                    <FontAwesomeIcon
                        icon={faCircleChevronRight}
                        size="1x"
                        className="fa-circle-chevron-right"
                    />
                </Link>
            </div>
        </ProgramWrapperStyled>
    );
}

const ProgramWrapperStyled = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.5rem;
    padding: 1rem;
    min-width: 21rem;
    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    border-radius: 1rem;
    .small-flag-img img {
        width: 70%;
    }

    .program_wrapper_top {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0.25rem;
    }

    .program_wrapper_top_heading1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 29px;
        color: #113a68;
    }
    .program_wrapper_top_heading2 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 17px;
        color: #6a92c4;
    }
    .program_wrapper_bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .program_wrapper_bottom_country_img {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
    }
    @media (max-width: 500px) {
        min-width: 100%;
    }

    @media (max-width: 325px) {
        padding: 0.8rem;
    }
    @media (max-width: 313px) {
        min-height: 8.8rem;
    }
`;
export default ProgramCard;
