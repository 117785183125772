const ageRange = (val) => {
    let range = {};
    for (let index = 0; index < 10; index++) {
        range[index + 20] = val;
    }
    return range;
};

export const englishSkillCLBCalculation = (
    iel_listening,
    iel_reading,
    iel_speaking,
    iel_writing
) => {
    var iel_final = '';

    if (
        iel_listening == '' ||
        iel_reading == '' ||
        iel_speaking == '' ||
        iel_writing == ''
    ) {
        iel_final = 0;
    } else if (
        iel_reading >= '8' ||
        iel_writing >= '7.5' ||
        iel_listening >= '8.8' ||
        iel_speaking >= '7.5'
    ) {
        iel_final = 10;
    } else if (
        iel_reading >= '7' ||
        iel_writing >= '7' ||
        iel_listening >= '8' ||
        iel_speaking >= '7'
    ) {
        iel_final = 9;
    } else if (
        iel_reading >= '6.5' ||
        iel_writing >= '6.5' ||
        iel_listening >= '7.5' ||
        iel_speaking >= '6.5'
    ) {
        iel_final = 8;
    } else if (
        iel_reading >= '6' ||
        iel_writing >= '6' ||
        iel_listening >= '6' ||
        iel_speaking >= '6'
    ) {
        iel_final = 7;
    } else if (
        iel_reading >= '5' ||
        iel_writing >= '5.5' ||
        iel_listening >= '5.5' ||
        iel_speaking >= '5.5'
    ) {
        iel_final = 6;
    } else if (
        iel_reading >= '4' ||
        iel_writing >= '5' ||
        iel_listening >= '5' ||
        iel_speaking >= '5'
    ) {
        iel_final = 5;
    } else if (
        iel_listening >= '3.5' ||
        iel_reading >= '4' ||
        iel_speaking >= '4.5' ||
        iel_writing >= '4'
    ) {
        iel_final = 4;
    }
    return iel_final;
};

export const combinationCalculation = {
    combEduAndCBL: {
        secondaryHighSchool: {
            7: 0,
            8: 0,
            9: 0,
            10: 0,
        },
        bachelors: {
            7: 13,
            8: 13,
            9: 25,
            10: 25,
        },
        diploma: {
            7: 13,
            8: 13,
            9: 50,
            10: 25,
        },
        masters: {
            7: 25,
            8: 25,
            9: 50,
            10: 50,
        },
        phd: {
            7: 25,
            8: 25,
            9: 50,
            10: 50,
        },
    },
    combForeWorkExpCanadWorkExp: {
        1: {
            oneYear: 13,
            twoYears: 25,
            threeYears: 25,
            fourYears: 25,
            fiveYearsOrMore: 25,
        },
        2: {
            oneYear: 13,
            twoYears: 25,
            threeYears: 25,
            fourYears: 25,
            fiveYearsOrMore: 25,
        },
        3: {
            oneYear: 25,
            twoYears: 50,
            threeYears: 50,
            fourYears: 50,
            fiveYearsOrMore: 50,
        },
    },

    combCanWorkExpDegree: {
        secondaryHighSchool: {
            oneYear: 0,
            twoYears: 0,
            threeYears: 0,
            fourYears: 0,
            fiveYearsOrMore: 0,
        },
        bachelors: {
            oneYear: 13,
            twoYears: 25,
            threeYears: 25,
            fourYears: 25,
            fiveYearsOrMore: 25,
        },
        diploma: {
            oneYear: 13,
            twoYears: 25,
            threeYears: 25,
            fourYears: 25,
            fiveYearsOrMore: 25,
        },
        masters: {
            oneYear: 25,
            twoYears: 50,
            threeYears: 50,
            fourYears: 50,
            fiveYearsOrMore: 50,
        },
        phd: {
            oneYear: 25,
            twoYears: 50,
            threeYears: 50,
            fourYears: 50,
            fiveYearsOrMore: 50,
        },
    },
};
export const prCalculation = {
    age: {
        Single: {
            17: 0,
            18: 99,
            19: 105,
            ...ageRange(110),
            30: 105,
            31: 99,
            32: 94,
            33: 88,
            34: 83,
            35: 77,
            36: 72,
            37: 66,
            38: 61,
            39: 55,
            40: 50,
            41: 39,
            42: 28,
            43: 17,
            44: 6,
            45: 0,
        },
        Divorced: {
            17: 0,
            18: 99,
            19: 105,
            ...ageRange(110),
            30: 105,
            31: 99,
            32: 94,
            33: 88,
            34: 83,
            35: 77,
            36: 72,
            37: 66,
            38: 61,
            39: 55,
            40: 50,
            41: 39,
            42: 28,
            43: 17,
            44: 6,
            45: 0,
        },
        Married: {
            17: 0,
            18: 90,
            19: 95,
            ...ageRange(100),
            30: 95,
            31: 90,
            32: 85,
            33: 80,
            34: 75,
            35: 70,
            36: 65,
            37: 60,
            38: 55,
            39: 50,
            40: 45,
            41: 35,
            42: 25,
            43: 15,
            44: 5,
            45: 0,
        },
    },
    eductionLevel: {
        Single: {
            phd: 150,
            masters: 135,
            bachelors: 120,
            diploma: 90,
            secondaryHighSchool: 30,
            lessThanSecondaryHighSchool: 0,
        },
        Divorced: {
            phd: 150,
            masters: 135,
            bachelors: 120,
            diploma: 90,
            secondaryHighSchool: 30,
            lessThanSecondaryHighSchool: 0,
        },
        Married: {
            phd: 140,
            masters: 126,
            bachelors: 112,
            diploma: 84,
            secondaryHighSchool: 28,
            lessThanSecondaryHighSchool: 0,
        },
        sopouseEduction: {
            phd: 10,
            masters: 10,
            bachelors: 8,
            diploma: 6,
            secondaryHighSchool: 2,
            lessThanSecondaryHighSchool: 0,
        },
    },
    canadianWorkExperience: {
        Single: {
            noExperience: 0,
            oneYear: 40,
            twoYears: 53,
            threeYears: 64,
            fourYears: 72,
            fiveYearsOrMore: 80,
        },
        Divorced: {
            noExperience: 0,
            oneYear: 40,
            twoYears: 53,
            threeYears: 64,
            fourYears: 72,
            fiveYearsOrMore: 80,
        },
        Married: {
            noExperience: 0,
            oneYear: 35,
            twoYears: 46,
            threeYears: 56,
            fourYears: 63,
            fiveYearsOrMore: 70,
        },
        sopouseWorkExperience: {
            noExperience: 0,
            oneYear: 5,
            twoYears: 7,
            threeYears: 8,
            fourYears: 9,
            fiveYearsOrMore: 10,
        },
    },
    offerLetterCanada: {
        above_manager_level: 200,
        management_job: 50,
        doctore_dentist_architect: 50,
    },
    yearsExperience: {
        7: {
            1: 13,
            2: 13,
            3: 25,
        },
        8: {
            1: 13,
            2: 13,
            3: 25,
        },
        9: {
            1: 25,
            2: 25,
            3: 50,
        },
    },
    conversionOfCLB: {
        Single: {
            4: 24,
            5: 24,
            6: 36,
            7: 68,
            8: 92,
            9: 124,
            10: 136,
        },
        Divorced: {
            4: 24,
            5: 24,
            6: 36,
            7: 68,
            8: 92,
            9: 124,
            10: 136,
        },
        Married: {
            4: 24,
            5: 24,
            6: 32,
            7: 64,
            8: 88,
            9: 116,
            10: 128,
        },
    },
    conversionOfSpouseCLB: {
        5: 4,
        6: 4,
        7: 12,
        8: 12,
        9: 20,
        10: 20,
    },
    canadaEducation: {
        one_year_diploma: 15,
        diploma_or_more: 30,
        none: 0,
    },
};
