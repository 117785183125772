import React, { useState } from 'react';
import Layout from '../components/layout.component';
import styled from 'styled-components';
import SEO from '../components/seo';
import { InputItem } from '../components/commonComponent/input';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Select from '../components/commonComponent/select';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
const whenToTakeExam = [
    {
        value: 'As soon as possible',
        label: 'As soon as possible',
    },
    {
        value: 'Within 1 month',
        label: 'Within 1 month',
    },
    {
        value: 'Within 6 months',
        label: 'Within 6 months',
    },
    {
        value: 'within a year',
        label: 'within a year',
    },
    {
        value: 'Already given the exam',
        label: 'Already given the exam',
    },
];

const decisionLevel = [
    {
        value: 'Country, Course and University is decided, just need a trustworthy service',
        label: 'Country, Course and University is decided, just need a trustworthy service',
    },
    {
        value: 'Country is decided but I need guidance in the University selection',
        label: 'Country is decided but I need guidance in the University selection',
    },
    {
        value: 'I have decided to Study Abroad but open to all options of country and college',
        label: 'I have decided to Study Abroad but open to all options of country and college',
    },
    {
        value: 'I am confused about studying abroad. Is it worth it?',
        label: 'I am confused about studying abroad. Is it worth it?',
    },
];
const experienceYear = [
    {
        value: 'I have no work experience',
        label: 'I have no work experience',
    },
    {
        value: '0 to 1 years',
        label: '0 to 1 years',
    },
    {
        value: '1 to 3 years',
        label: '1 to 3 years',
    },
    {
        value: 'More than 3 years',
        label: 'More than 3 years',
    },
];

const targetIELTSExam = [
    {
        value: '9',
        label: '9',
    },
    {
        value: '8',
        label: '8',
    },
    {
        value: '7',
        label: '7',
    },
    {
        value: '6',
        label: '6',
    },
    {
        value: 'Above 6',
        label: 'Above 6',
    },
    {
        value: 'Other',
        label: 'Other',
    },
];

const describesRequirement = [
    {
        value: 'Only Higher Studies',
        label: 'Only Higher Studies',
    },
    {
        value: 'Higher Studies + Permanent Residence with job',
        label: 'Higher Studies + Permanent Residence with job',
    },
    {
        value: 'Higher Studies + Job',
        label: 'Higher Studies + Job',
    },
];
const country = [
    {
        name: 'United States',
        img: 'http://s3.us-east-2.wasabisys.com/ufaber-lms/files/cc81c0d98d1b41a78b93d4ad30006113.16686908206281710',
    },
    {
        name: 'United Kingdom',
        img: 'http://s3.us-east-2.wasabisys.com/ufaber-lms/files/c2431a9d201559f8de1dcfb6a9dd3168.png',
    },
    {
        name: 'Canada',
        img: 'http://s3.us-east-2.wasabisys.com/ufaber-lms/files/ba346e59318860577e722ce383926a4a.1670414933492711',
    },
    {
        name: 'Germany',
        img: 'http://s3.us-east-2.wasabisys.com/ufaber-lms/files/9cc8521e1fa804502f49016d3649a970.16686908483471710',
    },
    // {
    // 	name: "New Zealand",
    // 	img: "http://s3.us-east-2.wasabisys.com/ufaber-lms/files/11bf82b7606da0a60f62cf0744936f58.webp",
    // },
    {
        name: 'Australia',
        img: 'http://s3.us-east-2.wasabisys.com/ufaber-lms/files/05f013271cc96dce5a0204327147eb0a.16686907684581710',
    },
    // {
    // 	name: "Scotland",
    // 	img: "http://s3.us-east-2.wasabisys.com/ufaber-lms/files/3036e1b487604092b6e1dc01bd8a1420.16686911355901710",
    // },
    // {
    // 	name: "Ireland",
    // 	img: "http://s3.us-east-2.wasabisys.com/ufaber-lms/files/319d0ee679420f52354a4da4510c0d82.16686912204401710",
    // },
    // {
    // 	name: "Italy",
    // 	img: "http://s3.us-east-2.wasabisys.com/ufaber-lms/files/18fd9ade54cd354a32e4e50677e75d6e.16686913043741710",
    // },
    // {
    // 	name: "England",
    // 	img: "http://s3.us-east-2.wasabisys.com/ufaber-lms/files/500f384c29effaf743f0d651e4df86d6.webp",
    // },
];

const targetDegree = [
    {
        value: 'Bachelors Degree',
        label: 'Bachelors Degree',
    },
    {
        value: 'Masters Degree',
        label: 'Masters Degree',
    },
    {
        value: 'Graduate Diploma',
        label: 'Graduate Diploma',
    },
    {
        value: 'Post Graduate Diploma',
        label: 'Post Graduate Diploma',
    },
    {
        value: 'PhD',
        label: 'PhD',
    },
    {
        value: 'Other',
        label: 'Other',
    },
];
const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const schema = yup
    .object({
        name: yup
            .string()
            .label('Please enter valid Name')
            .trim()
            .required()
            .min(3)
            .max(64),
        email: yup.string().email().required('Please enter valid Email'),
        phone: yup
            .string()
            .matches(phoneRegExp, 'Phone number is not valid')
            .required(),
        ageGroup: yup
            .string()
            .required('Please Select Your Age Group')
            .nullable(),
        tenthGrade: yup
            .string()
            .required('Please Enter Your 10th grade')
            .nullable(),
        twelvethGrade: yup
            .string()
            .required('Please Enter Your 12th grade')
            .nullable(),
        bachelorsdegree: yup
            .string()
            .required('Please Select Your Bachelor Degree')
            .nullable(),
        streaminbachelor: yup
            .string()
            .required('Please Select Your stream in Bachelor Degree')
            .nullable(),
        bachlorspecialization: yup
            .string()
            .required('Please enter you  specialization'),
        completedMaster: yup
            .string()
            .required('Please Select the Option')
            .nullable(),
        masterscore: yup
            .string()
            .when('completedMaster', {
                is: (status) => status === 'Yes',
                then: yup
                    .string()
                    .required('Enter Your Master Score')
                    .nullable(),
            })
            .nullable(),
        streaminmaster: yup
            .string()
            .when('completedMaster', {
                is: (status) => status === 'Yes',
                then: yup
                    .string()
                    .required('Please Select Your Master’s Degree')
                    .nullable(),
            })
            .nullable(),
        mastespecialization: yup
            .string()
            .when('completedMaster', {
                is: (status) => status === 'Yes',
                then: yup.string().required('Please enter you  specialization'),
            })
            .nullable(),

        yearsExperience: yup
            .string()
            .required('Please Select Your Experience')
            .nullable(),
        pursueabroad: yup.string().required('Please enter Pursue  Abroad'),
        budgethigherstudies: yup
            .string()
            .required('Please Select Your  budget for higher studies')
            .nullable(),
        targetIeltsExam: yup
            .string()
            .required('Please Select Your  targeting in IELTS Exam')
            .nullable(),
        whenToTakeExam: yup
            .string()
            .required('Please Select When You Want to Take Exam'),
        decisionLevel: yup.string().required('Please Select Level of decision'),
        describesRequirement: yup
            .string()
            .required('Please Select Your  describes your requirement')
            .nullable(),
        preferredcountry: yup
            .string()
            .required('Please Select Your Preferred Country')
            .nullable(),
        targetDegree: yup
            .string()
            .required('Please Select Your targeted Degree')
            .nullable(),
    })

    .required();

const defaultValues = {
    form1: ['name', 'email', 'phone'],
    form2: ['preferredcountry'],
    form3: ['pursueabroad', 'targetDegree'],
    form4: ['decisionLevel'],
    form5: ['budgethigherstudies'],
    form6: ['describesRequirement'],
    form7: ['targetIeltsExam', 'whenToTakeExam'],
    form8: ['tenthGrade', 'twelvethGrade'],
    form9: ['bachelorsdegree', 'streaminbachelor', 'bachlorspecialization'],
    form10: ['completedMaster'],
    form11: ['masterscore', 'streaminmaster', 'mastespecialization'],
    form12: ['yearsExperience'],
    form13: ['ageGroup'],
};
const isBrowser = typeof window !== 'undefined';
isBrowser && localStorage.removeItem('profile_evaluation');
const ProfileEvaluation = () => {
    const TOTAL_FORMS = 13;
    const [currentPage, setCurrentPage] = useState(1);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const {
        control,
        setValue,
        clearErrors,
        register,
        handleSubmit,
        watch,
        resetField,
        getValues,
        trigger,

        formState: { errors },
    } = useForm({
        mode: 'all',
        reValidateMode: 'onChange',
        criteriaMode: 'all',
        resolver: yupResolver(schema),
    });

    const watchAgeGroup = watch('ageGroup');
    const watchBachelorsDegree = watch('bachelorsdegree');
    const watchStreamInBachelor = watch('streaminbachelor');
    const watchCompletedMaster = watch('completedMaster');
    const watchStreamInMaster = watch('streaminmaster');
    const watchBudgetHigherStudies = watch('budgethigherstudies');
    const watchPreferredCountry = watch('preferredcountry');

    const onSubmit = async (data) => {
        setIsSubmitted(true);

        if (typeof window !== 'undefined') {
            let formData = {
                source: 'abroad-ninja',
                brandid: 17,
                segment: 1880,
                no_university: Math.floor(Math.random() * 10) + 10,
                ...data,
            };
            isBrowser &&
                localStorage.setItem(
                    'profile_evaluation',
                    JSON.stringify(formData)
                );
            await fetch(`https://api.ufaber.com/api/leads-submit/fetch-lead/`, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(formData),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.success === true) {
                        setIsSubmitted(false);
                        NotificationManager.success(
                            'Form Submitted Successfully',
                            'success!!'
                        );
                        window.location = '/profile-evaluation-result';
                    }
                    if (data.error === true) {
                        setIsSubmitted(false);
                        NotificationManager.error(
                            'While Submitting error Occurs',
                            'error!!'
                        );
                    }
                });
        }
    };

    const onClickShowNext = async () => {
        trigger();
        let formValues = defaultValues[`form${currentPage}`];
        if (await trigger(formValues)) {
            clearErrors();
        }

        (await trigger(formValues)) &&
            currentPage !== TOTAL_FORMS &&
            (watchCompletedMaster === 'No' && currentPage === 10
                ? setCurrentPage((prevState) => prevState + 2)
                : setCurrentPage((prevState) => prevState + 1));
    };

    let formFiled = (currentPage / TOTAL_FORMS) * 100;

    return (
        <Layout>
            <SEO title={'Profile Evaluation'} />
            <MainFormStyle>
                <ProfileEvaluateFormWrapper>
                    <div
                        style={{
                            display: `${currentPage == 1 ? 'block' : 'none'}`,
                        }}
                    >
                        <h1
                            id="heading"
                            dangerouslySetInnerHTML={{
                                __html: 'Profile Evaluation',
                            }}
                        />
                        <p
                            id="subheading"
                            dangerouslySetInnerHTML={{
                                __html: 'Evaluate your profile for studying abroad',
                            }}
                        />
                    </div>

                    <ProgressBarStyled>
                        <span className="form-count">
                            {currentPage}/{TOTAL_FORMS}
                        </span>
                        <div className="animated-progress progress-blue">
                            <span style={{ width: `${formFiled}%` }}></span>
                        </div>
                    </ProgressBarStyled>
                    <MainFormWrapper onSubmit={handleSubmit(onSubmit)}>
                        <FormWrapper
                            display={`${currentPage == 1 ? 'block' : 'none'}`}
                        >
                            <label>Enter your Phone number</label>
                            <div className="phone_container">
                                <InputItem
                                    type="text"
                                    className="input_box phone_code"
                                    padding={'1rem'}
                                    border={'none'}
                                    margin={'0px 0px 0.5rem 0px'}
                                    backgroundColor={'#F4F4F4'}
                                    value={'+91'}
                                    readOnly
                                />
                                <InputItem
                                    type="text"
                                    className="input_box phone_number"
                                    padding={'1rem'}
                                    border={'none'}
                                    margin={'0px 0px 0.5rem 0px'}
                                    backgroundColor={'#F4F4F4'}
                                    placeholder={'Phone'}
                                    onChange={(e) => {
                                        setValue('phone', e.target.value);
                                    }}
                                    {...register('phone')}
                                />
                            </div>
                            <Error>{errors.phone?.message}</Error>
                            <label>Enter your Email Id</label>
                            <InputItem
                                type="text"
                                className="input_box"
                                padding={'1rem'}
                                border={'none'}
                                margin={'0px 0px 0.5rem 0px'}
                                backgroundColor={'#F4F4F4'}
                                placeholder={'Email'}
                                onChange={(e) => {
                                    setValue('email', e.target.value);
                                }}
                                {...register('email')}
                            />
                            <Error>{errors.email?.message}</Error>
                            <label>Enter your Full Name</label>
                            <InputItem
                                type="text"
                                className="input_box"
                                padding={'1rem'}
                                border={'none'}
                                margin={'0px 0px 0.5rem 0px'}
                                backgroundColor={'#F4F4F4'}
                                placeholder={'Name'}
                                onChange={(e) => {
                                    setValue('name', e.target.value);
                                }}
                                {...register('name')}
                            />
                            <Error>{errors.name?.message}</Error>
                        </FormWrapper>
                        <FormWrapper
                            display={`${currentPage == 2 ? 'block' : 'none'}`}
                        >
                            <label>What is your preferred country?</label>
                            <div className={`checkBoxCountryButton`}>
                                {country.map((item, index) => {
                                    return (
                                        <label
                                            className={` ${
                                                watchPreferredCountry ===
                                                item.name
                                                    ? 'active'
                                                    : ''
                                            } ${
                                                errors.preferredcountry
                                                    ? 'flex-class  checkbox-error'
                                                    : 'flex-class '
                                            }`}
                                        >
                                            <div className="country-image">
                                                <img src={item.img} />
                                                {/* {watchPreferredCountry} */}
                                                <input
                                                    type="radio"
                                                    value={`${item.name}`}
                                                    {...register(
                                                        'preferredcountry'
                                                    )}
                                                />
                                                <span>{item.name}</span>
                                            </div>
                                        </label>
                                    );
                                })}
                            </div>
                            <Error>{errors.preferredcountry?.message}</Error>
                        </FormWrapper>
                        <FormWrapper
                            display={`${currentPage == 3 ? 'block' : 'none'}`}
                        >
                            <label>
                                Select your target degree for studying abroad
                            </label>
                            <Select
                                options={targetDegree}
                                control={control}
                                name="targetDegree"
                                error={Boolean(errors.targetDegree)}
                                setValue={setValue}
                                clearErrors={clearErrors}
                            />
                            <Error>{errors.targetDegree?.message}</Error>
                            <label>
                                What program are you planning to pursue
                                abroad(Ex: computer science,law,etc)
                            </label>
                            <InputItem
                                type="text"
                                className="input_box"
                                padding={'1rem'}
                                border={'none'}
                                margin={'0px 0px 0.5rem 0px'}
                                backgroundColor={'#F4F4F4'}
                                placeholder={''}
                                {...register('pursueabroad')}
                            />
                            <Error>{errors.pursueabroad?.message}</Error>
                        </FormWrapper>
                        <FormWrapper
                            display={`${currentPage == 4 ? 'block' : 'none'}`}
                        >
                            <label>Select your current decision level</label>
                            <Select
                                options={decisionLevel}
                                control={control}
                                name="decisionLevel"
                                error={Boolean(errors.decisionLevel)}
                                setValue={setValue}
                                clearErrors={clearErrors}
                            />
                            <Error>{errors.decisionLevel?.message}</Error>
                        </FormWrapper>
                        <FormWrapper
                            display={`${currentPage == 5 ? 'block' : 'none'}`}
                        >
                            <label>
                                What is your budget for higher studies?
                            </label>
                            <div className={`checkBoxButton`}>
                                {[
                                    '5 to 10 lakhs',
                                    '10 to 15 lakhs',
                                    '15 to 25 lakhs',
                                    'No limit for the best college',
                                ].map((item, index) => {
                                    return (
                                        <label
                                            className={` ${
                                                watchBudgetHigherStudies ===
                                                item
                                                    ? 'active'
                                                    : ''
                                            } ${
                                                errors.budgethigherstudies
                                                    ? 'checkbox-error'
                                                    : ''
                                            }`}
                                        >
                                            <span className="alphabet-code">
                                                {String.fromCharCode(
                                                    65 + index
                                                )}
                                            </span>
                                            <input
                                                type="radio"
                                                value={`${item}`}
                                                {...register(
                                                    'budgethigherstudies'
                                                )}
                                            />
                                            <span>{item}</span>
                                        </label>
                                    );
                                })}
                            </div>
                            <Error>{errors.budgethigherstudies?.message}</Error>
                        </FormWrapper>
                        <FormWrapper
                            display={`${currentPage == 6 ? 'block' : 'none'}`}
                        >
                            <label>
                                Which of the following best describes your
                                requirement?
                            </label>
                            <Select
                                options={describesRequirement}
                                control={control}
                                name="describesRequirement"
                                error={Boolean(errors.describesRequirement)}
                                setValue={setValue}
                                clearErrors={clearErrors}
                            />
                            <Error>
                                {errors.describesRequirement?.message}
                            </Error>
                        </FormWrapper>
                        <FormWrapper
                            display={`${currentPage == 7 ? 'block' : 'none'}`}
                        >
                            <label>
                                What Band are you targeting in IELTS Exam?
                            </label>
                            <Select
                                options={targetIELTSExam}
                                control={control}
                                name="targetIeltsExam"
                                error={Boolean(errors.targetIeltsExam)}
                                setValue={setValue}
                                clearErrors={clearErrors}
                            />
                            <Error>{errors.targetIeltsExam?.message}</Error>
                            <label>
                                When are you planning to take the IELTS Exam?
                            </label>
                            <Select
                                options={whenToTakeExam}
                                control={control}
                                name="whenToTakeExam"
                                error={Boolean(errors.whenToTakeExam)}
                                setValue={setValue}
                                clearErrors={clearErrors}
                            />
                            <Error>{errors.whenToTakeExam?.message}</Error>
                        </FormWrapper>
                        <FormWrapper
                            display={`${currentPage == 8 ? 'block' : 'none'}`}
                        >
                            <label>
                                Enter your 10th grade score(% or CGPA)
                            </label>
                            <InputItem
                                type="text"
                                className="input_box"
                                padding={'1rem'}
                                border={'none'}
                                margin={'0px 0px 0.5rem 0px'}
                                backgroundColor={'#F4F4F4'}
                                placeholder={'Enter your grade here'}
                                {...register('tenthGrade')}
                            />
                            <Error>{errors.tenthGrade?.message}</Error>
                            <label>
                                Enter your 12th grade score(% or CGPA)
                            </label>
                            <InputItem
                                type="text"
                                className="input_box"
                                padding={'1rem'}
                                border={'none'}
                                margin={'0px 0px 0.5rem 0px'}
                                backgroundColor={'#F4F4F4'}
                                placeholder={'Enter your grade here'}
                                {...register('twelvethGrade')}
                            />
                            <Error>{errors.twelvethGrade?.message}</Error>
                        </FormWrapper>
                        <FormWrapper
                            display={`${currentPage == 9 ? 'block' : 'none'}`}
                        >
                            <label>
                                Duration of Bachelor’s Degree in years
                            </label>
                            <div className={`checkBoxButton`}>
                                {[
                                    'Less than 3 years (eg: Diploma)',
                                    '3 years (eg: B.com, B.sc)',
                                    '4 years (eg: BE, BTech)',
                                ].map((item, index) => {
                                    return (
                                        <label
                                            className={` ${
                                                watchBachelorsDegree === item
                                                    ? 'active'
                                                    : ''
                                            } ${
                                                errors.bachelorsdegree
                                                    ? 'checkbox-error'
                                                    : ''
                                            }`}
                                        >
                                            <span className="alphabet-code">
                                                {String.fromCharCode(
                                                    65 + index
                                                )}
                                            </span>
                                            <input
                                                type="radio"
                                                value={`${item}`}
                                                {...register('bachelorsdegree')}
                                            />
                                            <span>{item}</span>
                                        </label>
                                    );
                                })}
                            </div>
                            <Error>{errors.bachelorsdegree?.message}</Error>
                            <label>What was your stream in Bachelor’s</label>
                            <div className={`checkBoxButton`}>
                                {[
                                    'Core Sciences',
                                    'Arts and Humanities',
                                    'Engineering and technology',
                                    'Medical Sciences',
                                    'Business and Management',
                                    'Design and Graphics',
                                    'Banking, Finance and Accounting',
                                    'Animation and Multimedia',
                                    'Architecture',
                                    'Legal Studies',
                                    'Other',
                                ].map((item, index) => {
                                    return (
                                        <label
                                            className={` ${
                                                watchStreamInBachelor === item
                                                    ? 'active'
                                                    : ''
                                            } ${
                                                errors.streaminbachelor
                                                    ? 'checkbox-error'
                                                    : ''
                                            }`}
                                        >
                                            <span className="alphabet-code">
                                                {String.fromCharCode(
                                                    65 + index
                                                )}
                                            </span>
                                            <input
                                                type="radio"
                                                value={`${item}`}
                                                {...register(
                                                    'streaminbachelor'
                                                )}
                                            />
                                            <span>{item}</span>
                                        </label>
                                    );
                                })}
                            </div>
                            <Error>{errors.streaminbachelor?.message}</Error>
                            <label>
                                {' '}
                                In {watchStreamInBachelor ?? '_____'},my
                                specialization?
                            </label>
                            <InputItem
                                type="text"
                                className="input_box"
                                padding={'1rem'}
                                border={'none'}
                                margin={'0px 0px 0.5rem 0px'}
                                backgroundColor={'#F4F4F4'}
                                placeholder={'Enter your specialization'}
                                {...register('bachlorspecialization')}
                            />
                            <Error>
                                {errors.bachlorspecialization?.message}
                            </Error>
                        </FormWrapper>
                        <FormWrapper
                            display={`${currentPage == 10 ? 'block' : 'none'}`}
                        >
                            <label>Have you completed Masters?</label>
                            <div className={`checkBoxButton`}>
                                {['Yes', 'No'].map((item, index) => {
                                    return (
                                        <label
                                            className={` ${
                                                watchCompletedMaster === item
                                                    ? 'active'
                                                    : ''
                                            } ${
                                                errors.completedMaster
                                                    ? 'checkbox-error'
                                                    : ''
                                            }`}
                                        >
                                            <span className="alphabet-code">
                                                {String.fromCharCode(
                                                    65 + index
                                                )}
                                            </span>
                                            <input
                                                type="radio"
                                                value={`${item}`}
                                                {...register(
                                                    'completedMaster',
                                                    {
                                                        onChange: (e) => {
                                                            if (
                                                                e.target
                                                                    .value ===
                                                                'No'
                                                            ) {
                                                            }
                                                        },
                                                    }
                                                )}
                                            />
                                            <span>{item}</span>
                                        </label>
                                    );
                                })}
                            </div>
                            <Error>{errors.completedMaster?.message}</Error>
                        </FormWrapper>
                        <FormWrapper
                            display={`${currentPage == 11 ? 'block' : 'none'}`}
                        >
                            <label>
                                {' '}
                                Enter your Master’s score( % or CGPA)
                            </label>
                            <InputItem
                                type="text"
                                className="input_box"
                                padding={'1rem'}
                                border={'none'}
                                margin={'0px 0px 0.5rem 0px'}
                                backgroundColor={'#F4F4F4'}
                                placeholder={'Enter your Master’s score'}
                                {...register('masterscore')}
                            />
                            <Error>{errors.masterscore?.message}</Error>
                            <label>What was your stream in Master’s</label>
                            <div className={`checkBoxButton`}>
                                {[
                                    'Core Sciences',
                                    'Arts and Humanities',
                                    'Engineering and technology',
                                    'Medical Sciences',
                                    'Business and Management',
                                    'Design and Graphics',
                                    'Banking, Finance and Accounting',
                                    'Animation and Multimedia',
                                    'Architecture',
                                    'Legal Studies',
                                    'Other',
                                ].map((item, index) => {
                                    return (
                                        <label
                                            className={` ${
                                                watchStreamInMaster === item
                                                    ? 'active'
                                                    : ''
                                            } ${
                                                errors.streaminmaster
                                                    ? 'checkbox-error'
                                                    : ''
                                            }`}
                                        >
                                            <span className="alphabet-code">
                                                {String.fromCharCode(
                                                    65 + index
                                                )}
                                            </span>
                                            <input
                                                type="radio"
                                                value={`${item}`}
                                                {...register('streaminmaster')}
                                            />
                                            <span>{item}</span>
                                        </label>
                                    );
                                })}
                            </div>
                            <Error>{errors.streaminmaster?.message}</Error>
                            <label>
                                {' '}
                                In {watchStreamInMaster ?? '_____'},my
                                specialization?
                            </label>
                            <InputItem
                                type="text"
                                className="input_box"
                                padding={'1rem'}
                                border={'none'}
                                margin={'0px 0px 0.5rem 0px'}
                                backgroundColor={'#F4F4F4'}
                                placeholder={'Enter your specialization'}
                                {...register('mastespecialization')}
                            />
                            <Error>{errors.mastespecialization?.message}</Error>
                        </FormWrapper>
                        <FormWrapper
                            display={`${currentPage == 12 ? 'block' : 'none'}`}
                        >
                            <label>Enter your total work experience</label>
                            <Select
                                options={experienceYear}
                                control={control}
                                name="yearsExperience"
                                error={Boolean(errors.yearsExperience)}
                                setValue={setValue}
                                clearErrors={clearErrors}
                            />
                            <Error>{errors.yearsExperience?.message}</Error>
                        </FormWrapper>
                        <FormWrapper
                            display={`${currentPage == 13 ? 'block' : 'none'}`}
                        >
                            <label>What age group do you belong to?</label>
                            <div className={`checkBoxButton`}>
                                {[
                                    '20 to 24 years old',
                                    '25 to 27 years old',
                                    '28 to 30 years old',
                                    '30+ years old',
                                ].map((item, index) => {
                                    return (
                                        <label
                                            className={` ${
                                                watchAgeGroup === item
                                                    ? 'active'
                                                    : ''
                                            } ${
                                                errors.ageGroup
                                                    ? 'checkbox-error'
                                                    : ''
                                            }`}
                                        >
                                            <span className="alphabet-code">
                                                {String.fromCharCode(
                                                    65 + index
                                                )}
                                            </span>
                                            <input
                                                type="radio"
                                                value={`${item}`}
                                                {...register('ageGroup')}
                                            />
                                            <span>{item}</span>
                                        </label>
                                    );
                                })}
                            </div>
                            <Error>{errors.ageGroup?.message}</Error>
                        </FormWrapper>

                        <div className="main-submit-container">
                            <div className="submit-container">
                                {currentPage === TOTAL_FORMS && (
                                    <>
                                        <FormSubmitButton
                                            type="submit"
                                            value="submit"
                                            padding="1rem 3rem"
                                        />
                                        <FontAwesomeIcon icon={faAngleRight} />
                                    </>
                                )}
                            </div>
                            <div className="nex-prev">
                                <div
                                    className={`submit-container `}
                                    onClick={() => {
                                        currentPage !== 1 &&
                                            (watchCompletedMaster === 'No' &&
                                            currentPage === 12
                                                ? setCurrentPage(
                                                      (prevState) =>
                                                          prevState - 2
                                                  )
                                                : setCurrentPage(
                                                      (prevState) =>
                                                          prevState - 1
                                                  ));
                                    }}
                                >
                                    <FormSubmitButton
                                        background={`${
                                            currentPage === 1
                                                ? 'gray'
                                                : '#396eb0'
                                        }`}
                                        type="button"
                                        padding="1rem 2rem"
                                    />
                                    <FontAwesomeIcon icon={faAngleLeft} />
                                </div>
                                <div
                                    className={`submit-container `}
                                    onClick={onClickShowNext}
                                >
                                    <FormSubmitButton
                                        background={`${
                                            currentPage === TOTAL_FORMS
                                                ? 'gray'
                                                : '#396eb0'
                                        }`}
                                        type="button"
                                        padding="1rem 2rem"
                                    />
                                    <FontAwesomeIcon icon={faAngleRight} />
                                </div>
                            </div>
                        </div>
                    </MainFormWrapper>
                </ProfileEvaluateFormWrapper>
            </MainFormStyle>
            <NotificationContainer />
        </Layout>
    );
};

const ProgressBarStyled = styled.div`
    .form-count {
        font-size: 1rem;
        font-weight: 400;
        color: #396eb0;
    }
    .animated-progress {
        max-width: 40rem;
        min-width: 40rem;
        height: 0.6rem;
        border-radius: 5px;
        margin: 14px 0px;
        border: 1px solid #c7d3eb;
        overflow: hidden;
        position: relative;
        background-color: #c7d3eb;
    }
    @media (max-width: 690px) {
        width: 100%;
        .animated-progress {
            max-width: 100%;
            /* padding: 1.5rem; */
            min-width: 100%;
        }
    }
    .animated-progress span {
        height: 100%;
        display: block;
        width: 0;
        color: rgb(255, 251, 251);
        line-height: 30px;
        position: absolute;
        text-align: end;
        background-color: #113a68;
    }
`;
const FormWrapper = styled.div`
    transition: all 1s ease-in-out;
    /* overflow: hidden; */
    display: ${(props) => props.display || 'block'};
`;
const Error = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 16px;
    color: #d64545;
    margin: ${(props) => props.margin || '0.3rem 0rem 0.8rem 0'};
    display: block;
    opacity: 0.9;
`;
const MainFormStyle = styled.div`
    padding: 10rem 0.75rem;
    .checkbox-error {
        border: solid #d64545 1.5px !important;
        border-radius: 8px;
    }

    .multiple-radio-box {
        display: flex;
    }
    .english-skill {
        display: flex;
        flex-direction: column;
        /* gap: 1rem; */
        flex-wrap: wrap;
    }
    .error {
    }
    [class*='checkBoxCountryButton'] {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 1rem;
        .flex-class {
            flex: 1 1 30%; /*grow | shrink | basis */
            text-align: center;
        }
        .country-image {
            height: 84px;
            margin: 4px;
        }
        span {
            text-align: center;
            padding: 3px 0px;
            display: block;
            font-size: 13px;
        }
        @media (max-width: 500px) {
            .country-image {
                span {
                    display: block;
                    font-size: 13px;
                }
            }
        }
        input {
            position: absolute;
            left: -200px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 12px;
        }
        .active {
            background: #f4805e;
            /* color: #ffffff; */
        }
    }
    [class*='checkBoxButton'] {
        display: flex;
        flex-direction: column;
        margin: 1rem 0px;
        .alphabet-code {
            margin-right: 1rem;
            background: #396eb0;
            border-radius: 7px;
            color: white;
            padding: 0.6rem 0.8rem;
            font-size: 0.9rem;
        }
        label {
            margin: 4px;
            background-color: #efefef;
            border-radius: 8px;
            border: 1px solid #d0d0d0;
            overflow: auto;
            padding: 0.8rem;
            display: inline;
        }
        span {
            text-align: center;
            padding: 3px 0px;
        }
        input {
            position: absolute;
            left: -200px;
        }
        .active {
            background: #396eb0;
            color: #ffffff;
        }
    }

    .submit-container {
        position: relative;

        svg {
            position: absolute;
            top: 2.125rem;
            right: 1.6rem;
            color: white;
        }
    }

    .main-submit-container {
        display: flex;
        justify-content: space-between;
        .disable {
            background: gray !important;
        }
    }
    .nex-prev {
        display: flex;
        gap: 1rem;
    }
    @media (max-width: 500px) {
        .submit-container {
            svg {
                top: 2.125rem;
                right: 1.7rem;
            }
        }
    }
`;

const ProfileEvaluateFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 900;
        font-size: 3rem;
        line-height: 58px;
        text-align: center;
        color: #113a68;
        margin-bottom: 1rem;
    }
    #subheading {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 29px;
        text-align: center;
        color: #396eb0;
        margin-bottom: 4.5rem;
        max-width: 36.4375rem;
    }

    @media (max-width: 500px) {
        align-items: flex-start;

        h1 {
            font-weight: 900;
            font-size: 1.5rem;
            line-height: 29px;
            margin-bottom: 0.5rem;
        }
        #subheading {
            text-align: left;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 17px;
            margin-bottom: 1.5rem;
        }
    }

    .phone_container {
        display: flex;
    }
    .phone_code {
        width: 13%;
        border-radius: 0.25em 0 0 0.25em;
        &:focus {
            box-shadow: none;
        }
    }

    .phone_number {
        &:focus {
            box-shadow: none;
        }
    }
    @media (max-width: 500px) {
        .phone_code {
            width: 22%;
        }
    }
`;
const MainFormWrapper = styled.form`
    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 3.5rem;
    max-width: 40rem;
    min-width: 40rem;

    .required::after {
        content: '*';
        color: #dd1e1e;
    }

    form {
        display: flex;
        flex-direction: column;
    }
    label {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 19px;
        color: #396eb0;
        margin: 1rem 0rem 0.8rem 0;
        display: block;
    }
    @media (max-width: 500px) {
        box-shadow: none;
        border-radius: 0px;
        padding: 0rem !important;
    }

    @media (max-width: 690px) {
        max-width: 100%;
        padding: 1.5rem;
        min-width: 100%;
    }
`;

const FormSubmitButton = styled.input`
    -webkit-appearance: button;
    background: ${(props) => props.background || '#396eb0'};
    border-radius: 8px;
    font-weight: 700;
    font-size: 1rem;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
    border: none;
    outline: none;
    padding: ${(props) => props.padding || '1rem'};
    cursor: pointer;
    width: 100%;
    margin-top: 1rem;
`;
const DropDownOption = styled.ul`
    margin-top: 10px;
    display: none;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 25px rgb(0 0 0 / 10%);
    border-radius: 8px;
    .selectMenuDropdownOptn {
        display: flex;
        cursor: pointer;
        border-radius: 8px;
        align-items: center;
        .selectMenuDropdownOptn-text {
            font-weight: 300;
            font-size: 0.875rem;
            line-height: 17px;
            color: #113a68;
            padding: 0.7rem 0.5rem;
            width: 100%;
        }
    }
    .selectMenuDropdownOptn-text:hover {
        background: #f2f2f2;
    }
`;
export default ProfileEvaluation;
