import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { useBlogAndArticleData } from "../../hooks/blog_article";
const ReportAndArticle = () => {
	const articles = useBlogAndArticleData();
	const filter_articel = articles.filter((articel) => articel.type === "artice-settle");

	return (
		<ReportAndArticleStyled>
			<ReportAndArticleWrapperStyled>
				<div id="reports_articles_text">
					<small>Reports & Articles</small>
				</div>
				<ReportsArticlesWrapperStyled>
					{filter_articel.map((article) => (
						<IndividualReportsArticlesStyled key={article.key}>
							<span className="individual_reports_articles_heading">{article.heading}</span>
							<p className="individual_reports_articles_content">{article.content}</p>
							<Link className="read_more" to={article.read_more_redirect_to}>
								Read More
							</Link>
						</IndividualReportsArticlesStyled>
					))}
				</ReportsArticlesWrapperStyled>
				<Link to={"https://abroadninja.in/content/"}>
					<VewAllButtonStyled className="do_your_own_research_view_all">View All</VewAllButtonStyled>
				</Link>
			</ReportAndArticleWrapperStyled>
		</ReportAndArticleStyled>
	);
};
const ReportAndArticleStyled = styled.section`
	padding: 0rem 7.5rem;
	@media (max-width: 1300px) {
		padding: 0 3.5rem;
	}
	@media (max-width: 1200px) {
		padding: 0 1.5rem;
	}
	@media (max-width: 500px) {
		padding: 0.75rem;
	}
`;

const ReportAndArticleWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-bottom: 5rem;
	width: 100%;
	#do_your_own_research_heading {
		margin-bottom: 1rem;
		font-weight: 900;
		font-size: 48px;
		line-height: 58px;
		text-align: center;
		color: #113a68;
	}
	#do_your_own_research_subheading {
		font-family: "Inter";
		font-size: 24px;
		line-height: 29px;
		text-align: center;
		color: #396eb0;
		margin-bottom: 2.5rem;
	}
	#university_profile_tools_wrapper {
		background: #fff0eb;
		border-radius: 24px;
		margin-bottom: 2.5rem;
		& p {
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			text-align: center;
			color: #396eb0;
			padding: 1rem 6rem;
		}
	}

	#reports_articles_text {
		margin-top: 5rem;
		margin-bottom: 1.5rem;
		background: #fff0eb;
		border-radius: 28px;
		width: 100%;
		padding: 1rem 0;
		text-align: center;
	}
	#reports_articles_text small {
		font-family: "Inter";
		font-style: normal;
		font-weight: 500;
		font-size: 1rem;
		line-height: 19px;
		text-align: center;
		color: #396eb0;
	}

	@media (max-width: 500px) {
		#do_your_own_research_heading {
			font-size: 1.75rem;
			line-height: 34px;
		}
		#university_profile_tools_wrapper {
			display: none;
		}
		#do_your_own_research_subheading {
			font-size: 1rem;
			line-height: 19px;
		}
		#reports_articles_text {
			display: none;
		}
	}

	@media (max-width: 354px) {
		#do_your_own_research_heading {
			font-size: 1.37rem;
		}
	}
`;

const ReportsArticlesWrapperStyled = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 1.5rem 1.375rem;
	justify-content: center;
	place-items: center;
	@media (max-width: 1000px) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media (max-width: 700px) {
		grid-template-columns: 1fr;
	}
`;

const IndividualReportsArticlesStyled = styled.div`
	background: #ffffff;
	box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
	padding: 1rem;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;

	.individual_reports_articles_heading {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 1rem;
		line-height: 19px;
		color: #113a68;
		margin-bottom: 0.75rem;
	}
	.individual_reports_articles_content {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 19px;
		color: #396eb0;
	}
	.read_more {
		width: 100%;
		margin-top: 1rem;
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 0.875rem;
		line-height: 17px;
		color: #fc997c;
		text-align: center;
	}

	@media (max-width: 1200px) {
		min-height: 10.8125rem;
		justify-content: space-between;
	}
`;

const VewAllButtonStyled = styled.button`
	padding: 1rem 3rem;
	cursor: pointer;
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-size: 1rem;
	line-height: 19px;
	color: #113a68;
	border: 1px solid #113a68;
	border-radius: 8px;
	outline: none;
	background-color: #ffffff;
	margin-top: 3rem;
`;
export default ReportAndArticle;
