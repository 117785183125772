import React from 'react';

const SEO = ({
    title,
    children,
    description,
    keywords,
    viewport,
    og_type,
    og_url,
    og_title,
    og_description,
    og_image,
    twitter_card,
    twitter_url,
    twitter_title,
    twitter_description,
    twitter_image,
    robots,
    canonical,
}) => {
    return (
        <>
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, maximum-scale=1"
            ></meta>
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="description" content={description} />
            <meta
                name="google-site-verification"
                content="7EX4MVOsEzJzfA8mFq2359Qc7mjP8U6UytpHpiZhf30"
            />
            <meta name="keywords" content={keywords} />
            <meta property="og:type" content={og_type} />
            <meta property="og:url" content={og_url} />
            <meta property="og:title" content={og_title} />
            <meta property="og:description" content={og_description} />
            <meta property="og:image" content={og_image} />
            <meta property="twitter:card" content={twitter_card} />
            <meta property="twitter:url" content={twitter_url} />
            <meta property="twitter:title" content={twitter_title} />
            <meta
                property="twitter:description"
                content={twitter_description}
            />
            <meta property="twitter:image" content={twitter_image} />
            <meta name="robots" content={robots} />
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="language" content="English" />
            <meta name="viewport" content={viewport} />
            <link rel="canonical" href={canonical} />
            {children}
        </>
    );
};

export default SEO;
