import React from 'react';
import Layout from '../../components/layout.component';
import PopularScholarship from '../../components/StudyAbroadScholarship/PopularScholarship';
import SEO from '../../components/seo';



const ScholarshipCard = () => {
    return (
        <Layout>
            <PopularScholarship />
        </Layout>
    );
}
export const Head = () => {
    return (
        <SEO
            title="Study Abroad Scholarships | Abroad Ninja" 
            description="Check out these trending scholarships to study abroad. Find details on the active scholarships, amount, application deadline & much more."
            og_type="website"
            og_url="https://abroadninja.in/study-abroad-scholarship/scholarships/"
            og_title="Study Abroad Scholarships | Abroad Ninja"
            og_description="Check out these trending scholarships to study abroad. Find details on the active scholarships, amount, application deadline & much more."
            og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            twitter_card="summary_large_image"
            twitter_url="https://abroadninja.in/study-abroad-scholarship/scholarships/"
            twitter_title="Study Abroad Scholarships | Abroad Ninja"
            twitter_description="Check out these trending scholarships to study abroad. Find details on the active scholarships, amount, application deadline & much more."
            twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            robots="index, follow"
            canonical="https://abroadninja.in/study-abroad-scholarship/scholarships/"
        />
    )
}

export default ScholarshipCard;
