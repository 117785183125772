import { useStaticQuery, graphql } from 'gatsby';

export const useBlogAndArticleData = () => {
    const { allStrapiBlogAndArticle } = useStaticQuery(
        graphql`
            query caseBlogArticle {
                allStrapiBlogAndArticle {
                    nodes {
                        id
                        read_more_redirect_to
                        type
                        heading
                        content
                        blog_slug
                        img {
                            url
                            size
                        }
                    }
                }
            }
        `
    );
    return allStrapiBlogAndArticle.nodes;
};
