import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery, Link } from "gatsby";
import { TypewriterHeader } from "../../utils";
const Hero = ({ slug }) => {
	const Querydata = useStaticQuery(query);
	const {
		allStrapiMastersHero: { nodes: mastersHero },
	} = Querydata;

	let aMasterHero = mastersHero.filter((item) => item.slug === slug[0]);
	let renderMaster =
		aMasterHero?.length > 0 ? aMasterHero[0] : mastersHero.filter((item) => item.slug === slug[1])[0];

	const { heading, subheading, button_name, pop_up_form, redirect_to } = renderMaster;
	const newHeader = TypewriterHeader(heading);
	return (
		<HeroStyled>
			<h1 key={`heading`} id="custom_counselling_heading" dangerouslySetInnerHTML={{ __html: newHeader }} />

			<p key={`subheading`} id="hero_section_subheading" dangerouslySetInnerHTML={{ __html: subheading }} />

			<div>
				{!pop_up_form ? (
					<Link to={`/abroad-form?formName=${redirect_to}`}>
						<button id="schedule_free_consultation">{button_name}</button>
					</Link>
				) : (
					<button id="schedule_free_consultation">{button_name}</button>
				)}
			</div>
		</HeroStyled>
	);
};
const HeroStyled = styled.section`
	padding: 10rem 1rem 0rem 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	& h1 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		color: #396eb0;
		margin-bottom: 0.3em;
		max-width: 10em;
	}
	& h1 span {
		color: #113a68;
	}

	#hero_section_subheading {
		margin-bottom: 4.5em;
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1.5rem;
		line-height: 29px;
		text-align: center;
		color: #396eb0;
		max-width: 26em;
	}
	#schedule_free_consultation {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 1.25rem;
		line-height: 24px;
		color: #ffffff;
		border-radius: 0.75em;
		background: #113a68;
		padding: 1.25em 2.5em;
		border: none;
		outline: none;
		cursor: pointer;
	}

	@media (max-width: 500px) {
		& h1 {
			font-size: 1.75rem;
			line-height: 2.125rem;
			max-width: 7em;
		}
		#hero_section_subheading {
			font-size: 1rem;
			line-height: 1.1875em;
			max-width: 15em;
			margin-bottom: 3.5em;
		}
		#schedule_free_consultation {
			font-size: 1rem;
			line-height: 1.1875em;
			padding: 0.9em 1.75em;
		}
	}

	@media (max-width: 370px) {
		& h1 {
			font-size: 1.6rem;
		}
		#hero_section_subheading {
			font-size: 0.9rem;
		}
	}

	@media (max-width: 340px) {
		& h1 {
			font-size: 1.4rem;
			line-height: 1.7rem;
			max-width: 13.875em;
			margin-bottom: 0.4em;
		}
		#hero_section_subheading {
			font-size: 0.9rem;
			margin-bottom: 2.5rem;
		}
		#schedule_free_consultation {
			font-size: 0.7rem;
			padding: 0.9em 1.2em;
			font-weight: 400;
		}
	}
`;
export const query = graphql`
	query StrapiMastersHero($slug: [String]) {
		allStrapiMastersHero(filter: { slug: { in: $slug } }) {
			nodes {
				slug
				heading
				subheading
				button_name
				pop_up_form
				redirect_to
			}
		}
	}
`;
export default Hero;
