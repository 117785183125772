import ReactPaginate from 'react-paginate';
import React, { useState, useEffect } from 'react';
import useAPI from '../../hooks/study-abroad/university-search/useAPI';
import {
    pageItem,
    active,
    pagination,
} from '../study-abroad/university-search/university-search-results/UniversitySearchResults.module.css';
import styled from 'styled-components';
import AllscholarshipCard from './AllscholarshipCard';
const ScholarShipResults = (props) => {
    const { scholarshipData, totalPages, url, sortFilters } = props;
    const [scholarships, setScholarships] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    useEffect(() => {
        setScholarships(scholarshipData);
        setPageCount(totalPages);
    }, [scholarshipData, totalPages]);

    const { getScholarships } = useAPI();

    const fetchUniversities = async (currentPage) => {
        if (url.includes('?')) {
            const scholarships = await getScholarships(
                `${url}&page=${currentPage}`
            );
            if (sortFilters.value === 'max_to_low') {
                setScholarships(
                    scholarships.data.sort((a, b) => b.amount - a.amount)
                );
            } else if (sortFilters.value === 'low_to_max') {
                setScholarships(
                    scholarships.data.sort((a, b) => a.amount - b.amount)
                );
            } else {
                setScholarships(scholarships.data);
            }
            setPageCount(scholarships.pagination[0]['total_pages']);
        } else {
            const scholarships = await getScholarships(
                `${url}?page=${currentPage}`
            );
            if (sortFilters.value === 'max_to_low') {
                setScholarships(
                    scholarships.data.sort((a, b) => b.amount - a.amount)
                );
            } else if (sortFilters.value === 'low_to_max') {
                setScholarships(
                    scholarships.data.sort((a, b) => a.amount - b.amount)
                );
            } else {
                setScholarships(scholarships.data);
            }
            setPageCount(scholarships.pagination[0]['total_pages']);
        }
    };

    const handlePageClick = async (data) => {
        let currentPage = data.selected + 1;
        await fetchUniversities(currentPage);
        window.scrollTo(0, 0);
    };

    return (
        <>
            {scholarships.length === 0 && totalPages === 0 ? (
                <NotFound>
                    <p>
                        We are currently working on this Scholarship details, to
                        know more you can contact our counsellor
                    </p>
                    <ScheduleSessionLink
                        href="https://abroadninja.in/abroad-form?formName=study-abroad&source=abroad-ninja&utm_sou[…]medium=medium&language=lang&brandid=17&do_what=-&segment=1880"
                        target="_blank"
                    >
                        Schedule Session
                    </ScheduleSessionLink>
                </NotFound>
            ) : (
                <>
                    {scholarships.map((data) => (
                        <AllscholarshipCard data={data} />
                    ))}
                    <ReactPaginate
                        previousLabel={'< Previous'}
                        nextLabel={'Next >'}
                        breakLabel={'...'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        onPageChange={handlePageClick}
                        containerClassName={pagination}
                        pageClassName={pageItem}
                        previousClassName={pageItem}
                        nextClassName={pageItem}
                        breakClassName={pageItem}
                        activeClassName={active}
                    />
                </>
            )}
        </>
    );
};
const NotFound = styled.div`
    justify-content: space-between !important;
    width: 100%;
    margin: 1rem;
`;

const ScheduleSessionLink = styled.div`
    margin-top: 10px;
    background-color: #113a68;
    color: #fff;
    border: none;
    width: 200px;
    padding: 1rem 2rem;
    border-radius: 8px;
    font-weight: 400;
    font-size: 1rem;
`;

export default ScholarShipResults;
