import React from "react";
import Layout from "../components/layout.component";
import StudyAbroadPage from "../components/studyAbroadPages/index";
import SEO from "../components/seo";
const StudyAbroad = () => {
	return (
		<Layout>
			<StudyAbroadPage />
		</Layout>
	);
};
export function Head () {
	return (
		<SEO 
			title="Best Study Abroad Consultants in India | Abroad Ninja" 
			description="Wondering how to achieve your study abroad dream? Here’s the best overseas education consultant at your service. Click to get started with the best study abroad program and courses."
			og_type="website"
			og_url="https://www.abroadninja.in/study-abroad/"
			og_title="Best Study Abroad Consultants in India | Abroad Ninja"
			og_description="Wondering how to achieve your study abroad dream? Here’s the best overseas education consultant at your service. Click to get started with the best study abroad program and courses."
			og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
			twitter_card="summary_large_image"
			twitter_url="https://www.abroadninja.in/study-abroad/"
			twitter_title="Best Study Abroad Consultants in India | Abroad Ninja"
			twitter_description="Wondering how to achieve your study abroad dream? Here’s the best overseas education consultant at your service. Click to get started with the best study abroad program and courses."
			twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
			robots="index, follow"
			canonical="https://www.abroadninja.in/study-abroad/"
		children={

			<script type="application/ld+json">
			{`
				{
					"@context": "https://schema.org/",
					"@type": "WebSite",
					"name": "AbroadNinja",
					"url": "https://abroadninja.in/",
					"potentialAction": 
					{
						"@type": "SearchAction",
						"target": "https://abroadninja.in/{search_term_string}",
						"query-input": "required name=search_term_string"
					}
				}
			`}
			</script>
		}/>
	);
}
export default StudyAbroad;
