import React, { useState } from "react";
import { Link } from 'gatsby';
import styled from "styled-components";
import Flickity from "react-flickity-component";
import { studyAbroadDemoVideoData } from "../data";
import { useCaseStudyData } from "../hooks/caseStudy";
import { useCaseStudyHeadingData } from "../hooks/caseStudyHeading";
import LORandScholarship from "../components/StudyAbroadDemo/LORandScholarship";
import OurCaseStudy from "../components/StudyAbroadDemo/OurCaseStudy";


const StudyAbroadDemo = () => {

    const flickityOptions = {
        cellAlign: "left",
        contain: true,
        pageDots: false,
        freeScroll: true,
        wrapAround: true,
        adaptiveHeight: true,
        draggable: true,
    };



    return (
        <StudyAbroadDemoWrapper>
            <img src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/23e523d814f2621a3aeb1e0375eb2e87.1684492671046194" alt="About Abroad Ninja" />
            <img src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/0decc370f968469417afb3da11960347.1684492852027194" alt="Our Meeting Agenda" />
            <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/653d9d667d38d7602c7a2d3a011979c0.svg" alt="8 simple Steps" />
            <img src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/aa739c0c810f754c48b3ea513df31071.1684493240673194" alt="Actual Process 30 Steps" />
            <ProfileEvaluationWrapper>
                <div className="profile-evaluation-heading">
                    <span><img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/bbe6f116787ff7df099ee9d75bc6d5b3.svg" alt="" /></span>
                    <span><img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/cc60c15c3bfcf38a8db435f8075db82d.svg" alt="" /></span>
                </div>
                <Flickity
                    className={"main-carousel"}
                    elementType={"div"}
                    options={flickityOptions}
                    disableImagesLoaded={false}
                    reloadOnUpdate
                    static
                >
                    <div className="cellVideo">
                        <div className="imageWrapperCarousel">
                            <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/284fbbe52c133fdb19d516bcbb09be74.svg" alt="University Shortlist Data" />
                        </div>
                    </div>
                    <div className="cellVideo">
                        <div className="imageWrapperCarousel">
                            <img src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/2e3dce1ff12f837891b2891c09a1ca89.168630234035295" alt="University Shortlist Data" />
                        </div>
                    </div>
                    <div className="cellVideo">
                        <div className="imageWrapperCarousel">
                            <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/5302e8d08bfad073b69ff7028e4ad2d7.svg" alt="University Shortlist Data" />
                        </div>
                    </div>
                    <div className="cellVideo">
                        <div className="imageWrapperCarousel">
                            <img src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/4fa2ba74c1271cf0c0d6743dd01f50e4.168630263689395" alt="University Shortlist Data" />
                        </div>
                    </div>
                    <div className="cellVideo">
                        <div className="imageWrapperCarousel">
                            <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/75ddabe016210f8dcd5cf37bf809ccff.svg" alt="University Shortlist Data" />
                        </div>
                    </div>
                    <div className="cellVideo">
                        <div className="imageWrapperCarousel">
                            <img src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/93721f4049620cc43c362ac0397bbc2e.168630271946995" alt="University Shortlist Data" />
                        </div>
                    </div>
                </Flickity>
            </ProfileEvaluationWrapper>
            <div className="university-long-list-wrapper">
                <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8c64b99f11e89c9a96b6252ede85fcc5.svg" alt="University Long List" />
                <Link className="university-shortlist-btn" to="https://docs.google.com/spreadsheets/d/e/2PACX-1vT1UZA0QMtPffh7ZlitS14yepOBAw0Px93xMypt4vjewPJJ9-FHb14o3HyDgf7XMz2Jv46ZFzWhT7PC/pubhtml" target='_blank'><img src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/f2269ae7c2bbf483cf4a09312a17bd68.168624371189085" alt="" /></Link>
            </div>
            <UniversityShortlistWrapper>
                <div className="university-shortlist-heading">
                    <span><img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/bc5973b52ed4234fabc4d97722eb3572.svg" alt="" /></span>
                    <span><img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/cc60c15c3bfcf38a8db435f8075db82d.svg" alt="" /></span>

                </div>
                <Flickity
                    className={"main-carousel"}
                    elementType={"div"}
                    options={flickityOptions}
                    disableImagesLoaded={false}
                    reloadOnUpdate
                    static
                >
                    <div className="cellVideo">
                        <div className="imageWrapperCarousel">
                            <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/6a754c406f9215bb3a237c9711d0e0a5.svg" alt="University Shortlist Data" />
                        </div>
                    </div>
                    <div className="cellVideo">
                        <div className="imageWrapperCarousel">
                            <img src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/8193efa5681135c3dce61f63946bbaa9.168630086981595" alt="University Shortlist Data" />
                        </div>
                    </div>
                    <div className="cellVideo">
                        <div className="imageWrapperCarousel">
                            <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/99b70271a40a5bcd4c9aeaf597b17cb4.svg" alt="University Shortlist Data" />
                        </div>
                    </div>
                    <div className="cellVideo">
                        <div className="imageWrapperCarousel">
                            <img src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/ddc72ac92c7a963db5bcd0acaf562223.168630096456595" alt="University Shortlist Data" />
                        </div>
                    </div>

                    <div className="cellVideo">
                        <div className="imageWrapperCarousel">
                            <img src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/b1c1c568d967b312e417804efa04c0a0.168630082257595" alt="University Shortlist Data" />
                        </div>
                    </div>

                    <div className="cellVideo">
                        <div className="imageWrapperCarousel">
                            <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/334755c8f01b004b3b1416afbfb04e42.svg" alt="University Shortlist Data" />
                        </div>
                    </div>

                </Flickity>
                <div className="university-shortlist-content">
                    <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/264458dcfb87cd5ba7ffda8fff2003fb.svg" alt="" />
                </div>
            </UniversityShortlistWrapper>
            {/* <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/92759de8688c7b499aa523873aa0a15e.svg" alt="Shotlist" /> */}
            <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/f68a12aaaeb8cdf707a783839c2dad29.svg" alt="Application Process" />
            <LORandScholarship />
            <VisaApplicationWrapper>
                <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/f62dbaf1a3a392bab16f9a3ab5b2e633.svg" alt="Visa Application" />
                <div className="visa_application_video_section">
                    <h2>Our Extraordinary Case Studies</h2>
                    <div className="visa_application_video_wrapper">
                        {studyAbroadDemoVideoData?.map((videoData, key) => (
                            <div className="video_card" key={key}>
                                <Link to={videoData.VideoUrl} target="_blank">
                                    <img src={videoData.imgLink} alt={key} />
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </VisaApplicationWrapper>
            <OurCaseStudy />
            <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/ea599c9e1220b9d9aafa57e5bba77528.svg" alt="Ensure Perfection" />
            <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/e773788a8b8c4af9ccfff56120968e2e.svg" alt="Next Step" />
        </StudyAbroadDemoWrapper>
    );
};

const StudyAbroadDemoWrapper = styled.div`
	display: flex;
    flex-direction:column;
	align-items: center;
	justify-content: center;
    width: 100%;
    background-color: #FFF0EB;
    img{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        object-fit:contain;
    }

    .university-long-list-wrapper{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .university-shortlist-btn{
        display: flex;
        width: 100%
        max-width: 260px;
        height: 55px;
        top: 95%;
        position: absolute;
    }
`;

const ProfileEvaluationWrapper = styled.div`
	display: flex;
    flex-direction:column;
	align-items: center;
	justify-content: center;
    width: 100%;
    img{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        object-fit:contain;
    }
    .flickity-enabled.is-draggable{
        width: 100%;
    }
    .flickity-viewport{
        position: relative;
        overflow: hidden;
    }
    .imageWrapperCarousel{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
    }
    .cellVideo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
	}
    .flickity-prev-next-button{
        z-index: 10;
        top: 2%;
        width: 44px;
        height: 44px;
        color: #113A68;
        background: transparent;
        border: 2px solid #113A68;
    }
    .flickity-prev-next-button.previous{
        left: 86%
    }
    .flickity-prev-next-button.next {
        right: 7%;
    }

    .profile-evaluation-heading{
                display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 10px 30px 10px 100px;
    }
        .profile-evaluation-heading span{
        display: flex;
        width: 380px;
        height: 45px;
    }
`;

const UniversityShortlistWrapper = styled.div`
	display: flex;
    flex-direction:column;
	align-items: center;
	justify-content: center;
    width: 100%;
    img{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        object-fit:contain;
    }
    .flickity-enabled.is-draggable{
        width: 100%;
    }
    .flickity-viewport{
        position: relative;
        overflow: hidden;
    }
    .imageWrapperCarousel{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100vw;
    }
    .cellVideo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
	}
    .flickity-prev-next-button{
        z-index: 10;
        top: 2%;
        width: 44px;
        height: 44px;
        color: #113A68;
        background: transparent;
        border: 2px solid #113A68;
    }
    .flickity-prev-next-button.previous{
        left: 86%
    }
    .flickity-prev-next-button.next {
        right: 7%;
    }
    .university-shortlist-heading{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 10px 30px 10px 100px;
    }

    .university-shortlist-heading span{
        display: flex;
        width: 380px;
        height: 45px;
    }
    .university-shortlist-content{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 206px;
    }
`;

const VisaApplicationWrapper = styled.div`
	display: flex;
    flex-direction:column;
	align-items: center;
	justify-content: center;
    width: 100%;
    padding: 0px 100px;
    background-color: #FFF0EB;
    img{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        object-fit:contain;
    }
    .visa_application_video_section{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        margin: 100px 0px;
    }
    .visa_application_video_section h2{
        font-style: normal;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 1.5rem;
        leading-trim: both;
        text-edge: cap;
        text-transform: capitalize;
        color: #113A68;
        margin-bottom: 80px;
    }
    .visa_application_video_wrapper{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        flex-wrap: wrap;
        gap: 45px;
    }
    .video_card{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30%;
        cursor: pointer;
    }
`;



export default StudyAbroadDemo;
