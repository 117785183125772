import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { graphql, Link, useStaticQuery } from 'gatsby';
const PricePackage = ({ package_id }) => {
    let aPackId = [2447];
    const data = useStaticQuery(query);
    const {
        allPackage: { nodes: Package },
    } = data;
    let filterPackage = Package.filter((item) => {
        return item.package_id == package_id;
    });
    let newFilterPackage = filterPackage[0];
    let aPackageSpecification = newFilterPackage?.package_specification
        .trim()
        .split(/\r?\n|\r|\n|\;/g);
    let latest_package_id = newFilterPackage.package_id;
    let aPackageSpecification_filter = aPackageSpecification.filter(
        (data) => data !== ''
    );
    console.log(package_id === 2447);
    // 2447
    return (
        <PriceAndPackageMainStyled>
            <PriceAndPackageStyled>
                <span id="price_and_package_heading">Price & Package</span>
                <PricePackageKeypointsListWrapperStyled>
                    <div className="price_package_keypoints_list">
                        <ul className="price_package_keypoints">
                            {aPackageSpecification_filter.length > 0 &&
                                aPackageSpecification_filter?.map(
                                    (item, index) => {
                                        return (
                                            index < 6 &&
                                            item !== '' && (
                                                <div
                                                    className="list-package"
                                                    key={index}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faCircleCheck}
                                                        size="1x"
                                                        className="check_circle"
                                                    />
                                                    <li>{item}</li>
                                                </div>
                                            )
                                        );
                                    }
                                )}
                        </ul>
                    </div>
                    <div className="price_package_keypoints_list">
                        <ul className="price_package_keypoints">
                            {aPackageSpecification_filter.length > 0 &&
                                aPackageSpecification_filter?.map(
                                    (item, index) => {
                                        return (
                                            index > 5 &&
                                            item !== '' && (
                                                <div
                                                    className="list-package"
                                                    key={index}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faCircleCheck}
                                                        size="1x"
                                                        className="check_circle"
                                                    />
                                                    <li>{item}</li>
                                                </div>
                                            )
                                        );
                                    }
                                )}
                        </ul>
                    </div>
                </PricePackageKeypointsListWrapperStyled>
                {!aPackId.includes(package_id) ? (
                    <small id="package_price">
                        MRP: ₹ {newFilterPackage?.price}
                    </small>
                ) : null}

                <Link
                    id="enroll_now"
                    to={`https://app.ieltsninja.com/app-signup/?enroll=true&pack_id=${latest_package_id}`}
                >
                    Enroll Now
                </Link>
            </PriceAndPackageStyled>
        </PriceAndPackageMainStyled>
    );
};
const PriceAndPackageMainStyled = styled.section`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 6rem;
    @media (max-width: 500px) {
        padding: 0 0.75rem;
    }
`;
const PriceAndPackageStyled = styled.div`
    padding: 3rem 6rem;
    background: #113a68;
    border-radius: 32px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 85%;
    #price_and_package_heading {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 900;
        font-size: 3rem;
        line-height: 58px;
        text-align: center;
        color: #fff0eb;
        margin-bottom: 3.5rem;
    }

    #package_price {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 3rem;
        line-height: 58px;
        color: #fff0eb;
    }
    #enroll_now {
        margin-top: 1.25rem;
        padding: 2.09375rem 0;
        border: none;
        outline: none;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 29px;
        color: #fff0eb;
        background: #fc997c;
        border-radius: 16px;
        width: 100%;
        cursor: pointer;
        text-align: center;
    }
    @media (max-width: 1099px) {
        width: 100%;
    }
    @media (max-width: 1014px) {
        padding: 3rem;
    }
    @media (max-width: 500px) {
        padding: 2rem 0 0 0;
        align-items: center;
        border-radius: 1rem;
        #price_and_package_heading {
            font-size: 1.75rem !important ;
            line-height: 34px;
        }
        #package_price {
            font-size: 1.5rem;
            line-height: 29px;
        }
        #enroll_now {
            border-top-right-radius: 0rem;
            border-top-left-radius: 0rem;
            border-bottom-right-radius: 1rem;
            border-bottom-left-radius: 1rem;
            font-size: 1.25rem;
            line-height: 24px;
            padding: 1.75rem 0;
        }
    }
    @media (max-width: 529px) {
        #price_and_package_heading {
            font-size: 2.79rem;
        }
    }
    @media (max-width: 310px) {
        #enroll_now {
            padding: 1.3rem 0;
        }
    }
`;
const PricePackageKeypointsListWrapperStyled = styled.div`
    display: flex;

    gap: 14.5rem;
    margin-bottom: 3rem;
    width: 100%;
    .check_circle {
        min-width: 1.5rem;
        max-width: 1.5rem;
        min-height: 1.5rem;
        max-height: 1.5rem;
        display: inline-block;
        font-family: FontAwesome;
        color: #fc997c;
        margin-right: 0.75rem;
    }
    .list-package {
        display: flex;
        inline-size: 100%;
        overflow-wrap: break-word;
    }

    .price_package_keypoints {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 2.5rem;
        /* & li::before {
			content: "\f058";
			min-width: 1.5rem;
			max-width: 1.5rem;
			min-height: 1.5rem;
			max-height: 1.5rem;
			display: inline-block;
			font-family: FontAwesome;
			color: #fc997c;
			margin-right: 0.75rem;
		} */
        & li {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 1.25rem;
            line-height: 24px;
            color: #fff0eb;
            list-style: none;
        }
    }
    @media (max-width: 1215px) {
        gap: 8.5rem;
    }
    @media (max-width: 1099px) {
        gap: 4rem;
    }
    @media (max-width: 920px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
    @media (max-width: 500px) {
        .price_package_keypoints {
            padding-left: 1.5625rem;
            gap: 1rem;
        }
        .price_package_keypoints_list {
            width: 100%;
            & li::before {
                margin-right: 0rem;
            }
            & li {
                font-size: 0.9375rem;
                line-height: 18px;
            }
        }
    }
`;
export const query = graphql`
    query Package($packageID: Int) {
        allPackage(filter: { package_id: { eq: $packageID } }) {
            nodes {
                package_id
                brand
                package_specification
                price
                floor_price
            }
        }
    }
`;

export default PricePackage;
