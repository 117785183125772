import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
const ProgramHighlight = ({ slug }) => {
	const data = useStaticQuery(query);
	const {
		allStrapiMastersProgramHighlight: { nodes: programHighlight },
	} = data;

	let aProgramHighlight = programHighlight.filter((item) => item.slug === slug[0]);
	let renderMaster =
		aProgramHighlight?.length > 0
			? aProgramHighlight[0]
			: programHighlight.filter((item) => item.slug === slug[1])[0];

	const { heading, program_highlight_list } = renderMaster;
	return (
		<ProgramHighlightStyled>
			<ProgramHighlightWrapperStyled>
				<h2 key={`heading`} id="custom_counselling_heading" dangerouslySetInnerHTML={{ __html: heading }} />
				<ProgramHighlightDetailsWrapperStyled>
					{program_highlight_list.map((data) => {
						return (
							<ProgramHighlightIndividualDetailStyled>
								{/* <div className="program_highlight_individual_detail_image"> */}
								<img src={data?.counselling_img?.url} alt="" />
								{/* </div> */}
								<p style={{ width: "99%" }}>{data.counselling_details}</p>
							</ProgramHighlightIndividualDetailStyled>
						);
					})}
					{/* <ProgramHighlightIndividualDetailStyled>
						<div className="program_highlight_individual_detail_image"></div>
						<p>
							Personal dedicated
							<br />
							consultant
						</p>
					</ProgramHighlightIndividualDetailStyled>
					<ProgramHighlightIndividualDetailStyled>
						<div className="program_highlight_individual_detail_image"></div>
						<p>
							Top-ranked university
							<br />
							targetted
						</p>
					</ProgramHighlightIndividualDetailStyled>
					<ProgramHighlightIndividualDetailStyled>
						<div className="program_highlight_individual_detail_image"></div>
						<p>
							Scholarships up to
							<br />
							USD 10k
						</p>
					</ProgramHighlightIndividualDetailStyled>
					<ProgramHighlightIndividualDetailStyled>
						<div className="program_highlight_individual_detail_image"></div>
						<p>
							Plagiarism-free
							<br />
							personalized SOP/LOR
						</p>
					</ProgramHighlightIndividualDetailStyled>
					<ProgramHighlightIndividualDetailStyled>
						<div className="program_highlight_individual_detail_image"></div>
						<p>
							Post Graduate Work
							<br />
							permit assured
						</p>
					</ProgramHighlightIndividualDetailStyled>
					<ProgramHighlightIndividualDetailStyled>
						<div className="program_highlight_individual_detail_image"></div>
						<p>
							97% Visa success
							<br />
							rate guaranteed
						</p>
					</ProgramHighlightIndividualDetailStyled> */}
				</ProgramHighlightDetailsWrapperStyled>
			</ProgramHighlightWrapperStyled>
		</ProgramHighlightStyled>
	);
};
const ProgramHighlightStyled = styled.section`
	margin-top: 14.8125rem;
`;

const ProgramHighlightWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	padding: 0rem 1.375em 10rem;
	& h2 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		color: #113a68;
		margin-bottom: 4rem;
	}

	@media (max-width: 310px) {
		& h2 {
			font-size: 1.5rem;
		}
	}

	@media (max-width: 500px) {
		& h2 {
			font-size: 1.75rem;
			line-height: 34px;
			margin-bottom: 3rem;
		}
	}
`;

const ProgramHighlightDetailsWrapperStyled = styled.div`
	display: grid;
	justify-content: center;
	place-items: center;
	grid-template-columns: repeat(3, 1fr);
	gap: 2.625rem 2rem;
	@media (max-width: 1215px) {
		grid-template-columns: repeat(2, 1fr);
		gap: 3.625rem 5rem;
	}
	@media (max-width: 700px) {
		gap: 3.625rem 2rem;
	}
	@media (max-width: 310px) {
		grid-template-columns: 1fr;
		gap: 1rem;
	}
	@media (max-width: 700px) {
		gap: 2rem 0.5rem;
	}
`;

const ProgramHighlightIndividualDetailStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 0.75rem;
	min-width: 13rem;
	& p {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1.25rem;
		line-height: 24px;
		text-align: center;
		color: #396eb0;
	}
	.program_highlight_individual_detail_image {
		width: 4.5rem;
		height: 4.5rem;
		border-radius: 50%;
		background: #d9d9d9;
	}

	@media (max-width: 500px) {
		min-width: 10rem;
		& p {
			font-size: 0.8rem;
			line-height: 17px;
		}
		.program_highlight_individual_detail_image {
			width: 3.5rem;
			height: 3.5rem;
		}

		@media (max-width: 355px) {
			.program_highlight_individual_detail {
				min-width: 8rem;
				min-height: 8rem;
				justify-content: flex-start;
			}
		}
	}
`;
export const query = graphql`
	query StrapiMastersProgramHighlight($slug: [String]) {
		allStrapiMastersProgramHighlight(filter: { slug: { in: $slug } }) {
			nodes {
				slug
				heading
				program_highlight_list {
					counselling_img {
						url
						name
					}
					counselling_details
					strapi_id
				}
			}
		}
	}
`;
export default ProgramHighlight;
