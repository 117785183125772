import React from 'react';
import styled from 'styled-components';
import Hero from './hero';
import ProgramHighlight from './programHighlight';
import HowItWorks from './howItWorks';
import IntakeSuccessStories from './intakeSuccessStories';
import EvalauteYourChances from './evalauteYourChances';
import FlashNews from './flashNews';
import PricePackage from './pricePackage';
import StudyingInUSCanada from './studyingInUSCanada';
import ProgramResult from './result';
import SEO from '../seo';

const MasterInUSAndCanadaPages = ({ slug, package_id, type }) => {
    return (
        <MasterInUSAndCanadaPagesStyled>
            <Hero slug={slug} />
            <ProgramHighlight slug={slug} />
            <ProgramResult slug={slug} />
            <HowItWorks slug={slug} />
            <IntakeSuccessStories slug={slug} />
            <EvalauteYourChances slug={slug} />
            <PricePackage slug={slug} package_id={package_id} />
            {/* <FlashNews slug={slug} /> */}
            <StudyingInUSCanada slug={slug} type={type} />
        </MasterInUSAndCanadaPagesStyled>
    );
};
const MasterInUSAndCanadaPagesStyled = styled.body`
    background-image: url('https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/09472a56ed99461fbedcf8561423a705.svg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100%;
    @media (max-width: 600px) {
        background-image: url('https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/096db779ef6644a183f4d62a481f36c7.svg');
    }
`;

export default MasterInUSAndCanadaPages;
