
import React from "react";
import Index from "../components/BestStudyAbroadConsultant";

const BestStudyAbroadConsultant = () => {
    return (
        <Index />
    )

}

export default BestStudyAbroadConsultant;