import { Link } from 'gatsby';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInr } from '@fortawesome/free-solid-svg-icons';
import './style.css';

const AllscholarshipCard = (props) => {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const date = new Date(props?.data?.deadline?.date).toLocaleDateString();

    return (
        <div className="allscholarships">
            <div className="allscholarshipcard">
                <div className="allscholarship_top_item">
                    <h2>{props?.data?.scholarshipName}</h2>
                    {props?.data?.isCollegeSpecific ? (
                        <span>Offered By: {props?.data?.college}</span>
                    ) : (
                        <span>
                            Offered By : {props?.data?.organizationName}
                        </span>
                    )}
                </div>
                <div className="allscholarshipcard_item">
                    <div>
                        <p>Course Level</p>
                        <span>
                            {props?.data?.eligibility?.educationLevel[0]}
                        </span>
                    </div>
                    <div>
                        <p>Deadline</p>
                        {props?.data?.deadline?.status === 'Available' ? (
                            <span>{date}</span>
                        ) : (
                            <span>Always Open</span>
                        )}
                    </div>
                    <div>
                        <p>Amount</p>
                        <span>
                            <FontAwesomeIcon icon={faInr} />{' '}
                            {props?.data?.amount.toLocaleString('en-In')}
                        </span>
                    </div>
                </div>
                <div className="allscholarshipcard__btn-wrapper">
                    {props?.data?.isCollegeSpecific ? (
                        <Link
                            to={`/study-abroad-scholarship/view-details/?offered_by=${props?.data?.college.replaceAll(
                                /\s/g,
                                ''
                            )}&query=${props?.data?.scholarshipName.replaceAll(
                                /&/g,
                                '%26'
                            )}`}
                            className="btn-viewdetails"
                            state={{ prevUrl: url }}
                        >
                            View Details
                        </Link>
                    ) : (
                        <Link
                            to={`/study-abroad-scholarship/view-details/?offered_by=${props?.data?.organizationName.replaceAll(
                                /\s/g,
                                ''
                            )}&query=${props?.data?.scholarshipName.replaceAll(
                                /&/g,
                                '%26'
                            )}`}
                            className="btn-viewdetails"
                            state={{ prevUrl: url }}
                        >
                            View Details
                        </Link>
                    )}

                    {props?.data?.isCollegeSpecific ? (
                        <Link
                            className="btn-checkeligibilty"
                            to={`https://form.typeform.com/to/OlG7xw8Z/?utm_source=${props?.data?.college}`}
                        >
                            Check Eligibility
                        </Link>
                    ) : (
                        <Link
                            className="btn-checkeligibilty"
                            to={`https://form.typeform.com/to/OlG7xw8Z/?utm_source=${props?.data?.scholarshipName}`}
                        >
                            Check Eligibility
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AllscholarshipCard;
