import React from 'react';
import StudyAbroadLocalStyled from './style';
import { Link, navigate } from 'gatsby';
import PrivacyPolicyLink from '../marketingComponent/privacy-policy-link';

const Index = () => {
    const isBrowser = typeof window !== 'undefined';
    let redirect = '';
    if (isBrowser) {
        let url_string = window.location.href;
        let url = new URL(url_string);
        let params = new URLSearchParams(url.search);
        redirect = params.toString();
    }

    const redirectTo = (url) => {
        let final_redirect = `/abroad-form?${url}&isMarketing=true&redirect_to=program-result`;

        url && navigate(final_redirect);
    };
    return (
        <StudyAbroadLocalStyled>
            <div className="main-wrapper_pr">
                <div className="header__top-section">
                    <p className="header__top-section-heading">
                        <span>Can your</span> Local Agent <span>get you </span>{' '}
                        Scholarship of 5 lakhs?
                    </p>
                </div>
                <div className="hero__img-section">
                    <img
                        src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/42359210f2da57ddec98127f2755c285.webp"
                        alt=""
                        className="hero__web-view-image"
                    />
                    <img
                        src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/a36a58e883d1712d74f2852b7f722826.webp"
                        alt=""
                        className="hero__mobile-view-image"
                    />
                </div>
                <div className="section__send-your-profile">
                    <div className="send-your-profile__brand-logo">
                        <img
                            src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/031af81e60de3ffdc44d24249b4c36df.svg"
                            alt=""
                        />
                    </div>
                    <p className="indias-leader-s-a-s__text">
                        India's leader in <span>Study Abroad Scholarship</span>
                    </p>
                    <div className="send-your-profile__bottom-sec">
                        <div className="send-your-profile__right-btn-text-wrapper">
                            <p className="send-your-profile__right-text">
                                Send your profile for{' '}
                                <span>Detailed study</span>
                            </p>
                            <a
                                to={redirect}
                                className="btn-eval-elig"
                                onClick={() => redirectTo(redirect)}
                            >
                                <svg
                                    width={16}
                                    height={16}
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M13.1592 1.81205H11.6851V1.07497C11.6851 0.879484 11.6074 0.692005 11.4692 0.553776C11.331 0.415547 11.1435 0.337891 10.948 0.337891C10.7525 0.337891 10.565 0.415547 10.4268 0.553776C10.2886 0.692005 10.2109 0.879484 10.2109 1.07497V1.81205H5.78846V1.07497C5.78846 0.879484 5.7108 0.692005 5.57257 0.553776C5.43434 0.415547 5.24686 0.337891 5.05138 0.337891C4.85589 0.337891 4.66841 0.415547 4.53018 0.553776C4.39196 0.692005 4.3143 0.879484 4.3143 1.07497V1.81205H2.84014C2.25369 1.81205 1.69125 2.04502 1.27656 2.4597C0.861875 2.87439 0.628906 3.43683 0.628906 4.02328V12.8682C0.628906 13.4547 0.861875 14.0171 1.27656 14.4318C1.69125 14.8465 2.25369 15.0795 2.84014 15.0795H13.1592C13.7457 15.0795 14.3081 14.8465 14.7228 14.4318C15.1375 14.0171 15.3705 13.4547 15.3705 12.8682V4.02328C15.3705 3.43683 15.1375 2.87439 14.7228 2.4597C14.3081 2.04502 13.7457 1.81205 13.1592 1.81205ZM13.8963 12.8682C13.8963 13.0637 13.8187 13.2512 13.6804 13.3894C13.5422 13.5277 13.3547 13.6053 13.1592 13.6053H2.84014C2.64466 13.6053 2.45718 13.5277 2.31895 13.3894C2.18072 13.2512 2.10306 13.0637 2.10306 12.8682V7.70868H13.8963V12.8682ZM13.8963 6.23452H2.10306V4.02328C2.10306 3.8278 2.18072 3.64032 2.31895 3.50209C2.45718 3.36386 2.64466 3.28621 2.84014 3.28621H4.3143V4.02328C4.3143 4.21877 4.39196 4.40625 4.53018 4.54448C4.66841 4.68271 4.85589 4.76036 5.05138 4.76036C5.24686 4.76036 5.43434 4.68271 5.57257 4.54448C5.7108 4.40625 5.78846 4.21877 5.78846 4.02328V3.28621H10.2109V4.02328C10.2109 4.21877 10.2886 4.40625 10.4268 4.54448C10.565 4.68271 10.7525 4.76036 10.948 4.76036C11.1435 4.76036 11.331 4.68271 11.4692 4.54448C11.6074 4.40625 11.6851 4.21877 11.6851 4.02328V3.28621H13.1592C13.3547 3.28621 13.5422 3.36386 13.6804 3.50209C13.8187 3.64032 13.8963 3.8278 13.8963 4.02328V6.23452Z"
                                        fill="white"
                                    />
                                </svg>
                                Evaluate Your Eligibility
                            </a>
                        </div>
                        <div className="send-your-profile__left-img-wrapper">
                            <img
                                src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/1f513b9e9ee9842a0fd52827df370979.16716053929582111"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
                <div className="section__comparison">
                    <div className="comparison-section__title">
                        <div className="comparsion-section__logo-image-wrapper">
                            <img
                                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/48a8628bb6770fd458587b23070b7925.png"
                                alt=""
                                className="abroad_logo_comp-sec"
                            />
                            <img
                                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/1d9baf077ee87921f57a8fe42d510b65.png"
                                alt=""
                                className="abroad_j_logo_comp-sec"
                            />
                        </div>
                        <img
                            src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/b196591e04a8e80a5bc93876fc8d1c91.16709243822961311"
                            alt=""
                            className="versus__image"
                        />
                        <span>Local Agent</span>
                    </div>
                    <div className="comparsion__table">
                        <div className="table__left-section">
                            <div className="table__left-heading">
                                <img
                                    src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/aa26cce2e055dccbd1b78456d65b7a0c.16709251315691311"
                                    alt=""
                                />
                            </div>
                            <div className="table__left-comparison-points">
                                <div className="individual-comparison-points">
                                    <img
                                        src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/e1b1b64765010b100ad46d7126d986d9.svg"
                                        alt=""
                                    />
                                    <p>Focus on best college</p>
                                </div>
                                <div className="individual-comparison-points">
                                    <img
                                        src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/e1b1b64765010b100ad46d7126d986d9.svg"
                                        alt=""
                                    />
                                    <p>Scholarship experts</p>
                                </div>
                                <div className="individual-comparison-points">
                                    <img
                                        src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/e1b1b64765010b100ad46d7126d986d9.svg"
                                        alt=""
                                    />
                                    <p>95% Visa success</p>
                                </div>
                                <div className="individual-comparison-points">
                                    <img
                                        src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/e1b1b64765010b100ad46d7126d986d9.svg"
                                        alt=""
                                    />
                                    <p>IELTS, GRE high covered</p>
                                </div>
                                <div className="individual-comparison-points">
                                    <img
                                        src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/e1b1b64765010b100ad46d7126d986d9.svg"
                                        alt=""
                                    />
                                    <p>Authentic Career consultants</p>
                                </div>
                            </div>
                        </div>
                        <div className="table__right-section">
                            <div className="table__right-heading">
                                <span>Local Group Class</span>
                            </div>
                            <div className="table__right-comparison-points">
                                <div className="individual-comparison-points right-comparison-points-text">
                                    <img
                                        src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/4f633f3bea669f410acca2b2b16da106.16709310822651311"
                                        alt=""
                                    />
                                    <p>Focus on maximum commission</p>
                                </div>
                                <div className="individual-comparison-points right-comparison-points-text">
                                    <img
                                        src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/4f633f3bea669f410acca2b2b16da106.16709310822651311"
                                        alt=""
                                    />
                                    <p>No scholarship assistance</p>
                                </div>
                                <div className="individual-comparison-points right-comparison-points-text">
                                    <img
                                        src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/4f633f3bea669f410acca2b2b16da106.16709310822651311"
                                        alt=""
                                    />
                                    <p>10% Visa success</p>
                                </div>
                                <div className="individual-comparison-points right-comparison-points-text">
                                    <img
                                        src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/4f633f3bea669f410acca2b2b16da106.16709310822651311"
                                        alt=""
                                    />
                                    <p>IELTS your problem</p>
                                </div>
                                <div className="individual-comparison-points right-comparison-points-text">
                                    <img
                                        src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/4f633f3bea669f410acca2b2b16da106.16709310822651311"
                                        alt=""
                                    />
                                    <p>Sales agent of university</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="free-consultation-text">
                        First Consultation is Free
                    </span>
                    <a
                        className="btn-schedule-session"
                        onClick={() => redirectTo(redirect)}
                    >
                        <svg
                            width={18}
                            height={21}
                            viewBox="0 0 18 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M15.3 2.95312H13.5V1.95312C13.5 1.68791 13.4052 1.43355 13.2364 1.24602C13.0676 1.05848 12.8387 0.953125 12.6 0.953125C12.3613 0.953125 12.1324 1.05848 11.9636 1.24602C11.7948 1.43355 11.7 1.68791 11.7 1.95312V2.95312H6.3V1.95312C6.3 1.68791 6.20518 1.43355 6.0364 1.24602C5.86761 1.05848 5.63869 0.953125 5.4 0.953125C5.1613 0.953125 4.93239 1.05848 4.7636 1.24602C4.59482 1.43355 4.5 1.68791 4.5 1.95312V2.95312H2.7C1.98392 2.95312 1.29716 3.2692 0.790812 3.8318C0.284464 4.39441 0 5.15748 0 5.95312V17.9531C0 18.7488 0.284464 19.5118 0.790812 20.0744C1.29716 20.6371 1.98392 20.9531 2.7 20.9531H15.3C16.0161 20.9531 16.7028 20.6371 17.2092 20.0744C17.7155 19.5118 18 18.7488 18 17.9531V5.95312C18 5.15748 17.7155 4.39441 17.2092 3.8318C16.7028 3.2692 16.0161 2.95312 15.3 2.95312ZM16.2 17.9531C16.2 18.2183 16.1052 18.4727 15.9364 18.6602C15.7676 18.8478 15.5387 18.9531 15.3 18.9531H2.7C2.46131 18.9531 2.23239 18.8478 2.0636 18.6602C1.89482 18.4727 1.8 18.2183 1.8 17.9531V10.9531H16.2V17.9531ZM16.2 8.95312H1.8V5.95312C1.8 5.68791 1.89482 5.43355 2.0636 5.24602C2.23239 5.05848 2.46131 4.95312 2.7 4.95312H4.5V5.95312C4.5 6.21834 4.59482 6.4727 4.7636 6.66023C4.93239 6.84777 5.1613 6.95312 5.4 6.95312C5.63869 6.95312 5.86761 6.84777 6.0364 6.66023C6.20518 6.4727 6.3 6.21834 6.3 5.95312V4.95312H11.7V5.95312C11.7 6.21834 11.7948 6.4727 11.9636 6.66023C12.1324 6.84777 12.3613 6.95312 12.6 6.95312C12.8387 6.95312 13.0676 6.84777 13.2364 6.66023C13.4052 6.4727 13.5 6.21834 13.5 5.95312V4.95312H15.3C15.5387 4.95312 15.7676 5.05848 15.9364 5.24602C16.1052 5.43355 16.2 5.68791 16.2 5.95312V8.95312Z"
                                fill="white"
                            />
                        </svg>
                        Scheduled a Session
                    </a>
                </div>
                <div className="section__our-results">
                    <div className="our-results-heading">
                        <img
                            src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/ca7a73c17fbed4d81e139537e743a2fe.svg"
                            alt=""
                        />
                    </div>
                    <span className="our-results-text">OUR Results</span>
                    <p className="our-results-subheading">
                        Speaks VOLUMES about us
                    </p>
                    <div className="our-results-card-wrapper">
                        <div className="result-card-img-wrapper">
                            <img
                                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8115f6482f9315dbb08fc3baf7385faf.webp"
                                alt=""
                            />
                        </div>
                        <div className="result-card-img-wrapper">
                            <img
                                src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/0abc8b2afe025a5487d09ce6ea2fcf98.16711728406111611"
                                alt=""
                            />
                        </div>
                        <div className="result-card-img-wrapper">
                            <img
                                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8f9da29f400314f069b75e5ce767bc2d.webp"
                                alt=""
                            />
                        </div>
                    </div>
                    <a
                        className="btn-view-all"
                        onClick={() => redirectTo(redirect)}
                    >
                        <svg
                            width={20}
                            height={20}
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.75 14.375H13.75V15.625H8.75V14.375ZM6.25 14.375H7.5V15.625H6.25V14.375ZM8.75 11.25H13.75V12.5H8.75V11.25ZM6.25 11.25H7.5V12.5H6.25V11.25ZM8.75 8.125H13.75V9.375H8.75V8.125ZM6.25 8.125H7.5V9.375H6.25V8.125Z"
                                fill="white"
                            />
                            <path
                                d="M15.625 3.125H13.75V2.5C13.75 2.16848 13.6183 1.85054 13.3839 1.61612C13.1495 1.3817 12.8315 1.25 12.5 1.25H7.5C7.16848 1.25 6.85054 1.3817 6.61612 1.61612C6.3817 1.85054 6.25 2.16848 6.25 2.5V3.125H4.375C4.04348 3.125 3.72554 3.2567 3.49112 3.49112C3.2567 3.72554 3.125 4.04348 3.125 4.375V17.5C3.125 17.8315 3.2567 18.1495 3.49112 18.3839C3.72554 18.6183 4.04348 18.75 4.375 18.75H15.625C15.9565 18.75 16.2745 18.6183 16.5089 18.3839C16.7433 18.1495 16.875 17.8315 16.875 17.5V4.375C16.875 4.04348 16.7433 3.72554 16.5089 3.49112C16.2745 3.2567 15.9565 3.125 15.625 3.125V3.125ZM7.5 2.5H12.5V5H7.5V2.5ZM15.625 17.5H4.375V4.375H6.25V6.25H13.75V4.375H15.625V17.5Z"
                                fill="white"
                            />
                        </svg>
                        View all
                    </a>
                </div>
                <PrivacyPolicyLink />
            </div>
        </StudyAbroadLocalStyled>
    );
};

export default Index;
