import React from 'react';
import LocalPrStyled from './style';
import { navigate } from 'gatsby';
import PrivacyPolicyLink from "../marketingComponent/privacy-policy-link"

const Index = () => {
    const isBrowser = typeof window !== 'undefined';
    let redirect = '';
    if (isBrowser) {
        let url_string = window.location.href;
        let url = new URL(url_string);
        let params = new URLSearchParams(url.search);
        redirect = params.toString();
    }

    const redirectTo = (url) => {
        url &&
            navigate(
                `/abroad-form?${url}&isMarketing=true&redirect_to=program-result`
            );
    };
    return (
        <LocalPrStyled>
            <div className="main-wrapper-localpr">
                <div className="header__top-section">
                    <p className="header__heading-one">
                        Your local canada PR agent has only
                        <svg
                            width="21"
                            height="29"
                            viewBox="0 0 21 29"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0.551648 17.05C0.918712 16.6833 1.36225 16.5073 1.88225 16.522C2.40226 16.5379 2.8458 16.7292 3.21286 17.0958L8.39763 22.275L8.39763 1.83333C8.39763 1.31389 8.57382 0.878167 8.9262 0.526167C9.27736 0.175389 9.71294 -4.69981e-07 10.2329 -4.4725e-07C10.753 -4.2452e-07 11.1891 0.175389 11.5415 0.526167C11.8927 0.878167 12.0683 1.31389 12.0683 1.83333L12.0683 22.275L17.2989 17.05C17.666 16.6833 18.1022 16.5 18.6075 16.5C19.1116 16.5 19.5472 16.6833 19.9142 17.05C20.2813 17.4167 20.4648 17.8524 20.4648 18.3572C20.4648 18.8607 20.2813 19.2958 19.9142 19.6625L11.5177 28.05C11.3341 28.2333 11.1353 28.3635 10.9212 28.4405C10.7071 28.5163 10.4777 28.5542 10.2329 28.5542C9.98824 28.5542 9.75882 28.5163 9.5447 28.4405C9.33058 28.3635 9.13176 28.2333 8.94823 28.05L0.505767 19.6167C0.169292 19.2806 0.00105203 18.8607 0.00105205 18.3572C0.00105208 17.8524 0.184585 17.4167 0.551648 17.05Z"
                                fill="#C80004"
                                fill-opacity="0.6"
                            />
                        </svg>
                        5% visa rate
                    </p>
                </div>
                <div className="hero__img-section">
                    <img
                        src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/d95b52320d621c52118650cf61c27edd.16709170100091311"
                        alt="rejected visa"
                    />
                </div>
                <div className="section__track-record">

                    <div className="track-record__left">
                        <div className="track-record__brand-logo">
                            <img
                                src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/0f4cd8906c4c26480bab71f1d56297c3.16709175291171311"
                                alt=""
                            />
                        </div>
                        <p className="track-record__heading">
                            We have a track record of
                        </p>
                        <div className="track-record__visa-success-rate">
                            <p className="percentage__number">
                                90<span className="percentage__symbol">%</span>
                            </p>
                            <div className="visa-success-rate-right">
                                <svg
                                    width={31}
                                    height={32}
                                    viewBox="0 0 31 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        x="0.753208"
                                        y="0.753208"
                                        width="29.4936"
                                        height="30.4936"
                                        rx="14.7468"
                                        fill="url(#paint0_linear_1695_4327)"
                                    />
                                    <path
                                        d="M22.7323 10.4004L12.7865 20.6671L8.26562 16.0004"
                                        stroke="white"
                                        strokeWidth="3.01283"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <rect
                                        x="0.753208"
                                        y="0.753208"
                                        width="29.4936"
                                        height="30.4936"
                                        rx="14.7468"
                                        stroke="#999999"
                                        strokeWidth="1.50642"
                                    />
                                    <defs>
                                        <linearGradient
                                            id="paint0_linear_1695_4327"
                                            x1="15.5"
                                            y1={0}
                                            x2="15.5"
                                            y2={32}
                                            gradientUnits="userSpaceOnUse"
                                        >
                                            <stop
                                                stopColor="#4DCE62"
                                                stopOpacity="0.74"
                                            />
                                            <stop
                                                offset={1}
                                                stopColor="#4DCE62"
                                            />
                                        </linearGradient>
                                    </defs>
                                </svg>
                                <p>VISA Success Rate</p>
                            </div>
                        </div>
                    </div>
                    <div className="track-record__right">
                        <div className='track-record__right-img-result'>
                            <img
                                src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/4c745ca7b942f7011584b3e239adf2dd.16714557226461911"
                                alt=""
                            />
                        </div>
                        <a
                            href="#"
                            className="btn-schedule-consultation"
                            onClick={() => redirectTo(redirect)}
                        >
                            <svg
                                width="19"
                                height="20"
                                viewBox="0 0 19 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M15.8 2H14V1C14 0.734784 13.9052 0.48043 13.7364 0.292893C13.5676 0.105357 13.3387 0 13.1 0C12.8613 0 12.6324 0.105357 12.4636 0.292893C12.2948 0.48043 12.2 0.734784 12.2 1V2H6.8V1C6.8 0.734784 6.70518 0.48043 6.5364 0.292893C6.36761 0.105357 6.13869 0 5.9 0C5.6613 0 5.43239 0.105357 5.2636 0.292893C5.09482 0.48043 5 0.734784 5 1V2H3.2C2.48392 2 1.79716 2.31607 1.29081 2.87868C0.784464 3.44129 0.5 4.20435 0.5 5V17C0.5 17.7956 0.784464 18.5587 1.29081 19.1213C1.79716 19.6839 2.48392 20 3.2 20H15.8C16.5161 20 17.2028 19.6839 17.7092 19.1213C18.2155 18.5587 18.5 17.7956 18.5 17V5C18.5 4.20435 18.2155 3.44129 17.7092 2.87868C17.2028 2.31607 16.5161 2 15.8 2ZM16.7 17C16.7 17.2652 16.6052 17.5196 16.4364 17.7071C16.2676 17.8946 16.0387 18 15.8 18H3.2C2.96131 18 2.73239 17.8946 2.5636 17.7071C2.39482 17.5196 2.3 17.2652 2.3 17V10H16.7V17ZM16.7 8H2.3V5C2.3 4.73478 2.39482 4.48043 2.5636 4.29289C2.73239 4.10536 2.96131 4 3.2 4H5V5C5 5.26522 5.09482 5.51957 5.2636 5.70711C5.43239 5.89464 5.6613 6 5.9 6C6.13869 6 6.36761 5.89464 6.5364 5.70711C6.70518 5.51957 6.8 5.26522 6.8 5V4H12.2V5C12.2 5.26522 12.2948 5.51957 12.4636 5.70711C12.6324 5.89464 12.8613 6 13.1 6C13.3387 6 13.5676 5.89464 13.7364 5.70711C13.9052 5.51957 14 5.26522 14 5V4H15.8C16.0387 4 16.2676 4.10536 16.4364 4.29289C16.6052 4.48043 16.7 4.73478 16.7 5V8Z"
                                    fill="white"
                                />
                            </svg>
                            Schedule Consultation
                        </a>
                    </div>
                </div>
                <div className="section__multiple-immigration-routes">
                    <div className="multiple-immigration-routes-wrapper">
                        <p className="multiple-immigration-routes-title">
                            Multiple Immigration routes
                        </p>
                        <div className="multiple-immigration-routes-card-wrapper">
                            <div className="multiple-immigration-route-card">
                                <div className="m-i-r-c__country-logo">
                                    <img
                                        src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/3d60b0f1c47b3e4ee8b061be9ee82179.16709224418751311"
                                        alt=""
                                    />
                                </div>
                                <span className="m-i-r-c__title max-width__fix">
                                    Canada Job
                                </span>
                            </div>
                            <div className="multiple-immigration-route-card">
                                <div className="m-i-r-c__country-logo">
                                    <img
                                        src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/3d60b0f1c47b3e4ee8b061be9ee82179.16709224418751311"
                                        alt=""
                                    />
                                </div>
                                <span className="m-i-r-c__title max-width__fix">
                                    Canada PR
                                </span>
                            </div>
                            <div className="multiple-immigration-route-card">
                                <div className="m-i-r-c__country-logo">
                                    <img
                                        src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/3d60b0f1c47b3e4ee8b061be9ee82179.16709224418751311"
                                        alt=""
                                    />
                                </div>
                                <span className="m-i-r-c__title max-width__fix">
                                    Canada PNP
                                </span>
                            </div>
                            <div className="multiple-immigration-route-card">
                                <div className="m-i-r-c__country-logo">
                                    <img
                                        src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/dd05a793787072b79ead86fcda9d8801.webp"
                                        alt=""
                                    />
                                </div>
                                <span className="m-i-r-c__title max-width__fix">
                                    Australia PR
                                </span>
                            </div>
                            <div className="multiple-immigration-route-card">
                                <div className="m-i-r-c__country-logo">
                                    <img
                                        src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/8623f72963784ed43277763d3263d24c.16709229893751311"
                                        alt=""
                                    />
                                </div>
                                <span className="m-i-r-c__title">
                                    Germany Job Seeker
                                </span>
                            </div>
                        </div>
                        <a
                            href="#"
                            className="btn-check-eligibility"
                            onClick={() => redirectTo(redirect)}
                        >
                            <svg
                                width={21}
                                height={20}
                                viewBox="0 0 21 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M14.4994 0H4.49937C3.38937 0 2.49937 0.89 2.49937 2V7.82C3.07759 7.25875 3.756 6.811 4.49937 6.5V2H11.4994V7.82C11.5294 7.85 11.5694 7.87 11.5994 7.9C11.9394 8.24 12.2294 8.61 12.4694 9H18.4994V16H12.4694C12.2294 16.39 11.9394 16.76 11.5994 17.1C11.2394 17.45 10.8494 17.74 10.4394 18H18.4994C19.6094 18 20.4994 17.11 20.4994 16V6L14.4994 0ZM13.4994 7V1.5L18.9994 7H13.4994ZM6.99937 8C4.49937 8 2.49937 10 2.49937 12.5C2.49937 13.38 2.74937 14.21 3.18937 14.9L0.109375 18L1.49937 19.39L4.61937 16.32C5.30937 16.75 6.12937 17 6.99937 17C9.49937 17 11.4994 15 11.4994 12.5C11.4994 10 9.49937 8 6.99937 8ZM6.99937 15C6.33633 15 5.70045 14.7366 5.23161 14.2678C4.76277 13.7989 4.49937 13.163 4.49937 12.5C4.49937 11.837 4.76277 11.2011 5.23161 10.7322C5.70045 10.2634 6.33633 10 6.99937 10C7.66242 10 8.2983 10.2634 8.76714 10.7322C9.23598 11.2011 9.49938 11.837 9.49938 12.5C9.49938 13.163 9.23598 13.7989 8.76714 14.2678C8.2983 14.7366 7.66242 15 6.99937 15Z"
                                    fill="white"
                                />
                            </svg>
                            Check Eligibility
                        </a>
                    </div>
                </div>
                <div className="section__comparison">
                    <div className="comparison-section__title">
                        <div className="comparsion-section__logo-image-wrapper">
                            <img
                                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/48a8628bb6770fd458587b23070b7925.png"
                                alt=""
                                className="abroad_logo_comp-sec"
                            />
                            <img
                                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/1d9baf077ee87921f57a8fe42d510b65.png"
                                alt=""
                                className="abroad_j_logo_comp-sec"
                            />
                        </div>
                        <img
                            src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/b196591e04a8e80a5bc93876fc8d1c91.16709243822961311"
                            alt=""
                            className="versus__image"
                        />
                        <span>Local Agent</span>
                    </div>
                    <div className="comparsion__table">
                        <div className="table__left-section">
                            <div className="table__left-heading">
                                <img
                                    src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/aa26cce2e055dccbd1b78456d65b7a0c.16709251315691311"
                                    alt=""
                                />
                            </div>
                            <div className="table__left-comparison-points">
                                <div className="individual-comparison-points">
                                    <img
                                        src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/e1b1b64765010b100ad46d7126d986d9.svg"
                                        alt=""
                                    />
                                    <p>Multiple Immigration Routes</p>
                                </div>
                                <div className="individual-comparison-points">
                                    <img
                                        src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/e1b1b64765010b100ad46d7126d986d9.svg"
                                        alt=""
                                    />
                                    <p>90% Visa Success</p>
                                </div>
                                <div className="individual-comparison-points">
                                    <img
                                        src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/e1b1b64765010b100ad46d7126d986d9.svg"
                                        alt=""
                                    />
                                    <p>IELTS Covered</p>
                                </div>
                                <div className="individual-comparison-points">
                                    <img
                                        src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/e1b1b64765010b100ad46d7126d986d9.svg"
                                        alt=""
                                    />
                                    <p>Settlement and Placement </p>
                                </div>
                                <div className="individual-comparison-points">
                                    <img
                                        src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/e1b1b64765010b100ad46d7126d986d9.svg"
                                        alt=""
                                    />
                                    <p>100% Control of Your Profile</p>
                                </div>
                            </div>
                        </div>
                        <div className="table__right-section">
                            <div className="table__right-heading">
                                <span>Local Group Class</span>
                            </div>
                            <div className="table__right-comparison-points">
                                <div className="individual-comparison-points right-comparison-points-text">
                                    <img
                                        src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/4f633f3bea669f410acca2b2b16da106.16709310822651311"
                                        alt=""
                                    />
                                    <p>Focuses on a single route</p>
                                </div>
                                <div className="individual-comparison-points right-comparison-points-text">
                                    <img
                                        src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/4f633f3bea669f410acca2b2b16da106.16709310822651311"
                                        alt=""
                                    />
                                    <p>5% Visa Success</p>
                                </div>
                                <div className="individual-comparison-points right-comparison-points-text">
                                    <img
                                        src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/4f633f3bea669f410acca2b2b16da106.16709310822651311"
                                        alt=""
                                    />
                                    <p>IELTS is Your Problem</p>
                                </div>
                                <div className="individual-comparison-points right-comparison-points-text">
                                    <img
                                        src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/4f633f3bea669f410acca2b2b16da106.16709310822651311"
                                        alt=""
                                    />
                                    <p>Not Available </p>
                                </div>
                                <div className="individual-comparison-points right-comparison-points-text">
                                    <img
                                        src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/4f633f3bea669f410acca2b2b16da106.16709310822651311"
                                        alt=""
                                    />
                                    <p>No Control on Profile</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="free-consultation-text">
                        First Consultation is Free
                    </span>
                    <a
                        href="#"
                        className="btn-schedule-session"
                        onClick={() => redirectTo(redirect)}
                    >
                        <svg
                            width={18}
                            height={21}
                            viewBox="0 0 18 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M15.3 2.95312H13.5V1.95312C13.5 1.68791 13.4052 1.43355 13.2364 1.24602C13.0676 1.05848 12.8387 0.953125 12.6 0.953125C12.3613 0.953125 12.1324 1.05848 11.9636 1.24602C11.7948 1.43355 11.7 1.68791 11.7 1.95312V2.95312H6.3V1.95312C6.3 1.68791 6.20518 1.43355 6.0364 1.24602C5.86761 1.05848 5.63869 0.953125 5.4 0.953125C5.1613 0.953125 4.93239 1.05848 4.7636 1.24602C4.59482 1.43355 4.5 1.68791 4.5 1.95312V2.95312H2.7C1.98392 2.95312 1.29716 3.2692 0.790812 3.8318C0.284464 4.39441 0 5.15748 0 5.95312V17.9531C0 18.7488 0.284464 19.5118 0.790812 20.0744C1.29716 20.6371 1.98392 20.9531 2.7 20.9531H15.3C16.0161 20.9531 16.7028 20.6371 17.2092 20.0744C17.7155 19.5118 18 18.7488 18 17.9531V5.95312C18 5.15748 17.7155 4.39441 17.2092 3.8318C16.7028 3.2692 16.0161 2.95312 15.3 2.95312ZM16.2 17.9531C16.2 18.2183 16.1052 18.4727 15.9364 18.6602C15.7676 18.8478 15.5387 18.9531 15.3 18.9531H2.7C2.46131 18.9531 2.23239 18.8478 2.0636 18.6602C1.89482 18.4727 1.8 18.2183 1.8 17.9531V10.9531H16.2V17.9531ZM16.2 8.95312H1.8V5.95312C1.8 5.68791 1.89482 5.43355 2.0636 5.24602C2.23239 5.05848 2.46131 4.95312 2.7 4.95312H4.5V5.95312C4.5 6.21834 4.59482 6.4727 4.7636 6.66023C4.93239 6.84777 5.1613 6.95312 5.4 6.95312C5.63869 6.95312 5.86761 6.84777 6.0364 6.66023C6.20518 6.4727 6.3 6.21834 6.3 5.95312V4.95312H11.7V5.95312C11.7 6.21834 11.7948 6.4727 11.9636 6.66023C12.1324 6.84777 12.3613 6.95312 12.6 6.95312C12.8387 6.95312 13.0676 6.84777 13.2364 6.66023C13.4052 6.4727 13.5 6.21834 13.5 5.95312V4.95312H15.3C15.5387 4.95312 15.7676 5.05848 15.9364 5.24602C16.1052 5.43355 16.2 5.68791 16.2 5.95312V8.95312Z"
                                fill="white"
                            />
                        </svg>
                        Schedule a Session
                    </a>
                </div>
                <div className="section__our-results">
                    <div className="our-results-heading">
                        <img
                            src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/ca7a73c17fbed4d81e139537e743a2fe.svg"
                            alt=""
                        />
                    </div>
                    <span className="our-results-text">OUR Results</span>
                    <p className="our-results-subheading">
                        Speaks VOLUMES about us
                    </p>
                    <div className="our-results-card-wrapper">
                        <div className="result-card-img-wrapper">
                            <img
                                src="https://s3.us-east-1.wasabisys.com/lms-ufaber/Group%2039940.webp"
                                alt=""
                            />
                        </div>
                        <div className="result-card-img-wrapper">
                            <img
                                src="https://s3.us-east-1.wasabisys.com/lms-ufaber/Group%2039941.webp"
                                alt=""
                            />
                        </div>
                        <div className="result-card-img-wrapper">
                            <img
                                src="https://s3.us-east-1.wasabisys.com/lms-ufaber/Group%2039939.webp"
                                alt=""
                            />
                        </div>
                    </div>
                    <a
                        href="#"
                        className="btn-view-all"
                        onClick={() => redirectTo(redirect)}
                    >
                        <svg
                            width={20}
                            height={20}
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.75 14.375H13.75V15.625H8.75V14.375ZM6.25 14.375H7.5V15.625H6.25V14.375ZM8.75 11.25H13.75V12.5H8.75V11.25ZM6.25 11.25H7.5V12.5H6.25V11.25ZM8.75 8.125H13.75V9.375H8.75V8.125ZM6.25 8.125H7.5V9.375H6.25V8.125Z"
                                fill="white"
                            />
                            <path
                                d="M15.625 3.125H13.75V2.5C13.75 2.16848 13.6183 1.85054 13.3839 1.61612C13.1495 1.3817 12.8315 1.25 12.5 1.25H7.5C7.16848 1.25 6.85054 1.3817 6.61612 1.61612C6.3817 1.85054 6.25 2.16848 6.25 2.5V3.125H4.375C4.04348 3.125 3.72554 3.2567 3.49112 3.49112C3.2567 3.72554 3.125 4.04348 3.125 4.375V17.5C3.125 17.8315 3.2567 18.1495 3.49112 18.3839C3.72554 18.6183 4.04348 18.75 4.375 18.75H15.625C15.9565 18.75 16.2745 18.6183 16.5089 18.3839C16.7433 18.1495 16.875 17.8315 16.875 17.5V4.375C16.875 4.04348 16.7433 3.72554 16.5089 3.49112C16.2745 3.2567 15.9565 3.125 15.625 3.125V3.125ZM7.5 2.5H12.5V5H7.5V2.5ZM15.625 17.5H4.375V4.375H6.25V6.25H13.75V4.375H15.625V17.5Z"
                                fill="white"
                            />
                        </svg>
                        View all
                    </a>
                </div>
                <PrivacyPolicyLink/>
            </div>
        </LocalPrStyled>
    );
};

export default Index;
