import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery, Link } from "gatsby";
import scrollTo from "gatsby-plugin-smoothscroll";
const IndustryDefyingPerformance = (props) => {
	const data = useStaticQuery(query);
	const {
		allStrapiAbroadAdmission: { nodes: dataAdmission },
	} = data;
	const { heading, admissions } = dataAdmission[0];

	return (
		<IndustryDefyingPerformanceStyle>
			<IndustryDefyingWrapperPerformanceStyle>
				<p
					key={`heading`}
					id="industry_defying_performance_heading"
					dangerouslySetInnerHTML={{ __html: heading }}
				/>
				<IndustryBottomSectionStyle>
					{admissions.map((data) => {
						return (
							<div className="success_rate_result" key={data.strapi_id}>
								<small>{data.heading}</small>
								<span>{data.info}</span>
							</div>
						);
					})}
				</IndustryBottomSectionStyle>

				{!!props?.isMarketing && (
					<ViewAllButtonStyle onClick={() => scrollTo("#fold_form")}>Book a demo</ViewAllButtonStyle>
				)}
			</IndustryDefyingWrapperPerformanceStyle>
		</IndustryDefyingPerformanceStyle>
	);
};

const IndustryDefyingPerformanceStyle = styled.section`
	padding: 2rem 7.5rem;
	@media (max-width: 1000px) {
		padding: 1rem;
	}
	@media (max-width: 500px) {
		margin-bottom: 7.5rem;
		padding: 0.75rem;
	}
`;

const IndustryDefyingWrapperPerformanceStyle = styled.div`
	background: #fff0eb;
	border-radius: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	padding: 3rem 1rem;
	#industry_defying_performance_heading {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 2rem;
		line-height: 39px;
		text-align: center;
		color: var(--dark-blue);
		max-width: 34rem;
		margin-bottom: 2rem;
	}

	@media (max-width: 500px) {
		padding: 2rem 0.5rem;
		#industry_defying_performance_heading {
			font-size: 1.75rem;
			line-height: 34px;
		}
	}
	@media (max-width: 340px) {
		#industry_defying_performance_heading {
			font-size: 1.5rem;
		}
	}
	@media (max-width: 340px) {
		#industry_defying_performance_heading {
			font-size: 1.3rem;
		}
	}
`;

const IndustryBottomSectionStyle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	flex-wrap: wrap;
	gap: 7.5rem;
	.success_rate_result {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 0.5rem;
		min-width: 12.1875em;
	}
	.success_rate_result > small {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 19px;
		text-align: center;
		color: #396eb0;
	}
	.success_rate_result > span {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 2rem;
		line-height: 39px;
		text-align: center;
		color: var(--dark-blue);
	}

	@media (max-width: 1250px) {
		gap: 2.5rem;
	}
	@media (max-width: 1000px) {
		gap: 1.5rem;
	}
	@media (max-width: 715px) {
		flex-direction: column;
	}

	@media (max-width: 500px) {

		.success_rate_result > span {
			font-size: 1.5rem;
			line-height: 29px;
		}
	}
	@media (max-width: 340px) {
		.success_rate_result > span {
			font-size: 1.3rem;
		}
	}
`;
const ViewAllButtonStyle = styled.button`
	margin-top: 2.5rem;
	background: #fc997c;
	border-radius: 12px;
	padding: 1rem 2.5rem;
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-size: 1.125rem;
	line-height: 22px;
	color: #ffffff;
	border: none;
	outline: none;
	cursor: pointer;
	@media (max-width: 500px) {
		padding: 0.90625rem 1.75rem;
	}
`;
export const query = graphql`
	{
		allStrapiAbroadAdmission {
			nodes {
				heading
				admissions {
					strapi_id
					heading
					info
				}
			}
		}
	}
`;
export default IndustryDefyingPerformance;
