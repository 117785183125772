import React, { useState } from 'react';
import "./style.css"
import Accordion from '../Accordion/accordion';
import VideoPopup from '../commonComponent/videoPopup';


const HealthcareServiceComponent = () => {

    const [showVideo, setShowVideo] = useState(false);
    const [videoDetails, setVideoDetails] = useState({
        url: '',
        name: '',
    });

    const onClickPlayVideo = (url, name) => {
        setVideoDetails({
            url: url,
            name: name,
        });
        setShowVideo(true);
    };
    const data = [
        {
          question: "What is the UK Nurse Visa?",
          answer:
            "The UK Nurse Visa, often referred to as the Tier 2 (Health and Care) Visa, allows qualified nurses and healthcare professionals to work in the United Kingdom's National Health Service (NHS) and other healthcare settings.",
        },
        {
          question: "Who is eligible for the UK Nurse Visa?",
          answer:
            "- Qualification: BSC in Nursing/GNM <br> - Experience: Minimum six months of experience <br> - Age limit: Approximately less than 50 years. <br> - Other Requirements: OET OR IELTS CLEARED",
        },
        {
          question: "What is a Certificate of Sponsorship (COS)?",
          answer:
            "A COS is a crucial document issued by your UK employer. It confirms your job offer and sponsorship and is required for your visa application.",
        },
        {
          question: "Do I need to take an English language test?",
          answer:
            "Yes, applicants are required to prove their English language proficiency by taking an approved English language test, such as IELTS or OET.",
        },
        {
            question: "How long does it take to process the Nurse Visa application?",
            answer:
              "2 to 6 Months",
        },
        {
            question: "Can I bring my family with me on the Nurse Visa?",
            answer:
              "Yes, you may be able to bring your spouse or dependent children with you, but you'll need to meet specific financial and immigration requirements.",
        },
        {
            question: "What happens after I receive my Nurse Visa?",
            answer:
              "Once you arrive in the UK, you can start your nursing job. Be sure to adhere to the visa's conditions and maintain your immigration status.",
        },
        {
            question: "Can I apply for UK citizenship as a Nurse Visa holder?",
            answer:
              "Yes, you can apply for UK citizenship (naturalization) after meeting residency and other eligibility requirements, typically after living in the UK for several years.",
        },
    ];

    
    return (
        <div className='nhs-webpage-mainwrapper'>
            <section id="slide_one">
                <img
                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/94dae06f71fbe40e0f33fb2279ee9d04.png"
                alt=""
                />
            </section>
            <section id="slide_two">
                <div className="abroad-ninja-CommonSlideLogo">
                <img
                    src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8f25df2ca1ce5d0c36261634ec75cbc6.png"
                    alt=""
                />
                <div className="abroad-ninja-CommonSlideLogo-Line" />
                </div>
                <h1>abroadninja healthcare services</h1>
                <p className="abroad-ninja-heathcare-services-text">
                At abroadninja Healthcare, we believe in the power of people. That's why,
                as an international staffing organization, we've spent the past 10 years
                building a reputation for excellence that's second to none. With
                specialized divisions operating in India, we're dedicated to finding the
                best talent in the Healthcare /Medical sector worldwide. We've
                successfully placed over 10,000 candidates in roles that match their
                skills and experience.
                </p>
                <p className="slide_two-subheading">
                At abroadninja Healthcare, we're not just staffing experts - we're your
                partners in success!
                </p>
            </section>
            <section id="slide_three">
                <div className="abroad-ninja-CommonSlideLogo">
                <img
                    src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8f25df2ca1ce5d0c36261634ec75cbc6.png"
                    alt=""
                />
                <div className="abroad-ninja-CommonSlideLogo-Line" />
                </div>
                <h2>About abroadninja</h2>
                <div className="about-abroad-ninja-slide-three-image">
                <img
                    src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8cffbe505ed040a473f9424582f04a8c.svg"
                    alt=""
                />
                </div>
            </section>
            <section id="slide_four">
                <div className="abroad-ninja-CommonSlideLogo">
                <img
                    src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8f25df2ca1ce5d0c36261634ec75cbc6.png"
                    alt=""
                />
                <div className="abroad-ninja-CommonSlideLogo-Line" />
                </div>
                <h3>our presence</h3>
                <div className="slide_four-countryFlag-ContinentMap-ImageWrapper">
                <div className="slide_four-countryFlagImageWrapper">
                    <img
                    src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/76b6aa7dc061375b9e48f0809050e6f4.1695801031900278"
                    alt=""
                    />
                </div>
                <div className="slide_four-ContinentMapImageWrapper">
                    <img
                    src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/872765d4b2ca6e190402a83040145504.png"
                    alt=""
                    />
                </div>
                </div>
            </section>
            <section id="slide_five">
                <div className="abroad-ninja-CommonSlideLogo">
                <img
                    src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8f25df2ca1ce5d0c36261634ec75cbc6.png"
                    alt=""
                />
                <div className="abroad-ninja-CommonSlideLogo-Line" />
                </div>
                <h4>international authorized hiring partner</h4>
                <div className="slide_five-NHS-HandShakeImageWrapper">
                <div className="slide_five-NHSImageWrapper">
                    <img
                    src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/803cffa767044eab4fdda6e98fd27ced.png"
                    alt=""
                    />
                </div>
                <div className="slide_five-HandShakeImageWrapper">
                    <img
                    src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/96150845a8d4593c2df5b98ed053e278.png"
                    alt=""
                    />
                </div>
                </div>
                
                <p className="slide_five-subtext-two">
                The National Health Service (NHS) is the publicly funded healthcare system
                in England and one of the four National Health Service systems in the
                United Kingdom. It is the second largest single-payer healthcare system in
                the world. The NHS in the UK is comprised of 219 trusts, including acute
                hospital trusts, mental health trusts, and ambulance trusts.
                </p>
            </section>
            <section id="slide_six">
                <div className="abroad-ninja-CommonSlideLogo">
                <img
                    src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8f25df2ca1ce5d0c36261634ec75cbc6.png"
                    alt=""
                />
                <div className="abroad-ninja-CommonSlideLogo-Line" />
                </div>
                <h5>eligibility for nurses</h5>
                <div className="slide_five-EligibilityPoints-LadyDoctorImageWrapper">
                <div className="slide_five-EligibilityPoints-Wrapper">
                    <div className="slide_five-IndividualEligibilityPoints">
                    <li>Department: All departments</li>
                    </div>
                    <div className="slide_five-IndividualEligibilityPoints">
                    <li>Qualification: BSC in Nursing/ GNM</li>
                    </div>
                    <div className="slide_five-IndividualEligibilityPoints">
                    <li>Experience: Minimum 6 months of experience</li>
                    </div>
                    <div className="slide_five-IndividualEligibilityPoints">
                    <li>Age limit: Approximately less than 50 years.</li>
                    </div>
                    <div className="slide_five-IndividualEligibilityPoints">
                    <li>Other Requirements: OET OR IELTS CLEARED</li>
                    </div>
                </div>
                <div className="slide_five-LadyDoctorImageWrapper">
                    <img
                    src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/af9a86d7829a081a20171d2bf23bb5da.png"
                    alt=""
                    />
                </div>
                </div>
            </section>
            <section id="slide_seven">
                <div className="abroad-ninja-CommonSlideLogo">
                <img
                    src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8f25df2ca1ce5d0c36261634ec75cbc6.png"
                    alt=""
                />
                <div className="abroad-ninja-CommonSlideLogo-Line" />
                </div>
                <h6>OET/ IELTS criteria</h6>
                <div className="oet-ielts-tableScore-PaperCheckImageWrapper">
                <div className="oet-ielts-tableScoreWrapper">
                    <div className="oet-score-TableWrapper">
                    <span>OET: Overall B Score</span>
                    <div className="oet-score-table">
                        <img
                        src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/63f91531d490f717ff7233143f0860c8.svg"
                        alt=""
                        />
                    </div>
                    </div>
                    <div className="ielts-score-TableWrapper">
                    <span>IELTS: Overall 7 Score ( Academics )</span>
                    <div className="ielts-score-table">
                        <img
                        src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/b0e09093d541cb48e9709b0515f8673f.svg"
                        alt=""
                        />
                    </div>
                    </div>
                </div>
                <div className="slide_seven-PaperCheckImage">
                    <img
                    src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/3d570c703db8a4c45b783323efaaa9c8.png"
                    alt=""
                    />
                </div>
                </div>
            </section>
            <section id="slide_eight">
                <div className="abroad-ninja-CommonSlideLogo">
                <img
                    src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8f25df2ca1ce5d0c36261634ec75cbc6.png"
                    alt=""
                />
                <div className="abroad-ninja-CommonSlideLogo-Line" />
                </div>
                <span className="slide_eight-heading">recruitment process details</span>
                <div className="recruitment-process-points-image">
                <img
                    src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/0c2d2abae6586de3cba991189c57a582.svg"
                    alt=""
                />
                </div>
                <span className="slide_eight-subheading">how training works</span>
                <div className="slide_eight-how-training-works-flowchart">
                <img
                    src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/32f76c1bcd3ae56c6848bfbdf769bd00.1695807762779278"
                    alt=""
                />
                </div>
                <span className="slide_eight-subheading2">how training works</span>
                <div className="slide_eight-LaptopImage-HowTrainingWorksPoints-Wrapper">
                <div className="slide_eight-laptopImage">
                    <img
                    src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/14b6814e317f18345d88f9e7ea845e57.1695816574363278"
                    alt=""
                    />
                    <a
                    className="play-button"

                    onClick = {() => {
                        onClickPlayVideo("https://www.youtube.com/embed/KPWqZX1oD0I?si=i4o0oZIawWUmC0WF", "How training works.")
                    }}
                    >
                    <img
                        src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/ed8ea42fb5269011914dc07dc78c4ff8.svg"
                        alt=""
                    />
                    </a>
                </div>
                <div className="HowTrainingWorksPointsWrapper">
                    <div className="HowTrainingWorksIndividualPoints">
                    <img
                        src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/0c38d78b0b5c1a6ff0b2e66447567e40.svg"
                        alt=""
                    />
                    <span>Strategy videos</span>
                    <p>20+ Hours of "deep" IELTS Strategies</p>
                    </div>
                    <div className="HowTrainingWorksIndividualPoints">
                    <img
                        src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/fc20a516b6c96166eeb7a38a8242561e.svg"
                        alt=""
                    />
                    <span>Practice</span>
                    <p>Over 100 Hrs of Practice Exercise</p>
                    </div>
                    <div className="HowTrainingWorksIndividualPoints">
                    <img
                        src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8a88a8fa474f66a6bf1c8b8005a5ced2.svg"
                        alt=""
                    />
                    <span>Daily Study Plan</span>
                    <p>Trainer assigns pre-class and post-class material</p>
                    </div>
                </div>
                </div>
            </section>
            <section id="slide_nine">
                <div className="abroad-ninja-CommonSlideLogo">
                <img
                    src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8f25df2ca1ce5d0c36261634ec75cbc6.png"
                    alt=""
                />
                <div className="abroad-ninja-CommonSlideLogo-Line" />
                </div>
                <span className="slide_nine-heading">
                benefits provided to nurses from the trust/hospital
                </span>
                <div className="slide_nine-nurseBenefits-NurseImageWrapper">
                <div className="benefits-provided-to-nurse-pointsWrapper">
                    <ul>
                    <li>IELTS exam fee- reimbursed (1st attempt)</li>
                    <li>4 to 8 weeks accommodation</li>
                    <li>Certificate of Sponsorship</li>
                    <li>Visa (cost will be covered by NHS)</li>
                    <li>
                        3 x attempts at sitting OSCE Exam (1st attempt sponsored by NHS)
                    </li>
                    <li>NMC Application- NMC registration fee</li>
                    <li>CBT for NMC registration</li>
                    <li>
                        Salary Details :Starting from £28,407 per annum -Salary is
                        subjective to experience and interview.
                    </li>
                    <li>£1,000 advance salary upon arrival in the UK.</li>
                    </ul>
                </div>
                <div className="slide_nine-nurseimage">
                    <img
                    src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/7f92371ba499833a55fa9f9a6ed8fe76.png"
                    alt=""
                    />
                </div>
                </div>
            </section>
            <section id="slide_ten">
                <div className="abroad-ninja-CommonSlideLogo">
                <img
                    src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8f25df2ca1ce5d0c36261634ec75cbc6.png"
                    alt=""
                />
                <div className="abroad-ninja-CommonSlideLogo-Line" />
                </div>
                <span className="slide_ten-heading">
                benefits provided to nurses from abroadninja
                </span>
                <div className="benefits-provided-to-nurse-fromAbroadNinja-NurseImage-Wrapper">
                <div className="benefits-provided-to-nurse-fromAbroadNinjaWrapper">
                    <ul>
                    <li>Free Recruitment</li>
                    <li>Free Interview preparation</li>
                    <li>Location options in UK</li>
                    <li>Free assistance with Visa</li>
                    <li>Assistance for HCPC registration</li>
                    <li>Assistance until deploy to the UK</li>
                    </ul>
                </div>
                <div className="slide_ten-NurseImage">
                    <img
                    src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/5d9c6e40818dc7820e6e470ff35583a3.1695820362241278"
                    alt=""
                    />
                </div>
                </div>
            </section>
            <section id="slide_eleven">
                <div className="abroad-ninja-CommonSlideLogo">
                <img
                    src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8f25df2ca1ce5d0c36261634ec75cbc6.png"
                    alt=""
                />
                <div className="abroad-ninja-CommonSlideLogo-Line" />
                </div>
                <span className="slide_eleven-heading">Hear from our student</span>
                <div
                className="slide_eleven-ImageWrapper"

                onClick = {() => {
                    onClickPlayVideo("https://www.youtube.com/embed/xeq3PDjZpNo?si=B5647okFxIwws_RT", "Hear From Our Student")
                }}
                >
                <img
                    src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/5561734e823183a6a6ffe94d5ea9c363.1695822126013278"
                    alt=""
                />
                </div>
            </section>
            <section id="slide_twelve">
                <div className="abroad-ninja-CommonSlideLogo">
                <img
                    src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8f25df2ca1ce5d0c36261634ec75cbc6.png"
                    alt=""
                />
                <div className="abroad-ninja-CommonSlideLogo-Line" />
                </div>
                <span className="slide_twelve-heading">Frequently asked questions</span>
                <div className="faq-accordion-wrapper">
                    <Accordion data={data}/>
                </div>
            </section>
            <section id="final_slide">
                <p className="final_slide-text">
                Don't wait for the right opportunity , Just create it! Abroadninja is here
                to help you get your dream job!
                </p>
                <div className="find-us-on-SocialLinks">
                <span>Find Us On</span>
                <div className="social-link-wrapper">
                    <a href="https://www.youtube.com/@abroadninja" target="_blank">
                    <img
                        src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/c66b447a95fd71c6a9d2ed52d1a111f0.svg"
                        alt=""
                    />
                    </a>
                    <a href="http://abroadninja.in/" target="_blank">
                    <img
                        src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/12a39acff093399a0eadfb37309e48a8.svg"
                        alt=""
                    />
                    </a>
                </div>
                </div>
            </section>
            {showVideo && videoDetails.url != '' && (
                <VideoPopup
                    setShowVideo={setShowVideo}
                    link={videoDetails.url}
                    name={videoDetails.name}
                />
            )}
        </div>
    );
}




export default HealthcareServiceComponent;
