import React from 'react'
import styled from "styled-components";

const OurCaseStudy = () => {
  return (
    <OurCaseStudyStyled>
        <h1>Our Case Studies</h1>

        <OurCaseStudyInnerStyled>

            <OurCaseStudyCardStyled>
                <div className='info-card'>
                    <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/1960bdac506ab553c8ef53a9562bf41a.png" alt="" />
                    <h3>Vaishak Biju | Canada</h3>
                </div>
                <p>Candidate has received 7 times rejection from previous Consultancy. Major Mistakes was Plagiarized Visa intent letter has been used from previous candidates. We have reworked on candidate profile completely and visa got approved in 45 working days</p>
                <div className='img-card'>
                    <div>
                        <p className='red'>Refusal Letter ❌</p>
                        <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/06235b8f72b16800918308c00d250377.png" alt="" />
                    </div>
                    <div>
                        <p className='green'>Acceptance Letter ✅</p>
                        <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/399ca4a8433419c5a93408769c769129.png" alt="" />
                    </div>
                </div>
                <div className='test-card'>
                    <h3>Testimonial</h3>
                    <a href="https://www.youtube.com/watch?v=7mkIRQgHP9Q" target='_blank'>
                        <div>
                            <img className='test-img' src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/ef0032a528f7004fd2d27d855cdac393.png" alt="" />
                            <img className='view' src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/29988eb1574905485687a5b2238883f4.png" alt="" />
                        </div>
                    </a>
                </div>
            </OurCaseStudyCardStyled>

            <OurCaseStudyCardStyled>
                <div className='info-card'>
                    <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/f32774e262f423b47c6169ff9a4f8ff9.png" alt="" />
                    <h3>Rajanpreet | Canada</h3>
                </div>
                <p>Candidate received 2 times visa refusal due to low score in IELTS and visa file has been created under NON SDS category. Candidate has visited multiple non authorised platform like youtube and quora and created profile on his own with low IELTS due to which candidate got rejected 2 times from canada. We have retrained candidate in IELTS and applied for Visa under SDS stream.</p>
                <div className='img-card'>
                    <div>
                        <p className='red'>Refusal Letter ❌</p>
                        <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/34af3f2861468ceb0a821cdf80777f7a.png" alt="" />
                    </div>
                    <div>
                        <p className='green'>Acceptance Letter ✅</p>
                        <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/1cc4f80904703f1f3608fdc010415312.png" alt="" />
                    </div>
                </div>
                <div className='test-card'>
                    <h3>Testimonial</h3>
                    <a href="https://www.youtube.com/watch?v=k9oTrFEHH2U" target="_blank">
                        <div>
                            <img className='test-img' src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/7fcf3ea92d06af4a424eb48edf6a24a5.png" alt="" />
                            <img className='view' src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/29988eb1574905485687a5b2238883f4.png" alt="" />
                        </div>
                    </a> 
                </div>
            </OurCaseStudyCardStyled>

        </OurCaseStudyInnerStyled>

    </OurCaseStudyStyled>
  )
}

export default OurCaseStudy


const OurCaseStudyStyled = styled.section`
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
    display: flex;
    flex-direction: column;
    width: 82%;
    padding: 50px 0px;

    h1 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 35px;
        line-height: 39px;
        leading-trim: both;
        text-edge: cap;
        color: #113A68;
    }
`;

const OurCaseStudyInnerStyled = styled.section`

`;

const OurCaseStudyCardStyled = styled.section`
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
    display: flex;
    flex-direction: column;
    padding: 30px 0;

    p {
        width: 90%;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 145%;
        leading-trim: both;
        text-edge: cap;
        color: #405772;
        padding: 0 0 40px;
    }

    .info-card {
        display: flex;
        flex-direction: row;
        gap: 20px;
        padding: 20px 0;
    }

    .info-card h3 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 145%;
        leading-trim: both;
        text-edge: cap;
        color: #113A68;
        padding: 10px 0;

    }

    .info-card img {
        width: fit-content;
    }

    .img-card {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 20px;
    }

    .img-card p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 145%;
        leading-trim: both;
        text-edge: cap;
        margin: 0;
        padding-bottom: 20px;
        
    }

    .img-card .red {
        color: #EE0000;
    }

    .img-card .green {
        color: #00BA00;
    }

    .test-card {
        padding: 70px 0 0;
        display: flex;
        flex-direction: column;

    }

    .test-card h3 {
        text-align: left;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 145%;
        leading-trim: both;
        text-edge: cap;
        color: #113A68;
    }

    .test-card div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .test-card .test-img {
        cursor: pointer;
        width: 60%;
        margin: 50px 0;
        background-color: rgba(0, 0, 0, 0.36);
        border-radius: 10px;
    }

    .test-card .view {
        cursor: pointer;
        width: 10%;
        position: absolute;
    }
`;