import React, { useState } from 'react';
import Layout from '../components/layout.component';
import SEO from '../components/seo';
import styled from 'styled-components';
import { useStudyData } from '../hooks/study';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';
import { useCaseStudyHeadingData } from '../hooks/caseStudyHeading';
const AllStudyPage = () => {
    const [filter, setFilter] = useState('all');
    const aDestinations = useStudyData();
    let destinations = aDestinations.filter(
        (item) => item.page !== 'case-studie'
    );
    const onClickFilter = (name) => {
        setFilter(name);
    };

    const caseStudyHeader = useCaseStudyHeadingData();
    const filterCaseStudyHeading = caseStudyHeader.filter(
        (item) => item.page_type === 'AllDestinationPage'
    );
    const { heading, subheading } = filterCaseStudyHeading[0] ?? '';

    return (
        <Layout>
            <SEO
                title={
                    'Study Abroad from India - Courses, Top Universities, Student Visa'
                }
                description={
                    'Want to study abroad from India? Get all information on best countries to study abroad, colleges, courses, student visa, and application process.'
                }
            />
            <SuccessStoriesStyled>
                <SuccessStoriesWrapperStyled>
                    <div dangerouslySetInnerHTML={{ __html: heading ?? '' }} />
                    <div
                        dangerouslySetInnerHTML={{ __html: subheading ?? '' }}
                    />

                    <DestinationFilterWrapper>
                        <label
                            htmlFor="all"
                            id="all_button"
                            name="all"
                            className={`${filter == 'all' ? 'isActive' : ''}`}
                            onClick={() => onClickFilter('all')}
                        >
                            All
                        </label>

                        <label
                            id="study_destination_button"
                            onClick={() => onClickFilter('study page')}
                            className={`${
                                filter == 'study page' ? 'isActive' : ''
                            }`}
                            name="Study Abroad"
                        >
                            Study Destination
                        </label>

                        <label
                            id="settle_destination_button"
                            name="settle page"
                            onClick={() => onClickFilter('settle page')}
                            className={`${
                                filter == 'settle page' ? 'isActive' : ''
                            }`}
                        >
                            Settle Destination
                        </label>
                    </DestinationFilterWrapper>
                    <DestinationsCard>
                        {destinations
                            .filter((data) => {
                                if (filter === 'all' || filter === ' ') {
                                    return data;
                                }
                                return data.type === filter;
                            })
                            .map((item) => {
                                let heading = item.heading;
                                let stringSplit = heading.split(' ');
                                let heading1 = stringSplit[3];
                                let heading2 = `${stringSplit[1]} ${stringSplit[2]}`;
                                return (
                                    <>
                                        <div className="card">
                                            <CardWrapper
                                                imgUrl={item?.img?.url}
                                            >
                                                <div className="left-card-section">
                                                    <p className="heading2">
                                                        {heading2}
                                                    </p>
                                                    <p className="heading1">
                                                        {heading1}
                                                    </p>
                                                </div>
                                                <div className="right-card-section">
                                                    {/* <img src={item.img.url}></img> */}
                                                    <Link
                                                        to={`/study-page/${item.slug}`}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={
                                                                faCircleChevronRight
                                                            }
                                                            size="1x"
                                                            className="fa-circle-chevron-right"
                                                        />
                                                    </Link>
                                                </div>
                                            </CardWrapper>
                                        </div>
                                    </>
                                );
                            })}
                    </DestinationsCard>
                </SuccessStoriesWrapperStyled>
            </SuccessStoriesStyled>
        </Layout>
    );
};

const DestinationFilterWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    #all_button {
        padding: 0.625rem 1.5rem;
    }
    #study_destination_button {
        padding: 0.625rem 1.25rem;
    }
    #settle_destination_button {
        padding: 0.625rem 1.25rem;
    }

    @media (max-width: 500px) {
        padding: 0.8rem 0rem;
        justify-content: flex-start;
        width: 100%;
        overflow-y: scroll;
        gap: 0.5rem;
    }

    .isActive {
        background-color: #113a68;
        color: #fff0eb;
        transition: 0.2s all;
    }
    label {
        white-space: nowrap;
        position: relative;
        font-weight: 500;
        font-size: 1rem;
        line-height: 19px;
        color: #113a68;
        background: #fff0eb;
        border-radius: 63px;
        cursor: pointer;
    }

    label::before {
        content: '';
        height: 30px;
        width: 30px;
    }
    padding: 2rem 0.75rem;
`;
const CardWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 4em;
    width: 16rem;
    height: 7.75rem;
    background-image: ${(props) => `linear-gradient(
			270deg,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 0.9) 62.3%,
			#ffffff 101.37%
		),url(${props.imgUrl})`};
    /* background-image: linear-gradient(
			270deg,
			rgba(255, 255, 255, 0) 0%,
			rgba(255, 255, 255, 0.9) 62.3%,
			#ffffff 101.37%
		),
		url("https://res.cloudinary.com/ddzvvtcnt/image/upload/v1667646755/united_states_ad11c6d792.png"); */
    background-repeat: no-repeat;
    background-size: cover;
    .left-card-section {
        position: absolute;

        left: 16px;
        bottom: 15px;
    }
    .right-card-section {
        position: absolute;
        /* top: 0; */
        right: 7px;
        bottom: 13px;
    }
    .heading2 {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #113a68;
        margin-bottom: 0.4rem;
    }
    .heading1 {
        font-weight: 700;
        font-size: 18px;
        line-height: 19px;
        color: #113a68;
    }
`;
const DestinationsCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3.5em;
    flex-wrap: wrap;
    width: 100%;
    .card {
        background: #ffffff;
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
    }

    .fa-circle-chevron-right {
        color: #fc997c;
        font-size: 1rem;
        cursor: pointer;
        height: 2.4rem;
        background: white;
        border-radius: 50%;
    }
`;
const SuccessStoriesWrapperStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 5rem;
    padding: 0.75rem;
    & h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 900;
        font-size: 3rem;
        line-height: 58px;
        text-align: center;
        color: #396eb0;
        margin-bottom: 1rem;
        margin-top: 4rem;
    }
    & h1 > span {
        color: #113a68;
    }
    .good_university_subheading {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 29px;
        text-align: center;
        color: #396eb0;
        margin-bottom: 2.25rem;
    }

    .good_university_people_info {
        font-size: 1rem;
        line-height: 19px;
        color: var(--dark-blue);
        width: 20em;
    }

    @media (max-width: 541px) {
        & h2 {
            font-size: 2.7rem;
        }
    }
    @media (max-width: 500px) {
        & h2 {
            font-size: 1.75rem;
            line-height: 34px;
        }
        #good_university_subheading {
            font-size: 1rem;
            line-height: 19px;
            font-weight: 500;
            margin-bottom: 3rem;
        }
    }

    @media (max-width: 355px) {
        & h2 {
            font-size: 1.5rem;
        }
    }

    @media (max-width: 315px) {
        & h2 {
            font-size: 1.3rem;
        }
        #good_university_subheading {
            font-size: 0.9rem;
        }
    }
`;

const SuccessStoriesStyled = styled.section`
    margin-top: 7rem;
    .good_university_people_name {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 19px;
        color: var(--dark-blue);
    }
    .good_university_people_desgn {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 17px;
        color: #889db4;
    }
    .good_university_people_read_more {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 19px;
        color: #fc997c;
        margin-right: 1.5em;
        cursor: pointer;
    }

    .good_university_people_card_wrapper {
        background: #ffffff;
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
        border-radius: 16px;
        min-width: 21em;
        max-width: 21em;
        height: 100%;
        padding: 0.5em;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 0.75em;
    }
    .good_university_people_card_img_wrapper {
        border-radius: 12px;
        width: 100%;
        height: auto;
        overflow: hidden;
        position: relative;
    }
    .good_university_people_card_img_wrapper img {
        min-width: 100%;
        max-width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .good_university_people_bottom_details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .good_university_people_left_bottom {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        padding-left: 0.5em;
    }
    #good_university_people_wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3.5rem;
        width: 100%;
        margin-bottom: 3rem;
        flex-wrap: wrap;
    }
    .fa-play {
        font-size: 3rem;
        color: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
    }
    @media (max-width: 390px) {
        .good_university_people_card_details {
            width: 100%;
        }
        .good_university_people_card_wrapper {
            min-width: 100%;
            max-width: 100%;
        }
        .good_university_people_card_img_wrapper {
            min-width: 100%;
            max-width: 100%;
        }
    }

    @media (max-width: 355px) {
        .good_university_people_card_wrapper {
            min-width: 18rem;
            max-width: 18rem;
        }
        .good_university_people_info {
            font-size: 0.8rem;
        }
        .good_university_people_read_more {
            font-size: 0.9rem;
        }
    }
    @media (max-width: 315px) {
        .good_university_people_card_wrapper {
            min-width: 15rem;
            max-width: 15rem;
        }
        .good_university_people_read_more {
            margin-right: 0rem;
            font-size: 0.8rem;
        }
        .good_university_people_name {
            font-size: 0.8rem;
        }
        .good_university_people_desgn {
            font-size: 0.7rem;
        }
        .good_university_people_info {
            font-size: 0.7rem;
        }
    }
`;
export default AllStudyPage;
