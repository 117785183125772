import styled from 'styled-components';
import React from 'react';
import universityLogo from '../../../assets/images/university-logo.png';
import topRankIcon from '../../../assets/images/top-rank.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';
const Universities = (props) => {
    const { universities, showExploreUniversity, searchText } = props;

    return (
        <>
            {searchText && (
                <SearchHeading>{`Search in "${searchText}" in university`}</SearchHeading>
            )}
            <UniversitiesAll>
                {universities.map((university) => {
                    let rankIsVisible = true;
                    let rankingMessage;
                    let universityRank = university.minrank;
                    // let universityRank = university.rank.split("-")[0];
                    // universityRank = parseInt(universityRank);
                    if (universityRank > 0 && universityRank <= 10) {
                        //   rankingMessage = "Top 5";
                        // } else if (universityRank > 5 && universityRank <= 10) {
                        rankingMessage = 'Top 10';
                    } else if (universityRank > 10 && universityRank <= 50) {
                        rankingMessage = 'Top 50';
                    } else if (universityRank > 50 && universityRank <= 100) {
                        rankingMessage = 'Top 100';
                    } else if (universityRank > 100 && universityRank <= 500) {
                        rankingMessage = 'Top 500';
                    } else {
                        rankIsVisible = false;
                    }

                    return (
                        <UniversityCard
                            key={university.id}
                            rankIsVisible={rankIsVisible}
                        >
                            <div className="university-details">
                                <img
                                    src={universityLogo}
                                    alt="University Logo"
                                />
                                <div>
                                    <p className="university-details__name">
                                        {university.name}
                                    </p>
                                    <p className="university-details__location">
                                        {university.city}, {university.country}
                                    </p>
                                </div>
                            </div>
                            <div className="university-ranking">
                                <img src={topRankIcon} alt="Ranking" />
                                <p className="university-ranking__rank">
                                    {rankingMessage}
                                </p>
                                {/* <p>
                {university.minrank}-{university.maxrank}
              </p> */}
                            </div>
                            {/* <div className="university-view">
                                {university.internationalWebsiteLink ? (
                                    <a
                                        href={
                                            university.internationalWebsiteLink
                                        }
                                        target="_blank"
                                    >
                                        View Details
                                    </a>
                                ) : (
                                    <a
                                        href="/study-abroad/not-found"
                                        target="_blank"
                                    >
                                        View Details
                                    </a>
                                )}
                            </div> */}
                            <div className="university-view">
                                <Link
                                    to={`/study-abroad/university-details/?university=${university.id}`}
                                >
                                    View Details
                                </Link>
                            </div>
                        </UniversityCard>
                    );
                })}
                {showExploreUniversity && (
                    <UniversityCard>
                        <div className="university-details show_all">
                            <img src={universityLogo} alt="University Logo" />
                            <div>
                                <p className="university-details__name__all">
                                    Explore universities
                                </p>
                            </div>
                            <Link to={'/study-abroad/university-search'}>
                                <FontAwesomeIcon
                                    icon={faCircleChevronRight}
                                    size="1x"
                                    className="fa-circle-chevron-right"
                                />
                            </Link>
                        </div>
                    </UniversityCard>
                )}
            </UniversitiesAll>
        </>
    );
};
const SearchHeading = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 1rem;
    line-height: 25px;
    color: #113a68;
    margin-bottom: 1rem;
`;
const UniversitiesAll = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem 2rem;
    margin-bottom: 3.5rem;

    @media (max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const UniversityCard = styled.div`
    background-color: #ffffff;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    padding: 1rem;
    min-width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    ${({ rankIsVisible }) =>
        !rankIsVisible &&
        `.university-ranking {
    visibility: hidden;
  }`}

    .university-details {
        display: flex;
        gap: 1rem;
    }
    .show_all {
        margin-top: 39px;
    }
    .university-details img {
        width: 40px;
        height: 40px;
        object-fit: contain;
    }

    .university-details__name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
        font-size: 1rem;
        line-height: 25px;
        color: #113a68;
        width: 250px;
    }

    .university-details__name__all {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
        font-size: 1rem;
        line-height: 25px;
        color: #113a68;
        margin-top: 9px;
    }

    .university-details__location {
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 17px;
        color: #396eb0;
    }

    .university-ranking {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        background-color: #fff0eb;
        border-radius: 5px;
        width: fit-content;
        padding: 0.25rem;
    }

    .university-ranking__rank {
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 17px;
        color: #396eb0;
    }

    .university-view {
        border-top: 1px solid #fff0eb;
        padding-top: 0.75rem;
        text-align: center;
    }

    .university-view a {
        font-weight: 700;
        font-size: 0.875rem;
        line-height: 17px;
        color: #fc997c;
    }
    .fa-circle-chevron-right {
        color: #fc997c;
        font-size: 2.5rem;
        cursor: pointer;
        height: 3.4rem;
    }
`;

export default Universities;
