import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import ReactMarkdown from "react-markdown";

import rehypeRaw from "rehype-raw";
import gfm from "remark-gfm";
import styled from "styled-components";
import Layout from "../components/layout.component";
import SEO from "../components/seo";
const TermsCondition = () => {
	const queryData = useStaticQuery(query);
	const {
		allStrapiTermsConditionPageTermsConditionTextnode: { nodes: data_terms_condition },
	} = queryData;
	const termCondition = data_terms_condition[0];

	return (
		<Layout>
			<SEO 
				title="Terms and Conditions | Abroad Ninja" 
				description="Have any doubts or confusion about enrolling in Abroad Ninja? Read our terms and conditions before coming to any conclusions here."
				og_type="website"
				og_url="https://abroadninja.in/terms_condition/"
				og_title="Terms and Conditions | Abroad Ninja"
				og_description="Have any doubts or confusion about enrolling in Abroad Ninja? Read our terms and conditions before coming to any conclusions here."
				og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
				twitter_card="summary_large_image"
				twitter_url="https://abroadninja.in/terms_condition/"
				twitter_title="Terms and Conditions | Abroad Ninja"
				twitter_description="Have any doubts or confusion about enrolling in Abroad Ninja? Read our terms and conditions before coming to any conclusions here."
				twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
				robots="index, follow"
				canonical="https://abroadninja.in/terms_condition/"
			/>
			<TermConditionStyled id="terms_and_condition">
				<div id="terms_and_condition_wrapper">
					<h1>Terms &amp; Conditions</h1>
					<div id="terms_and_condition_ol">
						<ReactMarkdown
							rehypePlugins={[rehypeRaw]}
							plugins={[gfm]}
							children={termCondition.terms_condition}
						/>
					</div>
				</div>
			</TermConditionStyled>
		</Layout>
	);
};

const TermConditionStyled = styled.section`
	padding: 10rem;
	#terms_and_condition_wrapper {
		width: 100%;
	}
	#terms_and_condition_wrapper h1 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: left;
		color: #113a68;
		margin-bottom: 4rem;
	}
	ol {
		list-style-position: inside;
	}
	ol li {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 19px;
		color: #242424;
		margin-bottom: 1rem;
	}
	@media (max-width: 1280px) {
		padding: 5rem;
	}
	@media (max-width: 850px) {
		padding: 3.5rem;
	}
	@media (max-width: 600px) {
		padding: 1.5rem;
	}
	@media (max-width: 500px) {
		padding: 0.75rem;

		#terms_and_condition_wrapper h1 {
			font-weight: 900;
			font-size: 1.25rem;
			margin-bottom: 1rem;
		}
		ol li {
			font-weight: 400;
			font-size: 0.75rem;
			line-height: 15px;
		}
	}
`;
export const query = graphql`
	query {
		allStrapiTermsConditionPageTermsConditionTextnode {
			nodes {
				terms_condition
			}
		}
	}
`;

export const Head = () => {
	return (
		<SEO
			title={"Read our Terms and Conditions | Abroad Ninja |"}
			description="Have any doubts or confusion about enrolling in Abroad Ninja? Read our terms and conditions before coming to any conclusion here."
		/>
	)
}
export default TermsCondition;
