// extracted by mini-css-extract-plugin
export var IndividualUniversity__mainwrapper = "UniversityDetails-module--IndividualUniversity__mainwrapper--a077e";
export var btnAskExperts = "UniversityDetails-module--btn-ask-experts--51f0f";
export var btnBack__icon = "UniversityDetails-module--btn-back__icon--19968";
export var btnKnowMore = "UniversityDetails-module--btn-know-more--a05e6";
export var courseDetails__title = "UniversityDetails-module--course-details__title--07a3e";
export var courseDetails__universityName = "UniversityDetails-module--course-details__university-name--f615b";
export var courseDetails__value = "UniversityDetails-module--course-details__value--47997";
export var detailsLink = "UniversityDetails-module--details-link--0059f";
export var horizontalLine = "UniversityDetails-module--horizontal-line--abe76";
export var mobileView__backbutton = "UniversityDetails-module--mobileView__backbutton--8678a";
export var notAvailable = "UniversityDetails-module--not-available--d85ec";
export var universityAdmission__applicationFee = "UniversityDetails-module--university-admission__application-fee--1936b";
export var universityAdmission__documents = "UniversityDetails-module--university-admission__documents--da9dd";
export var universityAdmission__test = "UniversityDetails-module--university-admission__test--fe66d";
export var universityAdmission__testAd = "UniversityDetails-module--university-admission__test-ad--34020";
export var universityAdmission__testAdMsg = "UniversityDetails-module--university-admission__test-ad-msg--bf910";
export var universityAdmission__testDetails = "UniversityDetails-module--university-admission__test-details--1c342";
export var universityAdmission__testName = "UniversityDetails-module--university-admission__test-name--2b372";
export var universityAdmission__testScore = "UniversityDetails-module--university-admission__test-score--c6480";
export var universityAdmission__testTitle = "UniversityDetails-module--university-admission__test-title--16424";
export var universityContact__links = "UniversityDetails-module--university-contact__links--5f463";
export var universityContact__text = "UniversityDetails-module--university-contact__text--a26bc";
export var universityContent = "UniversityDetails-module--university-content--c2509";
export var universityContent_Webviewleft = "UniversityDetails-module--universityContent_Webviewleft--b69f7";
export var universityContent_Webviewright = "UniversityDetails-module--universityContent_Webviewright--b282f";
export var universityContent__admission = "UniversityDetails-module--university-content__admission--78199";
export var universityContent__card = "UniversityDetails-module--university-content__card--e860e";
export var universityContent__rank = "UniversityDetails-module--university-content__rank--d5d08";
export var universityContent__ranking = "UniversityDetails-module--university-content__ranking--4bb5d";
export var universityContent__subTitleText = "UniversityDetails-module--university-content__sub-title-text--77fe6";
export var universityContent__title = "UniversityDetails-module--university-content__title--9148c";
export var universityCourse__details = "UniversityDetails-module--university-course__details--d40dd";
export var universityCourse__title = "UniversityDetails-module--university-course__title--b4831";
export var universityHeader = "UniversityDetails-module--university-header--3a30d";
export var universityHeader__content = "UniversityDetails-module--university-header__content--f4d90";
export var universityHeader__details = "UniversityDetails-module--university-header__details--a19a2";
export var universityHeader__location = "UniversityDetails-module--university-header__location--e5a40";
export var universityHeader__logo = "UniversityDetails-module--university-header__logo--bb501";
export var universityHeader__name = "UniversityDetails-module--university-header__name--aa6a4";
export var universityHeader__type = "UniversityDetails-module--university-header__type--f0542";
export var universityHeader__typeyearwrapper = "UniversityDetails-module--universityHeader__typeyearwrapper--b315d";
export var universityHeader__year = "UniversityDetails-module--university-header__year--1ec1b";
export var universityRank = "UniversityDetails-module--university-rank--c8f31";
export var universityRank__logo = "UniversityDetails-module--university-rank__logo--f8aa5";
export var universityRank__rankingType = "UniversityDetails-module--university-rank__ranking-type--5a43b";
export var universityRank__rankingValue = "UniversityDetails-module--university-rank__ranking-value--ca081";
export var viewDetails__icon = "UniversityDetails-module--view-details__icon--3a02c";
export var visitLink__icon = "UniversityDetails-module--visit-link__icon--9c909";