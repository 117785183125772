import { useStaticQuery, graphql } from 'gatsby';

export const useCaseStudyHeadingData = () => {
    const { allStrapiCaseStudyHeading } = useStaticQuery(
        graphql`
            query caseStudyDataHeading {
                allStrapiCaseStudyHeading {
                    nodes {
                        heading
                        subheading
                        page_type
                    }
                }
            }
        `
    );
    return allStrapiCaseStudyHeading.nodes;
};
