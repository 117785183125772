import React from 'react';
import styled from 'styled-components';
import SEO from '../components/seo';
import StudyAbroadHeroMarketieng from '../components/marketingComponent/study-abroad-hero-marketieng';
import Gooduniversity from '../components/studyAbroadPages/goodUniversity';
import HowWeDo from '../components/studyAbroadPages/howWeDo';
import ProgramForIndividual from '../components/studyAbroadPages/programForIndividual';
import NeedMoreClarity from '../components/studyAbroadPages/needMoreClarity';
import ProgramResult from '../components/MasterInUSAndCanadaPages/result';
import PrivacyPolicyLink from '../components/marketingComponent/privacy-policy-link';

const StudyAbroadMarketingPage = () => {
    return (
        <>
            <SEO
                title="Expert Guidance for Your Educational Journey | Abroad Ninja" 
                description="Avail of comprehensive study abroad consultation services with Abroad Ninja. Our experts will guide you through the entire process, from course selection to university applications. Begin your path to academic success today!"
                og_type="website"
                og_url="https://abroadninja.in/StudyAbroadconsultation/"
                og_title="Expert Guidance for Your Educational Journey | Abroad Ninja"
                og_description="Avail of comprehensive study abroad consultation services with Abroad Ninja. Our experts will guide you through the entire process, from course selection to university applications. Begin your path to academic success today!"
                og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
                twitter_card="summary_large_image"
                twitter_url="https://abroadninja.in/StudyAbroadconsultation/"
                twitter_title="Expert Guidance for Your Educational Journey | Abroad Ninja"
                twitter_description="Avail of comprehensive study abroad consultation services with Abroad Ninja. Our experts will guide you through the entire process, from course selection to university applications. Begin your path to academic success today!"
                twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
                robots="index, follow"
                canonical="https://abroadninja.in/StudyAbroadconsultation/"
            />
            <StudyAbroadPageContainer>
                <StudyAbroadHeroMarketieng />
                <ProgramResult
                    isMarketing={true}
                    slug={['bachelors-in-us-canada']}
                />
                <HowWeDo isMarketing={true} />
                <ProgramForIndividual isMarketing={true} />
                <Gooduniversity isMarketing={true} />
                <NeedMoreClarity isMarketing={true} />
                {/* <DoYourOwnResearch /> */}
                <PrivacyPolicyLink />
            </StudyAbroadPageContainer>
        </>
    );
};
const StudyAbroadPageContainer = styled.div`
    box-sizing: border-box;
    background-image: url('https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/09472a56ed99461fbedcf8561423a705.svg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100%;
    @media (max-width: 600px) {
        /* background-position: right 7rem; */
        background-image: url('https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/096db779ef6644a183f4d62a481f36c7.svg');
    }
`;
export default StudyAbroadMarketingPage;
