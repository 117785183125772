import { useStaticQuery, graphql } from 'gatsby';

export const useStudyMarketing = () => {
    const { allStrapiMarketingStudyCanada } = useStaticQuery(
        graphql`
            query studyMarketing {
                allStrapiMarketingStudyCanada {
                    nodes {
                        brand_color
                        button_color
                        formheading
                        whyHeading
                        brand_logo {
                            url
                            name
                        }
                        slug
                        marketing_form {
                            ... on STRAPI__COMPONENT_MARKETING_INPUT_MARKETING_INPUT {
                                id
                                name
                                place_holder
                                label
                                mandatory
                                type
                                strapi_component
                            }
                            ... on STRAPI__COMPONENT_MARKETING_SELECT_MARKETING_SELECT {
                                id
                                strapi_component
                                name
                                label
                                mandatory
                                option {
                                    option
                                    id
                                }
                                type
                            }
                        }
                        section_once {
                            heading
                            subheading_1
                            subheading_2
                            heading_img {
                                url
                                name
                            }
                            math_img {
                                url
                                name
                            }
                            mobile_heading_img {
                                url
                                name
                            }
                        }
                        section_two {
                            heading
                            heading_font_color
                            subheading_1
                            subheading_1_font_color
                            subheading_2
                            subheading_2_font_color
                            redirect_to
                            button_name
                            background_color
                            image {
                                url
                                name
                            }
                        }
                        section_three {
                            heading
                            info
                            button_name
                            redirect_to
                            card {
                                card_img {
                                    url
                                    name
                                }
                                description
                            }
                        }
                        section_four {
                            heading
                            subheading
                            background_color
                            button_name
                            redirect_to
                            success_img {
                                img {
                                    url
                                    name
                                }
                            }
                        }
                        why {
                            description
                            image {
                                url
                                name
                            }
                        }
                        marketing_footer {
                            heading
                            content
                        }
                    }
                }
            }
        `
    );
    return allStrapiMarketingStudyCanada.nodes;
};
