import { useStaticQuery, graphql } from 'gatsby';

export const useCaseStudyData = () => {
    const { allStrapiCaseStudie } = useStaticQuery(
        graphql`
            query caseStudyData {
                allStrapiCaseStudie {
                    nodes {
                        id
                        designation
                        name
                        page_type
                        case_studie_info
                        show_on_all_page

                        video {
                            ... on STRAPI__COMPONENT_UPLOAD_VIDEO_UPLOAD_VIDEO {
                                id
                                strapi_id
                                strapi_component
                                upload_video {
                                    url
                                    name
                                }
                            }
                            ... on STRAPI__COMPONENT_VIDEO_LINK_VIDEO_LINK {
                                id
                                strapi_id
                                video_link
                                strapi_component
                                video_name
                            }
                        }
                        thumbnail {
                            url
                            size
                        }
                    }
                }
            }
        `
    );

    return allStrapiCaseStudie.nodes;
};
