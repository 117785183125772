import React, { useState } from "react";
import styled from "styled-components";
import { graphql, useStaticQuery, Link } from "gatsby";
import { TypewriterHeader } from "../../utils";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "../commonComponent/select";
import { InputItem } from "../commonComponent/input";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { navigate } from "gatsby";
import { NotificationContainer, NotificationManager } from "react-notifications";

const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const schema = yup
	.object({
		name: yup.string().label("Please enter valid Name").trim().required().min(3).max(64),
		email: yup.string().email().required("Please enter valid Email"),
		phone: yup.string().matches(phoneRegExp, "Phone number is not valid").required(),
	})
	.required();
const PrHeroMarketing = () => {
	const isBrowser = typeof window !== "undefined";
	const data = useStaticQuery(query);

	const [isSubmitted, setIsSubmitted] = useState(false);
	const {
		control,
		setValue,
		clearErrors,
		register,
		handleSubmit,
		watch,
		resetField,
		formState: { errors },
	} = useForm({ resolver: yupResolver(schema) });
	const {
		allStrapiSettleHero: { nodes: AbroadHero },
	} = data;

	const { heading, subheading, button_name, pop_up_form, redirect_to } = AbroadHero[0];
	const newHeader = TypewriterHeader(heading);
	let redirect_to_page = "/";
	let tempUrl = {};
	if (isBrowser) {
		let url_string = window.location.href;
		let url = new URL(url_string);
		for (const [key, value] of url.searchParams.entries()) {
			tempUrl[key] = value;
		}

		redirect_to_page = url.searchParams.get("redirect_to") == "program" ? `/program` : "/program-result";
	}
	const onSubmit = async (data) => {
		setIsSubmitted(true);

		if (typeof window !== "undefined") {
			// let formData = {
			// 	source: "abroad-ninja",
			// 	brandid: 17,
			// 	segment: 1880,
			// 	...data,
			// };
			let formData = { ...tempUrl, ...data };

			await fetch(`https://api.ufaber.com/api/leads-submit/fetch-lead/`, {
				method: "post",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
				body: new URLSearchParams(formData),
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.success === true) {
						NotificationManager.success("Form Submitted Successfully", "success!!");
						setTimeout(() => {
							setIsSubmitted(false);
							// redirect_to_page !== "" ? navigate(redirect_to_page) : window.location.reload();
							navigate(redirect_to_page);
						}, 3000);
					}
					if (data.error === true) {
						setIsSubmitted(false);
						NotificationManager.error("While Submitting error Occurs", "error!!");
					}
				});
		}
	};

	return (
		<HeroMainContainer>
			<div className="logo">
				<div id="abroad_ninja_logo_wrapper">
					<img
						src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/cb99b4f7fb1e115fcd900d72e2fe1d9b.svg"
						alt=""
						class="mobile_view_logo"
					/>
					<img
						src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/f9212a8993b3ee44fa979d321d52f7e9.svg"
						alt=""
						class="web_view_logo"
					/>
				</div>
			</div>
			<HeroContainer>
				<h1
					key={`heading`}
					dangerouslySetInnerHTML={{ __html: "India's best <span>Canada PR  </span>program" }}
				/>
				<p
					id="fold_form"
					key={`subheading`}
					className="hero_section_subheading"
					dangerouslySetInnerHTML={{ __html: subheading }}
				/>

				<div>
					<FormWrapper onSubmit={handleSubmit(onSubmit)}>
						<label>Phone Number</label>
						<div className="phone_container">
							<InputItem
								type="text"
								className="input_box phone_code"
								padding={"1rem"}
								border={"none"}
								margin={"0px 0px 0.5rem 0px"}
								backgroundColor={"#F4F4F4"}
								value={"+91"}
								readOnly
							/>
							<InputItem
								type="number"
								className="input_box phone_number"
								padding={"1rem"}
								border={"none"}
								margin={"0px 0px 0.5rem 0px"}
								backgroundColor={"#F4F4F4"}
								placeholder={"Phone Number"}
								error={Boolean(errors.phone)}
								{...register("phone")}
							/>
						</div>
						<Error className="error">{errors.phone?.message}</Error>
						<label> Email ID</label>
						<InputItem
							type="text"
							className="input_box"
							padding={"1rem"}
							border={"none"}
							margin={"0px 0px 0.5rem 0px"}
							backgroundColor={"#F4F4F4"}
							placeholder={"Email"}
							error={Boolean(errors.name)}
							{...register("email")}
						/>
						<Error className="error">{errors.email?.message}</Error>
						<label>Name</label>
						<InputItem
							type="text"
							className="input_box"
							padding={"1rem"}
							border={"none"}
							margin={"0px 0px 0.5rem 0px"}
							backgroundColor={"#F4F4F4"}
							placeholder={"Name"}
							error={Boolean(errors.name)}
							{...register("name")}
						/>
						<Error className="error">{errors.name?.message}</Error>

						{/* <label htmlFor="">Whats your current decision level</label>
						<Select
							options={decisionLevel}
							control={control}
							name="decisionLevel"
							error={Boolean(errors.decisionLevel)}
							setValue={setValue}
							clearErrors={clearErrors}
						/>
						<Error>{errors.decisionLevel?.message}</Error> */}
						{isSubmitted ? (
							<FormSubmitButton type="button" value="Submitting..." />
						) : (
							<div className="submit-container">
								<FormSubmitButton
									type="submit"
									value={`${isSubmitted ? "Submitting... " : "Get Free Consultation"}`}
								/>
							</div>
						)}
					</FormWrapper>
				</div>
				<NotificationContainer />
			</HeroContainer>
		</HeroMainContainer>
	);
};

const HeroMainContainer = styled.section`
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	.mobile_view_logo {
		display: none;
	}
	.logo {
		padding: 2rem 2rem;
	}
	@media (max-width: 690px) {
		.logo {
			display: flex;
			justify-content: center;
		}
	}
`;
const HeroContainer = styled.div`
	padding: 1em 1em 5em 1em;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	& h1 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		color: #396eb0;
		margin-bottom: 0.3em;
		max-width: 10em;
	}
	& h1 span {
		color: var(--dark-blue);
	}
	.hero_section_subheading {
		margin-bottom: 2em;
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1.5rem;
		line-height: 24px;
		text-align: center;
		color: #396eb0;
		max-width: 26em;
	}
	#get_started {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 1.25rem;
		line-height: 24px;
		color: #ffffff;
		border-radius: 0.75em;
		background: var(--dark-blue);
		padding: 1.25em 2.5em;
		border: none;
		outline: none;
		cursor: pointer;
	}
	.phone_container {
		display: flex;
	}
	.phone_code {
		width: 50px;
		border-radius: 0.25em 0 0 0.25em;
		&:focus {
			box-shadow: none;
		}
	}

	.phone_number {
		&:focus {
			box-shadow: none;
		}
	}

	@media (max-width: 500px) {
		.phone_code {
			width: 22%;
		}
		& h1 {
			font-weight: 900;
			font-size: 1.75rem;
			line-height: 34px;
			margin-bottom: 0.5em;
		}
		.hero_section_subheading {
			font-size: 1rem;
			margin-bottom: 2em;
		}
	}
`;

const Error = styled.div`
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 0.9rem;
	line-height: 16px;
	color: #d64545;
	margin: ${(props) => props.margin || "0.3rem 0rem 0.8rem 0"};
	display: block;
	opacity: 0.9;
`;
const FormWrapper = styled.form`
	background: #ffffff;
	box-shadow: 0px 6.67478px 33.3739px rgba(17, 58, 104, 0.18);
	border-radius: 16px;
	padding: 2rem;
	max-width: 34.5rem;
	min-width: 34.5rem;

	.required::after {
		content: "*";
		color: #dd1e1e;
	}

	form {
		display: flex;
		flex-direction: column;
	}
	label {
		font-family: "Inter";
		font-style: normal;
		font-weight: 500;
		font-size: 1rem;
		line-height: 19px;
		color: #396eb0;
		margin: 1rem 0rem 0.8rem 0;
		display: block;
	}
	@media (max-width: 500px) {
		box-shadow: none;
		border-radius: 12px;
		padding: 1.5rem !important;
		box-shadow: 0px 4px 20px rgba(17, 58, 104, 0.18);
	}

	@media (max-width: 690px) {
		padding: 1.5rem;
		min-width: 90vw;
		box-shadow: 0px 4px 20px rgba(17, 58, 104, 0.18);
	}
`;
const FormSubmitButton = styled.input`
	-webkit-appearance: button;
	background: #113a68;
	border-radius: 8px;
	font-weight: 700;
	font-size: 1rem;
	line-height: 19px;
	text-align: center;
	color: #ffffff;
	border: none;
	outline: none;
	padding: 1rem;
	cursor: pointer;
	width: 100%;
	margin-top: 1rem;
`;
export const query = graphql`
	{
		allStrapiSettleHero {
			nodes {
				heading
				subheading
				button_name
				pop_up_form
				redirect_to
			}
		}
	}
`;
export default PrHeroMarketing;
