import React from 'react';
import styled from 'styled-components';
import SEO from '../components/seo';
import StudyAbroadHeroMarketieng from '../components/marketingComponent/study-abroad-hero-marketieng';
import Gooduniversity from '../components/studyAbroadPages/goodUniversity';
import HowWeDo from '../components/studyAbroadPages/howWeDo';
import ProgramForIndividual from '../components/studyAbroadPages/programForIndividual';
import NeedMoreClarity from '../components/studyAbroadPages/needMoreClarity';
import ProgramResult from '../components/MasterInUSAndCanadaPages/result';
import PrivacyPolicyLink from '../components/marketingComponent/privacy-policy-link';
import StudyAbroadConsultancyHero from '../components/marketingComponent/studyAbroadConsultancyHero';
import IndustryDefyingPerformance from '../components/studyAbroadPages/industryDefyingPerformance';
import ExpertisAndReach from '../components/studyAbroadPages/expertisAndReach';
import MarketingFooter from '../components/marketingComponent/marketingFooter';
// import ProgramForIndividual from '../components/studyAbroadPages/programForIndividual';
function abroadeducationconsultancy() {
    return (
        <>
            <StudyAbroadPageContainer>
                <StudyAbroadConsultancyHero
                    heading={`India's Leading Study Abroad Consultant For UK, USA, Canada, Australia`}
                    subheading={`Get Admission In Top Ranked Universities With Scholarships Over US$ 10000`}
                />
                <Gooduniversity isMarketing={true} />
                <IndustryDefyingPerformance />
                <HowWeDo isMarketing={true} />
                <ExpertisAndReach />
                <ProgramForIndividual isMarketing={true} />

                {/* <ProgramResult
                    isMarketing={true}
                    slug={['bachelors-in-us-canada']}
                /> */}
                <MarketingFooter />

                {/* <NeedMoreClarity isMarketing={true} /> */}
                {/* <PrivacyPolicyLink /> */}
            </StudyAbroadPageContainer>
        </>
    );
}
export const Head = () => {
    return (
        <SEO
            title="Study In UK | Study In UK Consultants | UK Education Consultants India"
            description="Fulfil Your Study In UK Dreams With Abroad Ninja, India's Leading Study In UK Consultants Trusted By Over 2 Lakh Students. Affiliation with Top UK Universities and 98% Success Rate."
            keywords="Study in uk,Study in uk consultants,Uk study consultants,Top universities in uk,Uk university admissions,Scholarship in UK"
        />
    );
};
const StudyAbroadPageContainer = styled.div`
    box-sizing: border-box;
    background-image: url('https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/09472a56ed99461fbedcf8561423a705.svg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100%;
    @media (max-width: 600px) {
        /* background-position: right 7rem; */
        background-image: url('https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/096db779ef6644a183f4d62a481f36c7.svg');
    }
`;
export default abroadeducationconsultancy;
