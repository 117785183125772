import Header from '../../header';
import './CourseDetail.css';
import { useWindowDimensions } from '../../../utils/index';
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import courseIcon from '../../../assets/icons/course.svg';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const CourseDetail = (props) => {
    const { course } = props;

    // const {
    //     info,
    //     highlights,
    //     tuitionFee,
    //     course.admissionFee,
    //     documents,
    //     test,
    //     courseID,
    // } = course;

    const [showWidth, setShowWidth] = useState(0);
    let dimension = useWindowDimensions();
    useEffect(() => {
        const { width, height } = dimension;
        setShowWidth(width);
    }, [dimension]);

    return Object.keys(course).length ? (
        <>
            {showWidth > 1124 ? <Header /> : <></>}
            <div className="IndividualCoursePageWrapper">
                <Link
                    to="/study-abroad/university-search"
                    className={'mobileView__backbutton'}
                >
                    <FontAwesomeIcon
                        icon={faChevronLeft}
                        className={'btnBack__icon'}
                    />
                </Link>
                <div className="courseDetails__mainWrapper">
                    <div className="courseDetails__TopSection">
                        <div className="topSection__LeftSection">
                            <div className="universityLogoWrapper">
                                <img src={courseIcon} alt="" />
                            </div>
                            <div className="courseNameUniDept__Wrapper">
                                <p className="CourseNameText">
                                    {course.info.courseName}
                                </p>

                                <div class="mobileView-UniversityLogo-NameWrapper">
                                    <div class="universityLogoWrapper-mobileView">
                                        <img
                                            src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/82deb2e255559a4610a5373a4fa85527.png"
                                            alt=""
                                        />
                                    </div>
                                    <p class="CourseByUniversityText">
                                        {course.info.universityName}
                                    </p>
                                </div>
                                <p className="CourseDepartmentText">
                                    Department of {course.info.department}
                                </p>
                            </div>
                            <div className="courseNameUniDept__WrapperMobileView">
                                <p className="CourseNameText">Master Program</p>
                                <p className="CourseDepartmentText">
                                    Department of {course.info.department}
                                </p>
                                <div className="mobileView-UniversityLogo-NameWrapper">
                                    <div className="universityLogoWrapper-mobileView">
                                        <img
                                            src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/82deb2e255559a4610a5373a4fa85527.png"
                                            alt=""
                                        />
                                    </div>
                                    <p className="CourseByUniversityText">
                                        {course.info.universityName}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="topSection__BtnWrapper">
                            {/* <button className="btn-shortlist">
                                <svg
                                    width={16}
                                    height={16}
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M7.7661 1.53467C7.84751 1.31909 8.15246 1.31909 8.23386 1.53467L9.72147 5.47414C9.89962 5.94593 10.3438 6.26445 10.8478 6.28182L15.0872 6.42783C15.3137 6.43564 15.4138 6.71664 15.2432 6.86586L12.3314 9.41283C11.9975 9.70487 11.8435 10.152 11.9265 10.5877L12.7377 14.844C12.7791 15.0614 12.5385 15.2215 12.354 15.0992L8.69057 12.6709C8.27195 12.3934 7.72792 12.3934 7.30931 12.6709L3.64598 15.0992C3.46151 15.2215 3.22084 15.0614 3.26227 14.844L4.07339 10.5877C4.15642 10.152 4.00233 9.70487 3.66848 9.41284L0.756783 6.86585C0.586198 6.71664 0.686272 6.43564 0.912776 6.42783L5.15204 6.28182C5.65604 6.26445 6.10025 5.94594 6.27841 5.47416L7.7661 1.53467Z"
                                        stroke="#113A68"
                                    />
                                </svg>
                                Shortlist
                            </button> */}
                            <Link
                                to={
                                    '/abroad-form/?formName=study-abroad&source=abroad-ninja&utm_source=source&utm_campaign=cap&utm_medium=medium&language=lang&brandid=17&do_what=-&segment=1880'
                                }
                                className="btn-askOurExperts"
                            >
                                <svg
                                    width={24}
                                    height={24}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M19.8844 11.3848H17.4229C17.0965 11.3848 16.7834 11.5144 16.5526 11.7452C16.3218 11.976 16.1921 12.2891 16.1921 12.6155V15.6924C16.1921 16.0188 16.3218 16.3318 16.5526 16.5627C16.7834 16.7935 17.0965 16.9231 17.4229 16.9231H18.6536C18.98 16.9231 19.2931 16.7935 19.5239 16.5627C19.7547 16.3318 19.8844 16.0188 19.8844 15.6924V11.3848ZM19.8844 11.3848C19.8844 10.4098 19.6914 9.44451 19.3164 8.54454C18.9415 7.64458 18.392 6.82777 17.6998 6.14125C17.0076 5.45472 16.1862 4.91207 15.2832 4.54461C14.3801 4.17714 13.4133 3.99213 12.4383 4.00026C11.4641 3.99315 10.498 4.17891 9.59588 4.54684C8.69373 4.91476 7.8733 5.45757 7.18187 6.144C6.49044 6.83042 5.94169 7.64688 5.56722 8.54634C5.19275 9.44579 4.99997 10.4105 5 11.3848V15.6924C5 16.0188 5.12967 16.3318 5.36048 16.5627C5.59129 16.7935 5.90433 16.9231 6.23075 16.9231H7.4615C7.78791 16.9231 8.10096 16.7935 8.33177 16.5627C8.56258 16.3318 8.69225 16.0188 8.69225 15.6924V12.6155C8.69225 12.2891 8.56258 11.976 8.33177 11.7452C8.10096 11.5144 7.78791 11.3848 7.4615 11.3848H5"
                                        stroke="white"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M19.8845 15.6914V17.5375C19.8845 18.1904 19.6252 18.8165 19.1635 19.2781C18.7019 19.7397 18.0758 19.999 17.423 19.999H13"
                                        stroke="white"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                                Ask our experts
                            </Link>
                        </div>
                    </div>
                    <div className="courseDetails__BottomSection">
                        <div className="bottomSectionDetailCards">
                            <small className="cardTitleText">Admissions</small>
                            <div className="cardContentWrapper">
                                <div className="admissionDetailsWrapper">
                                    <div className="ApplicationFeeDiv">
                                        <span>Application Fee</span>
                                        <small>
                                            {course.admissionFee
                                                .applicationFee || 'NA'}
                                        </small>
                                    </div>
                                    <div className="finalDeadlineDiv">
                                        <span>Final Deadline</span>
                                        <small>
                                            {' '}
                                            {course.admissionFee.feeDeadline ||
                                                'NA'}
                                        </small>
                                    </div>
                                </div>
                                <Link
                                    to={course.admissionFee.applicationLink}
                                    className="btn-ApplicationLink"
                                >
                                    Application Link
                                    <svg
                                        width={14}
                                        height={15}
                                        viewBox="0 0 14 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M8 6.5L13 1.5M9.66667 1.5H13V4.83333M13 8.83333V12.1667C13 12.5203 12.8595 12.8594 12.6095 13.1095C12.3594 13.3595 12.0203 13.5 11.6667 13.5H2.33333C1.97971 13.5 1.64057 13.3595 1.39052 13.1095C1.14048 12.8594 1 12.5203 1 12.1667V2.83333C1 2.47971 1.14048 2.14057 1.39052 1.89052C1.64057 1.64048 1.97971 1.5 2.33333 1.5H5.66667"
                                            stroke="#FC997C"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                </Link>
                            </div>
                        </div>
                        <div className="bottomSectionDetailCards">
                            <small className="cardTitleText">
                                Simiar Courses
                            </small>
                            <div className="cardContentWrapper">
                                <div className="similarCoursesDetailsWrapper">
                                    <div className="similarCoursesName_Uni">
                                        <span>MPhil in Economics</span>
                                        <small>Cambridge University</small>
                                    </div>
                                    <a href="" className="btn-details">
                                        Details
                                        <svg
                                            width={6}
                                            height={10}
                                            viewBox="0 0 6 10"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M1 1L5 5L1 9"
                                                stroke="#FC997C"
                                                strokeWidth="1.4"
                                                strokeLinecap="round"
                                            />
                                        </svg>
                                    </a>
                                </div>
                                <div className="similarCoursesDetailsWrapper">
                                    <div className="similarCoursesName_Uni">
                                        <span>MS in Social data Science</span>
                                        <small>MIT University</small>
                                    </div>
                                    <a href="" className="btn-details">
                                        Details
                                        <svg
                                            width={6}
                                            height={10}
                                            viewBox="0 0 6 10"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M1 1L5 5L1 9"
                                                stroke="#FC997C"
                                                strokeWidth="1.4"
                                                strokeLinecap="round"
                                            />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="bottomSectionDetailCards">
                            <small className="cardTitleText">
                                Program Highlights
                            </small>
                            <div className="cardContentWrapper">
                                <div className="programHighlightsPointsWrapper">
                                    <div className="individualProgramHighlights">
                                        <span>Program Duration</span>
                                        <small>
                                            {course.highlights.duration || 'NA'}
                                        </small>
                                    </div>
                                    <div className="individualProgramHighlights">
                                        <span>Program Type</span>
                                        <small>
                                            {' '}
                                            {course.highlights.programType ||
                                                'NA'}
                                        </small>
                                    </div>
                                    <div className="individualProgramHighlights">
                                        <span>Batch Type</span>
                                        <small>
                                            {' '}
                                            {course.highlights.batchType ||
                                                'NA'}
                                        </small>
                                    </div>
                                    <div className="individualProgramHighlights">
                                        <span>No of Semesters</span>
                                        <small>
                                            {course.highlights.totalSemesters ||
                                                'NA'}
                                        </small>
                                    </div>
                                    <div className="individualProgramHighlights">
                                        <span>Program Credits</span>
                                        <small>
                                            {' '}
                                            {course.highlights.programCredits ||
                                                'NA'}
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bottomSectionDetailCards">
                            <small className="cardTitleText">Tuition Fee</small>
                            <div className="cardContentWrapper">
                                <div className="feesMayDifferWrapper">
                                    <svg
                                        width={16}
                                        height={16}
                                        viewBox="0 0 16 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M8 15.5C6.14348 15.5 4.36301 14.7625 3.05025 13.4497C1.7375 12.137 1 10.3565 1 8.5C1 6.64348 1.7375 4.86301 3.05025 3.55025C4.36301 2.2375 6.14348 1.5 8 1.5C9.85652 1.5 11.637 2.2375 12.9497 3.55025C14.2625 4.86301 15 6.64348 15 8.5C15 10.3565 14.2625 12.137 12.9497 13.4497C11.637 14.7625 9.85652 15.5 8 15.5ZM8 16.5C10.1217 16.5 12.1566 15.6571 13.6569 14.1569C15.1571 12.6566 16 10.6217 16 8.5C16 6.37827 15.1571 4.34344 13.6569 2.84315C12.1566 1.34285 10.1217 0.5 8 0.5C5.87827 0.5 3.84344 1.34285 2.34315 2.84315C0.842855 4.34344 0 6.37827 0 8.5C0 10.6217 0.842855 12.6566 2.34315 14.1569C3.84344 15.6571 5.87827 16.5 8 16.5V16.5Z"
                                            fill="#FC997C"
                                        />
                                        <path
                                            d="M8.92995 7.088L6.63995 7.375L6.55795 7.755L7.00795 7.838C7.30195 7.908 7.35995 8.014 7.29595 8.307L6.55795 11.775C6.36395 12.672 6.66295 13.094 7.36595 13.094C7.91095 13.094 8.54395 12.842 8.83095 12.496L8.91895 12.08C8.71895 12.256 8.42695 12.326 8.23295 12.326C7.95795 12.326 7.85795 12.133 7.92895 11.793L8.92995 7.088ZM8.99995 5C8.99995 5.26522 8.89459 5.51957 8.70706 5.70711C8.51952 5.89464 8.26517 6 7.99995 6C7.73474 6 7.48038 5.89464 7.29285 5.70711C7.10531 5.51957 6.99995 5.26522 6.99995 5C6.99995 4.73478 7.10531 4.48043 7.29285 4.29289C7.48038 4.10536 7.73474 4 7.99995 4C8.26517 4 8.51952 4.10536 8.70706 4.29289C8.89459 4.48043 8.99995 4.73478 8.99995 5Z"
                                            fill="#FC997C"
                                        />
                                    </svg>
                                    <p>
                                        Fees mentioned may differ from the
                                        actual amount.
                                    </p>
                                </div>
                                <div className="AnnualFullTutionFeeWrapper">
                                    <div className="annualTutionFee">
                                        <span>Annual Tuition Fee</span>
                                        <small>
                                            {course.tuitionFee
                                                .annualTuitionFee || 'NA'}
                                        </small>
                                    </div>
                                    {/* <div className="FullTutionFee">
                                        <span>Full Tuition Fee</span>
                                        <small>£8274 (₹9,44,000)</small>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="bottomSectionDetailCards">
                            <small className="cardTitleText">
                                Pre-requisites
                            </small>
                            <div className="cardContentWrapper">
                                <ul className="prerequisitesPointsList">
                                    {course.documents?.requiredDocs?.map(
                                        (doc, index) => {
                                            return (
                                                <li key={`${doc}-${index}`}>
                                                    {doc}
                                                </li>
                                            );
                                        }
                                    )}
                                    {course.documents?.additionalDocs?.map(
                                        (doc, index) => {
                                            return (
                                                <li key={`${doc}-${index}`}>
                                                    {doc}
                                                </li>
                                            );
                                        }
                                    )}
                                </ul>
                                <div className="testScoreReqWrapper">
                                    <small className="testScoreReqHeading">
                                        Test Score Requirements
                                    </small>
                                    <div className="TestScoreExamWrapper">
                                        {course.test.map((item, index) => {
                                            return (
                                                <div
                                                    className="TestScoreIndividualExam"
                                                    key={`${item.testName}-${index}`}
                                                >
                                                    <span>{item.testName}</span>
                                                    <small>
                                                        {item.testScore}
                                                    </small>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    ) : (
        <>Loading ...</>
    );
};

export default CourseDetail;
