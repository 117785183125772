import React from "react";
import Layout from "../components/layout.component";
import SettleAbroadPage from "../components/SettleAbroadPages/index";
import SEO from "../components/seo";
const StudyAbroad = () => {
	return (
		<Layout>
			<SettleAbroadPage />
		</Layout>
	);
};
export function Head () {
	return (
		<SEO 
			title="Best PR Work & Settle Abroad | Work Visas to Settle Abroad" 
			description="Working abroad is now just a click away. Here’s the best PR program to help you find jobs in abroad to get work visas for Canada, USA, UK, Australia. Click now to settle abroad."
			og_type="website"
			og_url="https://abroadninja.in/settle-abroad/"
			og_title="Best PR Work & Settle Abroad | Work Visas to Settle Abroad"
			og_description="Working abroad is now just a click away. Here’s the best PR program to help you find jobs in abroad to get work visas for Canada, USA, UK, Australia. Click now to settle abroad."
			og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
			twitter_card="summary_large_image"
			twitter_url="https://abroadninja.in/settle-abroad/"
			twitter_title="Best PR Work & Settle Abroad | Work Visas to Settle Abroad"
			twitter_description="Working abroad is now just a click away. Here’s the best PR program to help you find jobs in abroad to get work visas for Canada, USA, UK, Australia. Click now to settle abroad."
			twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
			robots="index, follow"
			canonical="https://abroadninja.in/settle-abroad/"
		
		children={

			<script type="application/ld+json">
			{`
				{
					"@context": "https://schema.org/",
					"@type": "WebSite",
					"name": "AbroadNinja",
					"url": "https://abroadninja.in/",
					"potentialAction": 
					{
						"@type": "SearchAction",
						"target": "https://abroadninja.in/{search_term_string}",
						"query-input": "required name=search_term_string"
					}
				}
			`}
			</script>
		}/>
	);
}
export default StudyAbroad;
