import { Link } from 'gatsby';
import React from 'react';
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInr } from '@fortawesome/free-solid-svg-icons';

const ScholarshipCard = ({ scholarship }) => {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    return (
        <>
            <div className="popularScholarship__individual-card">
                <div className='popularScholarship__card-title-wrapper'>
                    <p className="popularScholarship__card-title">
                        {scholarship?.scholarshipName}
                    </p>
                </div>
                <div className="popularScholarship__card-details">
                    <div className="popularScholarship__card-courselevel-amount">
                        <img
                            src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/832e451e7947d014bb85be850d421bd1.1669881508306111"
                            alt=""
                        />
                        <small className="card__text">Course Level</small>
                        <small className="card__text_2">
                            {scholarship?.eligibility?.educationLevel[0]}
                        </small>
                    </div>
                    <div className="popularScholarship__card-courselevel-amount">
                        <img
                            src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/3c5feb977da8ce89d5ebb9a84219ab02.svg"
                            alt=""
                        />
                        <small className="card__text">Amount</small>
                        <small className="card__text_2">
                            <FontAwesomeIcon icon={faInr} />{' '}
                            {scholarship?.amount.toLocaleString('en-In')}
                        </small>
                    </div>
                </div>
                {scholarship.isCollegeSpecific ? (
                    <Link
                        to={`/study-abroad-scholarship/view-details/?offered_by=${scholarship?.college?.replaceAll(
                            /\s/g,
                            ''
                        )}&query=${scholarship?.scholarshipName.replaceAll(/&/g, "%26")}`}
                        className="popularScholarship__card-apply-btn"
                        state={{ prevUrl: url }}
                    >
                        View & Apply
                    </Link>
                ) : (
                    <Link
                        to={`/study-abroad-scholarship/view-details/?offered_by=${scholarship?.scholarshipName.replaceAll(
                            /\s/g,
                            ''
                        )}&query=${scholarship?.scholarshipName.replaceAll(/&/g, "%26")}`}
                        className="popularScholarship__card-apply-btn"
                        state={{ prevUrl: url }}
                    >
                        View & Apply
                    </Link>
                )}
            </div>
        </>
    );
};

export default ScholarshipCard;
