import React from "react";
import styled from "styled-components";
import Flickity from "react-flickity-component";
import { graphql, Link, useStaticQuery } from "gatsby";
const FlashNews = () => {
	const flickityOptions = {
		cellAlign: "left",
		contain: true,
		pageDots: false,
		freeScroll: true,
		wrapAround: true,
		adaptiveHeight: true,
		draggable: true,
	};

	const data = useStaticQuery(query);
	const {
		allStrapiMastersFlashNew: { nodes: flashNews },
	} = data;

	return (
		<FlashNewsStyle>
			<div id="flash_news_section_wrapper">
				<FlashNewsSectionTopStyled>
					<div id="flash_news_wrapper">
						<small>
							Flash news
							<div id="news_count_circle">
								<span>{flashNews.length}</span>
							</div>
						</small>
					</div>
					<div id="view_more_wrapper">
						<Link to={"/flash-news"}>
							<small>View All</small>
						</Link>

						<i className="fa-solid fa-circle-chevron-right view_more_arrow" />
					</div>
				</FlashNewsSectionTopStyled>
				<FlashNewsCarouselWrapperStyled>
					{/* <div
						className="flash_news_new_carousel "
						id="flash_news_carousel"
					> */}

					<Flickity
						className={"flash_news_new_carousel"}
						elementType={"div"}
						options={flickityOptions}
						disableImagesLoaded={false}
						reloadOnUpdate
						static
					>
						{flashNews.map((news) => {
							return (
								<div className="carousel-cell">
									<IndividualFlashNewsWrapperStyled>
										<div className="individual_flash_news_image_wrapper">
											<img src={news?.news_img?.url} alt="" />
										</div>
										<div className="individual_flash_news_content">
											<small className="individual_flash_news_heading">{news.heading}</small>
											<p className="individual_flash_news_shortnews">{news.news_content}</p>
										</div>
									</IndividualFlashNewsWrapperStyled>
								</div>
							);
						})}
					</Flickity>
				</FlashNewsCarouselWrapperStyled>
			</div>
		</FlashNewsStyle>
	);
};
const FlashNewsStyle = styled.section`
	padding: 0 7.5rem;
	margin-bottom: 5rem;
	@media (max-width: 1100px) {
		padding: 0 3.5rem;
	}

	@media (max-width: 500px) {
		padding: 0 0.75rem;
	}
`;

const FlashNewsSectionTopStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 2rem;

	#flash_news_wrapper small {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 1.75rem;
		line-height: 34px;
		text-align: center;
		color: #113a68;
		position: relative;
	}
	& small {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 1rem;
		line-height: 19px;
		color: #396eb0;
		cursor: pointer;
	}
	#news_count_circle {
		min-width: 1.3125rem;
		max-width: 1.3125rem;
		min-height: 1.3125rem;
		max-height: 1.3125rem;
		border-radius: 50%;
		background: #fc997c;
		font-family: "Inter";
		font-style: normal;
		font-weight: 500;
		font-size: 0.75rem;
		line-height: 15px;
		color: #ffffff;
		display: grid;
		place-items: center;
		top: 0;
		right: -24px;
		position: absolute;
	}
	#view_more_wrapper small {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 1rem;
		line-height: 19px;
		color: #396eb0;
		cursor: pointer;
	}
`;
const FlashNewsCarouselWrapperStyled = styled.div`
	.flash_news_new_carousel {
		width: 100%;
	}
	.carousel-cell {
		height: 8rem;
		margin: 1rem !important;
	}
	.flickity-prev-next-button.previous {
		display: block;
		width: 56px;
		height: 56px;
		left: -25px;
		top: 47%;
		background: #fc997c;
		box-shadow: 0px 0px 25px rgba(252, 153, 124, 0.25);
		color: #ffffff;
		filter: drop-shadow(0px 2px 25px rgba(249, 151, 70, 0.5));
	}
	.flickity-prev-next-button.next {
		width: 56px;
		height: 56px;
		right: -25px;
		top: 47%;
		background: #fc997c;
		box-shadow: 0px 0px 25px rgba(252, 153, 124, 0.25);
		color: #ffffff;
		filter: drop-shadow(0px 2px 25px rgba(249, 151, 70, 0.5));
	}

	@media (max-width: 500px) {
		.flickity-prev-next-button.previous {
			display: block;
			width: 24px;
			height: 24px;
			left: -6px;
			top: 41%;
			background: #113a68;
			box-shadow: 0px 0px 25px rgba(252, 153, 124, 0.25);
			color: #ffffff;
			filter: drop-shadow(none);
		}
		.flickity-prev-next-button.next {
			width: 24px;
			height: 24px;
			right: -6px;
			top: 41%;
			background: #113a68;
			box-shadow: 0px 0px 25px rgba(252, 153, 124, 0.25);
			color: #ffffff;
			filter: drop-shadow(none);
		}
	}
`;

const IndividualFlashNewsWrapperStyled = styled.div`
	background: #ffffff;
	box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
	border-radius: 12px;
	padding: 0.25rem 0.75rem 0.25rem 0.25rem;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 0.75rem;
	min-width: 23.25rem;
	max-width: 23.25rem;
	height: 7rem;
	.individual_flash_news_content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		gap: 0.25rem;
	}
	.individual_flash_news_heading {
		font-family: "Inter";
		font-style: normal;
		font-weight: 600;
		font-size: 1.25rem;
		line-height: 24px;
		color: #113a68;
		width: 230px;
		white-space: nowrap;
		overflow: hidden !important;
		text-overflow: ellipsis;
	}
	.individual_flash_news_shortnews {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 0.875rem;
		line-height: 17px;
		color: #396eb0;
	}
	.individual_flash_news_image_wrapper {
		min-height: 6.5rem;
		max-width: 6.5rem;
		min-width: 6.5rem;
		max-height: 6.5rem;
		border-radius: 12px;
		overflow: hidden;
	}
	.individual_flash_news_image_wrapper img {
		object-fit: cover;
		min-width: 100%;
		max-width: 100%;
		min-height: 100%;
		max-height: 100%;
		border-radius: 12px;
		min-height: 6.5rem;
		max-width: 6.5rem;
		min-width: 6.5rem;
		max-height: 6.5rem;
	}

	@media (max-width: 500px) {
		height: 6rem;
		min-width: 100%;
		max-width: 100%;
		width: 100%;
		.individual_flash_news_wrapper {
			height: 6rem;
			min-width: 100%;
			max-width: 100%;
			width: 100%;
		}
		.individual_flash_news_heading {
			font-size: 1rem;
			line-height: 19px;
		}
		.individual_flash_news_shortnews {
			font-size: 0.75rem;
			line-height: 15px;
			max-width: 12rem;
		}
		.individual_flash_news_image_wrapper {
			min-height: 5.5rem;
			max-width: 5.5rem;
			min-width: 5.5rem;
			max-height: 5.5rem;
			border-radius: 5px;
			overflow: hidden;
		}
		.individual_flash_news_image_wrapper img {
			border-radius: 6px;
		}
	}

	@media (max-width: 335px) {
		.individual_flash_news_wrapper {
			height: fit-content;
		}
		.individual_flash_news_heading {
			font-size: 0.8rem;
		}
		.individual_flash_news_shortnews {
			font-size: 0.7rem;
			max-width: 100%;
		}
	}

	@media (max-width: 310px) {
		.individual_flash_news_shortnews {
			font-size: 0.5rem;
		}
	}
`;

export const query = graphql`
	{
		allStrapiMastersFlashNew {
			nodes {
				news_img {
					url
					name
				}
				heading
				news_content
				strapi_id
				type
				slug
			}
		}
	}
`;
export default FlashNews;
