import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout.component';
import SEO from '../components/seo';

const EndUserLicenseAgreement = () => {
    return (
        <Layout>
            <SEO
                title="End User License Agreement (EULA) | Abroad Ninja" 
                description="Review Abroad Ninja's End User License Agreement (EULA) to comprehend the terms of using our platform. This agreement outlines your rights, responsibilities, and the conditions for accessing our services."
                og_type="website"
                og_url="https://abroadninja.in/end-user-license-agreement/"
                og_title="End User License Agreement (EULA) | Abroad Ninja"
                og_description="Review Abroad Ninja's End User License Agreement (EULA) to comprehend the terms of using our platform. This agreement outlines your rights, responsibilities, and the conditions for accessing our services."
                og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
                twitter_card="summary_large_image"
                twitter_url="https://abroadninja.in/end-user-license-agreement/"
                twitter_title="End User License Agreement (EULA) | Abroad Ninja"
                twitter_description="Review Abroad Ninja's End User License Agreement (EULA) to comprehend the terms of using our platform. This agreement outlines your rights, responsibilities, and the conditions for accessing our services."
                twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
                robots="index, follow"
                canonical="https://abroadninja.in/end-user-license-agreement/"
            />
            <MainWrapper>
                    <p className="EULATitleText">
                        End-User License Agreement (EULA) for uFaber Edutech
                        Pvt. Ltd. Online Service
                    </p>
                    <p className="ImportantEULANotice">
                        IMPORTANT - READ CAREFULLY: This End-User License
                        Agreement ("EULA'') is a legal agreement between you
                        (either an individual or a single entity) and uFaber
                        Edutech Pvt. Ltd. ("uFaber") for the use of uFaber's
                        online service ("Service"). By using the Service, you
                        agree to be bound by the terms of this EULA.{' '}
                    </p>
                    <p className="TitleText">1. License Grant </p>
                    <p>
                        uFaber grants you a limited, non-exclusive,
                        non-transferable license to access and use the Service
                        solely for your personal, non-commercial use.{' '}
                    </p>
                    <p className="TitleText">2. Ownership </p>
                    <p>
                        The Service is owned and operated by uFaber and its
                        licensors, and is protected by copyright and other
                        intellectual property laws. uFaber and its licensors
                        retain all rights, title and interest in and to the
                        Service, including all copyrights, trademarks, and other
                        intellectual property rights.{' '}
                    </p>
                    <p className="TitleText">3. Restrictions </p>
                    <p>
                        You agree not to, and will not allow any third party to:{' '}
                    </p>
                    <ol className="EULAListTag" type="a">
                        <li>
                            copy, modify, or distribute the Service, except as
                            expressly permitted by uFaber;{' '}
                        </li>
                        <li>
                            reverse engineer, decompile, or disassemble the
                            Service, except to the extent that such activity is
                            expressly permitted by applicable law;{' '}
                        </li>
                        <li>
                            use the Service in any manner that violates any
                            applicable laws or regulations;
                        </li>
                        <li>
                            use the Service to infringe any intellectual
                            property or other proprietary rights of any third
                            party; or{' '}
                        </li>
                        <li>
                            use the Service for any illegal or unauthorized
                            purpose.{' '}
                        </li>
                    </ol>
                    <p className="TitleText">4. Privacy Policy</p>
                    <p>
                        Your use of the Service is subject to uFaber's privacy
                        policy, which is available on the Service and
                        incorporated by reference into this EULA.{' '}
                    </p>
                    <p className="TitleText">5. Disclaimer of Warranties</p>
                    <p>
                        The Service is provided "AS IS" and without warranty of
                        any kind. uFaber disclaims all warranties, whether
                        express or implied, including without limitation any
                        warranties of merchantability, fitness for a particular
                        purpose, or non-infringement.{' '}
                    </p>
                    <p className="TitleText">6. Limitation of Liability</p>
                    <p>
                        In no event shall uFaber be liable for any direct,
                        indirect, incidental, special, or consequential damages
                        arising out of or in connection with the use of or
                        inability to use the Service, even if uFaber has been
                        advised of the possibility of such damages. Some
                        jurisdictions do not allow the exclusion of certain
                        warranties or the limitation or exclusion of liability
                        for certain types of damages. Accordingly, some of the
                        above limitations and disclaimers may not apply to you.{' '}
                    </p>
                    <p className="TitleText">7. Termination </p>
                    <p>
                        This EULA will terminate automatically if you fail to
                        comply with any of the terms and conditions of this
                        EULA. Upon termination, you must immediately cease all
                        use of the Service and destroy all copies of the Service
                        in your possession.{' '}
                    </p>
                    <p className="TitleText">8. Governing Law</p>
                    <p>
                        This EULA will be governed by and constructed in
                        accordance with the laws of India, without giving effect
                        to any principles of conflicts of law.{' '}
                    </p>
                    <p className="TitleText">9. Entire Agreement </p>
                    <p>
                        This EULA constitutes the entire agreement between you
                        and uFaber with respect to the Service and supersedes
                        all prior or contemporaneous communications and
                        proposals, whether oral or written, between you and
                        uFaber.{' '}
                    </p>
                    <p className="TitleText">10. Changes to this EULA </p>
                    <p>
                        uFaber reserves the right to modify this EULA at any
                        time, and your continued use of the Service after any
                        such modifications shall be deemed to be your acceptance
                        of such modifications.{' '}
                    </p>
                    <p className="TitleText">11. Contact Information</p>
                    <p>
                        If you have any questions about this EULA or the
                        Service, please contact uFaber at{' '}
                        <a href="tel: +91 93219 43330">+91 93219 43330</a> or{' '}
                        <a href="mailto: contact@ufaber.com.">
                            contact@ufaber.com.
                        </a>{' '}
                    </p>
                    <p className="byClickingIAgreeButtonText">
                        By clicking the "I Agree" button, or by using the
                        Service, you agree to be bound by the terms and
                        conditions of this EULA. If you do not agree to the
                        terms and conditions of this EULA, do not use the
                        Service.
                    </p>
            </MainWrapper>
        </Layout>
    );
}
const MainWrapper = styled.main`
    padding: 56px 16px;
    width: 100%;
    max-width: 1156px;
    margin: 4rem auto;
    .EULATitleText {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        text-decoration-line: underline;
        color: #354b60;
        margin-bottom: 28px;
    }
    .ImportantEULANotice {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 44px;
    }
    .TitleText {
        margin-bottom: 10px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 38px;
        color: #354b60;
    }
    p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 12px;
    }
    p > a {
        display: inline-block;
        text-decoration: underline !important;
        color: #007bff;
    }
    .byClickingIAgreeButtonText {
        margin-top: 32px;
    }
    .EULAListTag {
        padding-left: 24px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 44px;
    }
`;
export default EndUserLicenseAgreement;
