import React, { useState, useEffect } from 'react';
import Layout from '../components/layout.component';
import styled from 'styled-components';
import SEO from '../components/seo';
import { InputItem } from '../components/commonComponent/input';
import { useForm, Controller } from 'react-hook-form';
import Select from '../components/commonComponent/select';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    eduction,
    workExperience,
    Reading,
    Writing,
    Listening,
    Speaking,
    offerLetterCanada,
    yearsExperience,
    canadaEducation,
} from '../constants/prEligibility/option';
import * as yup from 'yup';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import {
    prCalculation,
    englishSkillCLBCalculation,
    combinationCalculation,
} from '../constants/prEligibility/calculation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const schema = yup
    .object({
        // name: yup.string().max(40).required('Please enter valid Name'),
        // email: yup.string().email().required('Please enter valid Email'),
        // phone: yup
        //     .string()
        //     .matches(phoneRegExp, 'Phone number is not valid')
        //     .required(),
        age: yup
            .number()
            .typeError('Please Input your Age')
            .positive()
            .integer()
            .required(),
        marriageStatus: yup
            .string()
            .required('Please Select Your Marriage Status')
            .nullable(),

        isSpouseCommingCanada: yup
            .string()
            .when('marriageStatus', {
                is: (status) => status === 'Married',
                then: yup
                    .string()
                    .required('Please select the option')
                    .nullable(),
            })
            .nullable(),

        sopouseEduction: yup
            .string()
            .when('isSpouseCommingCanada', {
                is: (item) => item === 'Yes',
                then: yup
                    .string()
                    .required('Please select  Spouse education level')
                    .nullable(),
            })
            .nullable(),

        SpouseExperience: yup
            .string()
            .when('isSpouseCommingCanada', {
                is: (item) => item === 'Yes',
                then: yup
                    .string()
                    .required('Please select  Spouse work experience')
                    .nullable(),
            })
            .nullable(),
        highestEduction: yup
            .string()
            .required('Please Select Your Highest Eduction'),
        educationInCanada: yup
            .string()
            .required('Please Select Your education in Canada'),
        isSpouseOrSiblingsPrCanada: yup
            .string()
            .required('Please Select Your Option')
            .nullable(),
        canadianWorkExperience: yup
            .string()
            .required('Please Select Your canadian work experience'),
        isOfferFromCanada: yup
            .string()
            .required('Please Select the option')
            .nullable(),

        offerLetterCanada: yup
            .string()
            .when('isOfferFromCanada', {
                is: (item) => item === 'Yes',
                then: yup
                    .string()
                    .required('Please select the Option')
                    .nullable(),
            })
            .nullable(),
        yearsExperience: yup
            .string()
            .required('Please Select Your Year of Experience'),
        speaking: yup.string().required('Please Select Your Speaking Skill'),
        listening: yup.string().required('Please Select Your listening Skill'),
        writing: yup.string().required('Please Select Your writing Skill'),
        reading: yup.string().required('Please Select Your reading Skill'),

        spouse_speaking: yup
            .string()
            .when('isSpouseCommingCanada', {
                is: (item) => item === 'Yes',
                then: yup
                    .string()
                    .required('Please Select Your spouse Speaking Skill')
                    .nullable(),
            })
            .nullable(),
        spouse_listening: yup
            .string()
            .when('isSpouseCommingCanada', {
                is: (item) => item === 'Yes',
                then: yup
                    .string()
                    .required('Please Select Your spouse listening Skill')
                    .nullable(),
            })
            .nullable(),
        spouse_writing: yup
            .string()
            .when('isSpouseCommingCanada', {
                is: (item) => item === 'Yes',
                then: yup
                    .string()
                    .required('Please Select Your spouse writing Skill')
                    .nullable(),
            })
            .nullable(),
        spouse_reading: yup
            .string()
            .when('isSpouseCommingCanada', {
                is: (item) => item === 'Yes',
                then: yup
                    .string()
                    .required('Please Select Your spouse reading Skill')
                    .nullable(),
            })
            .nullable(),

        // spouse_
    })
    .required();
let renderCount = 0;

const isBrowser = typeof window !== 'undefined';
isBrowser && localStorage.removeItem('pr_score');

const PrEligibilityCalculator = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [prCalculator, setPrCalculator] = useState({});
    const [data, setData] = useState('');
    const {
        control,
        setValue,
        clearErrors,
        register,
        handleSubmit,
        watch,
        resetField,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema) });

    const onSubmit = async (data) => {
        // setIsSubmitted(true);

        let age = prCalculation['age'][data.marriageStatus][data.age] ?? 0;
        let education =
            prCalculation['eductionLevel'][data.marriageStatus][
                data.highestEduction
            ] ?? 0;
        let canadianWorkExperience =
            prCalculation['canadianWorkExperience'][data.marriageStatus][
                data.canadianWorkExperience
            ] ?? 0;
        let sopouseEduction =
            prCalculation['eductionLevel']['sopouseEduction'][
                data.sopouseEduction
            ] ?? 0;
        let SpouseExperience =
            prCalculation['canadianWorkExperience']['sopouseWorkExperience'][
                data.SpouseExperience
            ] ?? 0;
        let offerLetterCanada =
            prCalculation['offerLetterCanada'][data.offerLetterCanada] ?? 0;
        let isSpouseOrSiblingsPrCanada =
            data?.isSpouseOrSiblingsPrCanada === 'Yes' ? 15 : 0;

        let ieltsScore = englishSkillCLBCalculation(
            data.listening,
            data.reading,
            data.speaking,
            data.writing
        );

        let spouse_ieltsScore = englishSkillCLBCalculation(
            data.spouse_listening,
            data.spouse_reading,
            data.spouse_speaking,
            data.spouse_writing
        );

        let ieltsCBL =
            prCalculation['conversionOfCLB'][data.marriageStatus][ieltsScore] ??
            0;
        let spouse_ieltsCBL =
            prCalculation['conversionOfSpouseCLB'][spouse_ieltsScore] ?? 0;

        let ieltsScoreyearsExperience =
            prCalculation['yearsExperience'][ieltsScore] ?? 0;
        let yearsExperienceScore =
            ieltsScoreyearsExperience[data.yearsExperience] ?? 0;
        let educationInCanada =
            prCalculation['canadaEducation'][data.educationInCanada] ?? 0;

        //education and CBL core combination
        let CalCombEduAndCBL =
            combinationCalculation['combEduAndCBL'][data.highestEduction][
                ieltsScore
            ] ?? 0;
        //Foreign work experience – With Canadian work experience
        let CalCombForeWorkExpCanadWorkExp =
            combinationCalculation['combForeWorkExpCanadWorkExp'][
                data.yearsExperience
            ][data.canadianWorkExperience] ?? 0;
        // Canadian work experience + education
        let CalcombCanWorkExpDegree =
            combinationCalculation['combCanWorkExpDegree'][
                data.highestEduction
            ][data.canadianWorkExperience] ?? 0;

        let finalScore =
            age +
            education +
            canadianWorkExperience +
            sopouseEduction +
            SpouseExperience +
            offerLetterCanada +
            isSpouseOrSiblingsPrCanada +
            yearsExperienceScore +
            educationInCanada +
            spouse_ieltsCBL +
            CalCombEduAndCBL +
            CalCombForeWorkExpCanadWorkExp +
            CalcombCanWorkExpDegree +
            ieltsCBL;

        setPrCalculator({
            age,
            education,
            canadianWorkExperience,
            sopouseEduction,
            SpouseExperience,
            offerLetterCanada,
            isSpouseOrSiblingsPrCanada,
            yearsExperienceScore,
            educationInCanada,
            spouse_ieltsCBL,
            CalCombEduAndCBL,
            CalCombForeWorkExpCanadWorkExp,
            CalcombCanWorkExpDegree,
            ieltsCBL,
            total: finalScore,
        });

        // console.log(
        // 	"age",
        // 	age,
        // 	"education",
        // 	education,
        // 	"canadianWorkExperience",
        // 	canadianWorkExperience,
        // 	"sopouseEduction",
        // 	sopouseEduction,
        // 	"SpouseExperience",
        // 	SpouseExperience,
        // 	"offerLetterCanada",
        // 	offerLetterCanada,
        // 	"isSpouseOrSiblingsPrCanada",
        // 	isSpouseOrSiblingsPrCanada,
        // 	"yearsExperienceScore",
        // 	yearsExperienceScore,
        // 	"ieltsScore",
        // 	ieltsScore,
        // 	"ieltsCBL",
        // 	ieltsCBL,
        // 	"educationInCanada",
        // 	educationInCanada,
        // 	"finalScore",

        // 	finalScore
        // );
        // if (finalScore > 0) {
        //     localStorage.removeItem('pr_score');
        //     let score = {
        //         finalScore,
        //         ieltsScore,
        //         name: data.name,
        //     };
        //     isBrowser &&
        //         localStorage.setItem('pr_score', JSON.stringify(score));
        //     window.location = '/pr-score';
        //     // if (typeof window !== 'undefined') {
        //     //     let formData = {
        //     //         source: 'abroad-ninja',
        //     //         brandid: 17,
        //     //         segment: 1880,
        //     //         ...data,
        //     //     };
        //     //     await fetch(
        //     //         `https://api.ufaber.com/api/leads-submit/fetch-lead/`,
        //     //         {
        //     //             method: 'post',
        //     //             headers: {
        //     //                 'Content-Type': 'application/x-www-form-urlencoded',
        //     //             },
        //     //             body: new URLSearchParams(formData),
        //     //         }
        //     //     )
        //     //         .then((response) => response.json())
        //     //         .then((data) => {
        //     //             if (data.success === true) {
        //     //                 setIsSubmitted(false);
        //     //                 NotificationManager.success(
        //     //                     'Form Submitted Successfully',
        //     //                     'success!!'
        //     //                 );

        //     //             }
        //     //             if (data.error === true) {
        //     //                 setIsSubmitted(false);
        //     //                 NotificationManager.error(
        //     //                     'While Submitting error Occurs',
        //     //                     'error!!'
        //     //                 );
        //     //             }
        //     //         });
        //     // }
        // }
    };
    renderCount++;
    // isSpouseOrSiblingsPrCanada
    const watchStatus = watch('marriageStatus');
    useEffect(() => {
        if (!!watchStatus && watchStatus !== 'Married') {
            setValue('isSpouseCommingCanada', null);
        }
    }, [watchStatus]);

    const watchIsSpouseCommingCanada = watch('isSpouseCommingCanada');
    useEffect(() => {
        if (
            (!!watchStatus && watchStatus !== 'Married') ||
            (!!watchIsSpouseCommingCanada &&
                watchIsSpouseCommingCanada !== 'Yes')
        ) {
            setValue('sopouseEduction', null);
            setValue('SpouseExperience', null);
        }
    }, [watchIsSpouseCommingCanada, watchStatus]);

    const watchIsOfferFromCanada = watch('isOfferFromCanada');
    useEffect(() => {
        if (!!watchIsOfferFromCanada && watchIsOfferFromCanada !== 'Yes') {
            setValue('offerLetterCanada', null);
        }
    }, [watchIsOfferFromCanada]);
    const watchIsSpouseOrSiblingsPrCanada = watch('isSpouseOrSiblingsPrCanada');

    return (
        <Layout>
            <SEO title={'Pr Eligibility Calculator'} />
            <MainFormStyle>
                <SettleAbroadFormWrapper>
                    <p> {JSON.stringify(prCalculator, null, 2)}</p>
                    <h1
                        id="heading"
                        dangerouslySetInnerHTML={{
                            __html: 'PR Eligibility Calculator',
                        }}
                    />
                    <p
                        id="subheading"
                        dangerouslySetInnerHTML={{
                            __html: 'Calculate your Estimated eligibility score here for permanent residency',
                        }}
                    />
                    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
                        {/* <label>Enter your Full Name</label>
                        <InputItem
                            type="text"
                            className="input_box"
                            padding={'1rem'}
                            border={'none'}
                            margin={'0px 0px 0.5rem 0px'}
                            backgroundColor={'#F4F4F4'}
                            placeholder={'Name'}
                            error={Boolean(errors.name)}
                            {...register('name')}
                        />
                        <Error className="error">{errors.name?.message}</Error>

                        <label>Enter your Email ID</label>
                        <InputItem
                            type="text"
                            className="input_box"
                            padding={'1rem'}
                            border={'none'}
                            margin={'0px 0px 0.5rem 0px'}
                            backgroundColor={'#F4F4F4'}
                            placeholder={'Email'}
                            error={Boolean(errors.name)}
                            {...register('email')}
                        />
                        <Error className="error">{errors.email?.message}</Error>

                        <label>Enter your Phone number</label>
                        <InputItem
                            type="text"
                            className="input_box"
                            padding={'1rem'}
                            border={'none'}
                            margin={'0px 0px 0.5rem 0px'}
                            backgroundColor={'#F4F4F4'}
                            placeholder={'Phone Number'}
                            error={Boolean(errors.phone)}
                            {...register('phone')}
                        />
                        <Error className="error">{errors.phone?.message}</Error> */}

                        <label>What is your Age?</label>
                        <InputItem
                            type="text"
                            className="input_box"
                            padding={'1rem'}
                            border={'none'}
                            margin={'0px 0px 0.5rem 0px'}
                            backgroundColor={'#F4F4F4'}
                            placeholder={'age'}
                            error={Boolean(errors.age)}
                            {...register('age')}
                        />
                        <Error className="error">{errors.age?.message}</Error>

                        <label htmlFor="">Select your marital status?</label>
                        <div className={`checkBoxButton`}>
                            {['Married', 'Divorced', 'Single'].map((item) => {
                                return (
                                    <label
                                        className={`${
                                            watchStatus === item ? 'active' : ''
                                        } ${
                                            errors.marriageStatus
                                                ? 'checkbox-error'
                                                : ''
                                        }`}
                                    >
                                        <input
                                            type="radio"
                                            value={`${item}`}
                                            {...register('marriageStatus')}
                                        />
                                        <span>{item}</span>
                                    </label>
                                );
                            })}
                        </div>
                        <Error className="error">
                            {errors.marriageStatus?.message}
                        </Error>

                        {watchStatus === 'Married' && (
                            <div
                                className={`status-married ${
                                    watchStatus === 'Married' ? 'show' : 'hide'
                                }`}
                            >
                                <label htmlFor="">
                                    Will your spouse come to Canada with you?
                                </label>
                                <div className={`checkBoxButton`}>
                                    {['Yes', 'No'].map((item) => {
                                        return (
                                            <label
                                                className={`${
                                                    watchIsSpouseCommingCanada ===
                                                    item
                                                        ? 'active'
                                                        : ''
                                                } ${
                                                    errors.isSpouseCommingCanada
                                                        ? 'checkbox-error'
                                                        : ''
                                                }`}
                                            >
                                                <input
                                                    type="radio"
                                                    value={`${item}`}
                                                    {...register(
                                                        'isSpouseCommingCanada'
                                                    )}
                                                />
                                                <span>{item}</span>
                                            </label>
                                        );
                                    })}
                                </div>
                                <Error className="error">
                                    {errors.isSpouseCommingCanada?.message}
                                </Error>
                            </div>
                        )}
                        {watchIsSpouseCommingCanada === 'Yes' && (
                            <div className="Spouse-comming-canada">
                                <label htmlFor="">
                                    Select your spouse’s education level
                                </label>
                                <Select
                                    options={eduction}
                                    control={control}
                                    name="sopouseEduction"
                                    error={Boolean(errors.sopouseEduction)}
                                    setValue={setValue}
                                    clearErrors={clearErrors}
                                />
                                <Error className="error">
                                    {errors.sopouseEduction?.message}
                                </Error>

                                <label htmlFor="">
                                    Select your spouse’s Canadian work
                                    experience
                                </label>
                                <Select
                                    options={workExperience}
                                    control={control}
                                    name="SpouseExperience"
                                    error={Boolean(errors.SpouseExperience)}
                                    setValue={setValue}
                                    clearErrors={clearErrors}
                                />
                                <Error className="error">
                                    {errors.SpouseExperience?.message}
                                </Error>

                                <label htmlFor="">
                                    Select your spouse’s IELTS Score for the
                                    following modules
                                </label>
                                <div className="spouse_english-skill">
                                    <Select
                                        options={Listening}
                                        control={control}
                                        name="spouse_listening"
                                        error={Boolean(errors.spouse_listening)}
                                        setValue={setValue}
                                        clearErrors={clearErrors}
                                        placeholder="Listening"
                                        // width="7.5rem"
                                    />
                                    <Error className="error">
                                        {errors.spouse_listening?.message}
                                    </Error>
                                    <Select
                                        options={Reading}
                                        control={control}
                                        name="spouse_reading"
                                        error={Boolean(errors.spouse_reading)}
                                        setValue={setValue}
                                        clearErrors={clearErrors}
                                        placeholder="Reading"
                                        // width="7.2rem"
                                    />
                                    <Error className="error">
                                        {errors.spouse_reading?.message}
                                    </Error>
                                    <Select
                                        options={Writing}
                                        control={control}
                                        name="spouse_writing"
                                        error={Boolean(errors.spouse_writing)}
                                        setValue={setValue}
                                        clearErrors={clearErrors}
                                        placeholder="Writing"
                                        // width="7rem"
                                    />
                                    <Error className="error">
                                        {errors.spouse_writing?.message}
                                    </Error>
                                    <Select
                                        options={Speaking}
                                        control={control}
                                        name="spouse_speaking"
                                        error={Boolean(errors.spouse_speaking)}
                                        setValue={setValue}
                                        clearErrors={clearErrors}
                                        placeholder="Speaking"
                                        // width="7.5rem"
                                    />
                                    <Error margin="">
                                        {errors.spouse_speaking?.message}
                                    </Error>
                                </div>
                            </div>
                        )}

                        <label htmlFor="">Select your education level</label>
                        <Select
                            options={eduction}
                            control={control}
                            name="highestEduction"
                            error={Boolean(errors.highestEduction)}
                            setValue={setValue}
                            clearErrors={clearErrors}
                        />
                        <Error className="error">
                            {errors.highestEduction?.message}
                        </Error>

                        <label htmlFor="">
                            How many years of foreign work experience do you
                            have?
                        </label>
                        <Select
                            options={yearsExperience}
                            control={control}
                            name="yearsExperience"
                            error={Boolean(errors.yearsExperience)}
                            setValue={setValue}
                            clearErrors={clearErrors}
                        />
                        <Error className="error">
                            {errors.yearsExperience?.message}
                        </Error>

                        <label htmlFor="">
                            What level of education from canada do you have?{' '}
                        </label>
                        <Select
                            options={canadaEducation}
                            control={control}
                            name="educationInCanada"
                            error={Boolean(errors.educationInCanada)}
                            setValue={setValue}
                            clearErrors={clearErrors}
                        />
                        <Error className="error">
                            {errors.educationInCanada?.message}
                        </Error>

                        <label htmlFor="">
                            Does your spouse or siblings have a PR in canada?
                        </label>
                        <div className={`checkBoxButton`}>
                            {['Yes', 'No'].map((item) => {
                                return (
                                    <label
                                        className={`${
                                            watchIsSpouseOrSiblingsPrCanada ===
                                            item
                                                ? 'active'
                                                : ''
                                        } ${
                                            errors.isSpouseOrSiblingsPrCanada
                                                ? 'checkbox-error'
                                                : ''
                                        }`}
                                    >
                                        <input
                                            type="radio"
                                            value={`${item}`}
                                            {...register(
                                                'isSpouseOrSiblingsPrCanada'
                                            )}
                                        />
                                        <span>{item}</span>
                                    </label>
                                );
                            })}
                        </div>
                        <Error className="error">
                            {errors.isSpouseOrSiblingsPrCanada?.message}
                        </Error>

                        <label htmlFor="">
                            How many years of Canadian work experience do you
                            have?
                        </label>
                        <Select
                            options={workExperience}
                            control={control}
                            name="canadianWorkExperience"
                            error={Boolean(errors.canadianWorkExperience)}
                            setValue={setValue}
                            clearErrors={clearErrors}
                        />
                        <Error className="error">
                            {errors.canadianWorkExperience?.message}
                        </Error>

                        <label htmlFor="">
                            Do you have any job offer from Canada?
                        </label>
                        <div className={`checkBoxButton`}>
                            {['Yes', 'No'].map((item) => {
                                return (
                                    <label
                                        className={`${
                                            watchIsOfferFromCanada === item
                                                ? 'active'
                                                : ''
                                        } ${
                                            errors.isOfferFromCanada
                                                ? 'checkbox-error'
                                                : ''
                                        }`}
                                    >
                                        <input
                                            type="radio"
                                            value={`${item}`}
                                            {...register('isOfferFromCanada')}
                                        />
                                        <span>{item}</span>
                                    </label>
                                );
                            })}
                        </div>
                        <Error className="error">
                            {errors.isOfferFromCanada?.message}
                        </Error>
                        {watchIsOfferFromCanada === 'Yes' && (
                            <>
                                <label htmlFor="">Select your role type</label>
                                <Select
                                    options={offerLetterCanada}
                                    control={control}
                                    name="offerLetterCanada"
                                    error={Boolean(errors.offerLetterCanada)}
                                    setValue={setValue}
                                    clearErrors={clearErrors}
                                />
                                <Error className="error">
                                    {errors.offerLetterCanada?.message}
                                </Error>
                            </>
                        )}
                        <label htmlFor="">
                            Select your IELTS Score for the following modules
                        </label>
                        <div className="english-skill">
                            <Select
                                options={Listening}
                                control={control}
                                name="listening"
                                error={Boolean(errors.listening)}
                                setValue={setValue}
                                clearErrors={clearErrors}
                                placeholder="Listening"
                                // width="7.5rem"
                            />
                            <Error className="error">
                                {errors.listening?.message}
                            </Error>
                            <Select
                                options={Reading}
                                control={control}
                                name="reading"
                                error={Boolean(errors.reading)}
                                setValue={setValue}
                                clearErrors={clearErrors}
                                placeholder="Reading"
                                // width="7.2rem"
                            />
                            <Error className="error">
                                {errors.reading?.message}
                            </Error>
                            <Select
                                options={Writing}
                                control={control}
                                name="writing"
                                error={Boolean(errors.writing)}
                                setValue={setValue}
                                clearErrors={clearErrors}
                                placeholder="Writing"
                                // width="7rem"
                            />
                            <Error className="error">
                                {errors.writing?.message}
                            </Error>
                            <Select
                                options={Speaking}
                                control={control}
                                name="speaking"
                                error={Boolean(errors.speaking)}
                                setValue={setValue}
                                clearErrors={clearErrors}
                                placeholder="Speaking"
                                // width="7.5rem"
                            />
                            <Error margin="">{errors.speaking?.message}</Error>
                        </div>

                        {/* <p>{errors.isOfferFromCanada?.message}</p> */}
                        <p>{data}</p>
                        {isSubmitted ? (
                            <FormSubmitButton
                                type="button"
                                value="Submitting..."
                            />
                        ) : (
                            <div className="submit-container">
                                <FormSubmitButton
                                    type="submit"
                                    value="Calculate your score"
                                />
                                <FontAwesomeIcon icon={faAngleRight} />
                            </div>
                        )}
                    </FormWrapper>
                </SettleAbroadFormWrapper>
                <NotificationContainer />
            </MainFormStyle>
        </Layout>
    );
};
const Error = styled.div`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 16px;
    color: #d64545;
    margin: ${(props) => props.margin || '0.3rem 0rem 0.8rem 0'};
    display: block;
    opacity: 0.9;
`;
const MainFormStyle = styled.div`
    padding: 10rem 0.75rem;
    .checkbox-error {
        border: solid #d64545 1.5px !important;
        border-radius: 8px;
    }

    .multiple-radio-box {
        display: flex;
    }
    .english-skill {
        display: flex;
        flex-direction: column;
        gap: 0.1rem;
        flex-wrap: wrap;
    }

    .spouse_english-skill {
        display: flex;
        flex-direction: column;
        gap: 0.1rem;
        flex-wrap: wrap;
    }
    .error {
    }
    [class*='checkBoxButton'] {
        margin: 2rem 0px;
        padding-bottom: 1rem;
        label {
            margin: 4px;
            background-color: #efefef;
            border-radius: 8px;
            border: 1px solid #d0d0d0;
            overflow: auto;
            padding: 0.8rem;
            display: inline;
        }
        span {
            text-align: center;
            padding: 3px 0px;
        }
        input {
            position: absolute;
            left: -200px;
        }
        .active {
            background: #396eb0;
            color: #ffffff;
        }
    }

    .submit-container {
        position: relative;
        svg {
            position: absolute;
            top: 2.125rem;
            right: 10.5rem;
            color: white;
        }
    }

    @media (max-width: 500px) {
        .submit-container {
            svg {
                top: 2.125rem;
                right: 4.3rem;
            }
        }
    }
`;

const SettleAbroadFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 900;
        font-size: 3rem;
        line-height: 58px;
        text-align: center;
        color: #113a68;
        margin-bottom: 1rem;
    }
    #subheading {
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 29px;
        text-align: center;
        color: #396eb0;
        margin-bottom: 4.5rem;
        max-width: 36.4375rem;
    }

    @media (max-width: 500px) {
        align-items: flex-start;

        h1 {
            font-weight: 900;
            font-size: 1.5rem;
            line-height: 29px;
            margin-bottom: 0.5rem;
        }
        #subheading {
            text-align: left;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 17px;
            margin-bottom: 1.5rem;
        }
    }
`;
const FormWrapper = styled.form`
    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    padding: 3.5rem;
    max-width: 40rem;
    min-width: 40rem;

    .required::after {
        content: '*';
        color: #dd1e1e;
    }

    form {
        display: flex;
        flex-direction: column;
    }
    label {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 19px;
        color: #396eb0;
        margin: 1.5rem 0rem 0.8rem 0;
        display: block;
    }
    @media (max-width: 500px) {
        box-shadow: none;
        border-radius: 0px;
        padding: 0rem !important;
    }

    @media (max-width: 690px) {
        max-width: 100%;
        padding: 1.5rem;
        min-width: 100%;
    }
`;

const FormSubmitButton = styled.input`
    -webkit-appearance: button;
    background: #396eb0;
    border-radius: 8px;
    font-weight: 700;
    font-size: 1rem;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
    border: none;
    outline: none;
    padding: 1rem;
    cursor: pointer;
    width: 100%;
    margin-top: 1rem;
`;
const DropDownOption = styled.ul`
    margin-top: 10px;
    display: none;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 25px rgb(0 0 0 / 10%);
    border-radius: 8px;
    .selectMenuDropdownOptn {
        display: flex;
        cursor: pointer;
        border-radius: 8px;
        align-items: center;
        .selectMenuDropdownOptn-text {
            font-weight: 300;
            font-size: 0.875rem;
            line-height: 17px;
            color: #113a68;
            padding: 0.7rem 0.5rem;
            width: 100%;
        }
    }
    .selectMenuDropdownOptn-text:hover {
        background: #f2f2f2;
    }
`;
export default PrEligibilityCalculator;
