import React from 'react';
import styled from 'styled-components';
import SEO from '../components/seo';
import StudyAbroadHeroMarketieng from '../components/marketingComponent/study-abroad-hero-marketieng';
import Gooduniversity from '../components/studyAbroadPages/goodUniversity';
import HowWeDo from '../components/studyAbroadPages/howWeDo';
import ProgramForIndividual from '../components/studyAbroadPages/programForIndividual';
import NeedMoreClarity from '../components/studyAbroadPages/needMoreClarity';
import ProgramResult from '../components/MasterInUSAndCanadaPages/result';
import PrivacyPolicyLink from '../components/marketingComponent/privacy-policy-link';
import StudyAbroadConsultancyHero from '../components/marketingComponent/studyAbroadConsultancyHero';
import IndustryDefyingPerformance from '../components/studyAbroadPages/industryDefyingPerformance';
import ExpertisAndReach from '../components/studyAbroadPages/expertisAndReach';
import MarketingFooter from '../components/marketingComponent/marketingFooter';
// import ProgramForIndividual from '../components/studyAbroadPages/programForIndividual';
function studyabroadconsultancy() {
    return (
        <>
            <StudyAbroadPageContainer>
                <StudyAbroadConsultancyHero
                    heading={`India's Leading Study Abroad Consultant Affiliated With 200+ Universities Globally`}
                    subheading={`Get Admission In Top Ranked Universities With Scholarships Over US$ 10000`}
                />
                <Gooduniversity isMarketing={true} />
                <IndustryDefyingPerformance />
                <HowWeDo isMarketing={true} />
                <ExpertisAndReach />
                <ProgramForIndividual isMarketing={true} />
                {/* <ProgramResult
                    isMarketing={true}
                    slug={['bachelors-in-us-canada']}
                /> */}

                <MarketingFooter />

                {/* <NeedMoreClarity isMarketing={true} /> */}
                {/* <PrivacyPolicyLink /> */}
            </StudyAbroadPageContainer>
        </>
    );
}
export const Head = () => {
    return (
        <SEO
            title="India's Leading Study Abroad Consultants | Overseas Education Consultants"
            description="Fulfil Your Study Abroad Dreams With Abroad Ninja, India's Leading Study Abroad Consultants Trusted By Over 2 Lakh Students. Affiliation with Over 200 Universities and 98% Success Rate."
            keywords="Study Abroad Consultants in Mumbai,Study Abroad Consultants in Delhi,Study Abroad Consultants in Bangalore,Study Abroad Consultants in Hyderabad,Study Abroad Consultants in Pune,Study Abroad Consultants in Nashik,Study Abroad Consultants in Nagpur,Study Abroad Consultants in Kochi,Study Abroad Consultants in Coimbatore,Study Abroad Consultants in Chennai,Study Abroad Consultants in Kolkata,Study Abroad Consultants in Vizag,Study Abroad Consultants in Guntur,Study Abroad Consultants in Vijayawada,Study Abroad Consultants in Ahmedabad,Study Abroad Consultants in Vadodara,Study Abroad Consultants in Jaipur,Study In UK,Study In USA,Study In Canada,Study In Australia,Masters Degree In Canada,Masters Degree In USA,Masters Degree In Australia,Masters Degree In UK"
        />
    );
};
const StudyAbroadPageContainer = styled.div`
    box-sizing: border-box;
    background-image: url('https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/09472a56ed99461fbedcf8561423a705.svg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: 100%;
    @media (max-width: 600px) {
        /* background-position: right 7rem; */
        background-image: url('https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/096db779ef6644a183f4d62a481f36c7.svg');
    }
`;
export default studyabroadconsultancy;
