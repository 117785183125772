import React, { useEffect, useState } from 'react';
import CourseDetail from '../../components/study-abroad/course-details/CourseDetail';
const CourseDetails = () => {
    const isBrowser = typeof window !== 'undefined';

    let courseId;
    if (isBrowser) {
        let url_string = window.location.href;
        let url = new URL(url_string);

        courseId = url.searchParams.get('course');
    }

    const [course, setCourse] = useState({});

    useEffect(() => {
        const fn = async () => {
            const url = `https://fapi.ufaber.com/study-abroad/courses/?course_ids=${courseId}`;

            try {
                const response = await fetch(url);
                const courseDetails = await response.json();

                setCourse(courseDetails[0]);
            } catch (err) {
                console.log(err);
            }
        };
        fn();
    }, [courseId]);

    return <div>{course && <CourseDetail course={course} />}</div>;
};

export default CourseDetails;
