import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "gatsby";
const Hero = () => {

    return (
        <HeroSectionStyled>
            <div className="main-wrapper-hero">
                <div className="left-div-wrapper">
                    <h1 key={`heading`}>Secure Your Scholarship<span className="orange-heading"> With Us</span></h1>
					<p key={`subheading`} id="hero_section_subheading" >Worried about financing your Study Abroad dreams? Check to see if you are eligible for scholarships with some of the best Universities across the world</p>
                    <div>
						<Link to={`/study-abroad-scholarship/scholarship-search`}>
                            <button id="get_started">Search Now  <FontAwesomeIcon icon={faArrowRight}/></button>
                        </Link>
                    </div>
                </div>
                <div className="img-wrapper">
                    <img
                        src="https://s3.us-east-2.wasabisys.com/ufaber-lms/Frame%20(1).png"
						alt="Scholarship, Books, Medals"
                    />
                </div>
            </div>
        </HeroSectionStyled>
    );
};

const HeroSectionStyled = styled.section`
background-repeat: no-repeat;
background-position: top;
background-size: 100%;
padding: 10em 1em 10.375em 1em;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
background-image: url(https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/09472a56ed99461fbedcf8561423a705.svg);
.main-wrapper-hero{
        display:flex;
        flex-wrap:wrap;
		justify-content: center;
		width: 100%;
    }
    .left-div-wrapper{
      display:block;
      padding:15px;
    }
	& h1 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
color: #113A68;
		margin-bottom: 0.3em;
		max-width: 14.875em;
	}
    .orange-heading{
        color:#F4805E;
    }
	& h1 span {
		color: #113a68;
	}
	#hero_section_subheading {
		margin-bottom: 2em;
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1.25rem;
		line-height: 24px;
		color: #396eb0;
		max-width: 26em;
	}
	#get_started {
		font-family: "Inter";

		font-weight: 700;
		font-size: 1.25rem;
		line-height: 24px;
		color: #ffffff;
		border-radius: 0.75em;
		background: #113a68;
		padding: 1.25em 2.5em;
		border: none;
		outline: none;
		cursor: pointer;
	}

	@media (max-width: 800px) {
		padding: 7em 0em 7em 0em;
		background-image: url(https://s3.us-east-2.wasabisys.com/ufaber-lms/Vector.png);
		& h1 {
			font-size: 1.75rem;
			line-height: 2.125rem;
			max-width: 13.875em;
		}
		.img-wrapper{
			padding: 2.5em 0em 0em 1em;
		}
		.main-wrapper-hero{
			flex-wrap:wrap-reverse;
			width:100%;
		}
		.img-wrapper img{
			min-width: 315px;
			max-width: 315px;
		}
		#hero_section_subheading {
			font-size: 1rem;
			line-height: 1.1875em;
			max-width: 20em;
		}
		#get_started {
			font-size: 1rem;
			line-height: 1.1875em;
			padding: 0.9em 1.75em;
		}
	}

	@media (max-width: 370px) {
		& h1 {
			font-size: 1.6rem;
		}
		#hero_section_subheading {
			font-size: 0.9rem;
		}
	}
	@media (max-width: 340px) {
		& h1 {
			font-size: 1.4rem;
			line-height: 1.7rem;
			max-width: 13.875em;
			margin-bottom: 0.4em;
		}
		#hero_section_subheading {
			font-size: 0.9rem;
		}
		#get_started {
			font-size: 0.9rem;
			padding: 0.9em 1.2em;
		}
	}
`;

export default Hero;
