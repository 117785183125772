import React from 'react';
import Index from '../components/LocalPR';
import SEO from '../components/seo';
const LocalPr = () => {
    return (
        <Index/>
    );
}

export const Head = ()=> {
    return (
        <SEO title={"Local PR"} />
    )
}
export default LocalPr;
