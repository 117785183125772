import { useStaticQuery, graphql } from 'gatsby';

export const useProgramResultData = () => {
    const { allStrapiProgramResult } = useStaticQuery(
        graphql`
            query programResult {
                allStrapiProgramResult(
                    sort: { fields: strapi_id, order: DESC }
                ) {
                    nodes {
                        student_img {
                            url
                            name
                        }
                        name
                        month_year
                        university_or_entery_type
                        show_on_all_page
                        slug
                        id
                        strapi_id
                        course
                        age
                        type
                    }
                }
            }
        `
    );

    return allStrapiProgramResult.nodes;
};
