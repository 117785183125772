import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SortFilter = (props) => {
    const { onFilterChange, filterName, filterValue, options, filterSelected } =
        props;
    const [isOptionsVisible, setIsOptionsVisible] = useState(false);
    const filterRef = useRef();
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        setIsSelected(filterSelected);
    }, [filterSelected]);

    const handleClickOutside = (event) => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            setIsOptionsVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    const selectFilterHandler = (filter, value, label) => {
        setIsSelected(true);
        setIsOptionsVisible(false);
        onFilterChange({ filter: filter, value: value, label: label });
    };

    const filterClickHandler = () => {
        setIsOptionsVisible((prevValue) => !prevValue);
    };

    return (
        <FilterContainer>
            <Filter onClick={filterClickHandler} isSelected={isSelected}>
                <p className="sort-para">
                    {isSelected ? filterValue : filterName}
                </p>
                <FontAwesomeIcon icon={faAngleDown} />
            </Filter>
            {isOptionsVisible && (
                <Options ref={filterRef}>
                    {options.map((option) => {
                        return (
                            <Option key={option.label}>
                                <p
                                    onClick={() =>
                                        selectFilterHandler(
                                            option.filter,
                                            option.value,
                                            option.label
                                        )
                                    }
                                >
                                    {option.label}
                                </p>
                            </Option>
                        );
                    })}
                </Options>
            )}
        </FilterContainer>
    );
};

const FilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2px;
`;

const Filter = styled.div`
    ${({ isSelected }) => isSelected && `background-color: #ffd6c9;`}
    border-radius: 12px;
    border: 1px solid #242424;
    color: #113a68;
    font-size: 1rem;
    padding: 0.7rem 0.5rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem;

    .sort-para {
        font-size: 1rem;
        width: max-content;
    }
    @media (max-width: 500px) {
        height: 40px;
    }
`;

const Options = styled.div`
    width: 100%;
    background-color: #fff;
    left: 0;
    top: 3rem;
    display: flex;
    gap: 0.15rem;
    flex-direction: column;
    padding: 2px;
    border: 1px solid #242424;
    border-radius: 12px;
`;

const Option = styled.div`
    border-radius: 12px;
    cursor: pointer;

    p {
        white-space: nowrap;
        font-size: 1rem;
        width: max-content;
        :hover {
            background-color: #ffd6c9;
        }
        border-radius: 9px;
        background-color: #fff;
        color: #113a68;
        padding: 0.7rem 0.5rem;
    }
`;

export default SortFilter;
