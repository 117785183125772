import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import searchButtonIcon from '../../../assets/icons/search-button.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
const UniversitySearch = ({
    setSearchUniversity,
    setSearchCourse,
    setSearchText,
    searchText,
}) => {
    const [isShowDropdown, setIsShowDropDown] = useState(false);
    const [filteredCourseUniversities, setFilteredCourseUniversities] =
        useState([]);
    const [filteredCourse, setFilteredCourse] = useState([]);
    const [filteredUniversities, setFilteredUniversities] = useState([]);
    const [debounceTimeout, setDebounceTimeout] = useState(null);
    const dropdownRef = useRef(null);
    const inputRef = useRef(null);
    const handleSearchInputChange = (event) => {
        const text = event.target.value;

        setSearchText(text);
        setIsShowDropDown(true);
        // Cancel any previously scheduled API call
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        // // Schedule a new API call after 500ms
        const timeout = setTimeout(() => {
            searchUniversities(text);
        }, 500);

        setDebounceTimeout(timeout);
    };
    const searchUniversities = async (text) => {
        let aUniversity = [];
        let aCourses = [];
        try {
            const response = await fetch(
                `https://api.ufaber.com/api/sales/abroad_ninja_search/?text_search=${text}`
            );
            const data = await response.json();
            if (data?.status === 'SUCCESS') {
                let maiData = data?.data?.hits?.hits;
                maiData.forEach((element) => {
                    if (
                        element?._source &&
                        element?.highlight &&
                        element?.highlight?.name
                    ) {
                        aUniversity.push({
                            ...element?._source,
                            type: 'university',
                        });
                    }
                    let course = element?.inner_hits?.courses?.hits?.hits ?? 0;
                    if (course) {
                        course.forEach((el) => {
                            if (el?.highlight && el?.highlight) {
                                aCourses.push({
                                    ...element?._source,
                                    ...el._source,
                                    type: 'course',
                                });
                            }
                        });
                    }
                });
            }

            setFilteredCourse([...aCourses]);
            setFilteredUniversities([...aUniversity]);
            setFilteredCourseUniversities([...aUniversity, ...aCourses]);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const handleDocumentClick = (event) => {
            if (inputRef.current && inputRef.current.contains(event.target)) {
                setIsShowDropDown(true);
            } else if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsShowDropDown(false);
            }
        };

        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    const onClickSearchUniversity = (universityId = null) => {
        if (universityId) {
            let university = filteredUniversities.filter(
                (uni) => uni.id === universityId
            );

            setSearchText(university[0].name);
            setSearchUniversity([...university]);
        } else {
            setSearchUniversity([...filteredUniversities]);
        }
        setSearchCourse(null);
        setIsShowDropDown(false);
    };

    const onClickSearchCourse = (CourseId = null) => {
        if (CourseId) {
            let Courses = filteredCourse.filter(
                (cour) => cour.courseID === CourseId
            );

            setSearchText(Courses[0].courseName);
            setSearchCourse([...filteredCourse]);
        } else {
            setSearchCourse([...filteredCourse]);
        }
        setSearchUniversity(null);
        setIsShowDropDown(false);
    };
    return (
        <SearchBar>
            <button className="university-search__btn-search">
                <img src={searchButtonIcon} />
            </button>
            <input
                ref={inputRef}
                className="university-search__text-box"
                type="text"
                placeholder="Search by university or course name"
                value={searchText}
                onChange={handleSearchInputChange}
            />
            {searchText && (
                <FontAwesomeIcon
                    icon={faTimes}
                    color="#9ca3af"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        setSearchText('');
                        setSearchUniversity(null);
                        setSearchCourse(null);
                    }}
                />
            )}
            {searchText != '' && isShowDropdown && (
                <div ref={dropdownRef} className="university-search__result">
                    {searchText && (
                        <div
                            data-se="search-university"
                            className="search_uni_course"
                        >
                            <div
                                className="search_uni"
                                onClick={() => onClickSearchUniversity()}
                            >
                                <FontAwesomeIcon
                                    icon={faSearch}
                                    color="#9ca3af"
                                    style={{ marginRight: '7px' }}
                                />
                                <p>
                                    {`Search in "${searchText}" in university`}{' '}
                                </p>
                            </div>
                            <div
                                className="search_course"
                                onClick={() => onClickSearchCourse()}
                            >
                                <FontAwesomeIcon
                                    icon={faSearch}
                                    color="#9ca3af"
                                    style={{ marginRight: '7px' }}
                                />
                                <p>{`Search in "${searchText}" in Course`} </p>
                            </div>
                        </div>
                    )}

                    {filteredCourseUniversities.map((university) => (
                        <>
                            {university.type === 'university' ? (
                                <div
                                    className="result"
                                    key={university.id}
                                    onClick={() =>
                                        onClickSearchUniversity(university.id)
                                    }
                                >
                                    <div className="primary_result">
                                        {university.name}
                                    </div>
                                    <div className="secondary_result">
                                        University
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="result"
                                    key={university.courseID}
                                    onClick={() =>
                                        onClickSearchCourse(university.courseID)
                                    }
                                >
                                    <div className="primary_result">
                                        {university.courseName}
                                    </div>
                                    <div className="secondary_result">
                                        Course
                                    </div>
                                </div>
                            )}
                        </>
                    ))}
                </div>
            )}
        </SearchBar>
    );
};

const SearchBar = styled.div`
    display: flex;
    align-items: center;
    align-self: flex-start;
    width: fit-content;
    border: 1px solid #ffd6c9;
    border-radius: 12px;
    padding: 0.7rem 1rem;
    position: relative;

    @media (max-width: 1200px) {
        width: 100%;
    }

    @media (max-width: 768px) {
        width: 100%;
    }

    @media (max-width: 480px) {
        width: 100%;
    }

    .university-search__text-box {
        width: 350px;
        color: #396eb0;
        border: none;
        font-size: 1rem;

        ::placeholder {
            color: #396eb0;
        }
        :focus {
            outline: none;
        }

        @media (max-width: 480px) {
            width: 280px;
        }
    }

    .university-search__btn-search {
        padding-right: 10px;
        display: flex;
        border: none;
        background-color: #fff;
    }

    .university-search__result {
        position: absolute;
        top: 52px;
        left: 0;
        z-index: 1;
        background-color: #fff;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
        width: 100%;
        max-height: 400px;
        overflow-y: scroll;
        padding: 10px;
        border-radius: 0.375rem;
    }
    .result {
        margin-top: 0.5rem;
        padding: 0.5rem 0;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        border-radius: 0.5rem;
        cursor: pointer;
        &:hover {
            background-color: #f4f4f4;
            cursor: pointer;
        }
    }

    .primary_result {
        margin-left: 0.75rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }
    .secondary_result {
        margin-left: 0.75rem;
        color: #9ca3af;
        font-size: 0.75rem;
        line-height: 1rem;
    }
    .search_uni_course {
        display: flex;
        padding-bottom: 0.4rem;
        flex-direction: column;
        border-bottom: 1px solid #e5e7eb;
    }
    .search_uni,
    .search_course {
        display: flex;
        padding: 1rem;
        &:hover {
            background-color: #f4f4f4;
            cursor: pointer;
        }
        p,
        button {
            color: #9ca3af;
            font-size: 0.875rem;
            line-height: 1.25rem;
        }
    }
`;

export default UniversitySearch;
