import React from 'react';
import StudyPage from '../components/studyPage/studyPage';
import SEO from '../components/seo';

const CaseStudyPage = (data) => {
    let slug = data.pageContext.slug;

    return (
        <div>
            <StudyPage linkSlug={slug} />{' '}
        </div>
    );
};

export default CaseStudyPage;
