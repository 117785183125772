import React from "react";
import styled from "styled-components";
import Flickity from "react-flickity-component";
import Layout from "../components/layout.component";
import { useAboutUsData } from "../hooks/aboutUs";
import { Link } from "gatsby";
import SEO from "../components/seo";
const AboutUs = () => {
	const flickityOptions = {
		cellAlign: "center",
		pageDots: true,
		wrapAround: true,
		draggable: true,
		prevNextButtons: false,
	};

	const { heading, about_us_desc, our_product, our_teams } = useAboutUsData();

	return (
		<Layout>
			<AboutUsStyled>
				<AboutUsMainWrapperStyled>
					<h1 dangerouslySetInnerHTML={{ __html: heading }} />
					<img
						src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/8760cb439b272fd2a2c8db08216d304c.svg"
						alt="Abroad Ninja"
						className="abroad_ninja_logo"
					/>
					{about_us_desc.map((desc, index) => {
						return (
							<div key={desc.strapi_id}>
								<AboutUsArticleStyled
									dangerouslySetInnerHTML={{
										__html: desc.description,
									}}
								/>
							</div>
						);
					})}

					<MeetOurTeamCarouselWrapper id="meet_our_team_carousel_wrapper">
						<h4>Meet our teams</h4>

						<Flickity
							className={"main-carousel"}
							elementType={"div"}
							options={flickityOptions}
							disableImagesLoaded={false}
							reloadOnUpdate
							static
						>
							{our_teams.map((team, index) => (
								<CarouselCellStyled>
									<div className="teamCard">
										<div className="teamImage">
											<img src={team?.team_img?.url} alt={team?.team_img?.name} />
										</div>
										<div className="teamBioWrapper">
											<div
												dangerouslySetInnerHTML={{
													__html: team.team_quote,
												}}
											/>
											{/* <p className="teamMemberQuote">
												"Unapologetically doing what
												everyone told me was not
												possible. I believe{" "}
												<span>
													every problem has a
													solution,
												</span>{" "}
												the naysayers can keep talking."{" "}
											</p> */}
											<small className="teamMemberName">{team.name}</small>
											<small className="teamMemberDesgn">{team.desg}</small>
										</div>
									</div>
								</CarouselCellStyled>
							))}
						</Flickity>
					</MeetOurTeamCarouselWrapper>
					<span id="our_products">Our Products</span>
					<UfaberProductsLogo>
						{our_product.map((product) => (
							<div key={product.strapi_id}>
								<Link to={product.redirect_to}>
									<img src={product?.product_img?.url} alt={product?.product_img?.name} />
								</Link>
							</div>
						))}
					</UfaberProductsLogo>
				</AboutUsMainWrapperStyled>
			</AboutUsStyled>
		</Layout>
	);
};
const AboutUsStyled = styled.section`
	padding: 9rem 0.75rem 3rem;
`;
const AboutUsMainWrapperStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	h1 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		color: #396eb0;
		max-width: 817px;
		margin-bottom: 6rem;
	}
	h1 > span {
		color: #113a68;
	}
	.abroad_ninja_logo {
		margin-bottom: 6rem;
	}
	#our_products {
		font-weight: 700;
		font-size: 2rem;
		line-height: 39px;
		text-align: center;
		color: #113a68;
		margin-bottom: 2rem;
	}

	@media (max-width: 500px) {
		h1 {
			font-weight: 900;
			font-size: 1.5rem;
			line-height: 29px;
			text-align: center;
			color: #396eb0;
			padding: 0rem 0.75rem;
			margin-bottom: 4rem;
		}
		.abroad_ninja_logo {
			height: 80px;
			width: 208px;
			margin-bottom: 4rem;
		}
	}
`;
const AboutUsArticleStyled = styled.article`
	margin-bottom: 4rem;
	max-width: 70rem;
	h2,
	h3 {
		font-weight: 700;
		font-size: 2rem;
		line-height: 39px;
		text-align: center;
		color: #113a68;
		margin-bottom: 1rem;
	}
	p {
		font-weight: 400;
		font-size: 1rem;
		line-height: 24px;
		text-align: center;
		color: #505050;
		margin-bottom: 1.5rem;
	}
	p:last-child {
		margin-bottom: 1.5rem;
	}
	span {
		font-weight: 700;
	}
	small {
		font-size: 1.5rem;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0em;
		text-align: center;
		color: #5b5b5b;
		display: block;
	}

	@media (max-width: 500px) {
		h2,
		h3 {
			font-weight: 700;
			font-size: 1.5rem;
			line-height: 29px;
			text-align: center;
		}
		p {
			font-weight: 400;
			font-size: 0.875rem;
			line-height: 17px;
			text-align: center;
		}
		small {
			font-weight: 400;
			font-size: 0.875rem;
			line-height: 17px;
			text-align: center;
		}
		p:last-child {
			margin-bottom: 1rem;
		}
	}
`;

const MeetOurTeamCarouselWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 725px;
	margin-bottom: 7.5rem;
	h4 {
		font-weight: 700;
		font-size: 2rem;
		line-height: 39px;
		text-align: center;
		color: #113a68;
		margin-bottom: 2rem;
	}
	@media (max-width: 770px) {
		width: 100%;
	}
`;

const CarouselCellStyled = styled.div`
	width: 100%;
	margin: 0rem 0.5rem;

	.teamCard {
		background: #fff0eb;
		border-radius: 32px;
		padding: 2rem 4rem;
		display: flex;
		align-items: center;
		gap: 3.5rem;
	}
	.teamImage {
		filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15));
		border-radius: 50%;
		min-width: 268px;
		max-width: 268px;
		min-height: 268px;
		max-height: 268px;
	}
	.teamImage img {
		object-fit: cover;
		max-width: 100%;
		min-width: 100%;
		min-height: 100%;
		max-height: 100%;
	}
	.teamBioWrapper {
		max-width: 16.8125rem;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	.teamMemberQuote {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 24px;
		color: #113a68;
		margin-bottom: 1rem;
	}
	.teamMemberQuote > span {
		color: #f4805e;
	}

	.teamMemberName {
		font-weight: 700;
		font-size: 1.25rem;
		line-height: 24px;
		color: #113a68;
		margin-bottom: 0.25rem;
	}
	.teamMemberDesgn {
		font-weight: 400;
		font-size: 1rem;
		line-height: 19px;
		color: #113a68;
	}
	.flickity-page-dots .dot {
		background-color: #113a68;
	}

	@media (max-width: 770px) {
		.teamCard {
			padding: 2rem;
			justify-content: center;
		}
	}
	@media (max-width: 630px) {
		.teamCard {
			padding: 1.5rem;
		}
	}
	@media (max-width: 610px) {
		.teamCard {
			flex-direction: column;
		}
		.teamBioWrapper {
			max-width: 100%;
			align-items: center;
		}
	}
	@media (max-width: 500px) {
		.teamCard {
			padding: 1rem;
			gap: 1.5rem;
		}
		.teamMemberDesgn {
			display: none;
		}
		.teamMemberName {
			order: 1;
			font-weight: 900;
			font-size: 1.25rem;
			line-height: 24px;
			color: #f4805e;
			margin-bottom: 0.5rem;
		}
		.teamMemberQuote {
			order: 2;
			font-weight: 400;
			font-size: 0.875rem;
			line-height: 16px;
			text-align: center;
		}
		.teamMemberQuote > span {
			color: #113a68;
		}
		.teamImage {
			min-width: 200px;
			max-width: 200px;
			min-height: 200px;
			max-height: 200px;
		}
	}

	@media (max-width: 400px) {
		.teamImage {
			min-width: 112px;
			max-width: 112px;
			min-height: 112px;
			max-height: 112px;
		}
	}
`;

const UfaberProductsLogo = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
	gap: 5rem;
	@media (max-width: 500px) {
		display: grid;
		grid-template-columns: 1fr;
		gap: 1rem;
		justify-items: center;
	}
`;
export const Head = () => {
	return (
		<SEO
			title="About us - Abroad Ninja | One-stop Solution to Study & Settel Abroad" 
			description="Get to know the vision, mission, and people behind the most comprehensive online study abroad programs and online study abroad consultations."
			og_type="website"
			og_url="https://abroadninja.in/about-us/"
			og_title="About us - Abroad Ninja | One-stop Solution to Study & Settel Abroad"
			og_description="Get to know the vision, mission, and people behind the most comprehensive online study abroad programs and online study abroad consultations."
			og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
			twitter_card="summary_large_image"
			twitter_url="https://abroadninja.in/about-us/"
			twitter_title="About us - Abroad Ninja | One-stop Solution to Study & Settel Abroad"
			twitter_description="Get to know the vision, mission, and people behind the most comprehensive online study abroad programs and online study abroad consultations."
			twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
			robots="index, follow"
			canonical="https://abroadninja.in/about-us/"
		/>
	)
}

export default AboutUs;
