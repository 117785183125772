import React, { useEffect, useState } from 'react';
import UniversityDetails from '../../components/study-abroad/university-details/UniversityDetails';
const University = () => {
    const isBrowser = typeof window !== 'undefined';

    let universityId;
    if (isBrowser) {
        let url_string = window.location.href;
        let url = new URL(url_string);

        universityId = url.searchParams.get('university');
    }

    const [university, setUniversity] = useState({});

    useEffect(() => {
        const fn = async () => {
            const url = `https://fapi.ufaber.com/study-abroad/universities/?f_ids=${universityId}`;
            try {
                const response = await fetch(url);
                const universityDetails = await response.json();
                setUniversity(universityDetails.data[0]);
            } catch (err) {
                console.log(err);
            }
        };
        fn();
    }, [universityId]);

    return (
        <div>{university && <UniversityDetails university={university} />}</div>
    );
};
export default University;
