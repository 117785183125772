import React from "react";
import { Link } from 'gatsby';
import styled from "styled-components";
import SEO from "../components/seo";


const PrDemo = () => {

	return (
        <PrDemoWrapper>
        <FirstFoldWrapper>
            <h1>Why Canada?</h1>
            <div className="firstfoldWrapperMain">
                <div className="firstfoldWrapperMainLeft">
                    <img src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/3068d78d4a06daca401f7b5dc3dd25fc.1680276996472312" alt=""/>
                </div>
                <div className="firstfoldWrapperMainRIght">
                    <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/9a228894b93f7b68fc959b372bde571c.svg" alt=""/>
                </div>
            </div>
            <div className="firstfoldWrapperSecond">
                <div className="firstfoldWrapperSecondLeft">
                    <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/aff0666485543577b93ebb1aebc774c5.svg" alt=""/>
                </div>
                <div className="firstfoldWrapperSecondRight">
                    <img src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/3e14cf44575ec0de974febe1b5ed0317.1680277325096312" alt=""/>
                </div>
            </div>
        </FirstFoldWrapper>
        <SecondFoldWrapper>
            <h1>Express entry Draw and Pool</h1>
            <h2>Let us understand what Express Entry is?</h2>
            <h3>Express Entry is a route that skilled workers use to immigrate to Canada.</h3>
            <div className="secondfoldWrapperMain">
                <img src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/c684397c00639e16f92db45a4dc5abd4.1680279252832312" alt=""/>
            </div>
            <Link className="redirectionBtn" to="https://www.cic.gc.ca/english/immigrate/skilled/crs-tool.asp" target="_blank">CRS Calculator</Link>
            <div className="secondfoldWrapperMain">
                <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/ae48eb688ef4c19cdb2f1ff8179bbe7b.svg" alt=""/>
            </div>
            <div className="secondfoldWrapperMain">
                <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/a87ff679a2f3e71d9181a67b7542122c.svg" alt=""/>
            </div>
            <div className="secondfoldWrapperMain">
                <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/e4da3b7fbbce2345d7772b0674a318d5.svg" alt=""/>
            </div>
            <div className="secondfoldWrapperMain">
                <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/1679091c5a880faf6fb5e6087eb1b2dc.svg" alt=""/>
            </div>
        </SecondFoldWrapper>
		<ThirdFoldWrapper>
		<h1>Job Code</h1>
		<div className="thirdFoldMainWrapper">
			<div className="thirdFoldLeftDiv">
				<h1>NOC Code</h1>
				<h2>Canada Job Search</h2>
				<p>Let have a look at the</p>
				<Link className="customLink" to="https://noc.esdc.gc.ca/Home/Welcome/4d655901c5a8499d8af705bb2a3aee03?GoCTemplateCulture=en-CA" target="_blank">National Occupational Classification </Link>
				<p>website</p>
				<div className="thirdFoldleftDivBottom">
					<h6>Risks:</h6>
					<p>Wrong NOC can lead to Failure to get ITA in PNP (needs, 24X7 NOC tracking)</p>
				</div>
			</div>
			<div className="thirdFoldRightDiv">
				<div className="thirdFoldRightDivImageWrapper">
					<img src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/4760fc9c94ee4c74a3dad022f21b2f59.168072078161363" alt=""/>
				</div>
			</div>
		</div>
		</ThirdFoldWrapper>
		<FourthFoldWrapper>
		<div className="fourthfoldWrapperMain">
			<img src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/88385f9fc844de0d3134ca3fb358e372.1684391437447184" alt=""/>
		</div>
		<div className="fourthfoldWrapperMain">
			<img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/45c48cce2e2d7fbdea1afc51c7c6ad26.svg" alt=""/>
		</div>
		<div className="fourthfoldWrapperMain">
			<img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/d3d9446802a44259755d38e6d163e820.svg" alt=""/>
		</div>
		<div className="fourthfoldWrapperMain">
			<img src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/32850ff9e8c2b27ebcb04e6430210d2f.168072313766163" alt=""/>
		</div>
		<div className="fourthfoldWrapperMain">
			<img src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/8e935df84be968d3408b13752eb7061b.168072328620863" alt=""/>
		</div>
		<Link className="redirectionBtn" to="https://abroadninja.in/program-result/" target="_blank">Visit Abroad Ninja</Link>
		<div className="fourthfoldWrapperMain">
			<img src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/62e2477940c7ed7b287f100fa0252010.168072353176563" alt=""/>
		</div>
		<div className="fourthfoldWrapperMain">
			<img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/c5e216e112695f67e4cc4ae79789ce50.PNG" alt=""/>
		</div>
		<div className="fourthfoldLastWrapper">
			<div className="fourthfoldLastLeftDiv">
				<p><span>67</span> points</p>
				<h4>Eligibility criteria for Canada PR</h4>
				<Link className="customLink" to="https://www.canada.ca/en/immigration-refugees-citizenship/services/immigrate-canada/express-entry/eligibility/federal-skilled-workers/six-selection-factors-federal-skilled-workers.html" target="_blank">67 Points Calculator</Link>
			</div>
			<div className="fourthfoldLastRightDiv fourthfoldWrapperMain">
				<img src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/95302c033464e857c661d0d83adfebd2.168072425297363" alt=""/>
			</div>
		</div>
		</FourthFoldWrapper>
        </PrDemoWrapper>
	);
};

const PrDemoWrapper = styled.div`
	display: flex;
    flex-direction:column;
	align-items: center;
	justify-content: center;
    width: 100%;
	gap: 1rem;
    background-color: #FFF0EB;
    img{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        object-fit:contain;
    }
`;
const FirstFoldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
    padding: 44px 60px;
    h1{
        font-style: normal;
        font-weight: 900;
        font-size: 3rem;
        line-height: 58px;
        text-align: center;
        color: #F4805E;
    }
    .firstfoldWrapperMain{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width:100%;
    }
    .firstfoldWrapperMainLeft{
        display: flex;
        align-items: center;
        justify-content: center;
        max-width:280px;
    }
    .firstfoldWrapperMainRight{
        display: flex;
        align-items: center;
        justify-content: center;
        max-width:650px;
    }
    .firstfoldWrapperSecond{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width:100%;
    }
    .firstfoldWrapperSecondLeft{
        display: flex;
        align-items: center;
        justify-content: center;
        max-width:370px;
    }
    .firstfoldWrapperSecondRight{
        display: flex;
        align-items: center;
        justify-content: center;
        align-self:flex-end;
        max-width:600px;
    }
`;

const SecondFoldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1rem;
    padding: 44px 60px;
    h1{
        font-style: normal;
        font-weight: 900;
        font-size: 3rem;
        line-height: 58px;
        text-align: center;
        color: #113A68;
    }
    h2{
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        text-align: center;
        color: #113A68;
    }
    h3{
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #113A68;
    }
    .secondfoldWrapperMain{
        display: flex;
        align-items: center;
        justify-content: center;
        max-width:100%;  
    }
    .redirectionBtn{
        display: flex;
        align-items:center;
        justify-content:center;
        width: 222px;
        height: 65px;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 29px;
        color: #FFFFFF;
        padding: 18px 28px;
        background: #113A68;
        border-radius: 12px;
        cursor:pointer;
    }
    .firstfoldWrapperMain{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width:100%;
    }
    .firstfoldWrapperMainLeft{
        display: flex;
        align-items: center;
        justify-content: center;
        max-width:280px;
    }
    .firstfoldWrapperMainRight{
        display: flex;
        align-items: center;
        justify-content: center;
        max-width:650px;
    }
    .firstfoldWrapperSecond{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width:100%;
    }
    .firstfoldWrapperSecondLeft{
        display: flex;
        align-items: center;
        justify-content: center;
        max-width:370px;
    }
    .firstfoldWrapperSecondRight{
        display: flex;
        align-items: center;
        justify-content: center;
        align-self:flex-end;
        max-width:600px;
    }
`;

const ThirdFoldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 65%;
    gap: 1rem;
    padding: 44px 60px;
	background: url("http://s3.us-east-2.wasabisys.com/ufaber-lms/files/f389999fcb3b742f6be463af93843c3a.168071589243853");
	background-position: center; /* Center the image */
	background-repeat: no-repeat; /* Do not repeat the image */
	background-size: cover; /* Resize the background image to cover the entire container */
	h1{
        font-style: normal;
        font-weight: 900;
        font-size: 3rem;
        line-height: 58px;
        text-align: center;
        color: #113A68;
    }
	.thirdFoldMainWrapper{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
	.thirdFoldLeftDiv{
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		width: 50%;
	}
	.thirdFoldRightDiv{
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-end;
		width: 50%;
		align-self: flex-end;
	}
	.thirdFoldLeftDiv h2{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 96px;
		line-height: 104.52%;
		color: #FFFFFF;
	}
	.thirdFoldLeftDiv p{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 32px;
		line-height: 121.52%;
		color: #000000;
	}
	.customLink{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 121.52%;
		text-decoration-line: underline !important;
		color: #3A42F7;
	}
	.thirdFoldleftDivBottom{
		display:flex;
		flex-direction:column;
		align-items:flex-start;
		justify-content:center;
		max-width: 410px;
		max-height: 156px;
		padding: 21px;
		background: #FFFFFF;
		border: 1px solid rgba(57, 110, 176, 0.5);
		border-radius: 12px;
		margin-top: 22px;
	}
	.thirdFoldleftDivBottom h6{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 600;
		font-size: 20px;
		line-height: 140%;
		color: #CB0D29;
	}
	.thirdFoldleftDivBottom p{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 140%;	
		color: #CB0D29;
	}
	.thirdFoldRightDivImageWrapper{
		display: flex;
		align-items: center;
		justify-content: center;	
		max-width: 334px;
		max-height: 178px;
	}

`;

const FourthFoldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 65%;
    gap: 1rem;
    h1{
        font-style: normal;
        font-weight: 900;
        font-size: 3rem;
        line-height: 58px;
        text-align: center;
        color: #113A68;
    }
    h2{
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 27px;
        text-align: center;
        color: #113A68;
    }
    h3{
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #113A68;
    }
    .fourthfoldWrapperMain{
        display: flex;
        align-items: center;
        justify-content: center;
        max-width:100%;  
		width: 100%;
    }
    .redirectionBtn{
        display: flex;
        align-items:center;
        justify-content:center;
        width: 240px;
        height: 65px;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 29px;
        color: #FFFFFF;
        padding: 18px 28px;
        background: #113A68;
        border-radius: 12px;
        cursor:pointer;
		margin-bottom:10px;
    }
    .firstfoldWrapperMain{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width:100%;
    }
    .firstfoldWrapperMainLeft{
        display: flex;
        align-items: center;
        justify-content: center;
        max-width:280px;
    }
    .firstfoldWrapperMainRight{
        display: flex;
        align-items: center;
        justify-content: center;
        max-width:650px;
    }
    .firstfoldWrapperSecond{
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width:100%;
    }
    .firstfoldWrapperSecondLeft{
        display: flex;
        align-items: center;
        justify-content: center;
        max-width:370px;
    }
    .firstfoldWrapperSecondRight{
        display: flex;
        align-items: center;
        justify-content: center;
        align-self:flex-end;
        max-width:600px;
    }
	.fourthfoldLastWrapper{
		display:flex;
		align-items:center;
		justify-content:center;
		width: 100%;
		padding-right: 60px;
		padding-bottom: 40px;
	}
	.fourthfoldLastLeftDiv{
		display:flex;
		flex-direction:column;
		align-items:center;
		justify-content:center;
		width: 50%;
	}
	.fourthfoldLastLeftDiv p{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 32px;
		line-height: 77px;
		color: #F4805E;
	}
	.fourthfoldLastLeftDiv p > span{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 700;
		font-size: 64px;
		line-height: 77px;
		color: #F4805E;
	}
	.fourthfoldLastLeftDiv h4{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 36px;
		line-height: 44px;
		text-align: center;
		color: #113A68;
	}
	.customLink{
		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 121.52%;
		text-decoration-line: underline !important;
		color: #3A42F7;
	}
	.fourthfoldLastRightDiv{
		display:flex;
		align-items:center;
		justify-content:center;
		width: 50%;
	}
`;
export const Head = () => {
    return (
        <SEO 
            title={"PR Demo"}
            viewport="width=device-width, initial-scale=0, user-scalable=no"
        />
        
    )
}
export default PrDemo;
