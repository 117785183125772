import React from 'react';
import styled from 'styled-components';
import { useBlogAndArticleData } from '../../hooks/blog_article';

import { Link } from 'gatsby';
const StudyingInUSCanada = ({ slug, type }) => {
    const blogs = useBlogAndArticleData();
    console.log('slug', slug);
    const filter_blogs = blogs.filter((blog) => {
        return (
            blog.type === 'blog-program' &&
            (blog.blog_slug === slug[0] ||
                blog.blog_slug === null ||
                blog.blog_slug === '')
        );
    });
    let header =
        type === 'Study Abroad'
            ? 'Know more about Studying Abroad'
            : slug[0] === 'canada-immigration'
            ? 'Know more about Canada PR'
            : 'Know more about Settling Abroad';

    return (
        <StudyingInUSCanadaStyled>
            <StudyingInUSCanadaWrapperStyled>
                <h5>{header}</h5>
                <small>Our Blog</small>
                <StudyingInUSCanadaCardWrapperStyled>
                    {filter_blogs.map(
                        (blog, index) =>
                            index < 3 && (
                                <StudyingInUSCanadaCardsStyled key={blog.id}>
                                    <div className="studying_in_US_canada_card_image_wrapper">
                                        <img
                                            src={blog.img.url}
                                            alt={blog.img.name || 'img'}
                                        />
                                    </div>
                                    <p className="studying_in_US_canada_card_heading">
                                        {blog.heading}
                                    </p>
                                    <p className="studying_in_US_canada_card_info">
                                        {blog.content}
                                    </p>
                                    <Link
                                        to={`https://abroadninja.in/${blog.read_more_redirect_to}`}
                                        style={{ textAlign: 'center' }}
                                    >
                                        <span className="studying_in_US_canada_card_read_more">
                                            Read More
                                        </span>
                                    </Link>
                                </StudyingInUSCanadaCardsStyled>
                            )
                    )}
                </StudyingInUSCanadaCardWrapperStyled>
                <Link to={'https://abroadninja.in/content/'}>
                    <button id="studying_in_US_canada_view_all">
                        View All
                    </button>
                </Link>
            </StudyingInUSCanadaWrapperStyled>
        </StudyingInUSCanadaStyled>
    );
};
const StudyingInUSCanadaStyled = styled.section`
    padding: 0 2rem;
    #studying_in_US_canada_view_all {
        margin-top: 3rem;
        cursor: pointer;
        outline: none;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 19px;
        color: #113a68;
        padding: 1rem 3rem;
        border: 1px solid #113a68;
        border-radius: 8px;
        background-color: white;
    }

    @media (max-width: 500px) {
        padding: 0 0.75rem;
        #studying_in_US_canada_view_all {
            padding: 0.90625rem 1.75rem;
        }
    }
`;
const StudyingInUSCanadaWrapperStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 5rem;
    width: 100%;

    & h5 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 900;
        font-size: 3rem;
        line-height: 58px;
        text-align: center;
        color: #113a68;
        max-width: 35.4375rem;
        margin-bottom: 1rem;
    }
    & small {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 29px;
        text-align: center;
        color: #396eb0;
        margin-bottom: 4.5rem;
    }

    @media (max-width: 500px) {
        & small {
            margin-bottom: 3rem;
            font-size: 1rem;
            line-height: 19px;
        }

        & h5 {
            font-size: 1.75rem;
            line-height: 34px;
        }
    }
    @media (max-width: 333px) {
        font-size: 1.5rem;
    }
`;

const StudyingInUSCanadaCardWrapperStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2.25rem;
    width: 100%;
    @media (max-width: 580px) {
        & h5 {
            font-size: 2.5rem;
        }
    }
`;

const StudyingInUSCanadaCardsStyled = styled.div`
    background: #ffffff;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    min-height: 25.75rem;
    max-height: 25.75rem;
    max-width: 24rem;
    justify-content: space-between;
    .studying_in_US_canada_card_read_more {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 19px;
        color: #fc997c;
        text-align: center;
        cursor: pointer;
    }
    .studying_in_US_canada_card_image_wrapper {
        border-radius: 8px;
        overflow: hidden;
        min-height: 13.5rem;
        max-height: 13.5rem;
        min-width: 22.5rem;
        max-width: 22.5rem;
        img {
            min-height: 13.5rem;
            max-height: 13.5rem;
            min-width: 22.5rem;
            max-width: 22.5rem;
            object-fit: cover;
        }
    }
    .studying_in_US_canada_card_info {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        line-height: 19px;
        color: #396eb0;
        max-width: fit-content;
        overflow: hidden;
        text-overflow: clip;
    }
    .studying_in_US_canada_card_heading {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 19px;
        color: #113a68;
        max-width: fit-content;
        margin: 10px 0px;
    }
    @media (max-width: 500px) {
        min-width: 21rem;
        max-width: 21rem;

        .studying_in_US_canada_card_heading {
            padding-left: 0rem;
        }
        .studying_in_US_canada_card_info {
            padding-left: 0rem;
            font-size: 0.875rem;
            line-height: 17px;
            margin-bottom: 0.875rem;
        }
        .studying_in_US_canada_card_read_more {
            font-size: 0.875rem;
            line-height: 17px;
        }
        .studying_in_US_canada_card_image_wrapper {
            height: 11.25rem;
            min-width: 20rem;
            max-width: 20rem;
        }
    }
    @media (max-width: 400px) {
        .studying_in_US_canada_cards {
            min-width: 100%;
            max-width: 100%;
            min-height: 100%;
            max-height: 100%;
        }
        .studying_in_US_canada_card_image_wrapper {
            height: 11.25rem;
            min-width: 100%;
            max-width: 100%;
            margin-bottom: 0.75rem;
        }
        .studying_in_US_canada_card_info {
            max-width: 100%;
        }
        .studying_in_US_canada_card_heading {
            max-width: 100%;
            margin-bottom: 0.5rem;
        }
    }
    @media (max-width: 333px) {
        .studying_in_US_canada_card_heading {
            font-size: 0.85rem;
        }
        .studying_in_US_canada_card_info {
            font-size: 0.75rem;
        }
        .studying_in_US_canada_card_read_more {
            font-size: 0.75rem;
        }
    }
`;
export default StudyingInUSCanada;
