import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
const VideoPopup = ({ link = "", name = "", setShowVideo }) => {
	return (
		<VideoPopupStyled className="show">
			<div className="modal_container">
				<div className="details_close_button_wrapper">
					<p className="modal_video_details"> {name}</p>
					<FontAwesomeIcon
						icon={faTimes}
						id="close"
						onClick={() => setShowVideo(false)}
					/>
				</div>
				<div className="iframe_container">
					<iframe
						width={560}
						height={315}
						src={link}
						// src="https://www.youtube.com/embed/DbQFF57ho3g?rel=0"
						//src="https://player.vimeo.com/video/749622498?h=fbf957aff6&color=fff&title=0&byline=0&portrait=0"
						// src="https://res.cloudinary.com/ddzvvtcnt/video/upload/v1662194767/Strapi_AWS_S3_Upload_Provider_How_to_Install_a_custom_file_upload_provider_with_Strapi_0a0e970ed6.mp4"
						title="YouTube video player"
						frameBorder={0}
						allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen=""
					/>
				</div>
			</div>
		</VideoPopupStyled>
	);
};
const VideoPopupStyled = styled.div`
	display: flex !important;

	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	place-items: center;

	background: rgba(0, 0, 0, 0.411);
	z-index: 999;
	.modal.show {
		display: grid;
	}
	.modal_container {
		width: 95%;
		max-width: 800px;
		padding: 1rem;
		position: relative;
		background-color: white;
		border-radius: 8px;
	}
	.modal_container #close {
		font-size: 1.75rem;
		float: right;
		color: black;
		cursor: pointer;
	}
	.iframe_container {
		position: relative;
		overflow: hidden;
		width: 100%;
		padding-top: 56.25%;
	}
	.iframe_container iframe {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
	}
	.details_close_button_wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 0.5rem;
	}
`;
export default VideoPopup;
