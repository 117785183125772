import React from "react"
import BestCanadaPRconsultantStyled from "./styles"
import PrivacyPolicyLink from "../marketingComponent/privacy-policy-link"

const Index = () => {

    const chatNowHandler = () => {
        const chat = document.getElementsByClassName('cc-m1n3')
        chat[0].click()
    }

    return (
        <BestCanadaPRconsultantStyled>
          
                <div className="hero__section">
                <div className="hero__heading">
                    <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/ca7a73c17fbed4d81e139537e743a2fe.png" alt="" />
                </div>
                <div className="hero__wrapper">
                    <div className="hero__left">
                    <p>
                        <span className="white">Inderpreet got an</span>
                        <path d="M16 8C16 10.1217 15.1571 12.1566 13.6569 13.6569C12.1566 15.1571 10.1217 16 8 16C5.87827 16 3.84344 15.1571 2.34315 13.6569C0.842855 12.1566 0 10.1217 0 8C0 5.87827 0.842855 3.84344 2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8V8ZM12.03 4.97C11.9586 4.89882 11.8735 4.84277 11.7799 4.80522C11.6863 4.76766 11.5861 4.74936 11.4853 4.75141C11.3845 4.75347 11.2851 4.77583 11.1932 4.81717C11.1012 4.85851 11.0185 4.91797 10.95 4.992L7.477 9.417L5.384 7.323C5.24182 7.19052 5.05378 7.1184 4.85948 7.12183C4.66518 7.12525 4.47979 7.20397 4.34238 7.34138C4.20497 7.47879 4.12625 7.66418 4.12283 7.85848C4.1194 8.05278 4.19152 8.24083 4.324 8.383L6.97 11.03C7.04128 11.1012 7.12616 11.1572 7.21958 11.1949C7.313 11.2325 7.41305 11.2509 7.51375 11.2491C7.61444 11.2472 7.71374 11.2251 7.8057 11.184C7.89766 11.1429 7.9804 11.0837 8.049 11.01L12.041 6.02C12.1771 5.8785 12.2523 5.68928 12.2504 5.49296C12.2485 5.29664 12.1698 5.10888 12.031 4.97H12.03Z" fill="url(#paint0_linear_1774_16254)">
                        <defs>
                            <linearGradient id="paint0_linear_1774_16254" x1={8} y1={0} x2={8} y2={16} gradientunits="userSpaceOnUse">
                            <stop stopColor="#4DCE62" stopOpacity="0.74">
                                <stop offset={1} stopColor="#4DCE62">
                                </stop></stop></linearGradient>
                        </defs>
                        <br />Ontario PNP<br /> in October
                        </path></p>
                    </div>
                    <div className="hero__right">
                    <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/521e4cb9174e49d107da0344fab3d3cf.png" alt="" />
                    </div>
                </div>
                </div>
                <div className="information">
                <div className="information__wrapper">
                    <p>3 things<span> Inderpreet did to Crack Canada  PR </span></p>
                    <div className="cards">
                    <div className="card">
                        <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/2600253ae47b12cc8d85e930d3f6fdbf.png" alt="" />
                        <span>NOC tracking</span>
                    </div>
                    <div className="card">
                        <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/df6848a000fb143209f5452c29568a67.png" alt="" />
                        <span>Timely application</span>
                    </div>
                    <div className="card">
                        <img src="https://s3.us-east-2.wasabisys.com/ufaber-lms/67d74e08517ac40ef83da49feb01e72b.png" alt="" />
                        <span>Hired 24x7 Ninja consultant</span>
                    </div>
                    </div>
                </div>
                </div>
                <div className="contact">
                <div className="contact__wrapper">
                    <div className="contact__left">
                    <p className="contact__left_content">
                        <span className="green">Let us track your Ontario profile 24X7</span>
                        <span className="black-1">Reach out and evaluate your</span>
                        <span className="black-2"> eligibility for Ontario</span>
                    </p>
                    </div>
                    <div className="contact__right">
                    <a className="whatsapp" href="https://api.whatsapp.com/send?phone=7977750508">
                        <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.5508 2.91006C16.634 1.98399 15.542 1.24973 14.3384 0.750111C13.1349 0.250494 11.8439 -0.00448012 10.5408 5.95696e-05C5.08078 5.95696e-05 0.630781 4.45006 0.630781 9.91006C0.630781 11.6601 1.09078 13.3601 1.95078 14.8601L0.550781 20.0001L5.80078 18.6201C7.25078 19.4101 8.88078 19.8301 10.5408 19.8301C16.0008 19.8301 20.4508 15.3801 20.4508 9.92006C20.4508 7.27006 19.4208 4.78006 17.5508 2.91006ZM10.5408 18.1501C9.06078 18.1501 7.61078 17.7501 6.34078 17.0001L6.04078 16.8201L2.92078 17.6401L3.75078 14.6001L3.55078 14.2901C2.72853 12.977 2.29192 11.4593 2.29078 9.91006C2.29078 5.37006 5.99078 1.67006 10.5308 1.67006C12.7308 1.67006 14.8008 2.53006 16.3508 4.09006C17.1183 4.85402 17.7265 5.76272 18.1402 6.76348C18.5539 7.76425 18.7648 8.83717 18.7608 9.92006C18.7808 14.4601 15.0808 18.1501 10.5408 18.1501ZM15.0608 11.9901C14.8108 11.8701 13.5908 11.2701 13.3708 11.1801C13.1408 11.1001 12.9808 11.0601 12.8108 11.3001C12.6408 11.5501 12.1708 12.1101 12.0308 12.2701C11.8908 12.4401 11.7408 12.4601 11.4908 12.3301C11.2408 12.2101 10.4408 11.9401 9.50078 11.1001C8.76078 10.4401 8.27078 9.63006 8.12078 9.38006C7.98078 9.13006 8.10078 9.00006 8.23078 8.87006C8.34078 8.76006 8.48078 8.58006 8.60078 8.44006C8.72078 8.30006 8.77078 8.19006 8.85078 8.03006C8.93078 7.86006 8.89078 7.72006 8.83078 7.60006C8.77078 7.48006 8.27078 6.26006 8.07078 5.76006C7.87078 5.28006 7.66078 5.34006 7.51078 5.33006H7.03078C6.86078 5.33006 6.60078 5.39006 6.37078 5.64006C6.15078 5.89006 5.51078 6.49006 5.51078 7.71006C5.51078 8.93006 6.40078 10.1101 6.52078 10.2701C6.64078 10.4401 8.27078 12.9401 10.7508 14.0101C11.3408 14.2701 11.8008 14.4201 12.1608 14.5301C12.7508 14.7201 13.2908 14.6901 13.7208 14.6301C14.2008 14.5601 15.1908 14.0301 15.3908 13.4501C15.6008 12.8701 15.6008 12.3801 15.5308 12.2701C15.4608 12.1601 15.3108 12.1101 15.0608 11.9901Z" fill="white" /></svg>
                        WhatsApp us on 7977750508
                    </a>
                    <button href id="chat-now" onClick={chatNowHandler}>
                        <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.5 20V2C20.5 1.45 20.304 0.979 19.912 0.587C19.5207 0.195667 19.05 0 18.5 0H2.5C1.95 0 1.479 0.195667 1.087 0.587C0.695667 0.979 0.5 1.45 0.5 2V14C0.5 14.55 0.695667 15.021 1.087 15.413C1.479 15.8043 1.95 16 2.5 16H16.5L20.5 20ZM18.5 15.175L17.325 14H2.5V2H18.5V15.175ZM18.5 2V15.175V2Z" fill="#454A44" /></svg>
                        Chat Now
                    </button>
                    </div>
                </div>
                </div>
                <div className="footer">
                <div className="footer__wrapper">
                    <img className="footer__logo" src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/7ab531ccc268e354d41199a89b102828.svg" alt="" />
                    <img className="footer__logo__mb" src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/e43c58a3a272237b80ae552093a6a458.16714348399051911" alt="" />
                    <span className="count">3,000</span>
                    <span className="footer__para">stories like Inderpreet</span>
                    <div className="img__wrapper">
                    <div className="individual-img-wrapper">
                        <img src="https://s3.us-east-1.wasabisys.com/lms-ufaber/Group%2039940.webp" alt="" />
                    </div>
                    <div className="individual-img-wrapper">
                        <img src="https://s3.us-east-1.wasabisys.com/lms-ufaber/Group%2039941.webp" alt="" />
                    </div>
                    <div className="individual-img-wrapper">
                        <img src="https://s3.us-east-1.wasabisys.com/lms-ufaber/Group%2039939.webp" alt="" />
                    </div>
                    </div>
                    <a href="https://abroadninja.in/program-result/" className="btn-view-all">
                    <svg width={14} height={19} viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.625 2.56836H10.75V1.94336C10.75 1.61184 10.6183 1.2939 10.3839 1.05948C10.1495 0.825055 9.83152 0.693359 9.5 0.693359H4.5C4.16848 0.693359 3.85054 0.825055 3.61612 1.05948C3.3817 1.2939 3.25 1.61184 3.25 1.94336V2.56836H1.375C1.04348 2.56836 0.725537 2.70006 0.491116 2.93448C0.256696 3.1689 0.125 3.48684 0.125 3.81836V16.9434C0.125 17.2749 0.256696 17.5928 0.491116 17.8272C0.725537 18.0617 1.04348 18.1934 1.375 18.1934H12.625C12.9565 18.1934 13.2745 18.0617 13.5089 17.8272C13.7433 17.5928 13.875 17.2749 13.875 16.9434V3.81836C13.875 3.48684 13.7433 3.1689 13.5089 2.93448C13.2745 2.70006 12.9565 2.56836 12.625 2.56836ZM4.5 1.94336H9.5V4.44336H4.5V1.94336ZM12.625 16.9434H1.375V3.81836H3.25V5.69336H10.75V3.81836H12.625V16.9434Z" fill="#454A44" />
                    </svg>
                    View all
                    </a>
                </div>
                </div>
                <PrivacyPolicyLink/>
        </BestCanadaPRconsultantStyled>
    )
}

export default Index