import styled from 'styled-components';

const BestCanadaPRconsultantStyled = styled.section`

    font-family: 'Inter';
    margin-top: 0px;

    @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
    .hero__section {
        padding-bottom: 104px;
    }
    .hero__section .hero__heading {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 60px;
        background-color: white;
    }
    .hero__heading img {
        margin-top: 15px;
        width: 164px;
        height: 26px;
    }
    .hero__wrapper {
        background: linear-gradient(180deg, rgba(17, 58, 104, 0.83) 0%, #113A68 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 68px;
        flex-wrap: wrap;
        width: 100%;
        top: 130px;
        margin-bottom: 13px;
        padding: 40px 0;
    }
    .hero__section > div > img {
        filter: drop-shadow(0px 18px 13px rgba(0, 0, 0, 0.25));
    }
    .hero__left {
        font-weight: 700;
        font-size: 48px;
        line-height: 51.84px;
        color: #68cd79;
        margin-bottom: 30px;
        max-width: 329px;
        width: 100%;
        margin-bottom: 17%;
    }
    .hero__left .white {
        font-size: 35px;
        color: #ffffff;
        font-weight: 800;
    }
    .hero__name {
        font-weight: 600;
        font-size: 16px;
        color: #454a44;
    }
    .hero__right {
        height: 474px;
        width: 346px;
    }
    .hero__right img {
        width: 90%;
        height: 100%;
        object-fit: contain;
        margin: 0 5%;
    }
    .information {
        display: flex;
        justify-content: center;
        padding: 4.1875rem 1rem;
    }
    .information__wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        max-width: 1120px;
        width: 100%;
        border: 2px solid rgba(106, 110, 105, 0.1);
        box-shadow: 0px 18px 35px rgba(0, 0, 0, 0.08);
        border-radius: 24px;
        padding: 88px 0;
    }
    .information__wrapper > p {
        margin-bottom: 39px;
        font-weight: 900;
        font-size: 32px;
        line-height: 39px;
        text-align: center;
        color: #396EB0;
    }
    .cards {
        display: flex;
        column-gap: 24px;
    }
    .card {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 291px;
        background: rgba(106, 110, 105, 0.1);
        border-radius: 16px;
        text-align: center;
        padding: 24px 0;
    }
    .card > span {
        max-width: 220px;
        margin-top: 1rem;
    }​
    .contact {
        display: flex;
        justify-content: center;
        padding: 0rem 1rem;
    }
    .contact__wrapper {
        display: flex;
        justify-content: center;
        column-gap: 102px;
        align-items: center;
        padding-top: 52px;
        padding-bottom: 104px;
    }
    .contact__right {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .contact__left .contact__left_content {
        display: flex;
        flex-direction: column;
    }
    .contact__left .green {
        max-width: 420px;
        width: 100%;
        font-weight: 700;
        font-size: 36px;
        color: #68cd79;
        line-height: 38.88px;
    }
    .contact__left > p > span {
        font-size: 16px;
        font-weight: 600;
        color: #454a44;
        line-height: 30.88px;
    }
    .contact__right > button {
        cursor: pointer;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 1rem;
        width: 328px;
        box-shadow: 0px 3.68539px 6.63371px rgba(40, 26, 69, 0.15),
            0px 0.737079px 1.47416px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        padding: 15px 0;
        color: #454a44;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
    }
    .contact__right > a {
        cursor: pointer;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 1rem;
        width: 328px;
        box-shadow: 0px 3.68539px 6.63371px rgba(40, 26, 69, 0.15),
            0px 0.737079px 1.47416px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        padding: 15px 0;
        color: #454a44;
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
    }
    .whatsapp {
        background-color: #68cd79;
    }
    .footer {
        background: linear-gradient(180deg, rgba(17, 58, 104, 0.83) 0%, #113A68 100%);
        padding-top: 56px;
        padding-bottom: 72px;
    }
    .footer__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .footer__logo__mb {
        display: none;
    }
    .footer__logo {
        margin-bottom: 32px;
    }   
    .count {
        font-weight: 600;
        font-size: 96px;
        line-height: 116px;
        text-align: center;
        text-transform: capitalize;
        color: #ffffff;
    }
    .footer__para {
        font-weight: 600;
        font-size: 22.755px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.26em;
        text-transform: uppercase;
        color: #68cd79;
        margin-bottom: 32px;
    }
    .img__wrapper {
        display: flex;
        justify-content: center;
        column-gap: 32px;
        row-gap: 20px;
        flex-wrap: wrap;
        width: 100%;
        margin-bottom: 64px;
    }
    .img__wrapper img {
        filter: drop-shadow(0px 18px 13px rgba(0, 0, 0, 0.25));
    }
    .btn-view-all {
        background: #68cd79;
        border: 1px solid #635557;
        box-shadow: 0px 5px 9px rgba(26, 69, 36, 0.15),
            0px 1px 2px rgba(7, 46, 16, 0.05);
        border-radius: 8px;
        padding: 21.5px 0;
        max-width: 339px;
        width: 100%;
        justify-content: center;
        display: flex;
        column-gap: 1rem;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #454a44;
        align-items: center;
    }
    .individual-img-wrapper {
        height: 480px;
        width: 350px;
    }
    .individual-img-wrapper img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    @media (max-width: 1000px) {
        .contact__wrapper {
            margin: 0 1rem 0 1rem;
            flex-wrap: wrap;
        }
        .cards {
            margin: 0 21px 0 21px;
            flex-wrap: wrap;
            justify-content: center;
            row-gap: 24px;
        }
    }
    @media (max-width: 800px) {
        .hero__section {
        padding: 0px 0 56px 0;
        top: 40px;
        }
        .hero__section {
        gap: 40px;
        }
        .contact__wrapper {
        column-gap: 50px;
        row-gap: 28px;
        }
    }
    @media (max-width: 500px) {
    .hero__wrapper {
        top: 40px;
        gap: 0.875rem;
    }
        .hero__section {
            column-gap: 0px;
            padding: 0rem 0rem 3.5rem;
        }
        .hero__left {
            font-size: 32px;
            line-height: 32.4px;
            max-width: 360px;
            margin: 0 5% 20px;
        }
        .hero__name {
            font-weight: 600;
            font-size: 16px;
            line-height: 17.28px;
        }
        .hero__left > p > img {
            width: 145.08px;
        }
        .information {
        padding: 0rem 1rem 3.5rem;
        }
        .contact {
            padding:0rem 1rem 4.125rem;
        }
        .contact__wrapper {
            column-gap: 0px;
            margin: 0rem;
            width: 100%;
            justify-content: flex-start;
            padding: 0rem;
        }
        .contact__right {
        width: 100%;
        }
        .contact__right > button {
            width: 100%;
            cursor: pointer;
        }
        .contact__right > a {
            width: 100%;
            cursor: pointer;
        }
        .information__wrapper {
            gap: 44px;
            border: 2px solid rgba(106, 110, 105, 0.1);
            box-shadow: 0px 18px 13px rgba(0, 0, 0, 0.08);
            border-radius: 16px;
            padding: 2rem 1rem 2.5rem;
        }
        .information__wrapper > p {
            font-size: 26px;
            line-height: 43.57px;
            margin-bottom: 0rem;
        }
        .card {
            background: rgba(106, 110, 105, 0.1);
            border-radius: 8px;
            width: 100%;
        }
        .cards {
        margin: 0rem;
        width: 100%;
        }
        .card > span {
            font-size: 16px;
            line-height: 19px;
            max-width: 100%;
            margin-top: 12px;
        }
        .card > img {
            max-width: 40px;
            width: 100%;
        }
        .footer__logo__mb {
            display: block;
            margin-bottom: 32px;
        }
        .footer__logo {
            display: none;
        }
        .count {
            font-size: 93.86px;
            line-height: 113.6px;
        }
        .footer__para {
            margin-bottom: 56px;
        }
        .img__wrapper {
            margin-bottom: 40px;
        }
        .btn-view-all {
            background: #68cd79;
            box-shadow: 0px 5px 9px rgba(26, 69, 36, 0.15),
            0px 1px 2px rgba(7, 46, 16, 0.05);
            border-radius: 12px;
            padding: 12px 0;
            width: 100%;
            column-gap: 11.12px;
            line-height: 24px;
        }
        .img__wrapper > img {
            filter: drop-shadow(0px 18px 13px rgba(0, 0, 0, 0.25));
            width: 100%;
        }
        .footer__wrapper {
            margin: 0 1rem;
        }
        .individual-img-wrapper {
            width: 100%;
            height: auto;
        }
        .hero__right {
            height: auto;
            width: 100%;
        }
    }
`;

export default BestCanadaPRconsultantStyled;