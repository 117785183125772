import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import useAPI from '../../hooks/study-abroad/university-search/useAPI';
import Layout from '../../components/layout.component';
import './module.css';
const ViewDetails = (props) => {

    const params = new URLSearchParams(props.location.search);
    const url = props?.location?.state?.prevUrl;
    const parameter1 = params.get('query');
    const { getScholarships } = useAPI();
    const [scholarship, setscholarship] = useState([]);
    useEffect(() => {
        const asyncFn = async () => {
            const data = await getScholarships(
                `https://fapi.ufaber.com/study-abroad/individual-scholarship/${parameter1.replaceAll(
                    /%26/g,
                    '&'
                )}`
            );
            setscholarship(data[0]);
        };
        asyncFn();
    }, [parameter1]);
    const date = new Date(scholarship?.deadline?.date).toLocaleDateString();
    return (
        <Layout>
            <section className="details-wrapper">
                {url ? (
                    <Link to={`${url}`}>
                        <FontAwesomeIcon icon={faArrowLeft} /> Back
                    </Link>
                ) : (
                    <Link to='/study-abroad-scholarship/scholarships/'><FontAwesomeIcon icon={faArrowLeft} /> Back</Link>
                )}

                <h1>{scholarship?.scholarshipName}</h1>
                <div className="view-details-flex">
                    {scholarship?.isCollegeSpecific ? (
                        <span>Offered By: {scholarship?.college?.name}</span>
                    ) : (
                        <span>
                            Offered By : {scholarship?.organizationName}
                        </span>
                    )}
                    {scholarship?.isCollegeSpecific ? (<Link
                        className="btn-checkeligibilty"
                        to={`https://form.typeform.com/to/OlG7xw8Z/?utm_source=${scholarship?.college?.name}`}
                    >
                        Check Eligibility
                    </Link>) : (<Link
                        className="btn-checkeligibilty"
                        to={`https://form.typeform.com/to/OlG7xw8Z/?utm_source=${scholarship?.scholarshipName}`}
                    >
                        Check Eligibility
                    </Link>)}
                </div>
                <div className="view-details-description">
                    <h2>Overview</h2>
                    {/* <h3>Description</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam
            volutpat, viverra sed interdum euismod. Vehicula non diam auctor
            sagittis pharetra montes. Urna, non quis condimentum eleifend ipsum
            ipsum. Tincidunt vitae non varius sit. Felis aliquam fringilla in
            facilisi facilisis scelerisque congue lorem auctor.
          </p>
          <p>
            Tellus sed eleifend etiam accumsan, massa aliquet id dictumst
            pellentesque. Nunc, facilisi scelerisque sit egestas pretium gravida
            ipsum. In integer risus a lectus pellentesque commodo. Tellus morbi
            dignissim commodo tristique sollicitudin adipiscing. Id feugiat
            suspendisse adipiscing odio. Volutpat egestas semper mauris mauris a
            ultrices neque cursus. A aliquet ipsum tempus enim vivamus lobortis
            augue. Pharetra fames dolor maecenas imperdiet sapien, at nunc,
            nullam. Nunc duis id egestas accumsan. Arcu ultrices vestibulum
            libero, lorem nunc, aliquet sed duis nulla.
          </p> */}
                </div>
                <div id="EduStructTableOneWrapper">
                    <table className="EducStructTableOne">
                        <tbody>
                            <tr>
                                <td>Course Level</td>
                                <td>
                                    {scholarship?.eligibility?.educationLevel.map(
                                        (data) => (
                                            <span>{data} </span>
                                        )
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>Type Of Scholarship</td>
                                <td>
                                    {scholarship?.scholarshipType?.map(
                                        (data) => (
                                            <span>{data}</span>
                                        )
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>Country</td>
                                {scholarship?.destinationCountry?.hasOwnProperty(
                                    'allCountry'
                                ) &&
                                    scholarship?.destinationCountry.allCountry
                                        .length !== 0 ? (
                                    <td>{scholarship?.destinationCountry?.allCountry}</td>
                                ) : scholarship?.destinationCountry?.hasOwnProperty(
                                    'country'
                                ) &&
                                    scholarship?.destinationCountry?.country !==
                                    0 ? (
                                    <td>
                                        {scholarship?.destinationCountry.country.join(',')}
                                    </td>
                                ) : scholarship?.destinationCountry?.hasOwnProperty(
                                    'otherCountry'
                                ) &&
                                    scholarship?.destinationCountry
                                        ?.otherCountry !== 0 ? (
                                    <td>
                                        {scholarship?.destinationCountry.otherCountry.join(',')}
                                    </td>
                                ) : (
                                    <td>
                                        {scholarship?.destinationCountry?.join(',')}
                                    </td>
                                )}
                            </tr>
                            <tr>
                                <td>Gender</td>
                                <td>{scholarship?.eligibility?.gender}</td>
                            </tr>
                            <tr>
                                <td>Deadline</td>
                                {scholarship?.deadline?.status ===
                                    'Not Available' ? (
                                    <td>Always Open</td>
                                ) : (
                                    <td>{date}</td>
                                )}
                            </tr>
                            <tr>
                                <td>Amount</td>
                                <td>
                                    Rs.{' '}
                                    {scholarship?.amount?.toLocaleString(
                                        'en-In'
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </Layout>
    );
};

export default ViewDetails;
