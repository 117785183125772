import React, { useState, useEffect } from 'react';
import './style.css';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ScholarshipCard from './PopularscholarshipCard';
import useAPI from '../../hooks/study-abroad/university-search/useAPI';
import Hero from './hero';
import { Link } from 'gatsby';
import styled from 'styled-components';

const PopularScholarship = () => {
  const [usaScholarships, setusaScholarships] = useState([]);
  const [canadaScholarships, setCanadaScholarships] = useState([]);
  const { getScholarships } = useAPI();
  const page = 1;
  useEffect(() => {
    getScholarships(
      `https://fapi.ufaber.com/study-abroad/all-scholarships?page=1&f_country=United%20States`
    ).then((response) => setusaScholarships(response.data));
    getScholarships(
      `https://fapi.ufaber.com/study-abroad/all-scholarships?page=1&f_country=Canada`
    ).then((response) => setCanadaScholarships(response.data));
  }, [page]);

  return (
    <Section>
      <Hero />
      <section className="popularScholarship__section1">
        <h2>
          Popular Scholarship in <span>USA</span>
        </h2>
        <div className="popularScholarship__wrapper">
          {usaScholarships?.slice(0, 5).map((scholarship) => (
            <ScholarshipCard
              key={scholarship._id}
              scholarship={scholarship}
            />
          ))}
          <div className="popularScholarship__view-all-card">
            <div className="viewAllCard__details">
              <span>100+</span>
              <small>Scholarships</small>
            </div>
            <Link
              to="/study-abroad-scholarship/scholarship-search/?country=USA"
              state={{
                filter: 'f_country',
                value: 'United States',
              }}
              className="btn-view-all"
            >
              View All
            </Link>
          </div>
        </div>
      </section>
      <section className="popularScholarship__section2">
        <h3>
          Popular Scholarship in <span>Canada</span>
        </h3>
        <div className="popularScholarship__wrapper">
          {canadaScholarships?.slice(0, 5)?.map((scholarship) => (
            <ScholarshipCard
              key={scholarship._id}
              scholarship={scholarship}
            />
          ))}
          <div className="popularScholarship__view-all-card">
            <div className="viewAllCard__details">
              <span>100+</span>
              <small>Scholarships</small>
            </div>
            <Link
              to="/study-abroad-scholarship/scholarship-search//country=Canada"
              state={{ filter: 'f_country', value: 'Canada' }}
              className="btn-view-all"
            >
              View All
            </Link>
          </div>
        </div>
      </section>
      <section className="whatAreYouGoingToStudy__section">
        <h4>
          What are you <span>going to study?</span>
        </h4>
        <div className="whatAreYouGoingToStudy__card-wrapper">
          <div className="whatAreYouGoingToStudy__individual-card bachelors__card">
            <span className="card__program-title">Bachelors</span>
            <div className="card__scholarship-amount-wrapper">
              <div className="card__no-of-scholarships">
                <small className="card__n-f-s-text">
                  Total no. of scholarships
                </small>
                <small className="card__n-f-s-detail">
                  200+
                </small>
              </div>
              <div className="card__total-amount">
                <small className="card__total-amount-text">
                  Total amount
                </small>
                <small className="card__total-amount-detail">
                  ₹ 2 Crore
                </small>
              </div>
            </div>
            <Link
              to={`/study-abroad-scholarship/scholarship-search/?course=Bachelors`}
              state={{
                filter: 'f_course_level',
                value: 'Bachelors',
              }}
              className="card__ViewLink"
            >
              <FontAwesomeIcon icon={faAngleRight} />
            </Link>
          </div>
          <div className="whatAreYouGoingToStudy__individual-card masters__card">
            <span className="card__program-title">Masters</span>
            <div className="card__scholarship-amount-wrapper">
              <div className="card__no-of-scholarships">
                <small className="card__n-f-s-text">
                  Total no. of scholarships
                </small>
                <small className="card__n-f-s-detail">
                  200+
                </small>
              </div>
              <div className="card__total-amount">
                <small className="card__total-amount-text">
                  Total amount
                </small>
                <small className="card__total-amount-detail">
                  ₹ 2 Crore
                </small>
              </div>
            </div>
            <Link
              to="/study-abroad-scholarship/scholarship-search/?course=Masters"
              state={{
                filter: 'f_course_level',
                value: 'Masters',
              }}
              className="card__ViewLink"
            >
              <FontAwesomeIcon icon={faAngleRight} />
            </Link>
          </div>
          <div className="whatAreYouGoingToStudy__individual-card phd__card">
            <span className="card__program-title">PHD</span>
            <div className="card__scholarship-amount-wrapper">
              <div className="card__no-of-scholarships">
                <small className="card__n-f-s-text">
                  Total no. of scholarships
                </small>
                <small className="card__n-f-s-detail">
                  200+
                </small>
              </div>
              <div className="card__total-amount">
                <small className="card__total-amount-text">
                  Total amount
                </small>
                <small className="card__total-amount-detail">
                  ₹ 2 Crore
                </small>
              </div>
            </div>
            <Link
              to="/study-abroad-scholarship/scholarship-search/?course=PHD"
              state={{ filter: 'f_course_level', value: 'PhD' }}
              className="card__ViewLink"
            >
              <FontAwesomeIcon icon={faAngleRight} />
            </Link>
          </div>
        </div>
      </section>
    </Section>
  );
};
const Section = styled.section`
}
`;

export default PopularScholarship;
