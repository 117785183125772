import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import { graphql, useStaticQuery, Link } from "gatsby";
const DoYourOwnResearch = () => {
	const data = useStaticQuery(query);
	const {
		allStrapiSettleResearch: { nodes: abroadResearch },
	} = data;
	const { heading, subheading, tool_subheading, search_evaluation } =
		abroadResearch[0];

	return (
		<DoYourOwnResearchStyled>
			<DoYourOwnResearchWrapperStyled>
				<span
					key={`heading`}
					id="do_your_own_research_heading"
					dangerouslySetInnerHTML={{ __html: heading }}
				/>
				<span
					key={`subheading`}
					id="do_your_own_research_subheading"
					dangerouslySetInnerHTML={{ __html: subheading }}
				/>

				<div id="university_profile_tools_wrapper">
					<p
						key={`tool_subheading`}
						dangerouslySetInnerHTML={{ __html: tool_subheading }}
					/>
				</div>
				<SearchWrapperStyled>
					{search_evaluation.map((data, index) => {
						return (
							<UniversitySearchProfileEvaluation key={data.id}>
								<div className="university_search_profile_evaluation_text_wrapper">
									<img src={data?.profile_img?.url} alt="" />
									<span className="university_search_profile_evaluation_text">
										{data.profile_header}
									</span>
								</div>
								<Link to={data.redirect_to}>
									<FontAwesomeIcon
										icon={faCircleChevronRight}
										size="1x"
										className="fa-circle-chevron-right"
									/>
								</Link>
							</UniversitySearchProfileEvaluation>
						);
					})}
				</SearchWrapperStyled>
			</DoYourOwnResearchWrapperStyled>
		</DoYourOwnResearchStyled>
	);
};

const DoYourOwnResearchStyled = styled.section`
	padding: 0rem 7.5rem;
	@media (max-width: 1300px) {
		padding: 0 3.5rem;
	}
	@media (max-width: 1200px) {
		padding: 0 1.5rem;
	}
	@media (max-width: 500px) {
		padding: 0.75rem;
	}
`;

const DoYourOwnResearchWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	width: 100%;
	#do_your_own_research_heading {
		margin-bottom: 1rem;
		font-weight: 900;
		font-size: 48px;
		line-height: 58px;
		text-align: center;
		color: #113a68;
	}
	#do_your_own_research_subheading {
		font-family: "Inter";
		font-size: 24px;
		line-height: 29px;
		text-align: center;
		color: #396eb0;
		margin-bottom: 2.5rem;
	}
	#university_profile_tools_wrapper {
		background: #fff0eb;
		border-radius: 24px;
		margin-bottom: 2.5rem;
		& p {
			font-weight: 500;
			font-size: 16px;
			line-height: 19px;
			text-align: center;
			color: #396eb0;
			padding: 1rem 6rem;
		}
	}

	#reports_articles_text {
		margin-top: 5rem;
		margin-bottom: 1.5rem;
		background: #fff0eb;
		border-radius: 28px;
		width: 100%;
		padding: 1rem 0;
		text-align: center;
	}
	#reports_articles_text small {
		font-family: "Inter";
		font-style: normal;
		font-weight: 500;
		font-size: 1rem;
		line-height: 19px;
		text-align: center;
		color: #396eb0;
	}

	@media (max-width: 500px) {
		#do_your_own_research_heading {
			font-size: 1.75rem;
			line-height: 34px;
		}
		#university_profile_tools_wrapper {
			display: none;
		}
		#do_your_own_research_subheading {
			font-size: 1rem;
			line-height: 19px;
		}
		#reports_articles_text {
			display: none;
		}
	}

	@media (max-width: 354px) {
		#do_your_own_research_heading {
			font-size: 1.37rem;
		}
	}
`;

const SearchWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	gap: 3rem;
	width: 100%;
	@media (max-width: 500px) {
		background: #fff0eb;
		border-radius: 20px;
		padding: 0.5rem;
		gap: 1rem;
		margin-bottom: 1rem;
		justify-content: space-evenly;
	}
`;

const UniversitySearchProfileEvaluation = styled.div`
	display: flex;
	align-items: center;
	gap: 4rem;
	justify-content: center;
	background: #ffffff;
	box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
	padding: 1.25rem 1rem;
	min-width: 20rem;
	max-width: 20rem;
	.university_search_profile_evaluation_text {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 1.5rem;
		line-height: 29px;
		color: #113a68;
	}
	.university_search_profile_evaluation_text_wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 1rem;
	}
	.fa-circle-chevron-right {
		color: #fc997c;
		font-size: 2.5rem;
		cursor: pointer;
		height: 2.3rem;
	}
	@media (max-width: 500px) {
		flex-direction: column;
		gap: 1rem;
		min-width: 8.8rem;
		max-width: 8.8rem;
		.university_search_profile_evaluation_text_wrapper img {
			display: none;
		}

		.university_search_profile_evaluation_text {
			font-size: 1rem;
			line-height: 19px;
		}
	}
	@media (max-width: 354px) {
		min-width: 100%;
		max-width: 100%;
	}
`;

export const query = graphql`
	{
		allStrapiSettleResearch {
			nodes {
				heading
				subheading
				tool_subheading
				search_evaluation {
					id
					profile_header
					redirect_to
					profile_img {
						url
						name
					}
				}
			}
		}
	}
`;
export default DoYourOwnResearch;
