import React, { useState, useEffect } from 'react';
import useAPI from '../../hooks/study-abroad/university-search/useAPI';
import Layout from '../../components/layout.component';
import ScholarshipFilter from '../../components/StudyAbroadScholarship/ScholarshipFilter';
import ScholarShipResults from '../../components/StudyAbroadScholarship/ScholarShipResults';
import './module.css';
import { Link } from 'gatsby';
import Loader from '../../components/commonComponent/loader';
import SortFilter from '../../components/StudyAbroadScholarship/SortFilter';
import SEO from "../../components/seo"

const ViewScholarships = (props) => {
    var receivedFilters = [];
    if (props.location.state) {
        receivedFilters = [
            {
                filter: props.location.state.filter,
                value: props.location.state.value,
            },
        ];
    } else {
        receivedFilters = [];
    }
    const [filters, setFilters] = useState(receivedFilters);
    const [sortFilters, setSortFilters] = useState('');
    const [url, setUrl] = useState('');
    const [totalPages, setTotalPages] = useState(0);
    const [scholarship, setScholarships] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [showFilter, setShowFilter] = useState(false);
    const [showSort, setshowSort] = useState(false);
    const { getScholarships } = useAPI();

    const countryOptions = [
        { value: 'United States', label: 'United States', filter: 'f_country' },
        {
            value: 'United Kingdom',
            label: 'United Kingdom',
            filter: 'f_country',
        },
        { value: 'Canada', label: 'Canada', filter: 'f_country' },
        { value: 'Germany', label: 'Germany', filter: 'f_country' },
        { value: 'New Zealand', label: 'New Zealand', filter: 'f_country' },
    ];
    const sortFilterOption = [
        {
            value: 'max_to_low',
            label: 'Price: Max To Low',
            filter: 'sort_filter',
        },
        {
            value: 'low_to_max',
            label: 'Price: Low To Max',
            filter: 'sort_filter',
        },
    ];
    const scholarshipTypeOptions = [
        {
            value: 'Merit based',
            label: 'Merit based',
            filter: 'f_scholarship_type',
        },
        {
            value: 'Need based',
            label: 'Need based',
            filter: 'f_scholarship_type',
        },
    ];
    const courseLevelOptions = [
        { value: 'Bachelors', label: 'Bachelors', filter: 'f_course_level' },
        { value: 'Masters', label: 'Masters', filter: 'f_course_level' },
        { value: 'PhD', label: 'PhD', filter: 'f_course_level' },
    ];
    useEffect(() => {
        const urlDomain = `https://fapi.ufaber.com/study-abroad/all-scholarships`;
        let query = '';
        if (filters) {
            filters.forEach((filter) => {
                if (query) {
                    query = query + `&${filter.filter}=${filter.value}`;
                } else {
                    query = query + `?${filter.filter}=${filter.value}`;
                }
            });
            setUrl(urlDomain + query);
        }
    }, [filters]);

    useEffect(() => {
        const asyncFn = async () => {
            const scholarship = await getScholarships(url);
            if (
                scholarship?.data?.length > 0 &&
                scholarship.pagination.length > 0
            ) {
                if (sortFilters.value === 'max_to_low') {
                    setScholarships(
                        scholarship.data.sort((a, b) => b.amount - a.amount)
                    );
                } else if (sortFilters.value === 'low_to_max') {
                    setScholarships(
                        scholarship.data.sort((a, b) => a.amount - b.amount)
                    );
                } else {
                    setScholarships(scholarship.data);
                }
                setTotalPages(scholarship.pagination[0]['total_pages']);
                setTotalItems(scholarship.pagination[0]['total_items']);
            } else {
                setScholarships([]);
                setTotalPages(0);
            }
        };
        asyncFn();
    }, [url, sortFilters]);

    const clearFiltersHandler = () => {
        setFilters([]);
        setSortFilters([]);
    };
    const filterChangeHandler = (filter) => {
        setFilters((prevValue) =>
            prevValue.filter((ftr) => ftr.filter !== filter.filter)
        );
        setFilters((prevValue) => [...prevValue, filter]);
    };
    const sortFilterHandler = (value) => {
        setSortFilters(value);
    };
    return (
        <Layout>
            <SEO
                title="Scholarships for Studying Abroad - Explore Your Options Now!" 
                description=" Explore study abroad scholarship search with Abroad Ninja. Discover various scholarship opportunities to support your international education journey. Use our search tool to unlock financial aid and make your dream of studying abroad a reality!"
                og_type="website"
                og_url="https://abroadninja.in/study-abroad-scholarship/scholarship-search/"
                og_title="Scholarships for Studying Abroad - Explore Your Options Now!"
                og_description=" Explore study abroad scholarship search with Abroad Ninja. Discover various scholarship opportunities to support your international education journey. Use our search tool to unlock financial aid and make your dream of studying abroad a reality!"
                og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
                twitter_card="summary_large_image"
                twitter_url="https://abroadninja.in/study-abroad-scholarship/scholarship-search/"
                twitter_title="Scholarships for Studying Abroad - Explore Your Options Now!"
                twitter_description=" Explore study abroad scholarship search with Abroad Ninja. Discover various scholarship opportunities to support your international education journey. Use our search tool to unlock financial aid and make your dream of studying abroad a reality!"
                twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
                robots="index, follow"
                canonical="https://abroadninja.in/study-abroad-scholarship/scholarship-search/"
            />
            <section>
                <div className="main-wrapper">
                    <div className="left-div">
                        <div className="filter-top">
                            {' '}
                            <p
                                onClick={() => {
                                    setShowFilter(!showFilter);
                                }}
                            >
                                Filters
                            </p>
                            <div onClick={clearFiltersHandler}>
                                <Link to="/study-abroad-scholarship/scholarship-search/">
                                    {' '}
                                    <span>Clear All</span>
                                </Link>
                            </div>
                        </div>
                        <div className="filters-div">
                            <p>Destination Country</p>
                            <ScholarshipFilter
                                onFilterChange={filterChangeHandler}
                                filterName="Select Country"
                                filterValue={
                                    filters &&
                                    filters.find(
                                        (ftr) => ftr.filter === 'f_country'
                                    ) &&
                                    filters.find(
                                        (ftr) => ftr.filter === 'f_country'
                                    )['value']
                                }
                                options={countryOptions}
                                filterSelected={
                                    filters &&
                                    filters.find(
                                        (ftr) => ftr.filter === 'f_country'
                                    ) !== undefined
                                }
                            />
                        </div>
                        <div className="filters-div">
                            <p>Scholarship Type</p>
                            <ScholarshipFilter
                                onFilterChange={filterChangeHandler}
                                filterName="Select Type"
                                filterValue={
                                    filters &&
                                    filters.find(
                                        (ftr) =>
                                            ftr.filter === 'f_scholarship_type'
                                    ) &&
                                    filters.find(
                                        (ftr) =>
                                            ftr.filter === 'f_scholarship_type'
                                    )['value']
                                }
                                options={scholarshipTypeOptions}
                                filterSelected={
                                    filters &&
                                    filters.find(
                                        (ftr) =>
                                            ftr.filter === 'f_scholarship_type'
                                    ) !== undefined
                                }
                            />
                        </div>
                        <div className="filters-div">
                            <p>Select Courses</p>
                            <ScholarshipFilter
                                onFilterChange={filterChangeHandler}
                                filterName="Select Type"
                                filterValue={
                                    filters &&
                                    filters.find(
                                        (ftr) => ftr.filter === 'f_course_level'
                                    ) &&
                                    filters.find(
                                        (ftr) => ftr.filter === 'f_course_level'
                                    )['value']
                                }
                                options={courseLevelOptions}
                                filterSelected={
                                    filters &&
                                    filters.find(
                                        (ftr) => ftr.filter === 'f_course_level'
                                    ) !== undefined
                                }
                            />
                        </div>
                    </div>
                    <div className="mobile-filter-section">
                        <div className="mobile-filter-container">
                            <div className="filter-top">
                                {' '}
                                <p
                                    onClick={() => {
                                        setShowFilter(!showFilter);
                                    }}
                                >
                                    Filters
                                </p>
                                <div onClick={clearFiltersHandler}>
                                    <Link to="/study-abroad-scholarship/scholarship-search">
                                        <span>Clear All</span>{' '}
                                    </Link>
                                </div>
                            </div>
                            {showFilter ? (
                                <div className="filters">
                                    <div className="filters-div">
                                        <p>Country</p>
                                        <ScholarshipFilter
                                            onFilterChange={filterChangeHandler}
                                            filterName="Country"
                                            filterValue={
                                                filters &&
                                                filters.find(
                                                    (ftr) =>
                                                        ftr.filter ===
                                                        'f_country'
                                                ) &&
                                                filters.find(
                                                    (ftr) =>
                                                        ftr.filter ===
                                                        'f_country'
                                                )['value']
                                            }
                                            options={countryOptions}
                                            filterSelected={
                                                filters &&
                                                filters.find(
                                                    (ftr) =>
                                                        ftr.filter ===
                                                        'f_country'
                                                ) !== undefined
                                            }
                                        />
                                    </div>
                                    <div className="filters-div">
                                        <p>Course Type</p>
                                        <ScholarshipFilter
                                            onFilterChange={filterChangeHandler}
                                            filterName="Scholarship Type"
                                            filterValue={
                                                filters &&
                                                filters.find(
                                                    (ftr) =>
                                                        ftr.filter ===
                                                        'f_scholarship_type'
                                                ) &&
                                                filters.find(
                                                    (ftr) =>
                                                        ftr.filter ===
                                                        'f_scholarship_type'
                                                )['value']
                                            }
                                            options={scholarshipTypeOptions}
                                            filterSelected={
                                                filters &&
                                                filters.find(
                                                    (ftr) =>
                                                        ftr.filter ===
                                                        'f_scholarship_type'
                                                ) !== undefined
                                            }
                                        />
                                    </div>
                                    <div className="filters-div">
                                        <p>Course Level</p>
                                        <ScholarshipFilter
                                            onFilterChange={filterChangeHandler}
                                            filterName="Course Level"
                                            filterValue={
                                                filters &&
                                                filters.find(
                                                    (ftr) =>
                                                        ftr.filter ===
                                                        'f_course_level'
                                                ) &&
                                                filters.find(
                                                    (ftr) =>
                                                        ftr.filter ===
                                                        'f_course_level'
                                                )['value']
                                            }
                                            options={courseLevelOptions}
                                            filterSelected={
                                                filters &&
                                                filters.find(
                                                    (ftr) =>
                                                        ftr.filter ===
                                                        'f_course_level'
                                                ) !== undefined
                                            }
                                        />
                                    </div>
                                    <div className="filter-top allscholarshipcard__btn-wrapper">
                                        <p
                                            className="btn-viewdetails "
                                            onClick={() =>
                                                setShowFilter(!showFilter)
                                            }
                                        >
                                            close
                                        </p>
                                        <p
                                            className="btn-checkeligibilty"
                                            onClick={() =>
                                                setShowFilter(!showFilter)
                                            }
                                        >
                                            Show result
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    </div>

                    <div className="right-div">
                        {scholarship.length === 0 ? (
                            <Loader />
                        ) : (
                            <>
                                <div className="filter-top">
                                    <p id="total-scholarships">
                                        {totalItems} Scholarships Found
                                    </p>
                                    {/* <Select
                              placeholder={'Sort By Amount'}
                              onChange={(event)=>{setSortFilters(event.value)}}
                              options={sortFilterOption} /> */}
                                    <SortFilter
                                        onFilterChange={sortFilterHandler}
                                        filterName="Sort By Amount"
                                        filterValue={sortFilters.label}
                                        options={sortFilterOption}
                                        filterSelected={
                                            sortFilters &&
                                            sortFilters.length !== 0
                                        }
                                    />
                                </div>
                                {filters.length === 0 ? (
                                    <ScholarShipResults
                                        url={url}
                                        scholarshipData={scholarship}
                                        totalPages={totalPages}
                                        sortFilters={sortFilters}
                                    />
                                ) : filters.length > 0 && scholarship ? (
                                    <ScholarShipResults
                                        url={url}
                                        scholarshipData={scholarship}
                                        totalPages={totalPages}
                                        sortFilters={sortFilters}
                                    />
                                ) : (
                                    ''
                                )}
                            </>
                        )}
                    </div>
                </div>
            </section>
        </Layout>
    );
};
export default ViewScholarships;
