import React, { useState } from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
const Index = () => {
	const [filter, setFilter] = useState("all");
	const [showNewsCount, setShowNewsCount] = useState(9);
	const data = useStaticQuery(query);
	const {
		allStrapiMastersFlashNew: { nodes: flashNews },
	} = data;

	const onClickFilter = (name) => {
		setFilter(name);
	};

	const onClickLoadMore = () => {
		setShowNewsCount(showNewsCount + 3);
	};

	return (
		<FlashNewsStyle id="flash_news">
			<FlashNewsWrapper>
				<h1>Flash News</h1>
				<p id="flash_news_wrapper_subheading">All your news about countries, colleges and policies</p>
				{/* <small id="set_preference">Set Preference</small> */}
				<FlashNewsBtnWrapper>
					<label
						htmlFor="all"
						id="all_button"
						className={`${filter === "all" ? "isActive" : ""}`}
						onClick={() => onClickFilter("all")}
						aria-hidden="true"
					>
						All
					</label>

					<label
						htmlFor="study_abroad"
						id="canada_button"
						className={`${filter === "Canada" ? "isActive" : ""}`}
						onClick={() => onClickFilter("Canada")}
						aria-hidden="true"
					>
						Canada
					</label>

					<label
						htmlFor="usa"
						id="canada_button"
						className={`${filter === "USA" ? "isActive" : ""}`}
						onClick={() => onClickFilter("USA")}
						aria-hidden="true"
					>
						USA
					</label>

					{/* <label
						htmlFor="computer_science"
						id="computer_science_button"
						className={`${filter === "Computer Science" ? "isActive" : ""}`}
						onClick={() => onClickFilter("Computer Science")}
						aria-hidden="true"
					>
						Computer Science
					</label> */}
				</FlashNewsBtnWrapper>
				<FlashMainNewsListWrapper>
					{flashNews
						.filter((data) => {
							if (filter === "all" || filter === " ") {
								return data;
							}
							return data.type === filter;
						})
						.map((news, index) => {
							return (
								showNewsCount > index && (
									<FlashNewsIndividualCard key={news.strapi_id}>
										<div className="flash_news_individual_card_img_wrapper">
											<img src={news.news_img.url} alt="Image related to news" />
										</div>
										<p className="flash_news_card_title">{news.heading}</p>
										<p className="flash_news_card_shortinfo">{news.news_content}</p>
									</FlashNewsIndividualCard>
								)
							);
						})}
				</FlashMainNewsListWrapper>
				<button
					id="view_all"
					onClick={onClickLoadMore}
					className={`${flashNews.length > 9 ? "showLoadMore" : "hideLoadMore"}`}
				>
					Load More
				</button>
			</FlashNewsWrapper>
		</FlashNewsStyle>
	);
};
const FlashNewsStyle = styled.section`
	padding: 10rem 0.75rem;
	input[type="radio"] {
		display: none;
	}
	label {
		position: relative;
		font-weight: 500;
		font-size: 1rem;
		line-height: 19px;
		color: #113a68;
		background: #fff0eb;
		border-radius: 63px;
		cursor: pointer;
	}

	label::before {
		content: "";
		height: 30px;
		width: 30px;
	}
	input[type="radio"]:checked + label {
		background-color: #113a68;
		color: #fff0eb;
		transition: 0.2s all;
	}
	input[type="radio"]:checked + label::before {
		background-color: white;
	}
	.isActive {
		background-color: #113a68;
		color: #fff0eb;
		transition: 0.2s all;
	}
	.showLoadMore {
		display: block;
	}
	.hideLoadMore {
		display: none;
	}
	#view_all {
		margin-top: 3.5rem;
		padding: 1rem 3rem;
		cursor: pointer;
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 1rem;
		line-height: 19px;
		color: #113a68;
		border: 1px solid #113a68;
		border-radius: 8px;
		outline: none;
		background-color: #ffffff;
	}
`;
const FlashNewsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	h1 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		color: #113a68;
		margin-bottom: 1rem;
	}

	#flash_news_wrapper_subheading {
		font-weight: 400;
		font-size: 1.5rem;
		line-height: 29px;
		text-align: center;
		color: #396eb0;
		margin-bottom: 1rem;
	}
	#set_preference {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 1.25rem;
		line-height: 24px;
		color: #fc997c;
		display: block;
		text-align: center;
		margin-bottom: 6rem;
	}
	@media (max-width: 500px) {
		h1 {
			font-weight: 900;
			font-size: 1.75rem;
			line-height: 34px;
		}
		#flash_news_wrapper_subheading {
			font-weight: 500;
			font-size: 1rem;
			line-height: 19px;
			max-width: 14.875rem;
		}
		#set_preference {
			font-weight: 700;
			font-size: 1rem;
			line-height: 19px;
			margin-bottom: 2rem;
		}
	}
`;
const FlashNewsBtnWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	#all_button {
		padding: 0.625rem 1.5rem;
	}
	#canada_button {
		padding: 0.625rem 1.25rem;
	}
	#canada_button {
		padding: 0.625rem 1.25rem;
	}
	#computer_science_button {
		padding: 0.625rem 1.25rem;
		min-width: 11.375rem;
		max-width: 11.375rem;
	}
	@media (max-width: 500px) {
		padding: 0.8rem 0rem;
		justify-content: flex-start;
		width: 100%;
		overflow-y: scroll;
		gap: 0.5rem;
	}
`;

const FlashMainNewsListWrapper = styled.div`
	margin-top: 3.5rem;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	width: fit-content;
	gap: 3.5rem;
	@media (max-width: 1180px) {
		grid-template-columns: repeat(2, 1fr);
	}
	@media (max-width: 780px) {
		grid-template-columns: 1fr;
	}
	@media (max-width: 500px) {
		margin-top: 2rem;
		gap: 1.5rem;
	}
`;

const FlashNewsIndividualCard = styled.div`
	padding: 0.5rem 0.5rem 1rem;
	background: #ffffff;
	box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
	border-radius: 16px;
	min-width: 21rem;
	max-width: 21rem;
	display: flex;
	align-items: center;
	flex-direction: column;
	.flash_news_card_title {
		font-weight: 600;
		font-size: 1rem;
		line-height: 19px;
		color: #113a68;
		margin-bottom: 0.25rem;
		padding: 0rem 0.25rem;
		width: 100%;
		text-align: left;
	}
	.flash_news_card_shortinfo {
		font-weight: 400;
		font-size: 0.875rem;
		line-height: 17px;
		color: #396eb0;
		padding: 0rem 0.25rem;
		width: 100%;
		text-align: left;
	}
	.flash_news_individual_card_img_wrapper {
		border-radius: 12px;
		overflow: hidden;
		width: 100%;
		min-width: 20rem;
		max-width: 20rem;
		height: 12.5rem;
		margin-bottom: 0.75rem;
	}
	.flash_news_individual_card_img_wrapper img {
		min-width: 100%;
		max-width: 100%;
		height: 100%;
		object-fit: cover;
	}

	@media (max-width: 380px) {
		min-width: 100%;
		max-width: 100%;

		.flash_news_individual_card_img_wrapper {
			min-width: 100%;
			max-width: 100%;
			height: 100%;
		}
	}
`;

export const query = graphql`
	{
		allStrapiMastersFlashNew {
			nodes {
				news_img {
					url
					name
				}
				heading
				news_content
				strapi_id
				type
				slug
			}
		}
	}
`;
export default Index;
