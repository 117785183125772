import React from "react";
import PrHeroMarketing from "../components/marketingComponent/pr-hero-marketing";
import styled from "styled-components";
import SEO from "../components/seo";
import ProgramResult from "../components/MasterInUSAndCanadaPages/result";
import Gooduniversity from "../components/SettleAbroadPages/goodUniversity";
import IndustryDefyingPerformance from "../components/SettleAbroadPages/industryDefyingPerformance";
import HowWeDo from "../components/SettleAbroadPages/howWeDo";
import ProgramForIndividual from "../components/SettleAbroadPages/programForIndividual";
import NeedMoreClarity from "../components/SettleAbroadPages/needMoreClarity";
import PrivacyPolicyLink from "../components/marketingComponent/privacy-policy-link";

const PrMarketingPage = () => {
	return (
		<>
			<SEO title="Pr  Marketing Page" />
			<PrMarketingPageStyled>
				<PrHeroMarketing />
				<ProgramResult isMarketing={true} slug={["canada-immigration", "default-program"]} />
				<IndustryDefyingPerformance isMarketing={true} />
				<HowWeDo isMarketing={true} />
				<ProgramForIndividual isMarketing={true} />
				<Gooduniversity isMarketing={true} />

				{/* <ExpertisAndReach isMarketing={true} /> */}

				<NeedMoreClarity isMarketing={true} />
				{/* <DoYourOwnResearch /> */}
				{/* <ReportAndArticle /> */}
				<PrivacyPolicyLink/>
			</PrMarketingPageStyled>
		</>
	);
};
const PrMarketingPageStyled = styled.div`
	background-image: url("https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/09472a56ed99461fbedcf8561423a705.svg");
	background-repeat: no-repeat;
	background-position: top;
	background-size: 100%;
	@media (max-width: 600px) {
		background-image: url("https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/096db779ef6644a183f4d62a481f36c7.svg");
	}
`;
export default PrMarketingPage;
