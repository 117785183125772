import React from "react";
import styled from "styled-components";
import { graphql, useStaticQuery } from "gatsby";
const HowItWorks = ({ slug }) => {
	const data = useStaticQuery(query);

	const {
		allStrapiMastersTimeline: { nodes: timeline },
	} = data;
	let aTimeline = timeline.filter((item) => item.slug === slug[0]);
	let renderMaster = aTimeline?.length > 0 ? aTimeline[0] : timeline.filter((item) => item.slug === slug[1])[0];

	const { heading, subheading, time_line } = renderMaster;

	return (
		<HowItWorksStyled>
			<HowItWorksWrapperStyled>
				<h3 key={`heading`} dangerouslySetInnerHTML={{ __html: heading }} />

				<small id="your_timeline" key={`subheading`} dangerouslySetInnerHTML={{ __html: subheading }} />
				{/* wrappThreeTimeLine */}
				<MonthWrapperStyled>
					{time_line.map((monthData) => {
						return (
							<IndividualMonthsWrapperStyled
								className={`${monthData.time_line_month.length > 2 ? "wrappThreeTimeLine" : ""}`}
							>
								<small className="month_top_card">{monthData.month}</small>
								{monthData.time_line_month.map((data, index) => {
									return (
										<>
											<HowItWorksProcesssWrapperStyled>
												<p className="how_it_works_processs_heading">{data.heading}</p>
												<p className="how_it_works_processs_answer">{data.subheading}</p>
											</HowItWorksProcesssWrapperStyled>
											{monthData.time_line_month.length > index + 1 && (
												<img
													src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/cf38ac6361224676b7fa27c4346a1cd4.svg"
													alt=""
												/>
											)}
										</>
									);
								})}
							</IndividualMonthsWrapperStyled>
						);
					})}
					{/* <IndividualMonthsWrapperStyled>
						<small className="month_top_card">Month One</small>
						<HowItWorksProcesssWrapperStyled>
							<p className="how_it_works_processs_heading">
								A personal mentor is assigned
							</p>
							<p className="how_it_works_processs_answer">
								She ensures that 20+ steps go with zero errors
								and right on time
							</p>
						</HowItWorksProcesssWrapperStyled>
						<img
							src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/cf38ac6361224676b7fa27c4346a1cd4.svg"
							alt=""
						/>
						<HowItWorksProcesssWrapperStyled>
							<p className="how_it_works_processs_heading">
								Profile evaluation
							</p>
							<p className="how_it_works_processs_answer">
								A detailed academic and work profile is created
								and accordingly, the best courses are identified{" "}
							</p>
						</HowItWorksProcesssWrapperStyled>
					</IndividualMonthsWrapperStyled>
					<IndividualMonthsWrapperStyled>
						<small className="month_top_card">Month Two</small>
						<HowItWorksProcesssWrapperStyled>
							<p className="how_it_works_processs_heading">
								University long list
							</p>
							<p className="how_it_works_processs_answer">
								Based on the profile long list of 30+
								universities is created
							</p>
						</HowItWorksProcesssWrapperStyled>
						<img
							src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/cf38ac6361224676b7fa27c4346a1cd4.svg"
							alt=""
						/>
						<HowItWorksProcesssWrapperStyled>
							<p className="how_it_works_processs_heading">
								University shortlist
							</p>
							<p className="how_it_works_processs_answer">
								After deeper research and discussion a final
								shortlist of 5 colleges is prepared
							</p>
						</HowItWorksProcesssWrapperStyled>
					</IndividualMonthsWrapperStyled>
					<IndividualMonthsWrapperStyled className="wrappThreeTimeLine">
						<small className="month_top_card">Month Four</small>
						<HowItWorksProcesssWrapperStyled className=" month_four_wrapper">
							<p className="how_it_works_processs_heading">
								University application
							</p>
							<p className="how_it_works_processs_answer">
								The application team starts applying to
								shortlisted universities
							</p>
						</HowItWorksProcesssWrapperStyled>
						<img
							src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/cf38ac6361224676b7fa27c4346a1cd4.svg"
							alt=""
						/>
						<HowItWorksProcesssWrapperStyled className=" month_four_middle_wrapper">
							<p className="how_it_works_processs_heading">
								Test preparation
							</p>
							<p className="how_it_works_processs_answer">
								Meanwhile a personal mentor ensures high test
								scores
							</p>
						</HowItWorksProcesssWrapperStyled>
						<img
							src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/cf38ac6361224676b7fa27c4346a1cd4.svg"
							alt=""
						/>
						<HowItWorksProcesssWrapperStyled className=" month_four_wrapper">
							<p className="how_it_works_processs_heading">
								Financial Planning
							</p>
							<p className="how_it_works_processs_answer">
								Your mentor gets you the best rates and quickest
								approvals
							</p>
						</HowItWorksProcesssWrapperStyled>
					</IndividualMonthsWrapperStyled>
					<IndividualMonthsWrapperStyled>
						<small className="month_top_card">Month Five</small>
						<HowItWorksProcesssWrapperStyled className="">
							<p className="how_it_works_processs_heading">
								Visa Prep
							</p>
							<p className="how_it_works_processs_answer">
								A visa expert is onboarded to a “rejection
								proof” visa application
							</p>
						</HowItWorksProcesssWrapperStyled>
						<img
							src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/cf38ac6361224676b7fa27c4346a1cd4.svg"
							alt=""
						/>
						<HowItWorksProcesssWrapperStyled className="">
							<p className="how_it_works_processs_heading">
								Landing and Settlement support
							</p>
							<p className="how_it_works_processs_answer">
								Alumni connect, find accommodation and part-time
								jobs etc
							</p>
						</HowItWorksProcesssWrapperStyled>
					</IndividualMonthsWrapperStyled> */}
				</MonthWrapperStyled>
			</HowItWorksWrapperStyled>
		</HowItWorksStyled>
	);
};
const HowItWorksStyled = styled.section`
	padding: 0rem 0.75rem;
	margin-bottom: 5rem;
	.month_four_wrapper {
		min-width: 17.25rem;
		max-width: 17.25rem;
	}
	.month_four_middle_wrapper {
		min-width: 14.5rem;
		max-width: 14.5rem;
	}
	.wrappThreeTimeLine {
		padding: 2rem 3rem;
		gap: 3rem;
		max-width: 78%;
	}

	@media (max-width: 500px) {
		& h3 {
			font-size: 1.75rem;
			line-height: 34px;
		}
	}
`;

const HowItWorksWrapperStyled = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-top: 4rem;
	& h3 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: center;
		color: #113a68;
		margin-bottom: 1rem;
	}
	#your_timeline {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1.5rem;
		line-height: 29px;
		text-align: center;
		color: #396eb0;
		margin-bottom: 3rem;
	}
	@media (max-width: 500px) {
		#your_timeline {
			font-size: 1rem;
			line-height: 19px;
			margin-bottom: 3rem;
		}
	}
`;

const MonthWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 3rem;
	@media (max-width: 500px) {
		gap: 1rem !important;
	}
`;

const IndividualMonthsWrapperStyled = styled.div`
	background: #fff0eb;
	border-radius: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4.5rem;
	padding: 2rem 4.5rem;
	position: relative;
	.month_top_card {
		position: relative;
		font-weight: 500;
		color: #113a68;
		background: #ffe6df;
		top: 0;
		margin-bottom: 0.5rem;
	}
	.month_top_card {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 0.75rem;
		line-height: 15px;
		text-align: center;
		color: #ffffff;
		padding: 0.5rem 1rem;
		background: #fc997c;
		border-radius: 100px;
		position: absolute;
		top: -15px;
		max-width: 6.25rem;
		min-width: 6.25rem;
	}
	@media (max-width: 1260px) {
		gap: 1.5rem;
	}
	@media (max-width: 1065px) {
		flex-direction: column;
		width: 100%;
		gap: 0rem;

		& img {
			transform: rotate(90deg);
		}
	}
	@media (max-width: 700px) {
		padding: 2rem 1rem;
	}
	@media (max-width: 500px) {
		padding: 1rem !important;

		gap: 0rem !important;
		max-width: 100% !important;
		.month_top_card {
			position: relative;
			font-weight: 500;
			color: #113a68;
			background: #ffe6df;
			top: 0;
			margin-bottom: 0.5rem;
		}
	}
`;

const HowItWorksProcesssWrapperStyled = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 0.5rem;
	min-width: 17.75rem;
	max-width: 24.75rem;
	max-height: 8.0625rem;
	.how_it_works_processs_heading {
		font-family: "Inter";
		font-style: normal;
		font-weight: 700;
		font-size: 1rem;
		line-height: 19px;
		text-align: center;
		color: #113a68;
	}
	.how_it_works_processs_answer {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 19px;
		text-align: center;
		color: #396eb0;
	}
	@media (max-width: 500px) {
		min-width: 100%;
		max-width: 100%;
		max-height: 100%;
	}
	@media (max-width: 310px) {
		.how_it_works_processs_answer {
			font-size: 0.8rem;
		}
	}
`;

export const query = graphql`
	query StrapiMastersTimeline($slug: [String]) {
		allStrapiMastersTimeline(filter: { slug: { in: $slug } }) {
			nodes {
				slug
				heading
				subheading
				time_line {
					month
					time_line_month {
						heading
						subheading
					}
				}
			}
		}
	}
`;
export default HowItWorks;
