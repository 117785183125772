import {
    faChevronLeft,
    faChevronRight,
    faUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import React, { useState, useEffect } from 'react';
import * as styles from './UniversityDetails.module.css';
import universityLogo from '../../../assets/images/university-logo.png';
import mentorIcon from '../../../assets/icons/mentor.svg';
import qsRankIcon from '../../../assets/icons/qs-rank.svg';
import ieltsNinjaImage from '../../../assets/icons/ielts-ninja.svg';
import { useWindowDimensions } from '../../../utils/index';
import Header from '../../header';

const UniversityDetails = (props) => {
    const { university } = props;
    const { ranking, courses } = university;

    const [showWidth, setShowWidth] = useState(0);
    let dimension = useWindowDimensions();
    useEffect(() => {
        const { width, height } = dimension;

        setShowWidth(width);
    }, [dimension]);

    // let documents, ranking;

    // if (Object.keys(university).length) {
    //     documents = university.documents.requiredDocs.concat(
    //         university.documents.additionalDocs
    //     );
    //     ranking = university.ranking.find(
    //         (rank) => rank.rankingType === 'QS Ranking' && rank.year === '2022'
    //     );
    // }
    let documents;

    if (Object.keys(university).length) {
        documents = university.documents.requiredDocs.concat(
            university.documents?.additionalDocs
        );
    }

    return (
        <>
            {showWidth > 1124 ? <Header /> : <></>}
            <div className={styles['IndividualUniversity__mainwrapper']}>
                {Object.keys(university).length && (
                    <div>
                        <div className={styles['universityHeader']}>
                            <div
                                className={styles['universityHeader__content']}
                            >
                                <Link
                                    to="/study-abroad/university-search"
                                    className={styles['mobileView__backbutton']}
                                >
                                    <FontAwesomeIcon
                                        icon={faChevronLeft}
                                        className={styles['btnBack__icon']}
                                    />
                                </Link>
                                {/* <img src={leftArrow} alt="Left Arrow Icon" /> */}
                                <div
                                    className={
                                        styles['universityHeader__details']
                                    }
                                >
                                    <img
                                        className={
                                            styles['universityHeader__logo']
                                        }
                                        src={universityLogo}
                                        alt="University Logo"
                                    />
                                    <div>
                                        <p
                                            className={
                                                styles['universityHeader__name']
                                            }
                                        >
                                            {university.info.universityName}
                                        </p>
                                        <p
                                            className={
                                                styles[
                                                    'universityHeader__location'
                                                ]
                                            }
                                        >
                                            {university.info.city},{' '}
                                            {university.info.country}
                                        </p>
                                    </div>
                                    <div
                                        className={
                                            styles[
                                                'universityHeader__typeyearwrapper'
                                            ]
                                        }
                                    >
                                        <p
                                            className={
                                                styles['universityHeader__year']
                                            }
                                        >
                                            Est. {university.info.establishedIn}
                                        </p>
                                        <p
                                            className={
                                                styles['universityHeader__type']
                                            }
                                        >
                                            {university.info.universityType}{' '}
                                            University
                                        </p>
                                    </div>
                                    <div className={styles['btnAskExperts']}>
                                        <a href="/abroad-form?formName=study-abroad&source=abroad-ninja&utm_source=source&utm_campaign=cap&utm_medium=medium&language=lang&brandid=6&do_what=-&segment=1880">
                                            <img src={mentorIcon} />
                                            <p>Ask our Experts</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showWidth > 768 ? (
                            <div className={styles['universityContent']}>
                                <div
                                    className={
                                        styles['universityContent_Webviewleft']
                                    }
                                >
                                    <div
                                        className={
                                            styles['universityContent__card']
                                        }
                                    >
                                        <div
                                            className={
                                                styles[
                                                    'universityContent__title'
                                                ]
                                            }
                                        >
                                            <h3>Ranking</h3>
                                        </div>
                                        <div
                                            className={
                                                styles[
                                                    'universityContent__ranking'
                                                ]
                                            }
                                        >
                                            <div
                                                className={
                                                    styles[
                                                        'universityContent__rank'
                                                    ]
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles[
                                                            'universityRank__logo'
                                                        ]
                                                    }
                                                >
                                                    <img src={qsRankIcon} />
                                                </div>
                                                <div
                                                    className={
                                                        styles['universityRank']
                                                    }
                                                >
                                                    {ranking?.minRank ===
                                                    ranking?.maxRank ? (
                                                        <p
                                                            className={
                                                                styles[
                                                                    'universityRank__rankingValue'
                                                                ]
                                                            }
                                                        >
                                                            #{ranking?.minRank}
                                                        </p>
                                                    ) : (
                                                        <p
                                                            className={
                                                                styles[
                                                                    'universityRank__rankingValue'
                                                                ]
                                                            }
                                                        >
                                                            #
                                                            {ranking?.minRank ??
                                                                'NA'}{' '}
                                                            - {ranking?.maxRank}
                                                        </p>
                                                    )}

                                                    <p
                                                        className={
                                                            styles[
                                                                'universityRank__rankingType'
                                                            ]
                                                        }
                                                    >
                                                        QS World University
                                                        Rankings
                                                    </p>
                                                </div>
                                            </div>
                                            {/* <div className={styles["university-content__rank"]}>
                                <div className={styles["university-rank__logo"]}>
                                <img src={countryRankIcon} />
                                </div>
                                <div className={styles["university-rank"]}>
                                <p className={styles["university-rank__ranking-value"]}>
                                    #1
                                </p>
                                <p className={styles["university-rank__ranking-type"]}>
                                    Country Ranking
                                </p>
                                </div>
                            </div> */}
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            styles['universityContent__card']
                                        }
                                    >
                                        <div
                                            className={
                                                styles[
                                                    'universityContent__title'
                                                ]
                                            }
                                        >
                                            <h3>Contact and Info</h3>
                                        </div>
                                        <div>
                                            <div>
                                                <p
                                                    className={
                                                        styles[
                                                            'universityContent__subTitleText'
                                                        ]
                                                    }
                                                >
                                                    Links
                                                </p>
                                                <ul
                                                    className={
                                                        styles[
                                                            'universityContact__links'
                                                        ]
                                                    }
                                                >
                                                    <li>
                                                        <a
                                                            href={
                                                                university.links
                                                                    .websiteLink
                                                            }
                                                            target="_blank"
                                                            className={
                                                                styles[
                                                                    'universityContact__text'
                                                                ]
                                                            }
                                                        >
                                                            <p>
                                                                University
                                                                Website
                                                            </p>
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faUpRightFromSquare
                                                                }
                                                                className={
                                                                    styles[
                                                                        'visitLink__icon'
                                                                    ]
                                                                }
                                                            />
                                                        </a>
                                                        {/* <img src={visitLinkIcon} alt="Visit Icon" /> */}
                                                    </li>
                                                    <li>
                                                        <a
                                                            href={
                                                                university.links
                                                                    .internationalWebsiteLink
                                                            }
                                                            target="_blank"
                                                            className={
                                                                styles[
                                                                    'universityContact__text'
                                                                ]
                                                            }
                                                        >
                                                            <p>
                                                                International
                                                                University
                                                                Website
                                                            </p>
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faUpRightFromSquare
                                                                }
                                                                className={
                                                                    styles[
                                                                        'visitLink__icon'
                                                                    ]
                                                                }
                                                            />
                                                        </a>
                                                        {/* <img src={visitLinkIcon} alt="Visit Icon" /> */}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div>
                                                <p
                                                    className={
                                                        styles[
                                                            'universityContent__subTitleText'
                                                        ]
                                                    }
                                                >
                                                    Email
                                                </p>
                                                <p
                                                    className={
                                                        styles[
                                                            'universityContact__text'
                                                        ]
                                                    }
                                                >
                                                    {
                                                        university.contact
                                                            .contactEmail
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={
                                        styles['universityContent_Webviewright']
                                    }
                                >
                                    <div
                                        className={
                                            styles['universityContent__card']
                                        }
                                    >
                                        <div
                                            className={
                                                styles[
                                                    'universityContent__title'
                                                ]
                                            }
                                        >
                                            <h3>Admission</h3>
                                        </div>
                                        <div
                                            className={
                                                styles[
                                                    'universityContent__admission'
                                                ]
                                            }
                                        >
                                            <div>
                                                <p
                                                    className={
                                                        styles[
                                                            'universityContent__sub-title-text'
                                                        ]
                                                    }
                                                >
                                                    Application Fee
                                                </p>
                                                {university.fees
                                                    .applicationFees && (
                                                    <p
                                                        className={
                                                            styles[
                                                                'universityAdmission__applicationFee'
                                                            ]
                                                        }
                                                    >
                                                        {
                                                            university.fees
                                                                .applicationFees
                                                        }{' '}
                                                        (
                                                        {
                                                            university.info
                                                                .currency
                                                        }
                                                        )
                                                    </p>
                                                )}
                                                {!university.fees
                                                    .applicationFees && (
                                                    <p
                                                        className={`${styles['universityAdmission__applicationFee']} ${styles['notAvailable']}`}
                                                    >
                                                        NA
                                                    </p>
                                                )}
                                            </div>
                                            <div
                                                className={
                                                    styles[
                                                        'universityAdmission__testDetails'
                                                    ]
                                                }
                                            >
                                                <p>
                                                    Minimum test score
                                                    requirements
                                                </p>
                                                <div>
                                                    <p
                                                        className={
                                                            styles[
                                                                'universityAdmission__testTitle'
                                                            ]
                                                        }
                                                    >
                                                        English Proficiency Test
                                                    </p>
                                                    <div
                                                        className={
                                                            styles[
                                                                'universityAdmission__test'
                                                            ]
                                                        }
                                                    >
                                                        {university.englishExams
                                                            .length > 0 &&
                                                            university.englishExams.map(
                                                                (
                                                                    englishExam
                                                                ) => {
                                                                    return (
                                                                        <div>
                                                                            <p
                                                                                className={
                                                                                    styles[
                                                                                        'universityAdmission__testName'
                                                                                    ]
                                                                                }
                                                                            >
                                                                                {
                                                                                    englishExam.exam
                                                                                }
                                                                            </p>
                                                                            {englishExam.minScore ? (
                                                                                <p
                                                                                    className={
                                                                                        styles[
                                                                                            'universityAdmission__testScore'
                                                                                        ]
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        englishExam.minScore
                                                                                    }
                                                                                </p>
                                                                            ) : (
                                                                                <p
                                                                                    className={`${styles['universityAdmission__testScore']} ${styles['notAvailable']}`}
                                                                                >
                                                                                    NA
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                    </div>
                                                </div>

                                                <div>
                                                    <p
                                                        className={
                                                            styles[
                                                                'universityAdmission__testTitle'
                                                            ]
                                                        }
                                                    >
                                                        Aptitude Test
                                                    </p>
                                                    <div
                                                        className={
                                                            styles[
                                                                'universityAdmission__test'
                                                            ]
                                                        }
                                                    >
                                                        {university.exams
                                                            .length &&
                                                            university.exams.map(
                                                                (exam) => {
                                                                    return (
                                                                        <div>
                                                                            <p
                                                                                className={
                                                                                    styles[
                                                                                        'universityAdmission__testName'
                                                                                    ]
                                                                                }
                                                                            >
                                                                                {
                                                                                    exam.exam
                                                                                }
                                                                            </p>
                                                                            {exam.minScore ? (
                                                                                <p
                                                                                    className={
                                                                                        styles[
                                                                                            'universityAdmission__testScore'
                                                                                        ]
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        exam.minScore
                                                                                    }
                                                                                </p>
                                                                            ) : (
                                                                                <p
                                                                                    className={`${styles['universityAdmission__testScore']} ${styles['notAvailable']}`}
                                                                                >
                                                                                    NA
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    styles[
                                                        'universityAdmission__testAd'
                                                    ]
                                                }
                                            >
                                                <div>
                                                    <img
                                                        src={ieltsNinjaImage}
                                                    />
                                                    <p
                                                        className={
                                                            styles[
                                                                'universityAdmission__testAdMsg'
                                                            ]
                                                        }
                                                    >
                                                        Achieve your band target
                                                        with us
                                                    </p>
                                                </div>
                                                <a
                                                    className={
                                                        styles['btnKnowMore']
                                                    }
                                                    href="https://ieltsninja.com/"
                                                >
                                                    Know more
                                                </a>
                                            </div>
                                            <div>
                                                <p
                                                    className={
                                                        styles[
                                                            'universityContent__subTitleText'
                                                        ]
                                                    }
                                                >
                                                    Documents required
                                                </p>
                                                <ul
                                                    className={
                                                        styles[
                                                            'universityAdmission__documents'
                                                        ]
                                                    }
                                                >
                                                    {documents.length &&
                                                        documents.map((doc) => {
                                                            return (
                                                                <li>{doc}</li>
                                                            );
                                                        })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            styles['universityContent__card']
                                        }
                                    >
                                        <div
                                            className={
                                                styles[
                                                    'universityContent__title'
                                                ]
                                            }
                                        >
                                            <h3>Top Courses</h3>
                                        </div>
                                        <div>
                                            {courses.map((course, index) => (
                                                <>
                                                    <div
                                                        className={
                                                            styles[
                                                                'universityCourse'
                                                            ]
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles[
                                                                    'universityCourse__title'
                                                                ]
                                                            }
                                                        >
                                                            <p
                                                                className={
                                                                    styles[
                                                                        'universityContent__subTitleText'
                                                                    ]
                                                                }
                                                            >
                                                                {
                                                                    course.info
                                                                        .courseName
                                                                }
                                                            </p>
                                                            <Link
                                                                to={`/study-abroad/course-details/?course=${course.courseID}`}
                                                                className={
                                                                    styles[
                                                                        'detailsLink'
                                                                    ]
                                                                }
                                                            >
                                                                <p>Details</p>
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faChevronRight
                                                                    }
                                                                    className={
                                                                        styles[
                                                                            'viewDetails__icon'
                                                                        ]
                                                                    }
                                                                />
                                                            </Link>
                                                        </div>
                                                        <p
                                                            className={
                                                                styles[
                                                                    'courseDetails__universityName'
                                                                ]
                                                            }
                                                        >
                                                            {
                                                                course.info
                                                                    .department
                                                            }
                                                        </p>
                                                        <div
                                                            className={
                                                                styles[
                                                                    'universityCourse__details'
                                                                ]
                                                            }
                                                        >
                                                            <div>
                                                                <p
                                                                    className={
                                                                        styles[
                                                                            'courseDetails__title'
                                                                        ]
                                                                    }
                                                                >
                                                                    Application
                                                                    Deadline
                                                                </p>
                                                                <span
                                                                    className={
                                                                        styles[
                                                                            'courseDetails__value'
                                                                        ]
                                                                    }
                                                                >
                                                                    Anytime
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <p
                                                                    className={
                                                                        styles[
                                                                            'courseDetails__title'
                                                                        ]
                                                                    }
                                                                >
                                                                    Degree
                                                                </p>
                                                                <span
                                                                    className={
                                                                        styles[
                                                                            'courseDetails__value'
                                                                        ]
                                                                    }
                                                                >
                                                                    {course
                                                                        ?.info
                                                                        ?.degree ??
                                                                        'NA'}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <p
                                                                    className={
                                                                        styles[
                                                                            'courseDetails__title'
                                                                        ]
                                                                    }
                                                                >
                                                                    Duration
                                                                </p>
                                                                <span
                                                                    className={
                                                                        styles[
                                                                            'courseDetails__value'
                                                                        ]
                                                                    }
                                                                >
                                                                    21 Months
                                                                </span>
                                                            </div>
                                                            <div>
                                                                <p
                                                                    className={
                                                                        styles[
                                                                            'courseDetails__title'
                                                                        ]
                                                                    }
                                                                >
                                                                    Tuition Fee
                                                                </p>
                                                                <span
                                                                    className={
                                                                        styles[
                                                                            'courseDetails__value'
                                                                        ]
                                                                    }
                                                                >
                                                                    {course
                                                                        ?.tuitionFee
                                                                        ?.annualTuitionFee ??
                                                                        'NA'}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {courses.length !==
                                                        index + 1 && (
                                                        <div
                                                            className={
                                                                styles[
                                                                    'horizontalLine'
                                                                ]
                                                            }
                                                        ></div>
                                                    )}
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={styles['universityContent']}>
                                <div
                                    className={
                                        styles['universityContent__card']
                                    }
                                >
                                    <div
                                        className={
                                            styles['universityContent__title']
                                        }
                                    >
                                        <h3>Ranking</h3>
                                    </div>
                                    <div
                                        className={
                                            styles['universityContent__ranking']
                                        }
                                    >
                                        <div
                                            className={
                                                styles[
                                                    'universityContent__rank'
                                                ]
                                            }
                                        >
                                            <div
                                                className={
                                                    styles[
                                                        'universityRank__logo'
                                                    ]
                                                }
                                            >
                                                <img src={qsRankIcon} />
                                            </div>
                                            <div
                                                className={
                                                    styles['universityRank']
                                                }
                                            >
                                                {ranking?.minRank ===
                                                ranking?.maxRank ? (
                                                    <p
                                                        className={
                                                            styles[
                                                                'universityRank__rankingValue'
                                                            ]
                                                        }
                                                    >
                                                        #{ranking?.minRank}
                                                    </p>
                                                ) : (
                                                    <p
                                                        className={
                                                            styles[
                                                                'universityRank__rankingValue'
                                                            ]
                                                        }
                                                    >
                                                        #
                                                        {ranking?.minRank ??
                                                            'NA'}{' '}
                                                        - {ranking?.maxRank}
                                                    </p>
                                                )}

                                                <p
                                                    className={
                                                        styles[
                                                            'universityRank__rankingType'
                                                        ]
                                                    }
                                                >
                                                    QS World University Rankings
                                                </p>
                                            </div>
                                        </div>
                                        {/* <div className={styles["university-content__rank"]}>
                        <div className={styles["university-rank__logo"]}>
                            <img src={countryRankIcon} />
                        </div>
                        <div className={styles["university-rank"]}>
                            <p className={styles["university-rank__ranking-value"]}>
                            #1
                            </p>
                            <p className={styles["university-rank__ranking-type"]}>
                            Country Ranking
                            </p>
                        </div>
                        </div> */}
                                    </div>
                                </div>

                                <div
                                    className={
                                        styles['universityContent__card']
                                    }
                                >
                                    <div
                                        className={
                                            styles['universityContent__title']
                                        }
                                    >
                                        <h3>Admission</h3>
                                    </div>
                                    <div
                                        className={
                                            styles[
                                                'universityContent__admission'
                                            ]
                                        }
                                    >
                                        <div>
                                            <p
                                                className={
                                                    styles[
                                                        'universityContent__sub-title-text'
                                                    ]
                                                }
                                            >
                                                Application Fee
                                            </p>
                                            {university.fees
                                                .applicationFees && (
                                                <p
                                                    className={
                                                        styles[
                                                            'universityAdmission__applicationFee'
                                                        ]
                                                    }
                                                >
                                                    {
                                                        university.fees
                                                            .applicationFees
                                                    }{' '}
                                                    ({university.info.currency})
                                                </p>
                                            )}
                                            {!university.fees
                                                .applicationFees && (
                                                <p
                                                    className={`${styles['universityAdmission__applicationFee']} ${styles['notAvailable']}`}
                                                >
                                                    NA
                                                </p>
                                            )}
                                        </div>
                                        <div
                                            className={
                                                styles[
                                                    'universityAdmission__testDetails'
                                                ]
                                            }
                                        >
                                            <p>
                                                Minimum test score requirements
                                            </p>
                                            <div>
                                                <p
                                                    className={
                                                        styles[
                                                            'universityAdmission__testTitle'
                                                        ]
                                                    }
                                                >
                                                    English Proficiency Test
                                                </p>
                                                <div
                                                    className={
                                                        styles[
                                                            'universityAdmission__test'
                                                        ]
                                                    }
                                                >
                                                    {university.englishExams
                                                        .length > 0 &&
                                                        university.englishExams.map(
                                                            (englishExam) => {
                                                                return (
                                                                    <div>
                                                                        <p
                                                                            className={
                                                                                styles[
                                                                                    'universityAdmission__testName'
                                                                                ]
                                                                            }
                                                                        >
                                                                            {
                                                                                englishExam.exam
                                                                            }
                                                                        </p>
                                                                        {englishExam.minScore ? (
                                                                            <p
                                                                                className={
                                                                                    styles[
                                                                                        'universityAdmission__testScore'
                                                                                    ]
                                                                                }
                                                                            >
                                                                                {
                                                                                    englishExam.minScore
                                                                                }
                                                                            </p>
                                                                        ) : (
                                                                            <p
                                                                                className={`${styles['universityAdmission__testScore']} ${styles['notAvailable']}`}
                                                                            >
                                                                                NA
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    styles[
                                                        'universityAdmission__testAd'
                                                    ]
                                                }
                                            >
                                                <div>
                                                    <img
                                                        src={ieltsNinjaImage}
                                                    />
                                                    <p
                                                        className={
                                                            styles[
                                                                'universityAdmission__testAdMsg'
                                                            ]
                                                        }
                                                    >
                                                        Achieve your band target
                                                        with us
                                                    </p>
                                                </div>
                                                <a
                                                    className={
                                                        styles['btnKnowMore']
                                                    }
                                                    href="https://ieltsninja.com/"
                                                >
                                                    Know more
                                                </a>
                                            </div>
                                            <div>
                                                <p
                                                    className={
                                                        styles[
                                                            'universityAdmission__testTitle'
                                                        ]
                                                    }
                                                >
                                                    Aptitude Test
                                                </p>
                                                <div
                                                    className={
                                                        styles[
                                                            'universityAdmission__test'
                                                        ]
                                                    }
                                                >
                                                    {university.exams.length &&
                                                        university.exams.map(
                                                            (exam) => {
                                                                return (
                                                                    <div>
                                                                        <p
                                                                            className={
                                                                                styles[
                                                                                    'universityAdmission__testName'
                                                                                ]
                                                                            }
                                                                        >
                                                                            {
                                                                                exam.exam
                                                                            }
                                                                        </p>
                                                                        {exam.minScore ? (
                                                                            <p
                                                                                className={
                                                                                    styles[
                                                                                        'universityAdmission__testScore'
                                                                                    ]
                                                                                }
                                                                            >
                                                                                {
                                                                                    exam.minScore
                                                                                }
                                                                            </p>
                                                                        ) : (
                                                                            <p
                                                                                className={`${styles['universityAdmission__testScore']} ${styles['notAvailable']}`}
                                                                            >
                                                                                NA
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <p
                                                className={
                                                    styles[
                                                        'universityContent__subTitleText'
                                                    ]
                                                }
                                            >
                                                Documents required
                                            </p>
                                            <ul
                                                className={
                                                    styles[
                                                        'universityAdmission__documents'
                                                    ]
                                                }
                                            >
                                                {documents.length &&
                                                    documents.map((doc) => {
                                                        return <li>{doc}</li>;
                                                    })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className={
                                        styles['universityContent__card']
                                    }
                                >
                                    <div
                                        className={
                                            styles['universityContent__title']
                                        }
                                    >
                                        <h3>Top Courses</h3>
                                    </div>
                                    <div>
                                        <div
                                            className={
                                                styles['universityCourse']
                                            }
                                        >
                                            <div
                                                className={
                                                    styles[
                                                        'universityCourse__title'
                                                    ]
                                                }
                                            >
                                                <p
                                                    className={
                                                        styles[
                                                            'universityContent__subTitleText'
                                                        ]
                                                    }
                                                >
                                                    MPhil in Economics
                                                </p>
                                                <a
                                                    href="#"
                                                    className={
                                                        styles['detailsLink']
                                                    }
                                                >
                                                    <p>Details</p>
                                                    <FontAwesomeIcon
                                                        icon={faChevronRight}
                                                        className={
                                                            styles[
                                                                'viewDetails__icon'
                                                            ]
                                                        }
                                                    />
                                                </a>
                                            </div>
                                            <p
                                                className={
                                                    styles[
                                                        'courseDetails__universityName'
                                                    ]
                                                }
                                            >
                                                Saïd Business School
                                            </p>
                                            <div
                                                className={
                                                    styles[
                                                        'universityCourse__details'
                                                    ]
                                                }
                                            >
                                                <div>
                                                    <p
                                                        className={
                                                            styles[
                                                                'courseDetails__title'
                                                            ]
                                                        }
                                                    >
                                                        Application Deadline
                                                    </p>
                                                    <span
                                                        className={
                                                            styles[
                                                                'courseDetails__value'
                                                            ]
                                                        }
                                                    >
                                                        Anytime
                                                    </span>
                                                </div>
                                                <div>
                                                    <p
                                                        className={
                                                            styles[
                                                                'courseDetails__title'
                                                            ]
                                                        }
                                                    >
                                                        Degree
                                                    </p>
                                                    <span
                                                        className={
                                                            styles[
                                                                'courseDetails__value'
                                                            ]
                                                        }
                                                    >
                                                        Bachelors/Masters
                                                    </span>
                                                </div>
                                                <div>
                                                    <p
                                                        className={
                                                            styles[
                                                                'courseDetails__title'
                                                            ]
                                                        }
                                                    >
                                                        Duration
                                                    </p>
                                                    <span
                                                        className={
                                                            styles[
                                                                'courseDetails__value'
                                                            ]
                                                        }
                                                    >
                                                        21 Months
                                                    </span>
                                                </div>
                                                <div>
                                                    <p
                                                        className={
                                                            styles[
                                                                'courseDetails__title'
                                                            ]
                                                        }
                                                    >
                                                        Tuition Fee
                                                    </p>
                                                    <span
                                                        className={
                                                            styles[
                                                                'courseDetails__value'
                                                            ]
                                                        }
                                                    >
                                                        £25,353/yr
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className={styles['horizontalLine']}
                                        ></div>
                                        <div
                                            className={
                                                styles['universityCourse']
                                            }
                                        >
                                            <div
                                                className={
                                                    styles[
                                                        'universityCourse__title'
                                                    ]
                                                }
                                            >
                                                <p
                                                    className={
                                                        styles[
                                                            'universityContent__subTitleText'
                                                        ]
                                                    }
                                                >
                                                    MS in Social Data Science
                                                </p>
                                                <a
                                                    href="#"
                                                    className={
                                                        styles['detailsLink']
                                                    }
                                                >
                                                    <p>Details</p>
                                                    <FontAwesomeIcon
                                                        icon={faChevronRight}
                                                        className={
                                                            styles[
                                                                'viewDetails__icon'
                                                            ]
                                                        }
                                                    />
                                                </a>
                                            </div>
                                            <p
                                                className={
                                                    styles[
                                                        'courseDetails__universityName'
                                                    ]
                                                }
                                            >
                                                Saïd Business School
                                            </p>
                                            <div
                                                className={
                                                    styles[
                                                        'universityCourse__details'
                                                    ]
                                                }
                                            >
                                                <div>
                                                    <p
                                                        className={
                                                            styles[
                                                                'courseDetails__title'
                                                            ]
                                                        }
                                                    >
                                                        Application Deadline
                                                    </p>
                                                    <span
                                                        className={
                                                            styles[
                                                                'courseDetails__value'
                                                            ]
                                                        }
                                                    >
                                                        Anytime
                                                    </span>
                                                </div>
                                                <div>
                                                    <p
                                                        className={
                                                            styles[
                                                                'courseDetails__title'
                                                            ]
                                                        }
                                                    >
                                                        Degree
                                                    </p>
                                                    <span
                                                        className={
                                                            styles[
                                                                'courseDetails__value'
                                                            ]
                                                        }
                                                    >
                                                        Bachelors/Masters
                                                    </span>
                                                </div>
                                                <div>
                                                    <p
                                                        className={
                                                            styles[
                                                                'courseDetails__title'
                                                            ]
                                                        }
                                                    >
                                                        Duration
                                                    </p>
                                                    <span
                                                        className={
                                                            styles[
                                                                'courseDetails__value'
                                                            ]
                                                        }
                                                    >
                                                        21 Months
                                                    </span>
                                                </div>
                                                <div>
                                                    <p
                                                        className={
                                                            styles[
                                                                'courseDetails__title'
                                                            ]
                                                        }
                                                    >
                                                        Tuition Fee
                                                    </p>
                                                    <span
                                                        className={
                                                            styles[
                                                                'courseDetails__value'
                                                            ]
                                                        }
                                                    >
                                                        £25,353/yr
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className={
                                        styles['universityContent__card']
                                    }
                                >
                                    <div
                                        className={
                                            styles['universityContent__title']
                                        }
                                    >
                                        <h3>Contact and Info</h3>
                                    </div>
                                    <div>
                                        <div>
                                            <p
                                                className={
                                                    styles[
                                                        'universityContent__subTitleText'
                                                    ]
                                                }
                                            >
                                                Links
                                            </p>
                                            <ul
                                                className={
                                                    styles[
                                                        'universityContact__links'
                                                    ]
                                                }
                                            >
                                                <li>
                                                    <a
                                                        href={
                                                            university.links
                                                                .websiteLink
                                                        }
                                                        target="_blank"
                                                        className={
                                                            styles[
                                                                'universityContact__text'
                                                            ]
                                                        }
                                                    >
                                                        <p>
                                                            University Website
                                                        </p>
                                                        <FontAwesomeIcon
                                                            icon={
                                                                faUpRightFromSquare
                                                            }
                                                            className={
                                                                styles[
                                                                    'visitLink__icon'
                                                                ]
                                                            }
                                                        />
                                                    </a>
                                                    {/* <img src={visitLinkIcon} alt="Visit Icon" /> */}
                                                </li>
                                                <li>
                                                    <a
                                                        href={
                                                            university.links
                                                                .internationalWebsiteLink
                                                        }
                                                        target="_blank"
                                                        className={
                                                            styles[
                                                                'universityContact__text'
                                                            ]
                                                        }
                                                    >
                                                        <p>
                                                            International
                                                            University Website
                                                        </p>
                                                        <FontAwesomeIcon
                                                            icon={
                                                                faUpRightFromSquare
                                                            }
                                                            className={
                                                                styles[
                                                                    'visitLink__icon'
                                                                ]
                                                            }
                                                        />
                                                    </a>
                                                    {/* <img src={visitLinkIcon} alt="Visit Icon" /> */}
                                                </li>
                                            </ul>
                                        </div>
                                        <div>
                                            <p
                                                className={
                                                    styles[
                                                        'universityContent__subTitleText'
                                                    ]
                                                }
                                            >
                                                Email
                                            </p>
                                            <p
                                                className={
                                                    styles[
                                                        'universityContact__text'
                                                    ]
                                                }
                                            >
                                                {
                                                    university.contact
                                                        .contactEmail
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};
export default UniversityDetails;
