import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useStudyMarketing } from '../hooks/studeyMarketing';
import { useForm } from 'react-hook-form';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { navigate } from 'gatsby';
import {
    NotificationContainer,
    NotificationManager,
} from 'react-notifications';
import SEO from '../components/seo';

const isBrowser = typeof window !== 'undefined';

const StudyMarketing = () => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    let aStudyMarketingData = useStudyMarketing();
    let slug = '';
    let tempUrl = {};

    const {
        register,
        handleSubmit,

        formState: { errors },
    } = useForm();
    if (isBrowser) {
        let url_string = window.location.href;
        let url = new URL(url_string);
        for (const [key, value] of url.searchParams.entries()) {
            tempUrl[key] = value;
        }

        slug = url.searchParams.get('brandName');
    }

    let filterSlug = aStudyMarketingData.filter((item) => {
        return item.slug === slug;
    })[0];

    if (filterSlug === undefined) {
        return;
    }

    const funPattern = (type) => {
        if (type === 'name') {
            return;
        }
        if (type === 'email') {
            return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        }
        if (type === 'phone') {
            return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        }
        return;
    };

    const onSubmit = async (data) => {
        let formData = { ...tempUrl, ...data };
        await fetch(`https://api.ufaber.com/api/leads-submit/fetch-lead/`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams(formData),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success === true) {
                    NotificationManager.success(
                        'Form Submitted Successfully',
                        'success!!'
                    );
                    setTimeout(() => {
                        setIsSubmitted(false);
                        // redirect_to_page !== "" ? navigate(redirect_to_page) : window.location.reload();
                        navigate(
                            'https://abroadninja.in/online/StudyAbroad/thankyou.html'
                        );
                    }, 3000);
                }
                if (data.error === true) {
                    setIsSubmitted(false);
                    NotificationManager.error(
                        'While Submitting error Occurs',
                        'error!!'
                    );
                }
            });
    };

    let sectionOneData = filterSlug.section_once;
    let sectionTwoData = filterSlug.section_two;
    let sectionThreeData = filterSlug.section_three;
    let sectionFourData = filterSlug.section_four;
    let whyData = filterSlug.why;
    let form = filterSlug.marketing_form;

    const onScrollTo = (id) => {
        scrollTo(id);
    };

    return (
        <StudyMarketingStyled>
            <>
                {filterSlug.slug === 'CanadaPRConsultants' ? (
                    <SEO
                        title="Canada Immigration Process | Canada PR Process"
                        description="Fulfil Your Canada PR Dream With Abroad Ninja, India's Leading Immigration Consultants with 98% Success Rate Trusted By Over 2 Lakh Canditates."
                        keywords="Canada PR, Canada Immigration, Canada Permanent Residency, Canada PR Consultants, Canada Immigration Consultants, Settle in Canada, Live in Canada, Work in Canada, Move to Canada Permanently, Canadian PR, Canadian Immigration"
                    />
                ) : filterSlug.slug === 'AustraliaPRConsultants' ? (
                    <SEO
                        title="Australia Immigration Process | Australia PR Process"
                        description="Fulfil Your Australia PR Dream With Abroad Ninja, India's Leading Immigration Consultants with 98% Success Rate Trusted By Over 2 Lakh Canditates."
                        keywords="Australia PR, Australia Immigration, Australia Permanent Residency, Australia PR Consultants, Australia Immigration Consultants, Settle in Australia, Live in Australia, Work in Australia, Move to Australia Permanently, Australian PR, Australian Immigration"
                    />
                ) : filterSlug.slug === 'StudyAbroadConsultants' ? (
                    <SEO
                        title="India's Leading Study Abroad Consultants | Overseas Education Consultants"
                        description="Fulfil Your Study Abroad Dreams With Abroad Ninja, India's Leading Study Abroad Consultants Trusted By Over 2 Lakh Students. Affiliation with Over 200 Universities and 98% Success Rate."
                        keywords="	
                            Study Abroad Consultants in Mumbai,Study Abroad Consultants in Delhi,Study Abroad Consultants in Bangalore,Study Abroad Consultants in Hyderabad,Study Abroad Consultants in Pune,Study Abroad Consultants in Nashik,Study Abroad Consultants in Nagpur,Study Abroad Consultants in Kochi,Study Abroad Consultants in Coimbatore,Study Abroad Consultants in Chennai,Study Abroad Consultants in Kolkata,Study Abroad Consultants in Vizag,Study Abroad Consultants in Guntur,Study Abroad Consultants in Vijayawada,Study Abroad Consultants in Ahmedabad,Study Abroad Consultants in Vadodara,Study Abroad Consultants in Jaipur,Study In UK,Study In USA,Study In Canada,Study In Australia,Masters Degree In Canada,Masters Degree In USA,Masters Degree In Australia,Masters Degree In UK"
                    />
                ) : (
                    ''
                )}
                <div
                    className="header-brand-logo"
                    style={{
                        backgroundColor: `${sectionTwoData.background_color}`,
                    }}
                >
                    <img src={filterSlug?.brand_logo?.url} alt="" />
                </div>
                {/* <section className="section__one">
                    <h1
                        style={{
                            color: `${filterSlug.brand_color}`,
                        }}
                    >
                        {sectionOneData.heading}
                    </h1>
                    <div className="count__div">
                        <p
                            style={{
                                color: `${filterSlug.brand_color}`,
                            }}
                        >
                            {sectionOneData.subheading_1}
                        </p>
                        <div className="count__right">
                            <img
                                src={sectionOneData.math_img.url}
                                alt={sectionOneData.math_img.name}
                            />
                            <p
                                style={{
                                    color: `${filterSlug.brand_color}`,
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: sectionOneData.subheading_2,
                                }}
                            />
                        </div>
                    </div>
                    <div className="flag__web__img">
                        <img
                            src={sectionOneData.heading_img.url}
                            alt={sectionOneData.heading_img.name}
                        />
                    </div>
                    <div className="flag__mob__img">
                        <img
                            src={sectionOneData.mobile_heading_img.url}
                            alt={sectionOneData.mobile_heading_img.name}
                        />
                    </div>
                </section> */}
                <section
                    className="section__two"
                    style={{
                        backgroundColor: `${sectionTwoData.background_color}`,
                    }}
                >
                    <div className="sec__two__info">
                        {/* <p
                            className="getupto"
                            style={{
                                color: `${filterSlug.brand_color}`,
                            }}
                        >
                            {sectionTwoData.heading}
                        </p> */}

                        <p
                            className="scholarship"
                            style={{
                                color: `${filterSlug.brand_color}`,
                            }}
                        >
                            {sectionTwoData.heading}
                            {sectionTwoData.subheading_1}
                        </p>

                        <p
                            className="profile__details"
                            style={{
                                color: `${filterSlug.brand_color}`,
                            }}
                            dangerouslySetInnerHTML={{
                                __html: sectionTwoData.subheading_2,
                            }}
                        />
                        {/* <p
                            className="profile__details"
                            style={{
                                color: `${filterSlug.brand_color}`,
                            }}
                        >
                            {sectionTwoData.subheading_2}
                        </p> */}
                        <div className="image-btn-wrapper">
                            <div className="sec__two-right-img-wrapper">
                                <img
                                    src={sectionTwoData?.image?.url}
                                    alt={'image'}
                                />
                            </div>
                        </div>
                        <a
                            className="check-your-eligibility"
                            onClick={() =>
                                onScrollTo(sectionTwoData.redirect_to)
                            }
                            style={{
                                backgroundColor: `${filterSlug?.button_color}`,
                            }}
                        >
                            {sectionTwoData.button_name}
                        </a>
                    </div>
                    <div id="marketing_form">
                        <div className="sec__five__wrapper">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <h2 className="form_heading">
                                    {filterSlug?.formheading ?? ''}
                                </h2>
                                {form.map((item) => {
                                    if (
                                        item.strapi_component ===
                                        'marketing-input.marketing-input'
                                    ) {
                                        return (
                                            <>
                                                <label
                                                    htmlFor=""
                                                    style={{
                                                        color: `${filterSlug.brand_color}`,
                                                    }}
                                                >
                                                    {item.label}
                                                </label>
                                                <div className="input-wrapper">
                                                    {item.name === 'phone' && (
                                                        <input
                                                            type="text"
                                                            value="+91"
                                                            readonly
                                                            className="phone_code"
                                                        />
                                                    )}

                                                    <input
                                                        type={`${
                                                            item.name ===
                                                            'phone'
                                                                ? 'number'
                                                                : 'text'
                                                        }`}
                                                        placeholder={
                                                            item.place_holder
                                                        }
                                                        {...register(
                                                            item.name,
                                                            {
                                                                required:
                                                                    item.mandatory,
                                                                pattern:
                                                                    funPattern(
                                                                        item.name
                                                                    ),
                                                            }
                                                        )}
                                                        className={`${
                                                            item.name ===
                                                            'phone'
                                                                ? 'phone_number'
                                                                : ''
                                                        }`}
                                                    />
                                                </div>
                                                {errors[item.name] && (
                                                    <span className="error">
                                                        This field is required
                                                    </span>
                                                )}
                                            </>
                                        );
                                    }

                                    if (
                                        item.strapi_component ===
                                        'marketing-select.marketing-select'
                                    ) {
                                        return (
                                            <>
                                                <label
                                                    htmlFor=""
                                                    style={{
                                                        color: `${filterSlug.brand_color}`,
                                                    }}
                                                >
                                                    {item.label}
                                                </label>
                                                <select
                                                    {...register(item.name, {
                                                        required:
                                                            item.mandatory,
                                                        pattern: funPattern(
                                                            item.name
                                                        ),
                                                    })}
                                                >
                                                    <option value="">
                                                        Select your Target
                                                        Degree
                                                    </option>
                                                    {item.option.map((opt) => {
                                                        return (
                                                            <option
                                                                value={
                                                                    opt.option
                                                                }
                                                            >
                                                                {opt.option}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                                {errors[item.name] && (
                                                    <span className="error">
                                                        This field is required
                                                    </span>
                                                )}
                                            </>
                                        );
                                    }
                                })}

                                {/* <p>Get in touch for a </p> */}

                                <button
                                    type="submit"
                                    disabled={isSubmitted}
                                    className="btn-detailed-eval"
                                    style={{
                                        backgroundColor: `${filterSlug?.button_color}`,
                                    }}
                                >
                                    <svg
                                        width={24}
                                        height={24}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M14.9994 3H4.99937C3.88937 3 2.99937 3.89 2.99937 5V10.82C3.57759 10.2587 4.256 9.811 4.99937 9.5V5H11.9994V10.82C12.0294 10.85 12.0694 10.87 12.0994 10.9C12.4394 11.24 12.7294 11.61 12.9694 12H18.9994V19H12.9694C12.7294 19.39 12.4394 19.76 12.0994 20.1C11.7394 20.45 11.3494 20.74 10.9394 21H18.9994C20.1094 21 20.9994 20.11 20.9994 19V9L14.9994 3ZM13.9994 10V4.5L19.4994 10H13.9994ZM7.49937 11C4.99937 11 2.99937 13 2.99937 15.5C2.99937 16.38 3.24937 17.21 3.68937 17.9L0.609375 21L1.99937 22.39L5.11937 19.32C5.80937 19.75 6.62937 20 7.49937 20C9.99937 20 11.9994 18 11.9994 15.5C11.9994 13 9.99937 11 7.49937 11ZM7.49937 18C6.83633 18 6.20045 17.7366 5.73161 17.2678C5.26277 16.7989 4.99937 16.163 4.99937 15.5C4.99937 14.837 5.26277 14.2011 5.73161 13.7322C6.20045 13.2634 6.83633 13 7.49937 13C8.16242 13 8.7983 13.2634 9.26714 13.7322C9.73598 14.2011 9.99938 14.837 9.99938 15.5C9.99938 16.163 9.73598 16.7989 9.26714 17.2678C8.7983 17.7366 8.16242 18 7.49937 18Z"
                                            fill="white"
                                        />
                                    </svg>
                                    {isSubmitted
                                        ? 'Submitting..'
                                        : 'Schedule Evaluation'}
                                </button>
                                <p className="form-accept">
                                    By submitting this form, you explicitly
                                    solicit a call & email from us
                                </p>
                            </form>
                        </div>
                    </div>
                </section>
                <section className="whyGermanySection">
                    {whyData.length && (
                        <p className="whyGermanyText">
                            {filterSlug?.whyHeading ?? 'Why Heading'}
                        </p>
                    )}

                    <div className="whyGermanyCardsWrapper">
                        {whyData.map((w, index) => (
                            <div
                                key={index}
                                className="whyGermanyIndividualCards"
                            >
                                <img src={w.image.url} alt={w.image.name} />
                                <p>{w.description}</p>
                            </div>
                        ))}
                        {/* <div className="whyGermanyIndividualCards">
                            <img
                                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/5b059a68f653854a33fee405025b2e62.svg"
                                alt=""
                            />
                            <p>11 QS ranking universities</p>
                        </div>
                        <div className="whyGermanyIndividualCards">
                            <img
                                src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/0e7d7f54a107c89dadb567fba028a9ab.168562790546015"
                                alt=""
                            />
                            <p>Affordable Or Free Education</p>
                        </div>
                        <div className="whyGermanyIndividualCards">
                            <img
                                src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/8fb41be4924215e6e816b2390ebf1d0d.168562792137115"
                                alt=""
                            />
                            <p>PSW visa is for 2-3 years</p>
                        </div>
                        <div className="whyGermanyIndividualCards">
                            <img
                                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/cf0ddaef542f9e5126749dd4ce3124b1.png"
                                alt=""
                            />
                            <p>
                                Avail scholarship programs starting from 10,000
                                €
                            </p>
                        </div>
                        <div className="whyGermanyIndividualCards">
                            <img
                                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/b45e90f7541d921270c6b2cd7b20bff2.png"
                                alt=""
                            />
                            <p>Work 20-40 hours per week while studying</p>
                        </div> */}
                    </div>
                </section>
                <section className="sectio__three">
                    <div className="sec__three__div">
                        <p
                            className="sec__three__heading"
                            style={{
                                color: `${filterSlug.brand_color}`,
                            }}
                            dangerouslySetInnerHTML={{
                                __html: sectionThreeData.heading,
                            }}
                        />

                        <div className="sec__three__card__wrapper">
                            {sectionThreeData.card.map((item) => {
                                return (
                                    <div className="card">
                                        <img
                                            src={item.card_img.url}
                                            alt={item.card_img.name}
                                        />
                                        <p>{item.description}</p>
                                    </div>
                                );
                            })}
                        </div>
                        <p className="free">{sectionThreeData.info}</p>
                        <a
                            className="sec__three__btn"
                            // href={sectionThreeData.redirect_to}
                            onClick={() =>
                                onScrollTo(sectionThreeData.redirect_to)
                            }
                            style={{
                                backgroundColor: `${filterSlug?.button_color}`,
                            }}
                        >
                            <svg
                                width={20}
                                height={20}
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.65625 10C8.35 10 7.23177 9.5349 6.30156 8.60469C5.37135 7.67448 4.90625 6.55625 4.90625 5.25C4.90625 3.94375 5.37135 2.82552 6.30156 1.89531C7.23177 0.965104 8.35 0.5 9.65625 0.5C10.9625 0.5 12.0807 0.965104 13.0109 1.89531C13.9411 2.82552 14.4062 3.94375 14.4062 5.25C14.4062 6.55625 13.9411 7.67448 13.0109 8.60469C12.0807 9.5349 10.9625 10 9.65625 10ZM0.15625 19.5V16.175C0.15625 15.5021 0.329625 14.8834 0.676375 14.3189C1.02233 13.7553 1.48229 13.325 2.05625 13.0281C3.28333 12.4146 4.53021 11.9542 5.79688 11.6471C7.06354 11.3407 8.35 11.1875 9.65625 11.1875C10.9625 11.1875 12.249 11.3407 13.5156 11.6471C14.7823 11.9542 16.0292 12.4146 17.2563 13.0281C17.8302 13.325 18.2902 13.7553 18.6361 14.3189C18.9829 14.8834 19.1562 15.5021 19.1562 16.175V19.5H0.15625ZM2.53125 17.125H16.7812V16.175C16.7812 15.9573 16.727 15.7594 16.6186 15.5813C16.5093 15.4031 16.3656 15.2646 16.1875 15.1656C15.1187 14.6313 14.0401 14.2303 12.9516 13.9627C11.863 13.6959 10.7646 13.5625 9.65625 13.5625C8.54792 13.5625 7.44948 13.6959 6.36094 13.9627C5.2724 14.2303 4.19375 14.6313 3.125 15.1656C2.94688 15.2646 2.80358 15.4031 2.69513 15.5813C2.58588 15.7594 2.53125 15.9573 2.53125 16.175V17.125ZM9.65625 7.625C10.3094 7.625 10.8687 7.39225 11.3342 6.92675C11.7989 6.46204 12.0312 5.90312 12.0312 5.25C12.0312 4.59688 11.7989 4.03796 11.3342 3.57325C10.8687 3.10775 10.3094 2.875 9.65625 2.875C9.00313 2.875 8.44421 3.10775 7.9795 3.57325C7.514 4.03796 7.28125 4.59688 7.28125 5.25C7.28125 5.90312 7.514 6.46204 7.9795 6.92675C8.63431 7.19943 9.00144 7.35232 9.65625 7.625Z"
                                    fill="white"
                                />
                            </svg>
                            {sectionThreeData.button_name}
                        </a>
                    </div>
                </section>
                <section
                    className="section__four"
                    style={{
                        backgroundColor: `${sectionFourData?.background_color}`,
                    }}
                >
                    <div className="sec-four-logo">
                        <img
                            src={filterSlug?.brand_logo?.url}
                            alt="brand logo"
                        />
                    </div>
                    <p
                        className="sec__four__heading"
                        style={{
                            color: `${filterSlug.brand_color}`,
                        }}
                    >
                        {sectionFourData.heading}
                    </p>
                    <p
                        className="sec__four__heading__bold"
                        style={{
                            color: `${filterSlug.brand_color}`,
                        }}
                    >
                        {sectionFourData.subheading}
                    </p>
                    <div className="form__wrapper">
                        {sectionFourData.success_img.map((item) => {
                            return (
                                <div className="form">
                                    <img
                                        src={item.img.url}
                                        alt={item.img.name}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <a
                        // href={sectionFourData.redirect_to}
                        onClick={() => onScrollTo(sectionFourData.redirect_to)}
                        className="btn-view-all"
                        style={{
                            backgroundColor: `${filterSlug?.button_color}`,
                        }}
                    >
                        <svg
                            width={17}
                            height={22}
                            viewBox="0 0 17 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M15.4545 2.46289H13.1364V1.71289C13.1364 1.31507 12.9735 0.933535 12.6837 0.65223C12.3939 0.370926 12.0008 0.212891 11.5909 0.212891H5.40909C4.99921 0.212891 4.60612 0.370926 4.31629 0.65223C4.02646 0.933535 3.86364 1.31507 3.86364 1.71289V2.46289H1.54545C1.13557 2.46289 0.742482 2.62093 0.452653 2.90223C0.162824 3.18354 0 3.56507 0 3.96289V19.7129C0 20.1107 0.162824 20.4922 0.452653 20.7736C0.742482 21.0549 1.13557 21.2129 1.54545 21.2129H15.4545C15.8644 21.2129 16.2575 21.0549 16.5473 20.7736C16.8372 20.4922 17 20.1107 17 19.7129V3.96289C17 3.56507 16.8372 3.18354 16.5473 2.90223C16.2575 2.62093 15.8644 2.46289 15.4545 2.46289ZM5.40909 2.11221H11.5909V4.71289H5.40909V2.11221ZM14.6296 18.9122H2.125V3.96289H3.36136V6.91221H13.2523V3.96289H14.4886L14.6296 18.9122Z"
                                fill="white"
                            />
                        </svg>
                        {sectionFourData.button_name}
                    </a>
                </section>
                <section className="seoTagsSection">
                    <div className="seoTagsWrapper">
                        {filterSlug?.marketing_footer.map((f) => (
                            <div className="individualSeoTagDiv">
                                <span className="individualSeoTagDiv-Title">
                                    {f.heading}
                                </span>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: f.content,
                                    }}
                                />
                            </div>
                        ))}
                        {/* <div className="individualSeoTagDiv">
                            <span className="individualSeoTagDiv-Title">
                                Study Abroad Consultants In
                            </span>
                            <div>
                                <p>Study Abroad Consultants in Mumbai</p>
                                <p>Study Abroad Consultants in Delhi</p>
                                <p>Study Abroad Consultants in Bangalore</p>
                                <p>Study Abroad Consultants in Hyderabad</p>
                                <p>Study Abroad Consultants in Pune</p>
                                <p>Study Abroad Consultants in Nashik</p>
                                <p>Study Abroad Consultants in Nagpur</p>
                                <p>Study Abroad Consultants in Kochi</p>
                                <p>Study Abroad Consultants in Coimbatore</p>
                                <p>Study Abroad Consultants in Chennai</p>
                                <p>Study Abroad Consultants in Kolkata</p>
                                <p>Study Abroad Consultants in Vizag</p>
                                <p>Study Abroad Consultants in Guntur</p>
                                <p>Study Abroad Consultants in Vijayawada</p>
                                <p>Study Abroad Consultants in Ahmedabad</p>
                                <p>Study Abroad Consultants in Vadodara</p>
                                <p>Study Abroad Consultants in Jaipur</p>
                            </div>
                        </div>
                        <div className="individualSeoTagDiv">
                            <span className="individualSeoTagDiv-Title">
                                Study Abroad
                            </span>
                            <div>
                                <p>Study In UK</p>
                                <p>Study In USA</p>
                                <p>Study In Canada</p>
                                <p>Study In Australia</p>
                            </div>
                        </div>
                        <div className="individualSeoTagDiv">
                            <span className="individualSeoTagDiv-Title">
                                Master Degree
                            </span>
                            <div>
                                <p>Masters Degree In Canada</p>
                                <p>Masters Degree In USA</p>
                                <p>Masters Degree In Australia</p>
                                <p>Masters Degree In UK</p>
                            </div>
                        </div> */}
                    </div>
                </section>
            </>
            <NotificationContainer />
            <script type="text/javascript">
                {window.$crisp.push(['do', 'chat:hide'])}
            </script>
        </StudyMarketingStyled>
    );
};

const StudyMarketingStyled = styled.div`
    @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
    .header-brand-logo {
        display: flex;
        justify-content: center;
        padding: 24px 0;
        background: #e8cd1c;
    }
    .form_heading {
        text-align: center;
        max-width: 280px;
        margin: 0 auto;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 18px;
        color: #113a68;
        line-height: 25px;
    }
    .form-accept {
        text-align: center;
        margin-top: 1rem;
        font-size: 10px;
    }
    .flag__web__img {
        width: 100%;
        max-width: 1127px;
    }
    .header-brand-logo img {
    }
    .flag__web__img > img {
        width: 100%;
    }
    .flag__mob__img {
        display: none;
    }
    .section__one {
        display: none;
        /* display: flex; */
        flex-direction: column;
        align-items: center;
        padding: 71.97px 16px 74.16px;
    }
    .section__one > h1 {
        width: 100%;
        max-width: 381px;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        text-transform: uppercase;
        /* color: #92140c; */
    }
    .count__div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }
    .count__div > p {
        font-weight: 600;
        font-size: 120px;
        line-height: 145px;
        display: flex;
        align-items: flex-end;
        letter-spacing: -0.035em;
        text-transform: capitalize;
        /* color: #92140c; */
    }
    .count__right {
        max-height: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        img {
            height: 35px;
            width: 35px;
        }
    }
    .count__right > p {
        font-weight: 800;
        font-size: 16px;
        line-height: 19px;
        display: flex;
        align-items: flex-end;
        letter-spacing: 0.04em;
        /* color: #92140c; */
    }

    /*section two*/
    .section__two {
        /* background-color: #ffcf99; */
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 72px 16px;
        gap: 93px;
    }
    .check-your-eligibility {
        display: none;
    }
    .sec__two__info {
        max-width: 370px;
    }
    .getupto {
        font-weight: 800;
        font-size: 24px;
        line-height: 29px;
        text-transform: uppercase;
        /* color: #92140c; */
        margin-bottom: 12px;
    }
    .scholarship {
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 39px;
        text-transform: uppercase;
        margin-bottom: 13px;
    }
    .scholarship > span {
        font-size: 32px;
        line-height: 38.73px;
    }
    .profile__details {
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #313131;
        margin-bottom: 1rem;
    }
    .sec__two__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12.84px;
        /* background: #e71d36; */
        border-radius: 8px;
        padding: 19px 0px;
        width: 100%;
        max-width: 291px;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        color: #ffffff;
        margin-top: 1rem;
    }
    .sec__two__btn-mobile {
        display: none;
    }
    /*section three*/
    .sectio__three {
        display: flex;
        justify-content: center;
        padding: 72px 16px;
    }
    .sec__three__div {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 48px 16px 25px;
        border: 2px solid rgba(60, 43, 173, 0.1);
        border-radius: 16px;
        width: 100%;
        max-width: 1079px;
    }
    .sec__three__heading {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #313131;
    }
    .sec__three__heading > span {
        font-size: 40px;
        line-height: 48.41px;
    }
    .sec__three__card__wrapper {
        display: flex;
        justify-content: center;
        gap: 24px;
        width: 100%;
        margin-bottom: 48px;
        margin-top: 2rem;
        flex-wrap: wrap;
    }
    .card {
        background: rgba(67, 80, 88, 0.1);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        width: 100%;
        max-width: 291px;
        text-align: center;
        padding: 32px 0;
    }
    .card > p {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #454a44;
    }
    .free {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #435058;
        margin-bottom: 1rem;
    }
    .sec__three__btn {
        /* background: #e71d36; */
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 335px;
        gap: 14.19px;
        padding: 19px 0px;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        text-transform: capitalize;
        color: #ffffff;
    }

    /*section four*/
    .section__four {
        display: flex;
        flex-direction: column;
        align-items: center;
        /* background-color: #ffcf99; */
        padding: 56.22px 16px 72px;
    }
    .sec__four__logo {
        margin-bottom: 32.22px;
    }
    .sec__four__heading {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        letter-spacing: 0.26em;
        text-transform: uppercase;
        /* color: #92140c; */
    }
    .sec__four__heading__bold {
        font-weight: 600;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        text-transform: capitalize;
        /* color: #92140c; */
        margin-bottom: 32px;
    }
    .form__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%;
        gap: 48.5px;
        margin-bottom: 48px;
    }
    .form {
        width: 100%;
        max-width: 277px;
        height: auto;
    }
    .form > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .btn-view-all {
        /* background: #e71d36; */
        border-radius: 8px;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        color: #ffffff;
        width: 100%;
        max-width: 335px;
        padding: 20.5px 0px;
        gap: 15.5px;
    }

    /*section five*/
    .section__five {
        display: flex;
        justify-content: center;
        padding: 72.29px 16px;
    }
    .sec__five__wrapper {
        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(17, 58, 104, 0.18);
        border-radius: 16px;
        width: 100%;
        max-width: 430px;
        padding: 24px 32px;
    }
    form {
        display: flex;
        flex-direction: column;
    }
    label {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        /* color: #92140c; */
        margin-bottom: 8px;
    }
    input {
        width: 100%;
        border: 1.12329px solid rgba(17, 58, 104, 0.2);
        border-radius: 8px;
        padding: 16px 12px;
        margin-bottom: 1rem;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
    }
    select {
        width: 100%;
        border: 1.12329px solid rgba(17, 58, 104, 0.2);
        border-radius: 8px;
        margin-bottom: 1rem;
        outline: none;
        background-color: white;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-indent: 16px;
        height: 50.22px;
        text-indent: 16px;
        color: #313131 !important;
    }
    input::placeholder {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: rgba(49, 49, 49, 0.4);
    }
    form > p {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #435058;
    }
    .input-wrapper {
        display: flex;
    }

    .phone_code {
        width: 50px;
        border-radius: 0.25em 0 0 0.25em;
        &:focus {
            box-shadow: none;
        }
    }

    .phone_number {
        border-radius: 0 8px 8px 0;
        &:focus {
            box-shadow: none;
        }
    }
    .error {
        color: red;
        margin-bottom: 0.8rem;
    }
    .btn-detailed-eval {
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        text-transform: capitalize;
        width: 100%;
        max-width: 100%;
        color: #ffffff;
        /* background: #e71d36; */
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        margin: 0 auto;
        padding: 19px 0;
        border: none;
        cursor: pointer;
    }
    .sec-four-logo {
        margin-bottom: 2rem;
    }
    .sec__two-right-img-wrapper {
        width: 330px;
        height: 480px;
    }
    .image-btn-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1rem;
    }
    .sec__two-right-img-wrapper img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .whyGermanySection {
        width: 100%;
        padding: 154.6px 16px 84px;
    }
    .whyGermanyText {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 800;
        font-size: 32px;
        line-height: 39px;
        text-transform: uppercase;
        color: #000000;
        text-align: center;
        margin-bottom: 48px;
    }
    .whyGermanyCardsWrapper {
        width: 100%;
        max-width: 1188px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        column-gap: 40px;
        row-gap: 40px;
    }

    .whyGermanyIndividualCards {
        width: 100%;
        max-width: 346px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 16px;
    }
    .whyGermanyIndividualCards p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        color: #000000;
        max-width: 346px;
    }
    .seoTagsSection {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        padding: 89.85px 16px;
    }
    .seoTagsWrapper {
        width: 100%;
        max-width: 1119px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .individualSeoTagDiv-Title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        color: #292929;
        margin-bottom: 16px;
    }
    .individualSeoTagDiv {
        display: flex;
        flex-direction: column;
    }
    .individualSeoTagDiv p {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        color: #292929;
    }
    @media (max-width: 1000px) {
        .sec__three__card__wrapper {
            flex-wrap: wrap;
        }
        .section__two {
            gap: 4rem;
            flex-wrap: wrap;
        }
    }
    @media (max-width: 890px) {
        .image-btn-wrapper {
            align-items: center;
        }
        .check-your-eligibility {
            font-style: normal;
            font-weight: 800;
            font-size: 20px;
            line-height: 24px;
            text-transform: capitalize;
            width: 100%;
            max-width: 100%;
            color: #ffffff;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 12px;
            padding: 19px 0;
            margin-top: 1rem;
        }
    }
    @media (max-width: 768px) {
        .seoTagsWrapper {
            flex-wrap: wrap;
            justify-content: flex-start;
            gap: 3rem;
        }
        .section__two {
            gap: 2rem;
            flex-wrap: wrap;
            align-items: center;
        }
    }
    @media (max-width: 500px) {
        .form-accept {
            font-size: 9px;
            margin-top: 6px;
        }
        .form_heading {
            font-size: 20px;
        }
        .seoTagsSection {
            padding: 48px 16px;
        }
        .individualSeoTagDiv-Title {
            text-align: center;
            font-weight: 600;
            font-size: 12px;
            line-height: 25px;
        }
        .individualSeoTagDiv p {
            font-weight: 500;
            font-size: 12px;
            line-height: 25px;
            text-align: center;
        }
        .seoTagsWrapper {
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
        }
        .check-your-eligibility {
            display: none;
        }
        .sec__two-right-img-wrapper {
            display: none;
        }
        .whyGermanyText {
            font-weight: 800;
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 20px;
        }
        .whyGermanyIndividualCards p {
            font-weight: 800;
            font-size: 14px;
            line-height: 17px;
        }
        .whyGermanySection {
            padding: 80px 16px 0px;
        }
        .phone_code {
            width: 22%;
        }
        .header-brand-logo {
            border-bottom: 0px;
            padding: 1.5rem 0rem;
        }
        .section__one {
            padding: 0rem 0rem 2.5rem;
        }
        .flag__web__img {
            display: none;
        }
        .flag__mob__img {
            display: block;
            width: 100%;
            position: relative;
            top: -10px;
        }
        .flag__mob__img img {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
        .section__one > h1 {
            font-weight: 600;
            font-size: 1.5rem;
            line-height: 29px;
            padding: 0rem 1rem;
        }
        .count__div > p {
            font-weight: 600;
            font-size: 80px;
            line-height: 60px;
        }
        .count__right {
            flex-direction: row;
        }
        .count__div {
            width: 100%;
            padding: 0rem 1rem;
            justify-content: flex-start;
            margin-top: 1rem;
            align-items: flex-end;
        }
        .section__two {
            padding: 4px 16px;
            gap: 0.1rem;
        }
        .scholarship {
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            text-transform: uppercase;
        }
        .profile__details {
            font-size: 1rem;
            line-height: 19px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            /* color: #92140c; */
        }
        .sec__three__heading {
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            /* color: #92140c; */
            text-align: left;
        }
        .sec__three__div {
            align-items: stretch;
        }
        .card {
            max-width: 100%;
            gap: 12px;
            padding: 18.5px 0px;
        }
        .section__four {
            align-items: flex-start;
        }
        .sec__four__heading__bold {
            text-align: left;
        }
        .sec-four-logo {
            display: flex;
            justify-content: center;
            width: 100%;
        }
        .form {
            max-width: 100%;
        }
        .form__wrapper {
            gap: 1.25rem;
        }
        .section__four {
            padding: 56.22px 16px 56px;
        }
        .sec__two-right-img-wrapper {
            width: 100%;
            height: 480px;
        }
        .sec__two__info {
            max-width: 100%;
        }
        .sec__two__btn {
            display: none;
        }
        .image-btn-wrapper {
            width: 100%;
        }
        .sec__two__btn-mobile {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12.84px;
            /* background: #e71d36; */
            border-radius: 8px;
            padding: 19px 0px;
            width: 100%;
            font-weight: 800;
            font-size: 20px;
            line-height: 24px;
            color: #ffffff;
            margin-top: 1rem;
            max-width: 100%;
        }

        .sec__three__btn {
            max-width: 100%;
        }
        #marketing_form {
            padding-top: 0.1rem;
        }

        .btn-view-all {
            max-width: 100%;
        }
        .sec__five__wrapper {
            padding: 30px 18px;
        }
    }
`;
export default StudyMarketing;
