import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import ReactMarkdown from "react-markdown";

import rehypeRaw from "rehype-raw";
import gfm from "remark-gfm";
import styled from "styled-components";
import Layout from "../components/layout.component";
import SEO from "../components/seo";
const TermsCondition = () => {
	const queryData = useStaticQuery(query);
	const {
		allStrapiPrivacyPolicyPrivacyPolicyTextnode: { nodes: data_privacy_policy },
	} = queryData;
	const privacyPolicy = data_privacy_policy[0];

	return (
		<Layout>
			
			<TermConditionStyled id="terms_and_condition">
				<div id="terms_and_condition_wrapper">
					<h1>Privacy Policy</h1>
					<div id="terms_and_condition_ol">
						<ReactMarkdown rehypePlugins={[rehypeRaw]} plugins={[gfm]}>
							{privacyPolicy.privacy_policy}
						</ReactMarkdown>
					</div>
				</div>
			</TermConditionStyled>
		</Layout>
	);
};

const TermConditionStyled = styled.section`
	padding: 10rem;

	#terms_and_condition_wrapper {
		width: 100%;
	}
	#terms_and_condition_wrapper h1 {
		font-family: "Inter";
		font-style: normal;
		font-weight: 900;
		font-size: 3rem;
		line-height: 58px;
		text-align: left;
		color: #113a68;
		margin-bottom: 4rem;
	}
	ol {
		list-style-position: inside;
	}
	ol li {
		font-family: "Inter";
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 19px;
		color: #242424;
		margin-bottom: 1rem;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 1rem;
	}
	p {
		margin-bottom: 0.8rem;
	}
	@media (max-width: 1280px) {
		padding: 5rem;
	}
	@media (max-width: 850px) {
		padding: 3.5rem;
	}
	@media (max-width: 600px) {
		padding: 1.5rem;
	}
	@media (max-width: 500px) {
		padding: 0.75rem;

		#terms_and_condition_wrapper h1 {
			font-weight: 900;
			font-size: 1.25rem;
			margin-bottom: 1rem;
		}
		ol li {
			font-weight: 400;
			font-size: 0.75rem;
			line-height: 15px;
		}
	}
`;
export const query = graphql`
	query {
		allStrapiPrivacyPolicyPrivacyPolicyTextnode {
			nodes {
				privacy_policy
			}
		}
	}
`;
export const Head = () => {
	return (
		<SEO
			title="Privacy Policies | Abroad Ninja |" 
			description="Read our privacy policy to learn how we protect your personal data when you use our study abroad services. Learn about our approach here."
			og_type="website"
			og_url="https://abroadninja.in/privacy_policy/"
			og_title="Privacy Policies | Abroad Ninja |"
			og_description="Read our privacy policy to learn how we protect your personal data when you use our study abroad services. Learn about our approach here."
			og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
			twitter_card="summary_large_image"
			twitter_url="https://abroadninja.in/privacy_policy/"
			twitter_title="Privacy Policies | Abroad Ninja |"
			twitter_description="Read our privacy policy to learn how we protect your personal data when you use our study abroad services. Learn about our approach here."
			twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
			robots="index, follow"
			canonical="https://abroadninja.in/privacy_policy/"
		/>
	)
}
export default TermsCondition;
