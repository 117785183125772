import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronUp,
	faEnvelope,
	faMapMarkerAlt,
	faPhone,
} from "@fortawesome/free-solid-svg-icons";

import { useFooterData } from "../hooks/footer";

const Footer = () => {
	const {
		email,
		location,
		phone_no,
		footer_bottom_section,
		footer_offerings,
		footer_tool,
		social,
	} = useFooterData();

	return (
		<FooterStyled>
			<Footerwrapper>
				<div className="back_to_top_wrapper">
					<Link to={"#"}>
						<FontAwesomeIcon icon={faChevronUp} size="1x" />
						<span id="back_to_top_text">Back to top</span>
					</Link>
				</div>
				<MiddleSectionWrapper>
					<div id="offering_aboutus_social_wrapper">
						<p id="offering_from_heading">
							<span id="offering_from_subheading">
								An Offering from
								<br />
							</span>
							uFaber Edutech Pvt. Ltd.
						</p>

						<div id="social_media_icons">
							{social.map((social, index) => {
								return (
									<Link key={index} to={social.redirect_to}>
										<div className="social_media_icons_wrapper">
											<img
												src={social.social_img.url}
												alt={social.social_img.name}
											/>
										</div>
									</Link>
								);
							})}
						</div>

						<div id="contact_details_wrapper">
							<div className="contact_details">
								<IconWrapper>
									<FontAwesomeIcon
										icon={faEnvelope}
										size="1x"
										color="#59445A"
									/>
								</IconWrapper>
								<p className="contact_detail">
									<p>{email}</p>
								</p>
							</div>

							<div className="contact_details">
								<IconWrapper>
									<FontAwesomeIcon
										icon={faMapMarkerAlt}
										size="1x"
										color="#59445A"
									/>
								</IconWrapper>
								<p id="contact_address">91Springboard, Godrej & Boyce, Gate No. 2, Plant No. 6, LBS Marg, Opposite Vikhroli Bus Depot, Vikhroli West, Mumbai, Maharashtra 400079</p>
							</div>

							<div className="contact_details">
								<IconWrapper>
									<FontAwesomeIcon
										icon={faPhone}
										size="1x"
										color="#59445A"
									/>
								</IconWrapper>
								<p className="contact_detail">
									<p>{phone_no}</p>
								</p>
							</div>
						</div>
					</div>

					<div id="tools__offerings_section_wrapper">
						<div className="tools__offerings_section">
							<span className="tools__offerings_heading">
								Tools
							</span>
							<div className="tools__offerings_content">
								{footer_tool.map((tool, index) => {
									return (
										<span key={index}>
											<Link to={tool.redirect_to}>
												{tool.heading}
											</Link>
										</span>
									);
								})}
							</div>
						</div>
						<div className="tools__offerings_section">
							<span className="tools__offerings_heading">
								Offerings
							</span>
							<div className="tools__offerings_content">
								{footer_offerings.map((offering, index) => {
									return (
										<span key={index}>
											<Link to={offering.redirect_to}>
												{offering.heading}
											</Link>
										</span>
									);
								})}
							</div>
						</div>
					</div>
				</MiddleSectionWrapper>

				<BottomWrapperSection>
					{footer_bottom_section.map((footer, index) => {
						return (
							<SmallStyled key={index} index={index}>
								<Link to={footer.redirect_to}>
									{footer.heading}
								</Link>
							</SmallStyled>
						);
					})}
				</BottomWrapperSection>

				<SocialMediaMobileStyle>
					{social.map((social, index) => {
						return (
							<Link key={index} to={social.redirect_to}>
								<div className="social_media_icons_wrapper">
									<img
										src={social.social_img.url}
										alt={social.social_img.name}
									/>
								</div>
							</Link>
						);
					})}
				</SocialMediaMobileStyle>
				<OfferingMobileViewHeading>
					<span id="mobile_view_offering_from_subheading">
						An Offering from
						<br />
					</span>
					uFaber Edutech Pvt. Ltd.
				</OfferingMobileViewHeading>
				<ContactMobileViewDetails>
					<div className="contact_details cont_email">
						<IconWrapper>
							<FontAwesomeIcon
								icon={faEnvelope}
								size="1x"
								color="#59445A"
							/>
						</IconWrapper>
						<p className="contact_detail">
							<p>{email}</p>
						</p>
					</div>

					<div className="contact_details cont_address">
						<IconWrapper>
							<FontAwesomeIcon
								icon={faMapMarkerAlt}
								size="1x"
								color="#59445A"
							/>
						</IconWrapper>
						<p id="contact_address">91Springboard, Godrej & Boyce, Gate No. 2, Plant No. 6, LBS Marg, Opposite Vikhroli Bus Depot, Vikhroli West, Mumbai, Maharashtra 400079</p>
					</div>

					<div className="contact_details cont_phone">
						<IconWrapper>
							<FontAwesomeIcon
								icon={faPhone}
								size="1x"
								color="#59445A"
							/>
						</IconWrapper>
						<p className="contact_detail">
							<p> {phone_no}</p>
						</p>
					</div>
				</ContactMobileViewDetails>
				<RightReservedWrapper>
					<p>
						2024 © uFaber, All rights reserved uFaber Edutech Pvt.
						Ltd.
					</p>
				</RightReservedWrapper>
			</Footerwrapper>
		</FooterStyled>
	);
};

const FlexColumnCenter = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

const FooterStyled = styled.footer`
	background-color: var(--dark-blue);
	position: relative;
	font-family: var(--ff-primary);
	color: #ffffff;
	background-image: url("https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/5f74fbb0a241420d9bde2b1e6382961c.165995013519987");
	background-repeat: no-repeat;
	background-position: bottom;
	background-size: 100%;

	@media (max-width: 700px) {
		background-image: url("https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/9dd2e3c4ab37411191e5496a71768cd4.svg");
		background-repeat: no-repeat;
		background-position: bottom;
		background-size: 100%;
	}
`;

const Footerwrapper = styled(FlexColumnCenter)`
	padding: 0em 2em;
	.back_to_top_wrapper {
		margin-top: 2rem;
	}
	.back_to_top_wrapper a {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		color: white !important;
		gap: 0.5rem;
	}
	.fa-chevron-up {
		font-size: 0.8rem;
	}
	#back_to_top_text {
		font-family: var(--ff-primary);
		font-style: normal;
		font-weight: 500;
		font-size: 1rem;
		line-height: 19px;
		text-align: center;
		color: #ffffff;
	}

	@media (max-width: 500px) {
		padding: 0 1rem;
	}
`;

const MiddleSectionWrapper = styled.div`
	margin-top: 2rem;
	display: flex;
	width: 100%;
	justify-content: center;
	gap: 16.6875rem;

	#offering_aboutus_social_wrapper {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;

		#offering_from_heading {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			flex-direction: column;
			font-family: var(--ff-primary);
			font-size: 1.5rem;
			font-weight: 700;
			line-height: 29.05px;
			letter-spacing: 0em;
			text-align: left;

			#offering_from_subheading {
				font-size: 1rem;
				font-weight: 400;
				line-height: 19px;
				letter-spacing: 0em;
				text-align: left;
			}
		}
		#social_media_icons {
			margin-top: 1rem;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 1rem;
		}
		.social_media_icons_wrapper {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			background: #ffffff;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		#contact_details_wrapper {
			margin-top: 2.225rem;
			display: flex;
			align-items: flex-start;
			justify-content: space-evenly;
			width: 100%;
			flex-direction: column;
			gap: 1.21875rem;
			.contact_details {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 1em;

				.contact_detail a {
					font-family: var(--ff-primary);
					font-style: normal;
					font-weight: 400;
					font-size: 1rem;
					line-height: 19px;
					color: #ffffff;
				}
				#contact_address {
					max-width: 22.5em;
					font-family: var(--ff-primary);
					font-style: normal;
					font-weight: 400;
					font-size: 1rem;
					line-height: 19px;
					color: #ffffff;
				}
			}
		}
	}
	#tools__offerings_section_wrapper {
		display: flex;
		gap: 12.5em;
		height: 100%;
		.tools__offerings_section {
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			flex-direction: column;
			gap: 1.25em;
			.tools__offerings_heading {
				font-family: var(--ff-primary);
				font-style: normal;
				font-weight: 700;
				font-size: 1.5rem;
				line-height: 29px;
				color: #ffd3d3;
			}
			.tools__offerings_content {
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				flex-direction: column;
				gap: 16px;
			}
			.tools__offerings_content span a {
				font-family: var(--ff-primary);
				font-style: normal;
				font-weight: 500;
				font-size: 1rem;
				line-height: 19px;
				color: #ffffff;
			}
		}
	}

	@media (max-width: 1200px) {
		gap: 13.6875rem;
		#tools__offerings_section_wrapper {
			gap: 4rem !important;
		}
	}

	@media (max-width: 1000px) {
		gap: 4.6875rem !important;
	}

	@media (max-width: 900px) {
		#tools__offerings_section_wrapper {
			flex-direction: column;
		}
	}

	@media (max-width: 700px) {
		flex-direction: column-reverse;
		#offering_aboutus_social_wrapper {
			display: none !important;
		}
		#tools__offerings_section_wrapper {
			gap: 3rem !important;
		}
	}

	@media (max-width: 500px) {
		padding-left: 1rem !important;
	}
`;

const SocialMediaMobileStyle = styled.div`
	display: none;

	@media (max-width: 700px) {
		margin-top: 3.6875em;
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 1rem;
		.social_media_icons_wrapper {
			width: 32px;
			height: 32px;
			border-radius: 50%;
			background: #ffffff;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
`;

const BottomWrapperSection = styled.div`
	margin-top: 3rem;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 3rem;

	@media (max-width: 1200px) {
		gap: 2rem;
	}
	@media (max-width: 900px) {
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
	}

	@media (max-width: 700px) {
		flex-direction: column;
		width: 100%;
		align-items: flex-start;
		gap: 1.25rem;
		flex-wrap: nowrap;
		margin-top: 3rem;
	}

	@media (max-width: 500px) {
		padding-left: 1rem;
	}
`;
const SmallStyled = styled.small`
	a {
		font-family: var(--ff-primary);
		font-style: normal;
		font-weight: 700;
		font-size: 1rem;
		line-height: 19px;
		${({ index }) => {
			if (index > 3)
				return `
			color: #ffd3d3
      `;
			if (index <= 3)
				return `
				color: #fc997c
      `;
		}}
	}
`;

const RightReservedWrapper = styled.div`
	margin-top: 2.5rem;
	> p {
		font-weight: 300;
		font-size: 1rem;
		line-height: 19px;
		color: #c7d0db;
		text-align: center;
		margin-bottom: 1.5em;
	}

	@media (max-width: 700px) {
		> p {
			font-size: 0.9rem;
		}
	}

	@media (max-width: 500px) {
		> p {
			font-size: 0.6875rem;
		}
	}
	@media (max-width: 335px) {
		> p {
			font-size: 0.6rem;
		}
	}
`;

const IconWrapper = styled.div`
	min-height: 24px;
	max-height: 24px;
	min-width: 24px;
	max-width: 24px;
	border-radius: 50%;
	background-color: #f1f1f1;
	display: flex;
	align-items: center;
	justify-content: center;
`;
const ContactMobileViewDetails = styled.div`
	display: none;
	.contact_details {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 1em;
	}

	.contact_detail a {
		font-family: var(--ff-primary);
		font-style: normal;
		font-weight: 400;
		font-size: 1rem;
		line-height: 19px;
		color: #ffffff;
	}
	@media (max-width: 700px) {
		margin-top: 2rem;
		display: flex;
		align-items: flex-start;
		justify-content: space-evenly;
		width: 100%;
		flex-direction: column;
		gap: 1.21875rem;
	}

	@media (max-width: 500px) {
		.contact_detail a,
		#contact_address {
			font-size: 0.875rem;
			line-height: 17px;
		}
		.cont_email {
			order: 3;
		}
		.cont_address {
			order: 1;
		}
		.cont_phone {
			order: 2;
		}
	}
`;

const OfferingMobileViewHeading = styled.p`
	display: none;
	@media (max-width: 700px) {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;
		flex-direction: column;
		font-family: "Inter";
		font-size: 1.5rem;
		font-weight: 700;
		line-height: 29.05px;
		letter-spacing: 0em;
		text-align: left;
		#mobile_view_offering_from_subheading {
			margin-top: 2.5rem;
			font-size: 1rem;
			font-weight: 400;
			line-height: 19px;
			letter-spacing: 0em;
			text-align: left;
		}
	}
	@media (max-width: 500px) {
		font-size: 1.5rem;
		line-height: 29px;
		#mobile_view_offering_from_subheading {
			font-size: 1rem;
			line-height: 19px;
		}
	}
	@media (max-width: 335px) {
		font-size: 1.2rem;
	}
`;

export default Footer;
