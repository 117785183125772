import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import Layout from "../../components/layout.component";
import backButtonIcon from "../../assets/icons/back-button.svg";

const NotFoundPage = () => {
  return (
    <Layout>
      <NotFound>
        <Back>
          <BackLink to="/study-abroad/university-search">
            <img src={backButtonIcon} />
            <p>Go Back</p>
          </BackLink>
        </Back>
        <Message>
          <p>
            We are currently working on this university details, to know more
            you can contact our counsellor
          </p>
          <ScheduleSessionLink
            to="https://abroadninja.in/abroad-form?formName=study-abroad&source=abroad-ninja&utm_sou[…]medium=medium&language=lang&brandid=17&do_what=-&segment=1880"
            target="_blank"
          >
            Schedule Session
          </ScheduleSessionLink>
        </Message>
      </NotFound>
    </Layout>
  );
};
export default NotFoundPage;

const NotFound = styled.div`
  width: 1120px;
  margin: auto;

  @media (max-width: 1200px) {
    max-width: 700px;
  }

  @media (max-width: 768px) {
    max-width: 450px;
  }

  @media (max-width: 480px) {
    max-width: 380px;
  }

  @media (max-width: 400px) {
    max-width: 300px;
  }
`;

const Message = styled.div`
  p {
    text-align: center;
  }
  box-sizing: border-box;
  display: flex;
  gap: 3rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10rem 0 10rem 0;
`;

const Back = styled.div`
  padding: 10rem 0 0 0;
`;

const BackLink = styled(Link)`
  display: flex;
  gap: 1rem;
  background-color: #fff;
  color: #113a68;
  font-weight: 400;
  font-size: 1rem;
`;

const ScheduleSessionLink = styled(Link)`
  background-color: #113a68;
  color: #fff;
  border: none;
  padding: 1rem 2rem;
  border-radius: 8px;
  font-weight: 400;
  font-size: 1rem;
`;
