import React from 'react';
import HealthcareServiceComponent from '../components/Healthcare-Services/HealthcareServiceComponent';
import SEO from "../components/seo";

const HealthcareServices = () => {
    
    return (
        <>        
            <SEO
                title={"Abroad Ninja UK Healthcare Worker Visa"}
                viewport="width=device-width, initial-scale=0, user-scalable=no"
                description={"Explore our specialized UK Nurse Visa program with dedicated mentors, IELTS/OET training, NMC registration assistance, job placement, and more. Start your journey to work as a registered nurse in the UK today!"}
                keywords={"UK Nurse Visa, Healthcare Worker Visa, UK Nursing Jobs, IELTS/OET Training, NMC Registration, Job Placement, Visa Application, OSCE Exam Preparation, Permanent Employment"}
            />
            <HealthcareServiceComponent/>
        </>
    );
}

export default HealthcareServices;
