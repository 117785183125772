import React from 'react';
import './loader.css'
const Loader = () => {
    return (
        <div class="loader-3">
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
            <div class="circle"></div>
        </div>
    );
}

export default Loader;
