import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Layout from '../layout.component';
import { Link } from 'gatsby';
import SEO from '../seo';
import { useStudyData } from '../../hooks/study';
import RightSection from './rightSection';
import HelpYouMakeChoiceCard from '../ui/helpYouMakeChoiceCard';
const StudyPage = ({ linkSlug }) => {
    const isBrowser = typeof window !== 'undefined';

    const studyPage = useStudyData();
    // const { study_content, heading, redirect_to, slug, img } = studyPage[0];
    // let linkSlug;
    // if (isBrowser) {
    //     let url_string = window.location.href;
    //     let url = new URL(url_string);

    //     linkSlug = url.searchParams.get('study-slug');
    // }
    let aStudyPages = studyPage.filter((item) => item.slug === linkSlug);

    let aStudyPage = aStudyPages[0];

    function logit(e) {
        let activeElement;
        document
            .querySelectorAll('.scroll-heading')
            .forEach(function (element, index) {
                let elementPosition = element.getBoundingClientRect().top;
                const elementID = element.getAttribute('data-id');
                let elementright = document.getElementById(elementID);
                let finalPosition = elementPosition - 150;
                elementright.classList.remove('active');
                if (finalPosition <= 0) {
                    activeElement = elementright;
                } else {
                    elementright.classList.remove('active');
                }
            });
        if (activeElement) {
            // document.querySelector(`${activeElement.id}`).scrollIntoView({ behavior: "smooth" });
            // const julie = document.getElementById(activeElement.id);

            // julie.scrollIntoView(false);
            activeElement.classList.add('active');
        } else {
            // activeElement && activeElement.classList.remove("active");
        }
    }

    useEffect(() => {
        function watchScroll() {
            window.addEventListener('scroll', logit);
        }
        watchScroll();
        // Remove listener (like componentWillUnmount)
        return () => {
            window.removeEventListener('scroll', logit);
        };
    }, []);

    if (aStudyPages.length === undefined) {
        return <div>No Study Page Found</div>;
    }

    const scrollToId = (e) => {
        let element = document.getElementById(`${e.currentTarget.id}_right`);

        document
            .querySelectorAll('.CountryPlanDetailsText')
            .forEach(function (element) {
                element.classList.remove('active');
            });
        e.currentTarget.classList.add('active');

        var headerOffset = 145;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition =
            elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        });
    };

    return (
        <Layout>
            <SEO title="Study Abroad" />
            <StudyPageStyle>
                <section className="CountryPageTopSection">
                    <div className="TopSectionWrapper">
                        <div className="CountryImageWrapper">
                            <img
                                src={aStudyPage?.img?.url}
                                alt={aStudyPage?.img?.name}
                            />
                        </div>
                        <div className="CountryNameWrapper">
                            <h1>{aStudyPage?.heading}</h1>
                            <Link
                                to={aStudyPage?.redirect_to}
                                className="getConsultation"
                            >
                                <img
                                    src="https://s3-ap-southeast-1.amazonaws.com/ufaber-lms/custom/6db7fa315d5e456e8a407ef8c8d4f283.svg"
                                    alt="Headphone with mic icon"
                                />
                                Get Consultation
                            </Link>
                        </div>
                    </div>
                </section>
                <section class="CountryStudySettlePlan">
                    <div class="BottomSectionWrapper">
                        <div className="leftMenuCard" id="leftMenuCardID">
                            <ul>
                                {aStudyPage?.study_content.map((data) => (
                                    <li>
                                        <label
                                            htmlFor="educationalStruc"
                                            className="CountryPlanDetailsText "
                                            id={data.heading.replace(
                                                /\W/g,
                                                '_'
                                            )}
                                            onClick={scrollToId}
                                        >
                                            {data.heading}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <RightSection aStudyPage={aStudyPage} />
                    </div>
                </section>

                {aStudyPage.page === 'case-studie' && (
                    <HelpYouMakeChoiceCard
                        heading=" Are you Ready to Explore Global Possibilities?"
                        subHeading="Get Personalized Guidance from Our Expert Consultants "
                    />
                )}
            </StudyPageStyle>
        </Layout>
    );
};
const StudyPageStyle = styled.div`
    .scroll-heading {
        /* display: none; */
    }
    .CountryPageTopSection {
        background: linear-gradient(
            180deg,
            #fff0eb 0%,
            rgba(255, 240, 235, 0) 0.01%,
            #fff0eb 100%
        );
        padding: 11rem 10rem 3.5rem 10rem;
    }
    .TopSectionWrapper {
        display: flex;
    }
    .CountryImageWrapper {
        border-radius: 50%;
        height: 160px;
        width: 160px;
        display: flex;
        align-items: center;
        overflow: hidden;
        margin-right: 3rem;
    }
    .CountryImageWrapper img {
        min-width: 100%;
        max-width: 100%;
        min-height: 100%;
        max-height: 100%;
        object-fit: cover;
    }
    .CountryNameWrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 1.375rem;
    }
    .CountryNameWrapper h1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 3rem;
        line-height: 58px;
        color: #113a68;
    }
    .getConsultation {
        background: #f4805e;
        border-radius: 10px;
        color: #ffffff;
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 22px;
        padding: 1rem 1.75rem;
        display: flex;
        align-items: center;
        gap: 0.75rem;
    }

    .BottomSectionWrapper {
        margin-top: 2rem;

        justify-content: center;
        display: grid;
        grid-template-columns: repeat(2, auto);
        width: 100%;
        column-gap: 2rem;
        padding: 0rem 0.75rem;
    }
    .leftMenuCard {
        background: #ffffff;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        padding-top: 1.5rem;
        padding-bottom: 1.125rem;
        min-width: 261px;
        max-width: 261px;
        min-height: 468px;
        max-height: 468px;
        position: sticky;
        top: 8rem;
    }
    .leftMenuCard ul {
        list-style-type: none;
    }
    .leftMenuCard ul li {
        margin-bottom: 1.5rem;
    }
    input[type='radio'] {
        display: none;
    }
    .CountryPlanDetailsText {
        position: relative;
        color: #505050;
        font-weight: 300;
        font-size: 1rem;
        line-height: 19px;
        cursor: pointer;
        padding-left: 1rem;
        display: block;
    }

    .active::after {
        position: absolute;
        content: '';
        height: 100%;
        width: 4px;
        left: 0;
        top: 0;
        background-color: #113a68;
    }
    .CountryPlanDetailsText {
    }
    .active {
        font-weight: 700;
        color: #113a68;
    }
    .rightDetailsSectionCard {
        background: #ffffff;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        padding-top: 1rem;
        max-width: 827px;
        margin-bottom: 2rem;
    }
    @media (max-width: 990px) {
        .CountryImageWrapper {
            margin-right: 0rem;
        }
        .CountryNameWrapper {
            align-items: center;
            gap: 1rem;
        }
        .TopSectionWrapper {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1.5rem;
        }
        .BottomSectionWrapper {
            column-gap: 0rem;
            row-gap: 1.5rem;
            grid-template-columns: repeat(2, 1fr);
            margin-top: 0rem;
            padding: 0rem;
        }
        .leftMenuCard {
            grid-column: 1 / 3;
            min-width: 100%;
            max-width: 100%;
            min-height: 100%;
            max-height: 100%;
            box-shadow: none;
            border-radius: 0px;
            padding-top: 0rem;
            padding-bottom: 0rem;
            position: sticky;
            top: 5rem;
            z-index: 1;
        }
        .active {
            font-weight: 700;
            color: #113a68;
        }
        .rightDetailsSectionCard {
            max-width: 100%;
            width: 100%;
        }

        .leftMenuCard ul li {
            margin-bottom: 0rem;
            white-space: nowrap;
        }

        .leftMenuCard ul {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            overflow-x: scroll;
            width: 100%;
        }
        .leftMenuCard ul::-webkit-scrollbar {
            display: none;
        }
        .CountryPlanDetailsText {
            padding-left: 0rem;
            padding: 1.25rem;
            display: inline-block;
            font-weight: 500;
            font-size: 1rem;
            line-height: 19px;
            color: #113a68;
        }
        input[type='radio']:checked + .CountryPlanDetailsText::after {
            position: absolute;
            content: '';
            height: 4px;
            width: 100%;
            left: 0%;
            top: unset;
            bottom: 0%;
            background-color: #fc997c;
        }
        input[type='radio']:checked + .CountryPlanDetailsText {
            color: #f4805e;
        }
        .CountryPageTopSection {
            padding: 11rem 0rem 3.5rem 0rem;
        }
    }
    @media (max-width: 660px) {
        .TopUniversities {
            grid-template-columns: repeat(3, 1fr);
        }
        .UniversityOfAlverta {
            grid-row: 3;
            grid-column: 1 / 3;
        }
        .UniversityOfMontreal {
            grid-row: 3;
            grid-column: 2 / 4;
        }
    }
    @media (max-width: 500px) {
        .CountryPageTopSection {
            padding: 7rem 0rem 3.5rem 0rem;
        }
        .CountryImageWrapper {
            min-height: 96px;
            max-height: 96px;
            min-width: 96px;
            max-width: 96px;
        }
        .CountryNameWrapper h1 {
            font-weight: 700;
            font-size: 1.25rem;
            line-height: 24px;
        }
        .getConsultation {
            font-weight: 700;
            font-size: 0.875rem;
            line-height: 17px;
            padding: 0.75rem 1.71875rem;
        }
        .DetailsSectionContentWrapper {
            padding: 0rem 0.75rem 1.5rem 0.75rem;
        }
        .OverviewSectionContent,
        .EducStructContent,
        .PlacementIndustrySectionContent,
        .COEandROISectionContent,
        .SalaryLifestyleSectionContent,
        .EducStructListTag li {
            font-size: 0.875rem;
            line-height: 17px;
        }
        .EducStructTableWrapperHeading {
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 17px;
        }
        .EducStructTableOne {
            font-size: 0.875rem;
        }
        .EducStructTableOne th,
        .EducStructTableOne td {
            width: 65%;
            padding: 0.5rem;
        }
        .TopUniversitiesSectionTitle {
            font-weight: 700;
            font-size: 1rem;
            line-height: 19px;
        }
        .TopUniversitiesListWrapper > small {
            font-weight: 400;
            font-size: 0.6875rem;
            line-height: 13px;
            text-align: center;
            max-width: 85px;
        }
        .TopUniversitiesListImgWrapper {
            min-height: 80px;
            max-height: 80px;
            min-width: 80px;
            max-width: 80px;
        }
        .TopUniversities {
            margin-bottom: 4rem;
        }
        .EducStructTableTwo {
            font-size: 0.875rem;
            font-weight: 300;
        }
        .EducStructTableTwo thead tr > th,
        .EducStructTableTwo thead tr th > span {
            font-size: 0.875rem;
        }
        .EducStructTableTwo tbody tr td:first-child {
            font-weight: 400;
        }
        .EducStructTableTwo th,
        .EducStructTableTwo td {
            padding: 0.8rem 0.6rem;
        }
        .TopCoursesNote ol > li,
        .TopCoursesNote ul > li {
            font-weight: 300;
            font-size: 0.875rem;
            line-height: 17px;
        }
        .PlacementIndustryTableOne th,
        .PlacementIndustryTableOne td {
            padding: 0.8rem 0.6rem;
        }
        .PlacementIndustryTableOne,
        .ECOEandROITableOne {
            font-size: 0.875rem;
        }
        .ECOEandROITableOne th,
        .ECOEandROITableOne td {
            padding: 0.8rem 0.6rem;
        }
        .COIandROIContent,
        .SaveUpto50KText,
        .AffordHouseCarContent {
            font-weight: 500;
            font-size: 1rem;
            line-height: 19px;
        }
        .DetailsSectionContentWrapper ol > li {
            font-size: 0.875rem;
        }
        .PathwaytoCitizenshipContent {
            font-size: 0.875rem;
        }
        .ForMoreAboutPRInCanada {
            margin-top: 1rem;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 17px;
        }
    }
    @media (max-width: 320px) {
        .UniversityOfToronto {
            grid-column: 3/4;
        }
        .UniversityOfColumbia {
            grid-row: 3;
            grid-column: 1/2;
        }
        .UniversityOfAlverta {
            grid-row: 4;
            grid-column: 2;
        }
        .UniversityOfMontreal {
            grid-column: 3/4;
        }
    }

    .program_wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1.5rem;
        padding: 1rem;
        min-width: 21rem;
        background: #ffffff;
        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
        border-radius: 1rem;
        width: 50%;
    }
    .small-flag-img img {
        width: 70%;
    }

    .program_wrapper_top {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0.25rem;
    }

    .program_wrapper_top_heading1 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 29px;
        color: #113a68;
    }
    .program_wrapper_top_heading2 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 17px;
        color: #6a92c4;
    }
    .program_wrapper_bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .program_wrapper_bottom_country_img {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
    }
    @media (max-width: 500px) {
        .program_wrapper {
            min-width: 100%;
        }
    }

    @media (max-width: 325px) {
        .program_wrapper {
            padding: 0.8rem;
        }
    }
    @media (max-width: 313px) {
        .program_wrapper {
            min-height: 8.8rem;
        }
    }
`;

export default StudyPage;
