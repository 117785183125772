import { useState, useEffect } from 'react';
import { useTypewriter } from 'react-simple-typewriter';
import { navigate } from 'gatsby';
const isBrowser = typeof window !== 'undefined';
function getWindowDimensions() {
    if (isBrowser) {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height,
        };
    }
}

export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions()
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        if (isBrowser) {
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        } else {
            return { width: 0, height: 0 };
        }
    }, []);

    return windowDimensions;
}

export const useScrollPosition = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        if (isBrowser) {
            const updatePosition = () => {
                setScrollPosition(window.pageYOffset);
            };
            window.addEventListener('scroll', updatePosition);
            updatePosition();
            return () => window.removeEventListener('scroll', updatePosition);
        }
    }, []);

    return scrollPosition;
};

export const TypewriterHeader = (heading) => {
    let firstIndex = heading.indexOf('[');
    let lastIndex = heading.indexOf(']');
    let startStr,
        endStr,
        arr1,
        arr2,
        finalArray = [];
    if (firstIndex !== -1 && lastIndex !== -1) {
        startStr = heading.substr(0, firstIndex);
        endStr = heading.substr(lastIndex + 1);
        arr1 = heading.substr(firstIndex + 1);
        arr2 = arr1.substr(0, arr1.indexOf(']'));
        finalArray = arr2.split(',');
    }
    const [text] = useTypewriter({
        words: finalArray,
        loop: 0,
    });
    let newHeading =
        firstIndex !== -1 && lastIndex !== -1
            ? `${startStr} ${text} ${endStr}`
            : heading;

    return newHeading;
};

export const appendUrl = (redirect = '') => {
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
        let finalParam = '';
        let url_string = window.location.href;
        let url = new URL(url_string);
        let params = new URLSearchParams(url.search);
        let redirect_to_page = url.searchParams.get('redirect_to');

        if (redirect_to_page !== '') {
            params.delete('redirect_to');
        }

        if (redirect !== '') {
            finalParam = `?${params.toString()}&redirect_to=${redirect}`;
        } else {
            finalParam = `?${params.toString()}`;
        }

        navigate(finalParam);
    }
};

export const getRankMessage = (minRank, maxRank) => {
    let rankingMessage;
    if (minRank === maxRank) {
        if (minRank > 0 && minRank <= 5) {
            rankingMessage = 'Top 5';
        } else if (minRank > 5 && minRank <= 10) {
            rankingMessage = 'Top 10';
        } else if (minRank > 10 && minRank <= 50) {
            rankingMessage = 'Top 50';
        } else if (minRank > 50 && minRank <= 100) {
            rankingMessage = 'Top 100';
        } else if (minRank > 100 && minRank <= 500) {
            rankingMessage = 'Top 500';
        } else {
            rankingMessage = `#${minRank}-${maxRank}`;
        }
    }
    return rankingMessage;
};
