import React from 'react';
import StudyPage from '../components/studyPage/studyPage';
import SEO from '../components/seo';

const NewStudyPage = (data) => {
    let slug = data.pageContext.slug;

    return (
        <div>
            <StudyPage linkSlug={slug} />{' '}
        </div>
    );
};
export const Head = (data) => {
    let slug = data.pageContext.slug;


    return (
      <>
        {   
            slug === 'study-in-australia' ? (
          <>
            <SEO title="Study in Australia - Best Courses, Cost and Top Universities" description={'Want to Study Abroad in Australia? Explore Best Courses to study In Australia, cost of study, and the top universities in Australia. Get information from Abroad Ninja.'}/>
          </>
        ) : slug === 'study-in-canada' ? (
          <>
            <SEO title="Study In Canada for Indian Students - Courses, Cost and Universities" description={'Willing to study in Canada? Get the expert advise from best consultants for studying in Canada. Get Guidance on cost of the study, courses and the top univerisities.'}/>
          </>
        ) : slug === 'study-in-germany' ? (
          <>
            <SEO title="Study In Germany - Courses, Cost of study, Top Universities" description={'Do you want to study in Germany? Get the complete guidance on the cost of the study, best courses, top universities and scholarships for Indian students from the experts.'}/>
          </>
        ) : slug === 'study-in-uk' ? (
          <>
            <SEO 

              title="Study in UK | Abroad Ninja - Unlock Opportunities for Quality Education in the UK" 
              description="Explore the advantages of studying in the UK with Abroad Ninja's support. Discover renowned universities, diverse courses, scholarships, and a vibrant student life. Begin your journey towards academic excellence in the United Kingdom today!"
              og_type="website"
              og_url="https://abroadninja.in/study-page/study-in-uk"
              og_title="Study in UK | Abroad Ninja - Unlock Opportunities for Quality Education in the UK"
              og_description="Explore the advantages of studying in the UK with Abroad Ninja's support. Discover renowned universities, diverse courses, scholarships, and a vibrant student life. Begin your journey towards academic excellence in the United Kingdom today!"
              og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
              twitter_card="summary_large_image"
              twitter_url="https://abroadninja.in/study-page/study-in-uk"
              twitter_title="Study in UK | Abroad Ninja - Unlock Opportunities for Quality Education in the UK"
              twitter_description="Explore the advantages of studying in the UK with Abroad Ninja's support. Discover renowned universities, diverse courses, scholarships, and a vibrant student life. Begin your journey towards academic excellence in the United Kingdom today!"
              twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
              robots="index, follow"
              canonical="https://abroadninja.in/study-page/study-in-uk"
            
            />
          </>
        ) : slug === 'study-in-usa' ? (
          <>
            <SEO 
              title="Study in USA | Abroad Ninja - Your Gateway to American Education" 
              description="Explore the endless possibilities of studying in the USA with Abroad Ninja's guidance. Discover top universities, programs, scholarships, and the unique American experience. Start your academic journey in the USA today!"
              og_type="website"
              og_url="https://abroadninja.in/study-page/study-in-usa"
              og_title="Study in USA | Abroad Ninja - Your Gateway to American Education"
              og_description="Explore the endless possibilities of studying in the USA with Abroad Ninja's guidance. Discover top universities, programs, scholarships, and the unique American experience. Start your academic journey in the USA today!"
              og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
              twitter_card="summary_large_image"
              twitter_url="https://abroadninja.in/study-page/study-in-usa"
              twitter_title="Study in USA | Abroad Ninja - Your Gateway to American Education"
              twitter_description="Explore the endless possibilities of studying in the USA with Abroad Ninja's guidance. Discover top universities, programs, scholarships, and the unique American experience. Start your academic journey in the USA today!"
              twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
              robots="index, follow"
              canonical="https://abroadninja.in/study-page/study-in-usa"
            />
          </>
        ) : slug === 'australiasettle' ?(
          <>
            <SEO 
              title="Settling in Australia After Study | Abroad Ninja" 
              description="Discover the pathway to settling in Australia after your study journey with Abroad Ninja's assistance. Explore visa options, job opportunities, and lifestyle tips to thrive in the Land Down Under. Start your successful life in Australia today!"
              og_type="website"
              og_url="https://abroadninja.in/study-page/australiasettle"
              og_title="Settling in Australia After Study | Abroad Ninja"
              og_description="Discover the pathway to settling in Australia after your study journey with Abroad Ninja's assistance. Explore visa options, job opportunities, and lifestyle tips to thrive in the Land Down Under. Start your successful life in Australia today!"
              og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
              twitter_card="summary_large_image"
              twitter_url="https://abroadninja.in/study-page/australiasettle"
              twitter_title="Settling in Australia After Study | Abroad Ninja"
              twitter_description="Discover the pathway to settling in Australia after your study journey with Abroad Ninja's assistance. Explore visa options, job opportunities, and lifestyle tips to thrive in the Land Down Under. Start your successful life in Australia today!"
              twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
              robots="index, follow"
              canonical="https://abroadninja.in/study-page/australiasettle"
            />
          </>
        ) : slug === 'settle-in-canada' ?(
          <>
            <SEO 
              title="Settle in Canada After Study | Abroad Ninja" 
              description="Discover the pathway to settling in Canada after your study journey with Abroad Ninja's assistance. Explore post-graduation work permits, permanent residency options, and the Canadian lifestyle. "
              og_type="website"
              og_url="https://abroadninja.in/study-page/settle-in-canada"
              og_title="Settle in Canada After Study | Abroad Ninja"
              og_description="Discover the pathway to settling in Canada after your study journey with Abroad Ninja's assistance. Explore post-graduation work permits, permanent residency options, and the Canadian lifestyle. "
              og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
              twitter_card="summary_large_image"
              twitter_url="https://abroadninja.in/study-page/settle-in-canada"
              twitter_title="Settle in Canada After Study | Abroad Ninja"
              twitter_description="Discover the pathway to settling in Canada after your study journey with Abroad Ninja's assistance. Explore post-graduation work permits, permanent residency options, and the Canadian lifestyle. "
              twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
              robots="index, follow"
              canonical="https://abroadninja.in/study-page/settle-in-canada"
            />
          </>
        ) : (
          ""
        )}
      </>
    );
};
export default NewStudyPage;
