import React from 'react';
import Layout from '../components/layout.component.js';
import MasterInUSAndCanadaPages from '../components/MasterInUSAndCanadaPages/index';
import SEO from '../components/seo';

const MastersPage = (data) => {
  let slug = data.pageContext.slug;
  let packageID = data.pageContext.packageID;
  let type = data.pageContext.type;

  return (
    <Layout>
      <MasterInUSAndCanadaPages
        slug={slug}
        package_id={packageID}
        type={type}
      />
    </Layout>
  );
};
export const Head = (data) => {
  return (
    <>
      {   data.pageContext.slug[0] === 'masters-in-us-canada' ? (
        <>
          <SEO
            title="Master's Programs in US and Canada | Study Abroad with Abroad Ninja" 
            description="Explore Master's programs in the US and Canada offered by Abroad Ninja. Discover opportunities for higher education, research, and career advancement. Start your journey to excellence today!"
            og_type="website"
            og_url="https://abroadninja.in/program/masters-in-us-canada"
            og_title="Master's Programs in US and Canada | Study Abroad with Abroad Ninja"
            og_description="Explore Master's programs in the US and Canada offered by Abroad Ninja. Discover opportunities for higher education, research, and career advancement. Start your journey to excellence today!"
            og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            twitter_card="summary_large_image"
            twitter_url="https://abroadninja.in/program/masters-in-us-canada"
            twitter_title="Master's Programs in US and Canada | Study Abroad with Abroad Ninja"
            twitter_description="Explore Master's programs in the US and Canada offered by Abroad Ninja. Discover opportunities for higher education, research, and career advancement. Start your journey to excellence today!"
            twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            robots="index, follow"
            canonical="https://abroadninja.in/program/masters-in-us-canada"
            children={
              <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org/",
                    "@type": "WebSite",
                    "name": "AbroadNinja",
                    "url": "https://abroadninja.in/",
                    "potentialAction": 
                    {
                      "@type": "SearchAction",
                      "target": "https://abroadninja.in/{search_term_string}",
                      "query-input": "required name=search_term_string"
                    }
                  }
                `}
              </script>
            }
          />
        </>
      ) : data.pageContext.slug[0] === 'bachelor-in-uk' ? (
        <>
          <SEO
            title="Bachelor's Programs in the UK | Study Abroad with Abroad Ninja" 
            description="Explore Bachelor's programs in the UK offered by Abroad Ninja. Discover prestigious universities, rich academic traditions, and a vibrant cultural experience. Start your journey to academic excellence today!"
            og_type="website"
            og_url="https://abroadninja.in/program/bachelor-in-uk"
            og_title="Bachelor's Programs in the UK | Study Abroad with Abroad Ninja"
            og_description="Explore Bachelor's programs in the UK offered by Abroad Ninja. Discover prestigious universities, rich academic traditions, and a vibrant cultural experience. Start your journey to academic excellence today!"
            og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            twitter_card="summary_large_image"
            twitter_url="https://abroadninja.in/program/bachelor-in-uk"
            twitter_title="Bachelor's Programs in the UK | Study Abroad with Abroad Ninja"
            twitter_description="Explore Bachelor's programs in the UK offered by Abroad Ninja. Discover prestigious universities, rich academic traditions, and a vibrant cultural experience. Start your journey to academic excellence today!"
            twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            robots="index, follow"
            canonical="https://abroadninja.in/program/bachelor-in-uk"
            children={
              <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org/",
                    "@type": "WebSite",
                    "name": "AbroadNinja",
                    "url": "https://abroadninja.in/",
                    "potentialAction": 
                    {
                      "@type": "SearchAction",
                      "target": "https://abroadninja.in/{search_term_string}",
                      "query-input": "required name=search_term_string"
                    }
                  }
                `}
              </script>
            }
          />
        </>
      ) : data.pageContext.slug[0] === 'canada-immigration' ? (
        <>
          <SEO
            title="Canada Immigration Services | Abroad Ninja - Your Pathway to Canada" 
            description="Explore Canada immigration services offered by Abroad Ninja. Discover pathways to permanent residency, work permits, and study visas. Begin your journey to living in Canada today!"
            og_type="website"
            og_url="https://abroadninja.in/program/canada-immigration"
            og_title="Canada Immigration Services | Abroad Ninja - Your Pathway to Canada"
            og_description="Explore Canada immigration services offered by Abroad Ninja. Discover pathways to permanent residency, work permits, and study visas. Begin your journey to living in Canada today!"
            og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            twitter_card="summary_large_image"
            twitter_url="https://abroadninja.in/program/canada-immigration"
            twitter_title="Canada Immigration Services | Abroad Ninja - Your Pathway to Canada"
            twitter_description="Explore Canada immigration services offered by Abroad Ninja. Discover pathways to permanent residency, work permits, and study visas. Begin your journey to living in Canada today!"
            twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            robots="index, follow"
            canonical="https://abroadninja.in/program/canada-immigration"
            children={
              <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org/",
                    "@type": "WebSite",
                    "name": "AbroadNinja",
                    "url": "https://abroadninja.in/",
                    "potentialAction": 
                    {
                      "@type": "SearchAction",
                      "target": "https://abroadninja.in/{search_term_string}",
                      "query-input": "required name=search_term_string"
                    }
                  }
                `}
              </script>
            }
          />
        </>
      ) : data.pageContext.slug[0] === 'australia-study-visa-pr' ? (
        <>
          <SEO
            title="Study Visa and Permanent Residency (PR) | Abroad Ninja's Pathway to Your Australian Dream"
            description="Discover the seamless pathway to obtaining an Australia study visa and Permanent Residency (PR) with Abroad Ninja's comprehensive services. Explore study opportunities and immigration options in Australia. Start building your Australian dream today!"
            og_type="website"
            og_url="https://abroadninja.in/program/australia-study-visa-pr"
            og_title="Study Visa and Permanent Residency (PR) | Abroad Ninja's Pathway to Your Australian Dream"
            og_description="Discover the seamless pathway to obtaining an Australia study visa and Permanent Residency (PR) with Abroad Ninja's comprehensive services. Explore study opportunities and immigration options in Australia. Start building your Australian dream today!"
            og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            twitter_card="summary_large_image"
            twitter_url="https://abroadninja.in/program/australia-study-visa-pr"
            twitter_title="Study Visa and Permanent Residency (PR) | Abroad Ninja's Pathway to Your Australian Dream"
            twitter_description="Discover the seamless pathway to obtaining an Australia study visa and Permanent Residency (PR) with Abroad Ninja's comprehensive services. Explore study opportunities and immigration options in Australia. Start building your Australian dream today!"
            twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            robots="index, follow"
            canonical="https://abroadninja.in/program/australia-study-visa-pr"
            children={
              <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org/",
                    "@type": "WebSite",
                    "name": "AbroadNinja",
                    "url": "https://abroadninja.in/",
                    "potentialAction": 
                    {
                      "@type": "SearchAction",
                      "target": "https://abroadninja.in/{search_term_string}",
                      "query-input": "required name=search_term_string"
                    }
                  }
                `}
              </script>
            }
          />
        </>
      ) : data.pageContext.slug[0] === 'pr-in-australia' ? (
        <>
          <SEO
            title="Permanent Residency (PR) in Australia | Abroad Ninja's Pathway to Australian Immigration" 
            description="Explore the process of obtaining Permanent Residency (PR) in Australia with Abroad Ninja's assistance. Discover pathways, eligibility criteria, and benefits of Australian immigration. Start your journey towards Australian PR today!"
            og_type="website"
            og_url="https://abroadninja.in/program/pr-in-australia"
            og_title="Permanent Residency (PR) in Australia | Abroad Ninja's Pathway to Australian Immigration"
            og_description="Explore the process of obtaining Permanent Residency (PR) in Australia with Abroad Ninja's assistance. Discover pathways, eligibility criteria, and benefits of Australian immigration. Start your journey towards Australian PR today!"
            og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            twitter_card="summary_large_image"
            twitter_url="https://abroadninja.in/program/pr-in-australia"
            twitter_title="Permanent Residency (PR) in Australia | Abroad Ninja's Pathway to Australian Immigration"
            twitter_description="Explore the process of obtaining Permanent Residency (PR) in Australia with Abroad Ninja's assistance. Discover pathways, eligibility criteria, and benefits of Australian immigration. Start your journey towards Australian PR today!"
            twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            robots="index, follow"
            canonical="https://abroadninja.in/program/pr-in-australia"
            children={
              <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org/",
                    "@type": "WebSite",
                    "name": "AbroadNinja",
                    "url": "https://abroadninja.in/",
                    "potentialAction": 
                    {
                      "@type": "SearchAction",
                      "target": "https://abroadninja.in/{search_term_string}",
                      "query-input": "required name=search_term_string"
                    }
                  }
                `}
              </script>
            }
          />
        </>
      ) : data.pageContext.slug[0] === 'bachelors-in-us-canada' ? (
        <>
          <SEO
            title="Bachelor's Programs in the US and Canada | Study Abroad with Abroad Ninja" 
            description=" Explore Bachelor's programs in the US and Canada offered by Abroad Ninja. Discover diverse educational options, enriching campus life, and embark on a journey of academic growth. Enroll now!"
            og_type="website"
            og_url="https://abroadninja.in/program/bachelors-in-us-canada"
            og_title="Bachelor's Programs in the US and Canada | Study Abroad with Abroad Ninja"
            og_description=" Explore Bachelor's programs in the US and Canada offered by Abroad Ninja. Discover diverse educational options, enriching campus life, and embark on a journey of academic growth. Enroll now!"
            og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            twitter_card="summary_large_image"
            twitter_url="https://abroadninja.in/program/bachelors-in-us-canada"
            twitter_title="Bachelor's Programs in the US and Canada | Study Abroad with Abroad Ninja"
            twitter_description=" Explore Bachelor's programs in the US and Canada offered by Abroad Ninja. Discover diverse educational options, enriching campus life, and embark on a journey of academic growth. Enroll now!"
            twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            robots="index, follow"
            canonical="https://abroadninja.in/program/bachelors-in-us-canada"
            children={
              <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org/",
                    "@type": "WebSite",
                    "name": "AbroadNinja",
                    "url": "https://abroadninja.in/",
                    "potentialAction": 
                    {
                      "@type": "SearchAction",
                      "target": "https://abroadninja.in/{search_term_string}",
                      "query-input": "required name=search_term_string"
                    }
                  }
                `}
              </script>
            }
          />
        </>
      ) : data.pageContext.slug[0] === 'masters-in-uk-ireland' ? (
        <>
          <SEO
            title="Master's Programs in the UK and Ireland | Study Abroad with Abroad Ninja" 
            description="Explore Master's programs in the UK and Ireland offered by Abroad Ninja. Find your dream course, experience diverse cultures, and embark on a journey of academic excellence. Enroll now!"
            og_type="website"
            og_url="https://abroadninja.in/program/masters-in-uk-ireland"
            og_title="Master's Programs in the UK and Ireland | Study Abroad with Abroad Ninja"
            og_description="Explore Master's programs in the UK and Ireland offered by Abroad Ninja. Find your dream course, experience diverse cultures, and embark on a journey of academic excellence. Enroll now!"
            og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            twitter_card="summary_large_image"
            twitter_url="https://abroadninja.in/program/masters-in-uk-ireland"
            twitter_title="Master's Programs in the UK and Ireland | Study Abroad with Abroad Ninja"
            twitter_description="Explore Master's programs in the UK and Ireland offered by Abroad Ninja. Find your dream course, experience diverse cultures, and embark on a journey of academic excellence. Enroll now!"
            twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            robots="index, follow"
            canonical="https://abroadninja.in/program/masters-in-uk-ireland"
            children={
              <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org/",
                    "@type": "WebSite",
                    "name": "AbroadNinja",
                    "url": "https://abroadninja.in/",
                    "potentialAction": 
                    {
                      "@type": "SearchAction",
                      "target": "https://abroadninja.in/{search_term_string}",
                      "query-input": "required name=search_term_string"
                    }
                  }
                `}
              </script>
            }
          />
        </>
      ) : data.pageContext.slug[0] === 'study-in-germany' ? (
        <>
          <SEO
            title="Study in Germany | Explore Educational Opportunities with Abroad Ninja" 
            description="Discover educational opportunities to study in Germany with Abroad Ninja. Experience high-quality education, cultural diversity, and career prospects in Europe. Start your German academic journey today!"
            og_type="website"
            og_url="https://abroadninja.in/program/study-in-germany"
            og_title="Study in Germany | Explore Educational Opportunities with Abroad Ninja"
            og_description="Discover educational opportunities to study in Germany with Abroad Ninja. Experience high-quality education, cultural diversity, and career prospects in Europe. Start your German academic journey today!"
            og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            twitter_card="summary_large_image"
            twitter_url="https://abroadninja.in/program/study-in-germany"
            twitter_title="Study in Germany | Explore Educational Opportunities with Abroad Ninja"
            twitter_description="Discover educational opportunities to study in Germany with Abroad Ninja. Experience high-quality education, cultural diversity, and career prospects in Europe. Start your German academic journey today!"
            twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            robots="index, follow"
            canonical="https://abroadninja.in/program/study-in-germany"
            children={
              <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org/",
                    "@type": "WebSite",
                    "name": "AbroadNinja",
                    "url": "https://abroadninja.in/",
                    "potentialAction": 
                    {
                      "@type": "SearchAction",
                      "target": "https://abroadninja.in/{search_term_string}",
                      "query-input": "required name=search_term_string"
                    }
                  }
                `}
              </script>
            }
          />
        </>
      ) : data.pageContext.slug[0] === 'canada-immigration-dep-visa' ? (
        <>
          <SEO
            title="Canada Immigration and Dependent Visa Services | Abroad Ninja" 
            description="Explore Canada immigration and dependent visa services offered by Abroad Ninja. Discover pathways to migrate to Canada and bring your family. Begin your journey to a new life together in Canada today!"
            og_type="website"
            og_url="https://abroadninja.in/program/canada-immigration-dep-visa"
            og_title="Canada Immigration and Dependent Visa Services | Abroad Ninja"
            og_description="Explore Canada immigration and dependent visa services offered by Abroad Ninja. Discover pathways to migrate to Canada and bring your family. Begin your journey to a new life together in Canada today!"
            og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            twitter_card="summary_large_image"
            twitter_url="https://abroadninja.in/program/canada-immigration-dep-visa"
            twitter_title="Canada Immigration and Dependent Visa Services | Abroad Ninja"
            twitter_description="Explore Canada immigration and dependent visa services offered by Abroad Ninja. Discover pathways to migrate to Canada and bring your family. Begin your journey to a new life together in Canada today!"
            twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            robots="index, follow"
            canonical="https://abroadninja.in/program/canada-immigration-dep-visa"
            children={
              <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org/",
                    "@type": "WebSite",
                    "name": "AbroadNinja",
                    "url": "https://abroadninja.in/",
                    "potentialAction": 
                    {
                      "@type": "SearchAction",
                      "target": "https://abroadninja.in/{search_term_string}",
                      "query-input": "required name=search_term_string"
                    }
                  }
                `}
              </script>
            }
          />
        </>
      ) : data.pageContext.slug[0] === 'canada-immigration-tourist-visa' ? (
        <>
          <SEO
            title="Canada Immigration Tourist Visa | Explore Abroad Ninja's Services" 
            description="Discover how to obtain a tourist visa for Canada with Abroad Ninja's assistance. Explore the requirements, application process, and make your travel dreams come true. Start your journey to Canada today!"
            og_type="website"
            og_url="https://abroadninja.in/program/canada-immigration-tourist-visa"
            og_title="Canada Immigration Tourist Visa | Explore Abroad Ninja's Services"
            og_description="Discover how to obtain a tourist visa for Canada with Abroad Ninja's assistance. Explore the requirements, application process, and make your travel dreams come true. Start your journey to Canada today!"
            og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            twitter_card="summary_large_image"
            twitter_url="https://abroadninja.in/program/canada-immigration-tourist-visa"
            twitter_title="Canada Immigration Tourist Visa | Explore Abroad Ninja's Services"
            twitter_description="Discover how to obtain a tourist visa for Canada with Abroad Ninja's assistance. Explore the requirements, application process, and make your travel dreams come true. Start your journey to Canada today!"
            twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            robots="index, follow"
            canonical="https://abroadninja.in/program/canada-immigration-tourist-visa"
            children={
              <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org/",
                    "@type": "WebSite",
                    "name": "AbroadNinja",
                    "url": "https://abroadninja.in/",
                    "potentialAction": 
                    {
                      "@type": "SearchAction",
                      "target": "https://abroadninja.in/{search_term_string}",
                      "query-input": "required name=search_term_string"
                    }
                  }
                `}
              </script>
            }
          />
        </>
      ) : data.pageContext.slug[0] === 'germany-immigration' ? (
        <>
          <SEO
            title="Germany Immigration Services | Abroad Ninja - Your Pathway to Germany" 
            description="Explore Germany immigration services offered by Abroad Ninja. Discover pathways to work permits, study visas, and permanent residency in Germany. Begin your journey to a new life in Germany today!"
            og_type="website"
            og_url="https://abroadninja.in/program/germany-immigration"
            og_title="Germany Immigration Services | Abroad Ninja - Your Pathway to Germany"
            og_description="Explore Germany immigration services offered by Abroad Ninja. Discover pathways to work permits, study visas, and permanent residency in Germany. Begin your journey to a new life in Germany today!"
            og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            twitter_card="summary_large_image"
            twitter_url="https://abroadninja.in/program/germany-immigration"
            twitter_title="Germany Immigration Services | Abroad Ninja - Your Pathway to Germany"
            twitter_description="Explore Germany immigration services offered by Abroad Ninja. Discover pathways to work permits, study visas, and permanent residency in Germany. Begin your journey to a new life in Germany today!"
            twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            robots="index, follow"
            canonical="https://abroadninja.in/program/germany-immigration"
            children={
              <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org/",
                    "@type": "WebSite",
                    "name": "AbroadNinja",
                    "url": "https://abroadninja.in/",
                    "potentialAction": 
                    {
                      "@type": "SearchAction",
                      "target": "https://abroadninja.in/{search_term_string}",
                      "query-input": "required name=search_term_string"
                    }
                  }
                `}
              </script>
            }
          />
        </>
      ) : data.pageContext.slug[0] === 'masters-in-australia-nz' ? (
        <>
          <SEO
            title="Master's Programs in Australia and New Zealand | Study Abroad with Abroad Ninja" 
            description="Explore Master's programs in Australia and New Zealand offered by Abroad Ninja. Discover world-class education, vibrant campuses, and exciting career prospects. Start your journey to academic excellence today!"
            og_type="website"
            og_url="https://abroadninja.in/program/masters-in-australia-nz"
            og_title="Master's Programs in Australia and New Zealand | Study Abroad with Abroad Ninja"
            og_description="Explore Master's programs in Australia and New Zealand offered by Abroad Ninja. Discover world-class education, vibrant campuses, and exciting career prospects. Start your journey to academic excellence today!"
            og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            twitter_card="summary_large_image"
            twitter_url="https://abroadninja.in/program/masters-in-australia-nz"
            twitter_title="Master's Programs in Australia and New Zealand | Study Abroad with Abroad Ninja"
            twitter_description="Explore Master's programs in Australia and New Zealand offered by Abroad Ninja. Discover world-class education, vibrant campuses, and exciting career prospects. Start your journey to academic excellence today!"
            twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            robots="index, follow"
            canonical="https://abroadninja.in/program/masters-in-australia-nz"
            children={
              <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org/",
                    "@type": "WebSite",
                    "name": "AbroadNinja",
                    "url": "https://abroadninja.in/",
                    "potentialAction": 
                    {
                      "@type": "SearchAction",
                      "target": "https://abroadninja.in/{search_term_string}",
                      "query-input": "required name=search_term_string"
                    }
                  }
                `}
              </script>
            }
          />
        </>
      ) : data.pageContext.slug[0] === 'bachelor-in-australia-nz' ? (
        <>
          <SEO
            title="Bachelor's Programs in Australia and New Zealand | Study Abroad with Abroad Ninja" 
            description="Explore Bachelor's programs in Australia and New Zealand offered by Abroad Ninja. Discover top universities, unique academic experiences, and a vibrant student community. Start your journey to academic excellence today!"
            og_type="website"
            og_url="https://abroadninja.in/program/bachelor-in-australia-nz"
            og_title="Bachelor's Programs in Australia and New Zealand | Study Abroad with Abroad Ninja"
            og_description="Explore Bachelor's programs in Australia and New Zealand offered by Abroad Ninja. Discover top universities, unique academic experiences, and a vibrant student community. Start your journey to academic excellence today!"
            og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            twitter_card="summary_large_image"
            twitter_url="https://abroadninja.in/program/bachelor-in-australia-nz"
            twitter_title="Bachelor's Programs in Australia and New Zealand | Study Abroad with Abroad Ninja"
            twitter_description="Explore Bachelor's programs in Australia and New Zealand offered by Abroad Ninja. Discover top universities, unique academic experiences, and a vibrant student community. Start your journey to academic excellence today!"
            twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            robots="index, follow"
            canonical="https://abroadninja.in/program/bachelor-in-australia-nz"
            children={
              <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org/",
                    "@type": "WebSite",
                    "name": "AbroadNinja",
                    "url": "https://abroadninja.in/",
                    "potentialAction": 
                    {
                      "@type": "SearchAction",
                      "target": "https://abroadninja.in/{search_term_string}",
                      "query-input": "required name=search_term_string"
                    }
                  }
					`}
              </script>
            }
          />
        </>
      ) : data.pageContext.slug[0] === 'australia-visitor-visa' ? (
        <>
          <SEO
            title="Australia Visitor Visa Services | Abroad Ninja" 
            description="Discover Australia visitor visa services provided by Abroad Ninja. Learn how to experience the breathtaking beauty, diverse culture, and unique attractions of Australia. Start planning your visit today!"
            og_type="website"
            og_url="https://abroadninja.in/program/australia-visitor-visa"
            og_title="Australia Visitor Visa Services | Abroad Ninja"
            og_description="Discover Australia visitor visa services provided by Abroad Ninja. Learn how to experience the breathtaking beauty, diverse culture, and unique attractions of Australia. Start planning your visit today!"
            og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            twitter_card="summary_large_image"
            twitter_url="https://abroadninja.in/program/australia-visitor-visa"
            twitter_title="Australia Visitor Visa Services | Abroad Ninja"
            twitter_description="Discover Australia visitor visa services provided by Abroad Ninja. Learn how to experience the breathtaking beauty, diverse culture, and unique attractions of Australia. Start planning your visit today!"
            twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            robots="index, follow"
            canonical="https://abroadninja.in/program/australia-visitor-visa"
            children={
              <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org/",
                    "@type": "WebSite",
                    "name": "AbroadNinja",
                    "url": "https://abroadninja.in/",
                    "potentialAction": 
                    {
                      "@type": "SearchAction",
                      "target": "https://abroadninja.in/{search_term_string}",
                      "query-input": "required name=search_term_string"
                    }
                  }
                `}
              </script>
            }
          />
        </>
      ) : data.pageContext.slug[0] === 'australia-dep-visa' ? (
        <>
          <SEO
            title="Australia Dependent Visa Services | Abroad Ninja" 
            description="Explore Australia dependent visa services offered by Abroad Ninja. Learn how to bring your family together and create lasting memories in Australia."
            og_type="website"
            og_url="https://abroadninja.in/program/australia-dep-visa"
            og_title="Australia Dependent Visa Services | Abroad Ninja"
            og_description="Explore Australia dependent visa services offered by Abroad Ninja. Learn how to bring your family together and create lasting memories in Australia."
            og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            twitter_card="summary_large_image"
            twitter_url="https://abroadninja.in/program/australia-dep-visa"
            twitter_title="Australia Dependent Visa Services | Abroad Ninja"
            twitter_description="Explore Australia dependent visa services offered by Abroad Ninja. Learn how to bring your family together and create lasting memories in Australia."
            twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            robots="index, follow"
            canonical="https://abroadninja.in/program/australia-dep-visa"
            children={
              <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org/",
                    "@type": "WebSite",
                    "name": "AbroadNinja",
                    "url": "https://abroadninja.in/",
                    "potentialAction": 
                    {
                      "@type": "SearchAction",
                      "target": "https://abroadninja.in/{search_term_string}",
                      "query-input": "required name=search_term_string"
                    }
                  }
                `}
              </script>
            }
          />
        </>
      ) : data.pageContext.slug[0] === 'masters-usa' ? (
            <>
              <SEO
                title="Master's Programs in the USA | Study Abroad with Abroad Ninja" 
                description="Explore Master's programs in the USA offered by Abroad Ninja. Gain insights into top universities, application procedures, and scholarships. Start your journey to a prestigious US education today!"
                og_type="website"
                og_url="https://abroadninja.in/program/masters-usa"
                og_title="Master's Programs in the USA | Study Abroad with Abroad Ninja"
                og_description="Explore Master's programs in the USA offered by Abroad Ninja. Gain insights into top universities, application procedures, and scholarships. Start your journey to a prestigious US education today!"
                og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
                twitter_card="summary_large_image"
                twitter_url="https://abroadninja.in/program/masters-usa"
                twitter_title="Master's Programs in the USA | Study Abroad with Abroad Ninja"
                twitter_description="Explore Master's programs in the USA offered by Abroad Ninja. Gain insights into top universities, application procedures, and scholarships. Start your journey to a prestigious US education today!"
                twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
                robots="index, follow"
                canonical="https://abroadninja.in/program/masters-usa"
                children={
                  <script type="application/ld+json">
                    {`
                      {
                        "@context": "https://schema.org/",
                        "@type": "WebSite",
                        "name": "AbroadNinja",
                        "url": "https://abroadninja.in/",
                        "potentialAction": 
                        {
                          "@type": "SearchAction",
                          "target": "https://abroadninja.in/{search_term_string}",
                          "query-input": "required name=search_term_string"
                        }
                      }
                    `}
                  </script>
                }
              />
            </>
      ) : data.pageContext.slug[0] === 'bachelors-usa' ?(
            <>
              <SEO
                title="Bachelor's Programs in the USA | Study Abroad with Abroad Ninja" 
                description="Explore Bachelor's programs in the USA offered by Abroad Ninja. Experience world-class education, diverse academic opportunities, and a fulfilling student life. Start your American academic journey today!"
                og_type="website"
                og_url="https://abroadninja.in/program/bachelors-usa"
                og_title="Bachelor's Programs in the USA | Study Abroad with Abroad Ninja"
                og_description="Explore Bachelor's programs in the USA offered by Abroad Ninja. Experience world-class education, diverse academic opportunities, and a fulfilling student life. Start your American academic journey today!"
                og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
                twitter_card="summary_large_image"
                twitter_url="https://abroadninja.in/program/bachelors-usa"
                twitter_title="Bachelor's Programs in the USA | Study Abroad with Abroad Ninja"
                twitter_description="Explore Bachelor's programs in the USA offered by Abroad Ninja. Experience world-class education, diverse academic opportunities, and a fulfilling student life. Start your American academic journey today!"
                twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
                robots="index, follow"
                canonical="https://abroadninja.in/program/bachelors-usa"
                children={
                  <script type="application/ld+json">
                    {`
                      {
                        "@context": "https://schema.org/",
                        "@type": "WebSite",
                        "name": "AbroadNinja",
                        "url": "https://abroadninja.in/",
                        "potentialAction": 
                        {
                          "@type": "SearchAction",
                          "target": "https://abroadninja.in/{search_term_string}",
                          "query-input": "required name=search_term_string"
                        }
                      }
                    `}
                  </script>
                }
              />
            </>
      ) : data.pageContext.slug[0] === 'uk-nurse-visa' ?(
        <>
          <SEO
            title="UK Healthcare Worker Visa | UK Nurse Visa - Abroad Ninja" 
            description="Navigate the requirements and application process for a UK Nurse Healthcare Worker Visa, opening doors to a fulfilling career in nursing in the United Kingdom."
            og_type="website"
            og_url="https://abroadninja.in/program/uk-nurse-visa"
            og_title="UK Healthcare Worker Visa | UK Nurse Visa - Abroad Ninja"
            og_description="Navigate the requirements and application process for a UK Nurse Healthcare Worker Visa, opening doors to a fulfilling career in nursing in the United Kingdom."
            og_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            twitter_card="summary_large_image"
            twitter_url="https://abroadninja.in/program/uk-nurse-visa"
            twitter_title="UK Healthcare Worker Visa | UK Nurse Visa - Abroad Ninja"
            twitter_description="Navigate the requirements and application process for a UK Nurse Healthcare Worker Visa, opening doors to a fulfilling career in nursing in the United Kingdom."
            twitter_image="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/8761e1d04d2176dae02d414454d0dda0.1689574323310176"
            robots="index, follow"
            canonical="https://abroadninja.in/program/uk-nurse-visa"
            children={
              <script type="application/ld+json">
                {`
                  {
                    "@context": "https://schema.org/",
                    "@type": "WebSite",
                    "name": "AbroadNinja",
                    "url": "https://abroadninja.in/",
                    "potentialAction": 
                    {
                      "@type": "SearchAction",
                      "target": "https://abroadninja.in/{search_term_string}",
                      "query-input": "required name=search_term_string"
                    }
                  }
                `}
              </script>
            }
          />
        </>
      ):(
        ''
      )}
    </>
  );
};
export default MastersPage;
