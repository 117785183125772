import React from 'react';

function EligibilityTable({ data }) {
    return (
        <table className="eligibility_table">
            <tbody>
                {data.map((section, index) => {
                    if (section?.isSpouse === false) {
                        return;
                    }
                    return (
                        <React.Fragment key={index}>
                            {section.sectionTitle && (
                                <tr className="table-heading">
                                    <td>{section.sectionTitle}</td>
                                    <td>{section.columns[0]}</td>
                                    <td>{section.columns[1]}</td>
                                    <td>{section.columns[2]}</td>
                                </tr>
                            )}
                            {section?.isCibilScore && (
                                <ScoreTableRows data={section.rows} />
                            )}

                            {!section?.isCibilScore &&
                                section.rows.map((row, rowIndex) => {
                                    const className = section?.isTotal
                                        ? 'total'
                                        : '';

                                    let obj = Object.keys(row).filter(
                                        (i) =>
                                            i !== 'type_val' &&
                                            i !== 'type_your_point'
                                    );

                                    return (
                                        <tr
                                            key={rowIndex}
                                            className={className}
                                        >
                                            {obj.map((cell, cellIndex) => (
                                                <td
                                                    key={cellIndex}
                                                    dangerouslySetInnerHTML={{
                                                        __html: row[cell],
                                                    }}
                                                />
                                            ))}
                                        </tr>
                                    );
                                })}
                            {index < data.length - 1 && (
                                <tr>
                                    <td colSpan="4"></td>
                                </tr>
                            )}
                        </React.Fragment>
                    );
                })}
            </tbody>
        </table>
    );
}

const ScoreTableRows = ({ data }) => {
    return (
        <>
            {data.map((item, index) => (
                <tr key={index}>
                    <td>{item.title}</td>
                    <td className="border-right">{item.value}</td>
                    {index === 0 && (
                        <>
                            <td rowSpan={data.length}>{item.your_point}</td>
                            <td rowSpan={data.length}>{item.maximum_point}</td>
                        </>
                    )}
                </tr>
            ))}
        </>
    );
};

export default EligibilityTable;
